import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Avatar_male, Avatar_female} from "../../../../Routes/ImagePath";
import AllEmployeeAddPopup from "../../../../components/modelpopup/AllEmployeeAddPopup";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import DeleteModal from "../../../../components/modelpopup/DeleteModal";
import EmployeeListFilter from "./EmployeeListFilter";
import { useSelector } from "react-redux";
import { multiArray126ToAttendaceObject, multiArrayToObject126, object126ToColumns } from "../../../../utils/s126/arrays126";
import { useDispatch } from "react-redux";
import { currentEmployee } from "../../../../salary126";
import Header10 from "../records/record10Header/Header10";
const AllEmployee = () => {
  const dispatch=useDispatch()
  const navigate=useNavigate()
  const salary = useSelector((state) => state.salary);
  const type20=salary.type20

  const [employeeData,setData]=useState([])
  const [allEmployees,setAllEmployees]=useState([])

  useEffect(()=>{
    if(type20.length>0){
      const dataList=multiArray126ToAttendaceObject(type20)
      setData(dataList)
      setAllEmployees(dataList)
    
    }
  },[type20])

  const handleEmployeeProfile=(emp)=>{
    dispatch(currentEmployee(emp))
    navigate("/126/employees/profile")
  }

  const handleSearch=(text)=>{
    if(!text) {setData(allEmployees)

      return
    }
    const list=allEmployees.filter(item=>item.firstName.includes(text) || item.lastName.includes(text) ||item.idNumber.includes(text))
    setData(list)
  }

  return (
    <div>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="موظفين"
            title="الرئيسية"
            subtitle="موظفين"
            modal="#add_employee"
            name="اضافة موظف"
            Linkname="/126/employees"
            Linkname1="/126/employees-list"
          />
          <Header10 />
           <EmployeeListFilter search={handleSearch}/>

          <div className="row">
            {employeeData.map((employee) => (
              <div
                className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3"
                key={employee.id}
              >
                <div className="profile-widget">
                  <div className="profile-img" onClick={()=>handleEmployeeProfile(employee)}>
                    <Link to="#" className="avatar">
                      <img src={employee.gender==1?Avatar_male : Avatar_female} alt=" Profile Picture" />
                    </Link>
                  </div>
                  {/* <div className="dropdown profile-action">
                    <Link
                      to="#"
                      className="action-icon dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="material-icons">more_vert</i>
                    </Link>
                    <div className="dropdown-menu dropdown-menu-right">
                      <Link
                        className="dropdown-item"
                        to="#"
                        data-bs-toggle="modal"
                        data-bs-target="#edit_employee"
                      >
                        <i className="fa fa-pencil m-r-5" /> Edit
                      </Link>
                      <Link
                        className="dropdown-item"
                        to="#"
                        data-bs-toggle="modal"
                        data-bs-target="#delete"
                      >
                        <i className="fa-regular fa-trash-can m-r-5" /> Delete
                      </Link>
                    </div>
                  </div> */}
                  <h4 className="user-name m-t-10 mb-0 text-ellipsis" onClick={()=>handleEmployeeProfile(employee)}>
                    <Link to="#">{employee.firstName} {employee.lastName}</Link>
                  </h4>
                  <div className="small text-muted">{employee.idNumber}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <AllEmployeeAddPopup />
      {/* Delete Modal */}
      <DeleteModal Name="Delete Employee" />
      {/* Delete Modal */}
    </div>
  );
};

export default AllEmployee;
