import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  iniFile: {},
  bkmvFile: {},
  accounts: {},
  currentDocument: { header: {}, details: [] },
  trialBalanceClassifications: {},
};

export const openFormatSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    iniFile: (state, action) => {
      state.iniFile = action.payload;
    },
    bkmvFile: (state, action) => {
      state.bkmvFile = action.payload;
    },
    accounts: (state, action) => {
      state.accounts = action.payload;
    },
    currentDocument: (state, action) => {
      state.currentDocument = action.payload;
    },
    trialBalanceClassifications: (state, action) => {
      state.trialBalanceClassifications = action.payload;
    },
  },
});

export const {
  bkmvFile,
  iniFile,
  accounts,
  currentDocument,
  trialBalanceClassifications,
} = openFormatSlice.actions;
export default openFormatSlice.reducer;
