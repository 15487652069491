import React from "react";
import Breadcrumbs from "../../../../../components/Breadcrumbs";
import Header10 from "./Header10";
const Record10Header = () => {
  return (
    <>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs
            maintitle="Record 10"
            title="الرئيسية"
            subtitle="سجل 10"
          />
          {/* /Page Header */}
          {/* Content Starts */}
          <Header10 />
          {/* /Content End */}
        </div>
        {/* /Page Content */}
      </div>
    </>
  );
};
export default Record10Header;
