import { document6111ByFinalReportsCode1 } from "../../views/pages/6111/Document6111"

export const restructure6111=(data,data2)=>{
    const list=[]
const tmpList=[]

document6111ByFinalReportsCode1.forEach(item=>{
item.data.forEach(el=>{
    tmpList.push(el)
})
})

tmpList.forEach(item=>{
        data2?
        list.push({
            ...item, 
            amount1:data[item.details6111] || 0,
            amount2:data2[item.details6111] || 0,
        }):
        list.push({
            ...item, 
            amount:data[item.details6111] || 0,
           
        })
    })
return list
}

export const sumBalanceByPropertyValue = (arr, property,value) => {
    let total = 0;
    arr.forEach((item) => {
      if (item[property] == value) total += item.amount
    });
    return total;
  };