import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { base_url } from "../../../base_urls";
import Header from "../../layout/Header";
import { RiLock2Fill } from "react-icons/ri";
import { useSelector } from "react-redux";
import { arrayToObject126 } from "../../../utils/s126/arrays126";
const FtIntro = () => {
  const ahid_header=useSelector(state=>state.ahid).iniFile?.headerList
  const salary_header_arr=useSelector(state=>state.salary).type10 
  const salary_header=salary_header_arr.length?arrayToObject126(salary_header_arr):{}
  const balance=useSelector(state=>state.balance).data6111
  const balancePrevious = useSelector((state) => state.balance).data6111PreviousYear;
  
  const data=[
      {
        id: 1,
        title: "نظام تحليل Open Fromat",
        department: "تحليل الملف الموحد",
        type: "دخول ",
        location: ahid_header?.businessName || "",
        salary:ahid_header?.Identification || "",
        posted: ahid_header?.taxYear || "",
        path: "/open-format",
      },
      {
        id: 2,
        title: "نظام تحليل رواتب",
        department: "تحليل ملف 126 ",
        type: "دخول",
        location: salary_header?.employerName || "",
        salary: salary_header?.taxFileNumber || "",
        posted: salary_header?.taxYear || "",
        path: "/126/import",
      },
      {
        id: 3,
        title: "نظام تحليل الميزانيات العمومية ",
        department: "تحليل ملف 6111",
        type: "دخول",
        location:`${balance?.companyName || ""} - ${balancePrevious?.companyName || ""}`,
        salary: `${balance?.caseNum || ""}  - ${balancePrevious?.caseNum || ""}`  ,
        posted: `${balance?.taxYear || ""} - ${balancePrevious?.taxYear || ""}` ,
        path: "/6111/import",
      },
      {
        id: 4,
        title: "نظام تحليل بطاقات الائتمان  ",
        department: "بطاقات ائتمان ",
        type: "locked",
        location: "California",
        salary: "$35,000 - $38,000",
        posted: "2 days ago",
        path: "#",
      },
      {
        id: 5,
        title: "نظام فحص البنوك ",
        department: "فحص بنوك ",
        type: "locked",
        location: "California",
        salary: "$35,000 - $38,000",
        posted: "2 days ago",
        path: "#",
      },
      {
        id: 6,
        title: "فحص البيانات بواسطة الذكاء الاصطناعي",
        department: "تحاليل ذكية",
        type: "locked",
        location: "California",
        salary: "$35,000 - $38,000",
        posted: "2 days ago",
        path: "#",
      },
    ];

  return (
    <div>
      <div className="main-wrapper">
        <Header />
        <div className="page-wrapper job-wrapper">
          {/* Page Content */}
          <div className="content container">
            {/* Page Header */}
            <Breadcrumbs
              maintitle="مرحبا بك في Fintrack"
              title="الرئيسية"
              subtitle="Ft-Intro"
            />
            {/* /Page Header */}
            <div className="row">
              <div className="row">
                {Array.isArray(data) && data.length > 0
                  ? data.map((job, index) => (
                    <div className="col-md-6" key={index}>
                      <Link className="job-list" to={job.path}>
                        <div className="job-list-det">
                          <div className="job-list-desc">
                            <h3 className="job-list-title">{job.title}</h3>
                            <h4 className="job-department">
                              {job.department}
                            </h4>
                          </div>
                          <div className="job-type-info">
                            <span className="job-types">{job.type == "locked" ? <RiLock2Fill /> : job.type} </span>
                          </div>
                        </div>
                        <div className="job-list-footer">
                          <ul>
                            <li>
                              <i className="fa-solid fa-signs-post" />{" "}
                              {job.location}
                            </li>
                            <li>
                              <i className="fa-regular fa-file" />{" "}
                              {job.salary}
                            </li>
                            <li>
                              <i className="fa-regular fa-clock" />{" "}
                              {job.posted}
                            </li>
                          </ul>
                        </div>
                      </Link>
                    </div>
                  ))
                  : " <p>No job listings available.</p>"}
              </div>
            </div>
          </div>
        </div>
        {/* /Page Wrapper */}
      </div>
    </div>
  );
};

export default FtIntro;
