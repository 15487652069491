/* eslint-disable no-unused-expressions */

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table } from "antd";
import { useTranslation } from "react-i18next";
import DeleteModal from "../../../../components/modelpopup/DeleteModal";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import { AdminLeaveAddModelPopup } from "../../../../components/modelpopup/AdminLeaveModelPopup";
import SearchBox from "../../../../components/SearchBox";
import { useSelector } from "react-redux";
import { arrayToObject126, calculateYearsBetweenDates, multiArrayToObject126 } from "../../../../utils/s126/arrays126";
import { formatNumbers, getDate } from "../../../../utils/openformat/numbers";
import EmployeeListFilter from "../Employees/EmployeeListFilter";
import Header10 from "../records/record10Header/Header10";
const PayrollCost = () => {
  const salary = useSelector((state) => state.salary);
  const type10=arrayToObject126(salary.type10)
  const type20=multiArrayToObject126(salary.type20)
  const {t,i18n}=useTranslation()
  
  const [total,setTotal]=useState(0)
  const [list,setList]=useState(type20)


  useEffect(()=>{
    // setList(type20)
    handleGetTotal()
  },[type20])

  const handleSearch=(text)=>{
    if(!text) {setList(type20)

      return
    }
    const filtered=type20.filter(item=>item.firstName.includes(text) || item.lastName.includes(text) ||item.idNumber.includes(text))
    setList(filtered)
  }

  const handleGetTotal=(monthlySalary,startDate)=>{
    let tot=0
    type20.forEach((item)=>{
      const number= item.normalTax /12 * calculateYearsBetweenDates(item.startDate,`${type10.taxYear}1231`) || 0
      tot+=number
    })
    
    setTotal(tot)
    // return number
  }

  const columns = [
    {
      title: "الاسم",
      dataIndex: "firstName",
      render: (text, record) => (
        <span className="table-avatar">
          <Link to="/profile" className="avatar">
            <img alt="" src={record.image} />
          </Link>
          <span className="table-avatar text-primary">
            <Link to="#" className="avatar"></Link>
            {text}
          </span>
        </span>
      ),
      sorter: (a, b) => a.name.length - b.name.length,
    },

    {
      title: "العائلة",
      dataIndex: "lastName",
      render: (text) => <span>{text}</span>,

      sorter: (a, b) => a.leavetype.length - b.leavetype.length,
    },
    {
      title: "رقم الهوية",
      dataIndex: "idNumber",
      render: (text) => <span>{text}</span>,

      sorter: (a, b) => a.leavetype.length - b.leavetype.length,
    },

    {
      title: "تاريخ بدء العمل",
      dataIndex: "startDate",
      render: (text) => <span>{getDate(text)}</span>,
      sorter: (a, b) => a.from.length - b.from.length,
    },
    {
      title: "حتى",
      dataIndex: "",
      render: () => <span>{`31/12/${type10.taxYear}`}</span>,

      sorter: (a, b) => a.to.length - b.to.length,
    },

    {
      title: "سنوات الخدمة",
      dataIndex: "startDate",
      render: (text) => <span>{calculateYearsBetweenDates(text,`${type10.taxYear}1231`)}</span>,

      // sorter: (a, b) => a.noofdays.length - b.noofdays.length,
    },
    {
      title: "معدل الراتب الشهري",
      dataIndex: "normalTax",
      render: (text,record) => <span>{formatNumbers((text*1)/12) }</span>,

      sorter: (a, b) => a.noofdays.length - b.noofdays.length,
    },

    {
      title: "مبلغ المخصصات",
      dataIndex: "normalTax",
      render: (text,record) => <span>{formatNumbers(calculateYearsBetweenDates(record.startDate,`${type10.taxYear}1231`)* (text*1)/12)}</span>,
      sorter: (a, b) => a.reason.length - b.reason.length,
    },
   
  ];

 
  const statsData = [
    {
      id: 1,
      title: "الشركة",
      value: type10.employerName,
    },
    {
      id: 2,
      title: "السنة المالية",
      value: type10.taxYear,
      subText: "",
    },
    {
      id: 3,
      title: "إجمالي المخصصات",
      value: formatNumbers(total),
      subText: "شيكل",
    },
    {
      id: 4,
      title: "عدد الموظفين",
      value: type20.length,
    },
  ];


  return (
    <>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs
            maintitle={t("payrollCost")}
            title="الرئيسية"
            subtitle={t("payrollCost")}
            modal="#add_leave"
            name="Add Leave"
          />
          {/* /Page Header */}
          <Header10 />
          {/* Leave Statistics */}
          <div className="row">
            {statsData.map((stat, index) => (
              <div className="col-md-3 d-flex" key={index}>
                <div className="stats-info w-100">
                  <h6>{stat.title}</h6>
                  {stat.subText ? (
                    <h4 className="text-primary">
                      {stat.value} <span>{stat.subText}</span>
                    </h4>
                  ) : (
                    <h4 className="text-primary">{stat.value}</h4>
                  )}
                </div>
              </div>
            ))}
          </div>
          <EmployeeListFilter search={handleSearch}/>
          {/* <LeaveFilter /> */}
          {/* /Leave Statistics */}
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <SearchBox />
                <Table
                  columns={columns}
                  dataSource={list}
                  className="table-striped page-a-table"
                  rowKey={(record) => record.id}
                />
              </div>
            </div>
          </div>
        </div>
        {/* /Page Content */}
        {/* Add Leave Modal */}
        <AdminLeaveAddModelPopup />
        {/* /Add Leave Modal */}

        {/* Delete Modal */}
        <DeleteModal Name="Delete Leaves" />
        {/* Delete Modal */}
      </div>
    </>
  );
};

export default PayrollCost;
