export const quickCheckReducer = (state, action) => {
  switch (action.type) {
    case "tax":
      return { ...state, tax: action.payload };
    case "salaryTax":
      return { ...state, salaryTax: action.payload };
    case "salarySavings":
      return { ...state, salarySavings: action.payload };
    case "bank":
      return { ...state, bank: action.payload };
    case "cash":
      return { ...state, cash: action.payload };
    case "stock":
      return { ...state, stock: action.payload };
    case "share":
      return { ...state, share: action.payload };
    case "taxLedger":
      return { ...state, taxLedger: action.payload };
    case "salaryTaxLedger":
      return { ...state, salaryTaxLedger: action.payload };
    case "salarySavingsLedger":
      return { ...state, salarySavingsLedger: action.payload };
    case "bankLedger":
      return { ...state, bankLedger: action.payload };
    case "cashLedger":
      return { ...state, cashLedger: action.payload };
    case "stockLedger":
      return { ...state, stockLedger: action.payload };
    case "shareLedger":
      return { ...state, shareLedger: action.payload };
    case "fill":
      return { ...action.payload };
    case "reset":
      return {
        tax: 0,
        salaryTax: 0,
        salarySavings: 0,
        bank: 0,
        cash: 0,
        stock: 0,
        share: 0,
        taxLedger: 0,
        salaryTaxLedger: 0,
        salarySavingsLedger: 0,
        bankLedger: 0,
        cashLedger: 0,
        stockLedger: 0,
        shareLedger: 0,
      };
    default:
      return state;
  }
};
