import React, { useEffect, useState } from 'react'
import Breadcrumbs from '../../../../components/Breadcrumbs'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { restructure6111, sumBalanceByPropertyValue } from '../../../../utils/a6111/restructure6111'
import { sumArrayByProperty, sumArrayByPropertyValue } from '../../../../utils/openformat/arraysLibrary'
import { formatNumbers } from '../../../../utils/openformat/numbers'

const CheckBalance = () => {
    const {t}=useTranslation()
    const data = useSelector((state) => state.balance).data6111
    const data2 = useSelector((state) => state.balance).data6111PreviousYear

    console.log(data,data2)
  return (
    <>
    <div className="page-wrapper">

      <div className="content container-fluid">

        <Breadcrumbs
          maintitle={t("checkImportedBalance")}
          title={t("main")}
          subtitle={t("checkImportedBalance")}
        />


        <CheckBalanceTable data={data}/>    
        {
            data2?.taxYear && <CheckBalanceTable data={data2}/>
        }

       

     
      </div>
    </div>
  </>
  )
}

export default CheckBalance

const CheckBalanceTable=({data})=>{
    const [displayData,setData]=useState([])



const [chekList,setCheckList]=useState([])

useEffect(()=>{
    setData(restructure6111(data.fullBalanceObj).filter(item=>item.isTotalGrp==1 && item.isBalanceSheet==1 && item.details6111=="8888" || item.details6111=="9999"))
   
},[])
useEffect(()=>{
    if(displayData.length){
        const list=[
            {
                title:"توازن الطرفين حسب الملف",
                value1:data.fullBalanceObj['8888'],
                value2:data.fullBalanceObj['9999'],
            },
            {
                title:"توازن الطرفين حسب الجمع",
                value1:sumArrayByProperty(displayData.filter(item=>item.isActive==1 && item.isPositiveDclear==1),"amount") -sumArrayByProperty(displayData.filter(item=>item.isActive==1 && item.isPositiveDclear==0),"amount"),
                value2:sumArrayByProperty(displayData.filter(item=>item.isActive==0 && item.isPositiveDclear==1),"amount") -sumArrayByProperty(displayData.filter(item=>item.isActive==0 && item.isPositiveDclear==0),"amount"),
                
            },
        
        ]
        setCheckList(list)
    }
   const d=displayData.filter(item=>item.details6111=="8888" || item.details6111=="9999")
   console.log(d)
},[displayData])





    return <div className="row">
    <div className="col-md-12">
      <div className="card">
        <div className="card-body">
            <div className="row">
                <h3>{data.companyName}</h3>
                <span>{data.taxYear}</span>
            </div>
          <div className="row">
          <table className="table datatable table-nowrap table-striped custom-table mb-0 ">
                    <thead>
                        <tr>
                           <th>البيان</th>
                           <th>القيمة الأولى</th>
                           <th>القيمة الثانية</th>
                           <th>النتيجة</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            chekList.map((item,index)=>(
                                <CheckBalanceRows  data={item}/>
                            ))
                        }
                    </tbody>
                </table>
          </div>

        </div>
      </div>
    </div>
  </div>
}

const CheckBalanceRows=({data})=>{
    const handleResult=(val1,val2)=>{
        const result= val1 ==val2
        return result ?"😊":"🙄"
    }
    return <tr>
    <td>{data.title}</td>
    <td>{formatNumbers(data.value1)}</td>
    <td>{formatNumbers(data.value2)}</td>
    <td>{handleResult(data.value1,data.value2)}</td>
</tr>
}