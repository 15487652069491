import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { userSlice } from "./user"; // Make sure this import is correct
import { openFormatSlice } from "./openFormat";
import { salary126Slice } from "./salary126";
import {file6111Slice} from "./file6111";

const reducer = {
  user: userSlice.reducer, // Access the 'reducer' property of the userSlice
  ahid: openFormatSlice.reducer,
  salary:salary126Slice.reducer,
  balance:file6111Slice.reducer
};

const store = configureStore({
  reducer: reducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
});

export default store;
