import React, { useEffect, useState } from "react";
import { Table } from "antd";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import TicketModelPopup from "../../../components/modelpopup/TicketModelPopup";
import Breadcrumbs from "../../../components/Breadcrumbs";
import TicketFilter from "../../../components/TicketFilter";
import { useSelector } from "react-redux";
import {
  formatNumbers,
  formatNumbers4Digits,
  getDate,
  getTime,
  sumArrayProperties,
} from "../../../utils/openformat/numbers";
import { useTranslation } from "react-i18next";
import OpenFormatHeader from "./OpenFormatHeader";
const OpenFormatStock = () => {
  const { t } = useTranslation();
  const [totals, setTotals] = useState([]);
  const [data, setData] = useState([]);
  const ahid = useSelector((state) => state.ahid);
  //   const data = useSelector((state) => state.ahid).bkmvFile?.Headers?.data || [];

  useEffect(() => {
    totalToUI();
  }, []);

  const totalToUI = () => {
    let arr = ahid.bkmvFile.Stock.data;
    const list = [];
    arr.forEach((element) => {
      let object = {
        ...element,
        endQuantity:
          element.starterQuantity + element.totalIn - element.totalOut,
      };
      list.push(object);
    });
    setData(list);
  };

  const columns = [
    {
      title: "الكود",
      dataIndex: "code",
      sorter: (a, b) => a.code < b.code,
    },
    {
      title: "رقم السجل",
      dataIndex: "recordId",
      sorter: (a, b) => a.recordId < b.recordId,
    },
    {
      title: "الرمز العالمي",
      dataIndex: "upcBarcode",
      sorter: (a, b) => a.upcBarcode < b.upcBarcode,
    },
    {
      title: "الرمز الداخلي",
      dataIndex: "InternalSKU",
      sorter: (a, b) => a.InternalSKU < b.InternalSKU,
    },
    {
      title: "رمز المورد",
      dataIndex: "vendorInternalSKU",
      sorter: (a, b) => a.vendorInternalSKU < b.vendorInternalSKU,
    },
    {
      title: "الصنف",
      dataIndex: "productName",
      sorter: (a, b) => a.productName < b.productName,
    },
    {
      title: "الفئة",
      dataIndex: "sortCodeDescription",
      sorter: (a, b) => a.sortCodeDescription < b.sortCodeDescription,
    },
    {
      title: "الوحدة",
      dataIndex: "unit",
      sorter: (a, b) => a.unit < b.unit,
    },
    {
      title: "الكمية أول المدة",
      dataIndex: "starterQuantity",
      render: (text) => (
        <span className="table-avatar">{formatNumbers4Digits(text)}</span>
      ),
      sorter: (a, b) => a.starterQuantity < b.starterQuantity,
    },
    {
      title: "دخول",
      dataIndex: "totalIn",
      render: (text) => (
        <span className="table-avatar">{formatNumbers4Digits(text)}</span>
      ),
      sorter: (a, b) => a.totalIn < b.totalIn,
    },
    {
      title: "خروج",
      dataIndex: "totalOut",
      render: (text) => (
        <span className="table-avatar">{formatNumbers4Digits(text)}</span>
      ),
      sorter: (a, b) => a.totalOut < b.totalOut,
    },
    {
      title: "الكمية آخر المدة",
      dataIndex: "endQuantity",
      render: (text) => (
        <span className="table-avatar">{formatNumbers4Digits(text)}</span>
      ),
      sorter: (a, b) => a.endQuantity < b.endQuantity,
    },
  ];

  return (
    <>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs
            maintitle={t("stock")}
            title={t("dashboard")}
            subtitle={t("stock")}
            modal="#add_ticket"
            name="Add Item"
          />
          <OpenFormatHeader />
          <div className="row">
            <div className="col-md-12">
              <div className="card-group m-b-30">
                {totals?.map((total, index) => (
                  <div className="card" key={index}>
                    <div className="card-body">
                      <div className="d-flex justify-content-between mb-3">
                        <div>
                          <span className="d-block">{total.title}</span>
                        </div>
                        <div>
                          <span
                            className={
                              total.percentage.includes("-")
                                ? "text-danger"
                                : "text-success"
                            }>
                            {total.percentage}
                          </span>
                        </div>
                      </div>
                      <h3 className="mb-3">{total.value}</h3>
                      <div className="progress mb-2" style={{ height: "5px" }}>
                        <div
                          className="progress-bar bg-primary"
                          role="progressbar"
                          style={{ width: "70%" }}
                          aria-valuenow={40}
                          aria-valuemin={0}
                          aria-valuemax={100}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <TicketFilter />

          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <Table
                  className="table-striped"
                  rowKey={(record) => record.id}
                  style={{ overflowX: "auto" }}
                  columns={columns}
                  dataSource={data?.length > 0 ? data : []}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <TicketModelPopup />
      <DeleteModal Name="Delete Ticket" />
    </>
  );
};

export default OpenFormatStock;
