import React, { useEffect, useState } from "react";
import { Table } from "antd";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import TicketModelPopup from "../../../components/modelpopup/TicketModelPopup";
import Breadcrumbs from "../../../components/Breadcrumbs";
import TicketFilter from "../../../components/TicketFilter";
import { useSelector } from "react-redux";
import {
  formatNumbers,
  sumArrayProperties,
} from "../../../utils/openformat/numbers";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import AccountingFilter from "./components/AccountingFilter";
import accountingStructures from "../../../utils/openformat/accounting_structures.json";
import OpenFormatHeader from "../OpenForamt/OpenFormatHeader";
const SortedBalance = () => {
  const { t } = useTranslation();
  const [totals, setTotals] = useState([]);
  const [data, setData] = useState([]);
  const [display, setDisplay] = useState([]);
  const ahid = useSelector((state) => state.ahid);

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    handleGetCategoriesTotals();
  }, []);

  const getTotals = (list) => {
    const sums = sumArrayProperties(list, ["starterAmount", "credit", "debit"]);

    const totals = [
      {
        title: "مجموع الأرصدة الافتتاحية",
        value: formatNumbers(sums?.starterAmount),
        percentage: "",
      },
      {
        title: "مجموع المدين",
        value: formatNumbers(sums?.debit),
        percentage: "",
      },
      {
        title: "مجموع الدائن",
        value: formatNumbers(sums?.credit),
        percentage: "",
      },
      {
        title: "الرصيد",
        value: formatNumbers(sums?.starterAmount + sums?.debit - sums?.credit),
        percentage: "",
      },
    ];

    setTotals(totals);
  };

  const columns = [
    {
      title: "كود التصنيف",
      dataIndex: "code",
      sorter: (a, b) => a.accountName < b.accountName,
    },

    {
      title: "اسم التصنيف",
      dataIndex: "description",
      sorter: (a, b) => a.accountName < b.accountName,
    },
    {
      title: "رصيد أول المدة",
      dataIndex: "starterAmount",
      render: (text) => (
        <span className="table-avatar">{formatNumbers(text)}</span>
      ),
      sorter: (a, b) => a.starterAmount - b.starterAmount,
    },

    {
      title: "مدين",
      dataIndex: "debit",
      render: (text) => (
        <span className="table-avatar">{formatNumbers(text)}</span>
      ),
      sorter: (a, b) => a.debit - b.debit,
    },
    {
      title: "دائن",
      dataIndex: "credit",
      render: (text) => (
        <span className="table-avatar">{formatNumbers(text)}</span>
      ),
      sorter: (a, b) => a.debit - b.debit,
    },
    {
      title: "الرصيد",
      dataIndex: "endTotal",
      render: (text) => (
        <span className="table-avatar">{formatNumbers(text)}</span>
      ),
      sorter: (a, b) => a.debit - b.debit,
    },
  ];

  const handleGetCategoriesTotals = () => {
    const list = [];
    console.log(ahid.bkmvFile?.Accounts?.data);
    for (let key in ahid?.trialBalanceClassifications) {
      let arr = ahid.bkmvFile?.Accounts?.data?.filter(
        (item) => item.trialBalanceCode == key
      );
      // console.log(arr);
      let credit = 0,
        debit = 0,
        starterAmount = 0;

      arr.forEach((el) => {
        credit += el.credit;
        debit += el.debit;
        starterAmount += el.starterAmount;
      });

      list.push({
        code: key,
        description: ahid.trialBalanceClassifications[key],
        starterAmount,
        credit,
        debit,
        endTotal: starterAmount + debit - credit,
      });
    }
    setData(list);
    setDisplay(list);
    getTotals(list);
  };

  const handleFilterZero = (value) => {
    // setHideZeors(value);
    if (value) setDisplay(data.filter((item) => item.endTotal != 0));
    else setDisplay(data);
  };

  return (
    <>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs
            maintitle={t("sortedBalance")}
            title={t("dashboard")}
            subtitle={t("sortedBalance")}
            modal="#add_ticket"
            name="Add Ticket"
          />
          <OpenFormatHeader />
          <div className="row">
            <div className="col-md-12">
              <div className="card-group m-b-30">
                {totals?.map((total, index) => (
                  <div className="card" key={index}>
                    <div className="card-body">
                      <div className="d-flex justify-content-between mb-3">
                        <div>
                          <span className="d-block">{total.title}</span>
                        </div>
                        <div>
                          <span
                            className={
                              total.percentage.includes("-")
                                ? "text-danger"
                                : "text-success"
                            }
                          >
                            {total.percentage}
                          </span>
                        </div>
                      </div>
                      <h3 className="mb-3">{total.value}</h3>
                      <div className="progress mb-2" style={{ height: "5px" }}>
                        <div
                          className="progress-bar bg-primary"
                          role="progressbar"
                          style={{ width: "70%" }}
                          aria-valuenow={40}
                          aria-valuemin={0}
                          aria-valuemax={100}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* <AccountingFilter
            categoryList={categories}
            setCurrentCategory={handleFilterByCategory}
          /> */}

          <div>
            <input
              type="checkbox"
              onChange={(e) => handleFilterZero(e.target.checked)}
            />
            <label className="focus-label">إخفاء الأرصدة الصفرية</label>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <Table
                  className="table-striped"
                  rowKey={(record) => record.id}
                  style={{ overflowX: "auto" }}
                  columns={columns}
                  dataSource={display?.length > 0 ? display : []}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <TicketModelPopup />
      <DeleteModal Name="Delete Ticket" />
    </>
  );
};

export default SortedBalance;
