import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { useSelector } from "react-redux";
import Breadcrumbs from "../../../../../components/Breadcrumbs";
import {multiArrayToObject126, structureArrayToObject } from "../../../../../utils/s126/arrays126";
import { formatNumbers} from "../../../../../utils/openformat/numbers";
import { useTranslation } from "react-i18next";
import structure from '../../../../../utils/s126/structure126.json'
import Header10 from "../../records/record10Header/Header10";
const ReportPageA = () => {
  const { t, i18n } = useTranslation()
  const salary = useSelector((state) => state.salary);
  const type50 = multiArrayToObject126(salary.type50)
  const structure50 = structureArrayToObject(structure.type50)


  const [statsData, setStatsData] = useState([])


  useEffect(() => {
    handleGetTotals()
  }, [i18n.language])

  const handleGetTotals = () => {
    const arr = ["totalSalaryAndPays", "employerTax", "taxBalance", "nonProfitInstitutionPayrollTax", "nationalInsurance", "totalSalaryAndPays2", "eilatEmployerBenifit", "foreingWorkerAndEmployerTax"]
    let obj = {}
    arr.forEach(item => {
      obj[item] = 0
    })

    type50.forEach(item => {
      arr.forEach(key => {
        obj[key] += item[key]
      })
    })

    const list = []
    arr.forEach((item, index) => {
      list.push({

        id: index,
        title: structure50[item][i18n.language],
        value: formatNumbers(obj[item]),

      })
    })

    setStatsData(list)
  }

  const columns = [
    {
      title: structure50.month[i18n.language],
      dataIndex: "month",
      render: (text, record) => <span className="text-primary">{text}/{record.taxYear}</span>,

      // sorter: (a, b) => a.leavetype.length - b.leavetype.length,
    },

    {
      title: structure50.numberOfEmployees[i18n.language],
      dataIndex: "numberOfEmployees",
      // sorter: (a, b) => a.from.length - b.from.length,
    },
    {
      title: structure50.totalSalaryAndPays[i18n.language],
      dataIndex: "totalSalaryAndPays",
      render: (text) => <span>{formatNumbers(text * 1)}</span>,

      // sorter: (a, b) => a.noofdays.length - b.noofdays.length,
    },
    {
      title: structure50.employerTax[i18n.language],
      dataIndex: "employerTax",
      render: (text) => <span>{formatNumbers(text * 1)}</span>,

      // sorter: (a, b) => a.to.length - b.to.length,
    },

    {
      title: structure50.taxBalance[i18n.language],
      dataIndex: "taxBalance",
      render: (text) => <span>{formatNumbers(text * 1)}</span>,

      // sorter: (a, b) => a.noofdays.length - b.noofdays.length,
    },

    {
      title: structure50.nonProfitInstitutionPayrollTax[i18n.language],
      dataIndex: "nonProfitInstitutionPayrollTax",
      render: (text, record) => (
        <span className="table-avatar">
          {formatNumbers(text * 1)}
        </span>
      ),
      // sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: structure50.nationalInsurance[i18n.language],
      dataIndex: "nationalInsurance",
      render: (text) => <span>{formatNumbers(text * 1)}</span>,

      // sorter: (a, b) => a.noofdays.length - b.noofdays.length,
    },
    {
      title: structure50.totalSalaryAndPays2[i18n.language],
      dataIndex: "totalSalaryAndPays2",
      render: (text) => <span>{formatNumbers(text * 1)}</span>,

      // sorter: (a, b) => a.noofdays.length - b.noofdays.length,
    },

    {
      title: structure50.eilatEmployerBenifit[i18n.language],
      dataIndex: "eilatEmployerBenifit",
      render: (text) => <span>{text}</span>,

      // sorter: (a, b) => a.leavetype.length - b.leavetype.length,
    },


    {
      title: structure50.foreingWorkerAndEmployerTax[i18n.language],
      dataIndex: "foreingWorkerAndEmployerTax",
      render: (text) => <span>{formatNumbers(text * 1)}</span>,

      // sorter: (a, b) => a.noofdays.length - b.noofdays.length,
    },




  ];


  return (
    <>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs
            maintitle={t("report-page-a")}
            title="الرئيسية"
            subtitle={t("report-page-a")}

          />
          {/* /Page Header */}
          <Header10 />
          {/* Leave Statistics */}
          <div className="row">
            {statsData.map((stat, index) => (
              <div className="col-md-3 d-flex" key={index}>
                <div className="stats-info w-100">
                  <h6>{stat.title}</h6>
                  {stat.subText ? (
                    <h4 className="text-primary">
                      {stat.value} <span>{stat.subText}</span>
                    </h4>
                  ) : (
                    <h4 className="text-primary">{stat.value}</h4>
                  )}
                </div>
              </div>
            ))}
          </div>

          {/* /Leave Statistics */}
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={type50}
                  className="table-striped page-a-table"
                  rowKey={(record) => record.id}
                  pagination={false}
                />
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
}

export default ReportPageA
