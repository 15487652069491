import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import EmployeeListFilter from "../../Employees/EmployeeListFilter";

const BudgetTable = ({columns,data,handleSearch,handleFilter}) => {

  return (
    <>
    <EmployeeListFilter search={handleSearch} filter={handleFilter}/>
      <Table
        className=" table-striped page-a-table "
        style={{ overflowX: "auto" }}
        columns={columns}
        dataSource={data}
        rowKey={(record) => record.id}
      />
    </>
  );
};

export default BudgetTable;
