import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../../../components/Breadcrumbs";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Bar, Line, Pie, Doughnut } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { file6111 } from "../../Document6111";
import { sumArrayByProperty } from "../../../../../utils/openformat/arraysLibrary";



const BalanceSheetAnalysis = () => {
  const state2 = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    datasets: [
      {
        label:"kj",
        data: [9, 4, 9, 11, 15, 17],
        backgroundColor: "#664dc9",
        borderWidth: 1,
        fill: true,
      },
      {
        label:'kf',
        data: [18, 19, 22, 21, 32, 28],
        backgroundColor: "#44c4fa",
        borderWidth: 1,
        fill: true,
      },
    ],
  };

  const { t } = useTranslation();
  const data = useSelector((state) => state.balance).data6111;
  const dataPrevious = useSelector((state) => state.balance).data6111PreviousYear;

  console.log(dataPrevious)
  console.log(data)

  const [allData,setAllData]=useState([])
  const [displayData,setDisplayData]=useState(state2)

  useEffect(()=>{
    handleCreateDisplayData()
  },[])

  const handletFilter=(arr,key,value = 1)=>{
    const tmpList=arr.filter(el=>el[key]==value && el.isBalanceSheet==1)
    return tmpList
}

  const getSummarries=(labelsKeys,arr)=>{
    const list=[]
    file6111.forEach(item=>{
      let value=arr.fullBalanceObj[item?.id] || 0
      // if(arr.fullBalanceObj[item?.id]){
      //   value=arr.fullBalanceObj[item.id]
      // }
      // try{
      //   value=arr.fullBalanceObj[item.id]
      // }catch(err){
      //   value=0
      // }

      list.push({
        ...item,
        value:value
      })
    })

    // const labels=["موجودات ثابتة","المدين","الدائن","النقد","المدينين","الدائنين","رأس المال"]
    // const labelsKeys=[{key:"isFixedAsset",value:1},{key:"isActive",value:1},{key:"isActive",value:0},{key:"isCash",value:1},
    //   {key:"isReceivable",value:1},{key:"isPayable",value:1},{key:"isCapital",value:1}]
    const totals=[]

    labelsKeys.forEach((item)=>{
      totals.push(sumArrayByProperty(handletFilter(list,item.key,item.value),"value"))
    })

  return totals
  }

  const handleCreateDisplayData=()=>{
    const labels=["موجودات ثابتة","المدين","الدائن","النقد","المدينين","الدائنين","رأس المال"]
    const labelsKeys=[{key:"isFixedAsset",value:1},{key:"isActive",value:1},{key:"isActive",value:0},{key:"isCash",value:1},
      {key:"isReceivable",value:1},{key:"isPayable",value:1},{key:"isCapital",value:1}]

      const currentTotals=getSummarries(labelsKeys,data)
      const previousTotals=getSummarries(labelsKeys,dataPrevious)

      setDisplayData(
        {
          labels,
          datasets: [
            {
              label:data.taxYear,
              data: currentTotals,
              backgroundColor: "#664dc9",
              borderWidth: 1,
              fill: true,
            },
            {
              label:dataPrevious.taxYear,
              data: previousTotals,
              backgroundColor: "#44c4fa",
              borderWidth: 1,
              fill: true,
            },
            
          ],
        }
      )
  }




  const option3 = {
    indexAxis: "y",
    maintainAspectRatio: false,
    legend: {
      display: true,
      labels: {
        display: true,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            fontSize: 10,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
            fontSize: 11,
            // max: 35,
          },
        },
      ],
    },
  };

  return (
    <>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs
            maintitle={t("balanceSheetAnalysis")}
            title="Dashboard"
            subtitle="Blank Page"
             modal="#warning-alert-modal"
          />
          {/* /Page Header */}
          {/* Content Starts */}
          {/* Rounded Button */}
          <div className="card card-buttons">
            <div className="card-body">
              <button type="button" className="btn btn-rounded btn-outline-primary me-1">
                موجودات ثابتة
              </button>
              <button type="button" className="btn btn-rounded btn-outline-success me-1">
                المدين
              </button>
              <button type="button" className="btn btn-rounded btn-outline-danger me-1">
                الدائن
              </button>
              <button type="button" className="btn btn-rounded btn-outline-warning me-1">
                النقد
              </button>
              <button type="button" className="btn btn-rounded btn-outline-success me-1">
                المدينين
              </button>
              <button type="button" className="btn btn-rounded btn-outline-danger me-1">
                الدائنين
              </button>
              <button type="button" className="btn btn-rounded btn-outline-warning me-1">
                رأس المال
              </button>

              <button type="button" className="btn btn-rounded btn-outline-secondary me-1">
                Secondary
              </button>
              <button type="button" className="btn btn-rounded btn-outline-info me-1">
                Info
              </button>
              <button type="button" className="btn btn-rounded btn-outline-dark me-1">
                Dark
              </button>
              <button type="button" className="btn btn-rounded btn-outline-light me-1">
                اظهار الكل
              </button>
            </div>
          </div>
          {/* /Rounded Button */}
          {/* /Content End */}
          <div className="col-md-6">
              <div className="card">
                <div className="card-header">
                  <div className="card-title">
                  <Link to="#">
                  {t("balanceSheetAnalysis")}
                  </Link>

                  </div>
                </div>
                <div className="card-body">
                  <Bar data={displayData} options={option3} />
                </div>
              </div>
            </div>
        </div>
        {/* /Page Content */}
      </div>
    </>
  );
};
export default BalanceSheetAnalysis;
