import React from "react";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import { useSelector } from "react-redux";
import { multiArrayToObject126, returnBarChart2ColumnsData } from "../../../../utils/s126/arrays126";
import GraphChartSalary from "./GraphChartSalary";
import Header10 from "../records/record10Header/Header10";

const Graphs = () => {
  const salary = useSelector((state) => state.salary);
  const type50 = multiArrayToObject126(salary.type50)

  const first={...returnBarChart2ColumnsData(type50,"تحليل الرواتب مقابل ضريبة الدخل",["month","totalSalaryAndPays","taxBalance"],["الشهر","إجمالي الراتب","الضريبة"])}
  const second={...returnBarChart2ColumnsData(type50,"تحليل الرواتب مقابل التأمين الوطني",["month","totalSalaryAndPays2","nationalInsurance"],["الشهر","إجمالي الراتب","التأمين الوطني"])}
  const chartsData=[first,second]
  console.log(chartsData)

  return (
    <div className="main-wrapper">
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs maintitle="التحليل البياني" title="الرئيسية" />
          <Header10 />
          {/* /Page Header */}
          {/* <div className="row">
            {Array.isArray(users) && users.length > 0 ? (
              users.map((item, index) => (
                <div
                  className="col-md-6 col-sm-6 col-lg-6 col-xl-3"
                  key={index}
                >
                  <div className="card dash-widget">
                    <div className="card-body">
                      <span className={`dash-widget-icon ${item.icon}`} />
                      <div className="dash-widget-info">
                        <h3>{item.number}</h3>
                        <span>{item.label}</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No data available</p>
            )}
          </div> */}
          {/* /Charts */}
          {/* <Charts title1="تحليل الرواتب مقابل ضريبة الدخل" title2="تحليل الرواتب مقابل التأمين الوطني"  /> */}
          {/* /Charts */}
          {/* <Reports /> */}
   
          {/* /Charts */}
          {/* <Charts title1="تحليل الرواتب مقابل صناديق الادخار"  /> */}
          {/* /Charts */}
          {/* <Reports /> */}
      
              <GraphChartSalary  data={chartsData}/>
         
        
          
        </div>
      </div>
    </div>
  );
};

export default Graphs;
