import store from "../../store";
import {upload6111,upload6111Previous} from '../../file6111'

const structure = [
  {
    he: "מספר תיק",
    ar: "رقم الملف",
    en: "Case Number",
    key: "caseNum",
    type: "נומרי",
    length: 9,
    from: 1,
    to: 9,
  },
  {
    he: "שנת מס",
    ar: "السنة الضريبية",
    en: "Tax Year",
    key: "taxYear",
    type: "נומרי",
    length: 4,
    from: 10,
    to: 13,
  },
  {
    he: "מס' זהות/ח.פ",
    ar: "رقم المشتغل / الهوية",
    en: "ID Number/Company Number",
    key: "idNum",
    type: "נומרי",
    length: 9,
    from: 14,
    to: 22,
  },
  {
    he: 'מס\' תיק מע""מ',
    ar: "رقم ملف ض.ق.م",
    en: "VAT Case Number",
    key: "vatCaseNum",
    type: "נומרי",
    length: 9,
    from: 23,
    to: 31,
  },
  {
    he: "מס' תיק ניכויים",
    ar: "رقم ملف الخصومات",
    en: "Deductions Case Number",
    key: "deductCaseNum",
    type: "נומרי",
    length: 9,
    from: 32,
    to: 40,
  },
  {
    he: "מס' ענף",
    ar: "رقم التخصص",
    en: "Branch Number",
    key: "branchNum",
    type: "נומרי",
    length: 4,
    from: 41,
    to: 44,
  },
  {
    he: "תאור העסק",
    ar: "وصف المصلحة",
    en: "Business Description",
    key: "bizDesc",
    type: "אלפאנומרי",
    length: 50,
    from: 45,
    to: 94,
  },
  {
    he: "סוג עסק",
    ar: "نوع المصلحة",
    en: "Business Type",
    key: "bizType",
    type: "נומרי",
    length: 2,
    from: 95,
    to: 96,
    options: [
      {
        value: 1,
        label: "صناعي",
      },
      {
        value: 2,
        label: "تجاري",
      },
      {
        value: 3,
        label: "خدمي",
      },
      {
        value: 4,
        label: "التقرير يحتوي أكثر من مصلحة",
      },
    ],
  },
  {
    he: "שיטת דיווח",
    ar: "طريقة التصريح",
    en: "Reporting Method",
    key: "reportMethod",
    type: "נומרי",
    length: 2,
    from: 97,
    to: 98,
    options: [
      {
        value: 1,
        label: "نقدي",
      },
      {
        value: 2,
        label: "تراكمي",
      },
      {
        value: 9,
        label: "حسب معايير الدولار",
      },
    ],
  },
  {
    he: "שיטת חשבונאות",
    ar: "طريقة المحاسبة",
    en: "Accounting Method",
    key: "acctMethod",
    type: "נומרי",
    length: 2,
    from: 99,
    to: 100,
    options: [
      {
        value: 1,
        label: "أحادية",
      },
      {
        value: 2,
        label: "ثنائية",
      },
    ],
  },
  {
    he: 'הנח""ש של העסק',
    ar: "طريقة إدارة الحسابات",
    en: "Business Accounting Guidelines",
    key: "acctGuidelines",
    type: "נומרי",
    length: 2,
    from: 101,
    to: 102,
    options: [
      {
        value: 1,
        label: "يدوية",
      },
      {
        value: 2,
        label: "محوسبة",
      },
    ],
  },
  {
    he: "דוח זה בגין שותפות",
    ar: "أهذا تقرير شراكة؟",
    en: "This Report is for Partnership",
    key: "isPartnership",
    type: "נומרי",
    length: 1,
    from: 103,
    to: 103,
    options: [
      {
        value: 1,
        label: "نعم",
      },
      {
        value: 2,
        label: "لا",
      },
    ],
  },
  {
    he: "מצורף דוח רווח הפסד",
    ar: "مرفق تقرير ربح وخسارة؟",
    en: "Attached Profit and Loss Report",
    key: "profitLossReport",
    type: "נומרי",
    length: 1,
    from: 104,
    to: 104,
    options: [
      {
        value: 1,
        label: "نعم",
      },
      {
        value: 2,
        label: "لا",
      },
    ],
  },
  {
    he: "מצורף דוח התאמה",
    ar: "مرفق تقرير ملاءمة؟",
    en: "Attached Adjustment Report",
    key: "adjustReport",
    type: "נומרי",
    length: 1,
    from: 105,
    to: 105,
    options: [
      {
        value: 1,
        label: "نعم",
      },
      {
        value: 2,
        label: "لا",
      },
    ],
  },
  {
    he: "מצורף דוח מאזן",
    ar: "مرفق ميزانية؟",
    en: "Attached Balance Report",
    key: "balanceReport",
    type: "נומרי",
    length: 1,
    from: 106,
    to: 106,
    options: [
      {
        value: 1,
        label: "نعم",
      },
      {
        value: 2,
        label: "لا",
      },
    ],
  },
  {
    he: "מספר נגררות פיסקת רווח הפסד",
    ar: "عدد مرفقات الربح والخسارة",
    en: "Number of Profit and Loss Clause Carried Over",
    key: "profitLossCarry",
    type: "נומרי",
    length: 3,
    from: 107,
    to: 109,
  },
  {
    he: "מספר נגררות פיסקת התאמה למס",
    ar: "عدد مرفقات الملاءمة الضريبية",
    en: "Number of Tax Adjustment Clause Carried Over",
    key: "taxAdjustCarry",
    type: "נומרי",
    length: 3,
    from: 110,
    to: 112,
  },
  {
    he: "מספר נגררות פיסקת מאזן",
    ar: "عدد مرفقات الميزانية",
    en: "Number of Balance Clause Carried Over",
    key: "balanceCarry",
    type: "נומרי",
    length: 3,
    from: 113,
    to: 115,
  },
  {
    he: "שנת מס - יישום תקני חשבונאות",
    ar: "سنة الضريبة - تطبيق المعايير الضريبية",
    en: "Tax Year - Implementation of Accounting Standards",
    key: "acctStandardsYear",
    type: "נומרי",
    length: 4,
    from: 116,
    to: 119,
  },
  {
    he: "דווח בחלופה- יישום תקני חשבונאות",
    ar: "التقرير في البديل - تطبيق المعايير الضريبية",
    en: "Reported in Alternative - Implementation of Accounting Standards",
    key: "acctStandardsAlt",
    type: "נומרי",
    length: 1,
    from: 120,
    to: 120,
  },
  {
    he: "מספר תעודת רישום- חייב ברישום תוכנה",
    ar: "رقم شهادة التسجيل - النظام البرمجي",
    en: "Registration Certificate Number - Required Software Registration",
    key: "regCertNum",
    type: "נומרי",
    length: 8,
    from: 121,
    to: 128,
  },
  {
    he: "דוח זה בגין שותפות: מספר השותפים",
    ar: "عدد الشركاء - في حالة شراكة",
    en: "This Report is for Partnership: Number of Partners",
    key: "partnershipNum",
    type: "נומרי",
    length: 3,
    from: 129,
    to: 131,
  },
  {
    he: "דוח זה בגין שותפות: חלקי ברווחי השותפות",
    ar: "حصتي في الأرباح - في حالة شراكة",
    en: "This Report is for Partnership: Share in Partnership Profits",
    key: "partnershipShare",
    type: "דצימלי",
    length: 6,
    from: 132,
    to: 137,
  },
  {
    he: "דיווח מטבע",
    ar: "العملة",
    en: "Currency Reporting",
    key: "currencyReport",
    type: "דצימלי",
    length: 2,
    from: 138,
    to: 139,
    options: [
      {
        value: 1,
        label: "شيكل",
      },
      {
        value: 2,
        label: "دولار",
      },
    ],
  },
  {
    he: "חוות דעת",
    ar: "إبداء الرأي",
    en: "Opinion",
    key: "opinion",
    type: "דצימלי",
    length: 2,
    from: 140,
    to: 141,
    options: [
      {
        value: 1,
        label: "نص موحد (غير متحفظ)",
      },
      {
        value: 2,
        label: "نص موحد مع لفت الانتباه لملاحظة النشاط المستمر",
      },
      {
        value: 3,
        label: "نص موحد مع لفت الانتباه لشيء آخر",
      },
      {
        value: 4,
        label: "تحفظ",
      },
      {
        value: 5,
        label: "سلبي",
      },
      {
        value: 6,
        label: "إمتناع",
      },
      {
        value: 9,
        label: "لا يوجد رأي",
      },
    ],
  },
  {
    he: "הסכום באלפי שקלים/דולרים",
    ar: "المبالغ بالآلاف",
    en: "Amount in Thousands of Shekels/Dollars",
    key: "amountInThousands",
    type: "דצימלי",
    length: 1,
    from: 142,
    to: 142,
    options: [
      {
        value: 1,
        label: "نعم",
      },
      {
        value: 2,
        label: "لا",
      },
    ],
  },
  {
    he: "Filler",
    ar: "فارغ",
    en: "empty",
    key: "empty",
    type: "נומרי",
    length: 370,
    from: 143,
    to: 512,
  },
  {
    he: "150 נגררות - פיסקת רווח הפסד",
    ar: "مرفقات الربح والخسارة",
    en: "Profit and Loss Attachments",
    key: "profitLossAttach",
    type: "",
    length: 2700,
    from: 513,
    to: 3212,
  },
  {
    he: "150 נגררות - פיסקת התאמה למס",
    ar: "مرفقات الملاءمة الضريبية",
    en: "Tax Adjustment Attachments",
    key: "taxAdjustAttach",
    type: "",
    length: 2700,
    from: 3213,
    to: 5912,
  },
  {
    he: "150 נגררות - פיסקת מאזן",
    ar: "مرفقات الميزانية",
    en: "Balance Attachments",
    key: "balanceAttach",
    type: "",
    length: 2700,
    from: 5913,
    to: 8613,
  },
];

const retreiver = (text,companyName,isCurrent =true) => {
  const obj = {companyName};
const list=[]

  structure.forEach((item) => {
    let data = text.substring(item.from - 1, item.to).trim();
    if (item.type == "נומרי" || item.type == "דצימלי") data = data * 1;
    obj[item.key] = data;
    list.push({
      name:item.ar,value:data
    })
  });

  const profitLossAttach= retreiveBalance(obj.profitLossAttach)
  const taxAdjustAttach= retreiveBalance(obj.taxAdjustAttach)
  const balanceAttach= retreiveBalance(obj.balanceAttach)
  const fullBalanceObj=createFullBalanceObject([...balanceAttach,...taxAdjustAttach,...profitLossAttach])

if(isCurrent){
  store.dispatch(upload6111({
    ...obj,
    profitLossAttach,
    taxAdjustAttach,
    balanceAttach,
    list,
    fullBalanceObj
  }))
}else{
  store.dispatch(upload6111Previous({
    ...obj,
    profitLossAttach,
    taxAdjustAttach,
    balanceAttach,
    list,
    fullBalanceObj
  }))
}

  
};

const retreiveBalance = (data) => {
  const list = [];
  let text = data;
  while (text.length) {
    let t = text.substring(0, 18);
    text = text.substring(18, text.length);
    let code = t.substring(0, 5) * 1;
    if (code)
      list.push({
        code,
        value: t.substring(5, 18) * 1,
      });
  }
  return list;
};

const createFullBalanceObject=(arr)=>{
  let obj={}
  let prof=0,tax=0

  arr.forEach(item=>{
    obj[item.code]=item.value
    if(item.code==6666) prof=item.value
    if(item.code==5600) tax=item.value
  })
  obj[5699]=prof-tax
  console.log(obj)
  return obj
}

export default retreiver
