import React, { useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useSelector } from "react-redux";
import { accounts, bkmvFile } from "../../../openFormat";
import {
  formatNumbers,
  getDate,
  getMonthFromNumber,
  sumArrayProperty,
  sumArrayPropertyByType,
} from "../../../utils/openformat/numbers";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import SearchFilter from "../Accounting/components/SearchFilter";
import { Table } from "antd";
import { ARABIC_MONTHS } from "../../../utils/constants";
import Breadcrumbs from "../../../components/Breadcrumbs";
import OpenFormatHeader from "./OpenFormatHeader";
const barchartdata = [
  { y: "2006", "Total Income": 100, "Total Outcome": 90 },
  { y: "2007", "Total Income": 75, "Total Outcome": 65 },
  { y: "2008", "Total Income": 50, "Total Outcome": 40 },
  { y: "2009", "Total Income": 75, "Total Outcome": 65 },
  { y: "2010", "Total Income": 50, "Total Outcome": 40 },
  { y: "2011", "Total Income": 75, "Total Outcome": 65 },
  { y: "2012", "Total Income": 100, "Total Outcome": 90 },
];
const linechartdata = [
  { y: "2006", "Total Sales": 50, "Total Revenue": 90 },
  { y: "2007", "Total Sales": 75, "Total Revenue": 65 },
  { y: "2008", "Total Sales": 50, "Total Revenue": 40 },
  { y: "2009", "Total Sales": 75, "Total Revenue": 65 },
  { y: "2010", "Total Sales": 50, "Total Revenue": 40 },
  { y: "2011", "Total Sales": 75, "Total Revenue": 65 },
  { y: "2012", "Total Sales": 100, "Total Revenue": 50 },
];

const FinCharts = () => {
  const ahid = useSelector((state) => state.ahid);
  const [bar, setBar] = useState([]);
  const [id, setId] = useState("");
  const [line, setLine] = useState([]);

  const { t } = useTranslation();
  //   const { id } = useParams();
  const [totals, setTotals] = useState([]);
  const [currentAccount, setCurrentAccount] = useState(null);
  const [data, setData] = useState([]);
  const accounts = ahid.accounts;

  useEffect(() => {
    if (id) {
      totalToUI(id);
      setCurrentAccount(accounts[id]);
      dataMaker();
    } else {
      setCurrentAccount(null);
      setTotals([]);
      setData([]);
      setBar([]);
    }
  }, [id]);

  const totalToUI = (accountId) => {
    let arr =
      ahid.bkmvFile?.Accounting_Transactions?.data?.filter(
        (item) => item.recordAccount == accountId
      ) || [];
    const list = [];

    let lineTotal = accounts[id]?.starterAmount;
    let totalDebit = 0,
      totalCredit = 0;

    arr.forEach((element) => {
      let debit = 0,
        credit = 0;
      element.actionSign == 2
        ? (credit = element.actionTotal * -1)
        : (debit = element.actionTotal);

      lineTotal += debit + credit;
      totalCredit += credit;
      totalDebit += debit;

      let object = {
        ...element,
        debit,
        credit,
        endTotal: element.starterAmount + debit - credit,
        counterAccountName: accounts[element.counterAccount]?.accountName,
        lineTotal,
      };
      list.push(object);
    });
    setData(list);

    getTotals(accounts[id]?.starterAmount, totalCredit, totalDebit, lineTotal);
  };

  const getTotals = (opening, credit, debit, total) => {
    const totals = [
      {
        title: "الرصيد الافتتاحي",
        value: formatNumbers(opening),
        percentage: "",
      },
      {
        title: "مجموع المدين",
        value: formatNumbers(debit),
        percentage: "",
      },
      {
        title: "مجموع الدائن",
        value: formatNumbers(credit),
        percentage: "",
      },
      {
        title: "الرصيد",
        value: formatNumbers(total),
        percentage: "",
      },
    ];

    setTotals(totals);
  };

  const columns = [
    {
      title: "الشهر",
      dataIndex: "y",
      //   sorter: (a, b) => a.portion < b.portion,
      render: (text) => ARABIC_MONTHS[text * 1 - 1],
    },

    {
      title: "الإجمالي",
      dataIndex: "Total Sales",
      //   sorter: (a, b) => a.debit < b.debit,
      render: (text) => formatNumbers(text * 1),
    },
  ];

  const dataMaker = () => {
    // const rivhitCodes = [70, 71, 1010, 1040, 1050, 1015, 1025, 1030];
    // const main6111Codes = [1010, 1040, 1050, 1015, 1025, 1030];

    // const isRivhit = ahid.iniFile?.headerList?.softwareId === "81702";
    // let accounts = {};
    // const arr = [];
    // for (let k in ahid?.accounts) {
    //   let item = ahid?.accounts[k];
    //   if (isRivhit) {
    //     if (rivhitCodes.findIndex((el) => el == item.trialBalanceCode) > -1)
    //       accounts[item.accountId] = {};
    //   } else {
    //     if (main6111Codes.findIndex((el) => el == item.trialBalanceCode) > -1)
    //       accounts[item.accountId] = {};
    //   }
    // }

    // for (let key in accounts) {
    //   for (let i = 1; i < 13; i++) {
    //     let list = ahid?.bkmvFile?.Accounting_Transactions?.data?.filter(
    //       (item) =>
    //         item.recordAccount == key && getMonthFromNumber(item.date) == i
    //     );
    //     let total =
    //       sumArrayPropertyByType(list, "actionTotal", "2", "actionSign") -
    //       sumArrayPropertyByType(list, "actionTotal", "1", "actionSign");
    //     accounts[key][i] = total;
    //   }
    // }

    // console.log(accounts);
    const data = [];
    for (let i = 1; i < 13; i++) {
      let list = ahid?.bkmvFile?.Accounting_Transactions?.data?.filter(
        (item) => getMonthFromNumber(item.date) == i && item.recordAccount == id
      );
      let total =
        sumArrayPropertyByType(list, "actionTotal", "2", "actionSign") -
        sumArrayPropertyByType(list, "actionTotal", "1", "actionSign");
      data.push({ y: i.toString(), "Total Sales": parseInt(total) });
    }
    setBar(data);
  };

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <Breadcrumbs
          maintitle={t("monthlyChart")}
          title={t("dashboard")}
          subtitle={t("monthlyChart")}
          modal="#add_ticket"
          name="Add Ticket"
        />
        <OpenFormatHeader />
        <div className="main-wrapper">
          <div className="row">
            <div className="col-md-12">
              {id && (
                <div className="row">
                  <div className="col-md-6 text-center">
                    <div className="card">
                      <div className="card-body">
                        <h3 className="card-title">رسم بياني</h3>
                        <ResponsiveContainer width="100%" height={300}>
                          <BarChart
                            //   data={barchartdata}
                            data={bar}
                            margin={{
                              top: 5,
                              right: 5,
                              left: 5,
                              bottom: 5,
                            }}>
                            <CartesianGrid />
                            <XAxis dataKey="y" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="Total Sales" fill="#ff9b44" />
                            {/* <Bar dataKey="Total Outcome" fill="#fc6075" /> */}
                          </BarChart>
                        </ResponsiveContainer>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 text-center">
                    <div className="card">
                      <div className="card-body">
                        <h3 className="card-title">رسم بياني</h3>
                        <ResponsiveContainer width="100%" height={300}>
                          <LineChart
                            data={bar}
                            margin={{ top: 5, right: 5, left: 5, bottom: 5 }}>
                            <CartesianGrid />
                            <XAxis dataKey="y" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line
                              type="monotone"
                              dataKey="Total Sales"
                              stroke="#ff9b44"
                              fill="#00c5fb"
                              strokeWidth={3}
                              dot={{ r: 3 }}
                              // activeDot={{ r: 7 }}
                            />
                            <Line
                            // type="monotone"
                            // dataKey="Total Revenue"
                            // stroke="#fc6075"
                            // fill="#0253cc"
                            // strokeWidth={3}
                            // dot={{ r: 3 }}
                            // activeDot={{ r: 7 }}
                            />
                          </LineChart>
                        </ResponsiveContainer>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* ======================================================================================= */}

              <div className="row">
                <div className="col-md-12">
                  <div className="card-group m-b-30">
                    {totals?.map((total, index) => (
                      <div className="card" key={index}>
                        <div className="card-body">
                          <div className="d-flex justify-content-between mb-3">
                            <div>
                              <span className="d-block">{total.title}</span>
                            </div>
                            <div>
                              <span
                                className={
                                  total.percentage.includes("-")
                                    ? "text-danger"
                                    : "text-success"
                                }>
                                {total.percentage}
                              </span>
                            </div>
                          </div>
                          <h3 className="mb-3">{total.value}</h3>
                          <div
                            className="progress mb-2"
                            style={{ height: "5px" }}>
                            <div
                              className="progress-bar bg-primary"
                              role="progressbar"
                              style={{ width: "70%" }}
                              aria-valuenow={40}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {!id ? (
                <SearchFilter
                  data={ahid.bkmvFile?.Accounts?.data}
                  current={id}
                  setCurrent={setId}
                />
              ) : (
                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                  <Link
                    to="#"
                    onClick={() => setId("")}
                    className="btn btn-success btn-block w-100">
                    {" "}
                    بحث{" "}
                  </Link>
                </div>
              )}

              {currentAccount && (
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between mb-3 ">
                      <div>
                        <span className="d-block">
                          {" "}
                          رقم الحساب: {currentAccount.accountId}
                        </span>
                      </div>
                    </div>
                    <h3 className="mb-3 ">
                      الحساب: {currentAccount.accountName}
                    </h3>
                  </div>
                </div>
              )}

              <div className="row">
                <div className="col-md-6">
                  <div className="table-responsive">
                    <Table
                      pagination={false}
                      className="table-striped"
                      rowKey={(record) => record.id}
                      style={{ overflowX: "auto" }}
                      columns={columns}
                      dataSource={bar?.length > 0 ? bar : []}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinCharts;
