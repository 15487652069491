import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Breadcrumbs from '../../../../components/Breadcrumbs'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { restructure6111 } from '../../../../utils/a6111/restructure6111'
import useMetrics from './useMetrics'

const BalanceMetrics = () => {
    const {t}=useTranslation()
    const [active,setActive]=useState('all')

    const {display,list,filterDisplay}=useMetrics()
    

    

  return (
    <div className="page-wrapper">
    <div className="content container-fluid">
      <Breadcrumbs
        maintitle={t("balanceSheetMetrics")}
        title="الرئيسية"
        subtitle={t("balanceSheetMetrics")}
        // modal="#warning-alert-modal"
        
      />
   

      <div className="card tab-box">
        <div className="row user-tabs">
          <div className="col-lg-12 col-md-12 col-sm-12 line-tabs">
            <ul className="nav nav-tabs nav-tabs-bottom">
              <li className="nav-item">
                <Link
                  to="#"
                  data-bs-toggle="tab"
                  className={active=="all" ? "nav-link active" :"nav-link"}
                  onClick={()=>{
                    filterDisplay()
                    setActive("all")
                  }}
                >
                  {t("showAll")}
                </Link>
              </li>
              {
                list.map(item=>(
                    <Link
                  to="#"
                  data-bs-toggle="tab"
                  className={active==item.type ? "nav-link active" :"nav-link"}
                  key={item.type}
                  onClick={()=>{
                    filterDisplay(item.type)
                    setActive(item.type)
                  }}
                >
                 {item.title}
                </Link>
                ))
              }
            </ul>
          </div>
        </div>
      </div>

      {
        display.map(item=>(
            <div className="card  " key={item.type}>
            <div className="card-body">
                <div className="card-header">
                    <h4 className="card-title mb-0 text-center text-primary">{item.title}</h4>
                </div>
                <div className="row">
                    <div className='col-6'>
                        <p>{item.description}</p>
                        <span>{item.function}</span>
                    </div>
                    <div className='col-6'>
                        <h4>{item.result}</h4>
                    </div>
                </div>
            </div>
        </div>
        ))
      }
  
    </div>
  </div>
  )
}

export default BalanceMetrics
