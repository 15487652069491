import { getDate } from "../openformat/numbers"

export const arrayToObject126 = (arr) => {
    let obj = {}
    arr.forEach(item => {
        const key = item.key
        obj[key] = item.value
    })
    return obj
}

export const multiArrayToObject126=(arr)=>{
    const list=[]
    arr.forEach(item=>{
        const obj=arrayToObject126(item)
        list.push(obj)
    })

    return list
}

export const multiArray126ToAttendaceObject=(arr)=>{
    const list=[]
    arr.forEach(item=>{
        const obj=arrayToObject126(item)
        let attArr;
        if(obj.workMonths=="N"){
            attArr=[true,true,true,true,true,true,true,true,true,true,true,true]
        }else{
            attArr=[false,false,false,false,false,false,false,false,false,false,false,false]
            let text=obj.workMonths
            while(text.length>0){
                let chek=text.substring(0,2)*1 - 1
                text=text.substring(2,text.length)

                attArr[chek]=true
            }
        }

        let chekObj={}

        attArr.forEach((item,index)=>{
            chekObj[index+1]=item
        })

        list.push({
            name:`${obj.firstName} ${obj.lastName}`,
            ...chekObj,
        ...obj
        })
    })

    return list
}

export const object126ToColumns=(arr)=>{
    const columns=[]
    arr.forEach(item=>{
        columns.push({
            title: item.ar,
            dataIndex: item.key,
            sorter: (a, b) => a.name.length - b.name.length,
        })
    })
return columns
   
}

export const calculateYearsBetweenDates=(startDate, endDate) =>{
    // Parse the dates (format: DD/MM/YYYY)
    const startParts = getDate(startDate).split('/');
    const endParts = getDate(endDate).split('/');

    // Create date objects
    const start = new Date(startParts[0], startParts[1] - 1, startParts[2]);
    const end = new Date(endParts[0], endParts[1] - 1, endParts[2]);

    // Calculate the difference in milliseconds
    const diffInMilliseconds = end - start;

    // Convert milliseconds to years
    const millisecondsInAYear = 1000 * 60 * 60 * 24 * 365.25; // Accounting for leap years
    const yearsDiff = diffInMilliseconds / millisecondsInAYear;

    return yearsDiff.toFixed(2);
}


export const structureArrayToObject=(arr)=>{
    let obj={}
    arr.forEach(item=>{
        obj[item.key]=item
    })

    return obj
}

export const getNetSalary=(obj)=>{
    const total=obj.normalTax*1- obj.nationalInsuranceExemptHealthTax*1 - obj.employeeHealthInsurancePremiums *1- obj.totalIncomeTaxDeducted *1- obj.employeeEducationContribution*1-obj.pensionEmployerContribution*1
    return total
}

export const getTotalCost=(obj)=>{
    const total=obj.normalTax*1 +getTotalEmployerContribution(obj)
    return total
}

export const getTotalEmployerContribution=(obj)=>{
    return obj.employerContributionToBILAmount*1+obj.employerEducationContribution*1+obj.employerDisabilityInsurance*1+obj.employerContributionToPensionFundCompensationComponent*1+obj.cpfEmployerContribution*1
}

export const getCostPercentage=(obj)=>{
    const cost =getTotalEmployerContribution(obj)
    return (cost/(obj.normalTax*1))*100
}

export const salaryCostTotals=(arr)=>{
    let netSalary=0,totalSalary=0,totalsCost=0,totalEmployer=0

    arr.forEach(item=>{
        totalSalary+=item.normalTax
        netSalary+=getNetSalary(item)
        totalsCost+=getTotalCost(item)
        totalEmployer+=getTotalEmployerContribution(item)


    })

    return {
        netSalary,totalEmployer,totalSalary,totalsCost,avgNetSalary:netSalary/arr.length, avgTotalSalary:totalSalary/arr.length,avgTotalEmployer:totalEmployer/arr.length
    }
}

// ==================================================== حسابات المخصصات ==================================================== //
export const employeeCompensationPart=(obj)=>{
    let total=obj.pensionEmployerContribution*1 + obj.employeeDisabilityInsurance*1 +obj.employeeEducationContribution*1
    return total
}

export const employerCompensationPart=(obj)=>{
    let total=obj.cpfEmployerContribution*1 + obj.employerContributionToPensionFundCompensationComponent*1 +obj.employerEducationContribution*1+ obj.employerDisabilityInsurance*1
    return total
}
export const employeesCompensationPart=(arr)=>{
    let total=0
    arr.forEach(item=>{
        total+=employeeCompensationPart(item)
    })
    return total
}

export const employersCompensationPart=(arr)=>{
    let total=0
    arr.forEach(item=>{
        total+=employerCompensationPart(item)
    })
    return total
}

export const compensationRecordTotals=(record)=>{
    let total=employerCompensationPart(record) + employeeCompensationPart(record)
    return total
}

// ==================================================== تحليل بياني ==================================================== //
export const returnBarChart2ColumnsData=(arr,title,keys,translations)=>{
    const data=[]
    arr.forEach(item=>{
        data.push({
            [translations[0]]:item[keys[0]],
            [translations[1]]:item[keys[1]],
            [translations[2]]:item[keys[2]],
        })
    })
    return {title,data,translations}
}