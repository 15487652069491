export const ARABIC_MONTHS = [
  "كانون ثاني",
  "شباط",
  "آذار",
  "نيسان",
  "أيار",
  "حزيران",
  "تموز",
  "آب",
  "أيلول",
  "تشرين أول",
  "تشرين ثاني",
  "كانون أول",
];

export const ARABIC_ORDERING = [
  "الأول",
  "الثاني",
  "الثالث",
  "الرابع",
  "الخامس",
  "السادس",
  "السابع",
  "الثامن",
  "التاسع",
  "العاشر",
];

export const checkAllowedCharactersForNumberInput = (text) => {
  const allowed = "0123456789.-";
  if (!text) return true;
  for (let i = 0; i < text.length; i++) {
    if (!allowed.includes(text[i])) return false;
  }
  return true;
};
