import { createSlice } from "@reduxjs/toolkit";

const initialState={
    data6111:{},
    data6111PreviousYear:{}
}

export const file6111Slice = createSlice({
    name: "file6111",
    initialState,
    reducers: {
      upload6111: (state, action) => {
        state.data6111 = action.payload
      },
      upload6111Previous: (state, action) => {
        state.data6111PreviousYear = action.payload
      },
      
    },
  });
  
  export const {
    upload6111,upload6111Previous
  } = file6111Slice.actions;
  export default file6111Slice.reducer;