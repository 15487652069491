import React, { useEffect } from "react";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { useSelector } from "react-redux";
import { AhidCodes } from "../../../utils/openformat/filesStructures";
import { getDate, getTime } from "../../../utils/openformat/numbers";

const OpenFormatGeneral = () => {
  const ahid = useSelector((state) => state.ahid);

  useEffect(() => {
    console.log(ahid);
  }, []);
  return (
    <div className="page-wrapper">
      {/* Page Content */}
      <div className="content container-fluid">
        {/* Page Header */}
        <Breadcrumbs maintitle="Index" title="Open Format" subtitle="Index" />
        {/* /Page Header */}
        {/* Content Starts */}

        {ahid.iniFile?.headerList && (
          <>
            {/* ================================    Start Hebrew    ================================ */}
            <div className="col-lg-6">
              <div className="row">
                <div className="col-sm-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">מספר עוסק מורשה</label>
                    <input
                      className="form-control"
                      type="text"
                      defaultValue="Dreamguy's Technologies"
                      value={ahid.iniFile?.headerList?.Identification}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">שם בית עסק</label>
                    <input
                      className="form-control"
                      type="text"
                      defaultValue="Dreamguy's Technologies"
                      value={ahid.iniFile?.headerList?.businessName}
                    />
                  </div>
                </div>
                {ahid.iniFile?.headerList?.taxYear > 0 && (
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        שנת המס עליה הופקו הנתונים:
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        defaultValue="Dreamguy's Technologies"
                        value={ahid.iniFile?.headerList?.taxYear}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            {ahid.iniFile?.headerList?.taxYear === 0 && (
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">תחילת דיווח</label>
                      <input
                        className="form-control"
                        type="text"
                        defaultValue="Dreamguy's Technologies"
                        value={getDate(
                          ahid.iniFile?.headerList?.dataPeriodStartDate
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">סוף דיווח</label>
                      <input
                        className="form-control"
                        type="text"
                        defaultValue="Dreamguy's Technologies"
                        value={getDate(
                          ahid.iniFile?.headerList?.dataPeriodEndDate
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="col-lg-6">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title mb-0">
                    פירוט סך סוגי הרשומות שנוצרו בקובץ BKMVDATA.txt :
                  </h4>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-bordered mb-0">
                      <thead>
                        <tr>
                          <th>קוד רשומה</th>
                          <th>תיאור רשומה</th>
                          <th>סך רשומות</th>
                          <th>נמצאו</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* {data.map((item, index) => (
                      <tr key={index}>
                        <td>{item.firstName}</td>
                        <td>{item.lastName}</td>
                        <td>{item.email}</td>
                      </tr>
                    ))} */}
                        {Object.keys(AhidCodes.BKMVDATA).map((key) => (
                          <tr key={key}>
                            <td>{key}</td>
                            <td>{AhidCodes.BKMVDATA[key].description}</td>
                            <td>{ahid.iniFile.recordsList[key]}</td>
                            <td>
                              {ahid.bkmvFile[AhidCodes.BKMVDATA[key].name].data
                                ?.length || 0}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row">
                <div className="col-sm-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      הנתונים הופקו באמצעות תוכנת
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      defaultValue="Dreamguy's Technologies"
                      value={ahid.iniFile?.headerList?.softwareName}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">מספר תעודת רישום</label>
                    <input
                      className="form-control"
                      type="text"
                      defaultValue="Dreamguy's Technologies"
                      value={ahid.iniFile?.headerList?.softwareId}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">בתאריך</label>
                    <input
                      className="form-control"
                      type="text"
                      defaultValue="Dreamguy's Technologies"
                      value={getDate(
                        ahid.iniFile?.headerList?.processStartDate
                      )}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">בשעה</label>
                    <input
                      className="form-control"
                      type="text"
                      defaultValue="Dreamguy's Technologies"
                      value={getTime(
                        ahid.iniFile?.headerList?.processStartTime
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr id="hr-split" />
            {/* ================================    Start Arabic    ================================ */}
            <div className="col-lg-6">
              <div className="row">
                <div className="col-sm-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">رقم المشتغل المرخص</label>
                    <input
                      className="form-control"
                      type="text"
                      defaultValue="Dreamguy's Technologies"
                      value={ahid.iniFile?.headerList?.Identification}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">اسم المصلحة/الشركة</label>
                    <input
                      className="form-control"
                      type="text"
                      defaultValue="Dreamguy's Technologies"
                      value={ahid.iniFile?.headerList?.businessName}
                    />
                  </div>
                </div>
                {ahid.iniFile?.headerList?.taxYear > 0 && (
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">السنة الضريبية</label>
                      <input
                        className="form-control"
                        type="text"
                        defaultValue="Dreamguy's Technologies"
                        value={ahid.iniFile?.headerList?.taxYear}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            {ahid.iniFile?.headerList?.taxYear === 0 && (
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">بداية التقرير</label>
                      <input
                        className="form-control"
                        type="text"
                        defaultValue="Dreamguy's Technologies"
                        value={getDate(
                          ahid.iniFile?.headerList?.dataPeriodStartDate
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">نهاية التقرير</label>
                      <input
                        className="form-control"
                        type="text"
                        defaultValue="Dreamguy's Technologies"
                        value={getDate(
                          ahid.iniFile?.headerList?.dataPeriodEndDate
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="col-lg-6">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title mb-0">
                    تفاصيل كميات السجلات في ملف BKMVDATA.txt
                  </h4>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-bordered mb-0">
                      <thead>
                        <tr>
                          <th>رمز السجل</th>
                          <th>الوصف</th>
                          <th>عدد السجلات في التقرير</th>
                          <th>عدد السجلات في الملف</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.keys(AhidCodes.BKMVDATA).map((key) => (
                          <tr key={key}>
                            <td>{key}</td>
                            <td>{AhidCodes.BKMVDATA[key].description}</td>
                            <td>{ahid.iniFile.recordsList[key]}</td>
                            <td>
                              {ahid.bkmvFile[AhidCodes.BKMVDATA[key].name].data
                                ?.length || 0}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row">
                <div className="col-sm-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      أنشئت البيانات باستخدام برنامج
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      defaultValue="Dreamguy's Technologies"
                      value={ahid.iniFile?.headerList?.softwareName}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">رقم الترخيص</label>
                    <input
                      className="form-control"
                      type="text"
                      defaultValue="Dreamguy's Technologies"
                      value={ahid.iniFile?.headerList?.softwareId}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">التاريخ</label>
                    <input
                      className="form-control"
                      type="text"
                      defaultValue="Dreamguy's Technologies"
                      value={getDate(
                        ahid.iniFile?.headerList?.processStartDate
                      )}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">الساعة</label>
                    <input
                      className="form-control"
                      type="text"
                      defaultValue="Dreamguy's Technologies"
                      value={getTime(
                        ahid.iniFile?.headerList?.processStartTime
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {/* /Content End */}
      </div>
      {/* /Page Content */}
    </div>
  );
};

export default OpenFormatGeneral;
