/**
 * Basic Table
 */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { formatNumbers } from "../../utils/openformat/numbers";

export default function TbBranchesBalaceSheet(props) {
    const data = useSelector((state) => state.balance).data6111.fullBalanceObj
    const data2 = useSelector((state) => state.balance).data6111PreviousYear?.fullBalanceObj || null

    const [displayData,setDisplayData]=useState([])
    const [total,setTotal]=useState(0)

    const getClasses = (obj) => {
        return obj.isTotalGrp && obj.cardNumber != 8888 && obj.cardNumber != 9999 ? "td-sum-borders td-sum" : !obj.isPositiveDclear ? " td-red" : obj.cardNumber == 8888 || obj.cardNumber == 9999 ? "td-green" : ""
    }

    useEffect(()=>{handleDisplayData()},[props])

    const handleDisplayData=()=>{
        const list=[]
        let tot=0

        props.data.forEach(item=>{
            data2?
            list.push({
                ...item, 
                amount1:data[item.details6111] || 0,
                amount2:data2[item.details6111] || 0,
            }):
            list.push({
                ...item, 
                amount1:data[item.details6111] || 0,
                amount2:0
               
            })
            tot+=(data[item.details6111] || 0)
        })
        setDisplayData(list)
        setTotal(tot)
    }

    
if(props.hideZeros && !total) return null
    return (
        <div className="card  ">
            <div className="card-body">
                <div className="card-header">
                    <h4 className="card-title mb-0 text-center text-primary">{props.title}</h4>
                </div>
                <div className="table-responsive">
                    <table className="table datatable table-nowrap table-striped custom-table mb-0 ">
                        <thead>
                            <tr>
                                {props.headers.map((header, i) => (
                                    <th key={i} className="text-primary">{header}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {displayData.map((obj, i) => (
                                !props.hideZeros ?
                                    <tr key={i}>
                                        <td>{i + 1}</td>
                                        <td >{obj.cardNumber}</td>
                                        <td>{obj.displayName}</td>

                                        <td className={getClasses(obj)}>{formatNumbers(obj.amount1)} </td>
                                        {props.showPercentage && <td >{props?.percentageTotal ? "%" + formatNumbers((data[obj.details6111] || 0) * 100 / props?.percentageTotal) : "0.00%"} </td>}
                                        {data2 && <td className={getClasses(obj)}>{formatNumbers(obj.amount2)} </td>}
                                        {data2 && props.showPercentage && <td >{props?.percentageTotal2 ? "%" + formatNumbers((data2[obj.details6111] || 0) * 100 / props?.percentageTotal2) : "0.00%"} </td>}
                                    </tr> : data[obj.details6111] > 0 ?
                                        <tr key={i}>
                                            <td>{i + 1}</td>
                                            <td>{obj.cardNumber}</td>
                                            <td>{obj.displayName}</td>
                                            <td className={getClasses(obj)}>{formatNumbers(obj.amount1)} </td>
                                            {props.showPercentage && <td >{props?.percentageTotal ? "%" + formatNumbers((data[obj.details6111] || 0) * 100 / props?.percentageTotal) : "0.00%"} </td>}
                                        {data2 &&<td className={getClasses(obj)}>{formatNumbers(obj.amount2)} </td>}
                                        {data2 && props.showPercentage && <td >{props?.percentageTotal2 ? "%" + formatNumbers((data2[obj.details6111] || 0) * 100 / props?.percentageTotal2) : "0.00%"} </td>}

                                        </tr> : ""
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}
