import React from 'react'
import { useSelector } from 'react-redux';
import { multiArray126ToAttendaceObject, multiArrayToObject126 } from '../../../../../utils/s126/arrays126';
import Breadcrumbs from '../../../../../components/Breadcrumbs';
import AttendanceComponent from './AttendanceComponent';
import Header10 from '../../records/record10Header/Header10';
const EmployeeAttendance = () => {
    const salary = useSelector((state) => state.salary);
  const type20=multiArray126ToAttendaceObject(salary.type20)

  console.log(type20)
  // workMonths - totalWorkMonths
  
  return (
    <div>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="شهور العمل"
            title="الرئيسية"
            subtitle="شهور العمل"
          />
          
          {/* <i className="fa fa-check text-success" />
          <i className="fa fa-close text-danger" /> */}
          <Header10 />
          <AttendanceComponent  data={type20}/>
         
        </div>
      </div>

    </div>
  )
}

export default EmployeeAttendance
