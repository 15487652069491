import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Avatar_02,
  Avatar_05,
  Avatar_09,
} from "../../../../../Routes/ImagePath";
import { useSelector } from "react-redux";
import { multiArrayToObject126, object126ToColumns } from "../../../../../utils/s126/arrays126";

const RecordTable = () => {
  const salary = useSelector((state) => state.salary);
  const type20=salary.type20

  const [data,setData]=useState([])
  const [columns,setColumns]=useState([])

  useEffect(()=>{
    if(type20.length>0){
      const dataList=multiArrayToObject126(type20)
      const columnsList=object126ToColumns(type20[0])

      setData(dataList)
      setColumns(columnsList)
    }
  },[type20])



   console.log(data)
 

  // const columns = [
  //   {
  //     title: "Employee Name",
  //     dataIndex: "name",
  //     render: (text, record) => (
  //       <div className="table-avatar">
  //         <Link to="/profile" className="avatar">
  //           <img alt="" src={record.image} />
  //         </Link>
  //         <Link to="/profile" className="text-primary">
  //           {text} <span>{record.number}</span>
  //         </Link>
  //       </div>
  //     ),
  //     sorter: (a, b) => a.name.length - b.name.length,
  //   },
  //   {
  //     title: "Employee Type",
  //     dataIndex: "employeetype",
  //     sorter: (a, b) => a.employeetype.length - b.employeetype.length,
  //   },
  //   {
  //     title: "Email",
  //     dataIndex: "email",
  //     render: (text) => <p className="text-info">{text}</p>,
  //     sorter: (a, b) => a.email.length - b.email.length,
  //   },
  //   {
  //     title: "Department",
  //     dataIndex: "department",
  //     sorter: (a, b) => a.department.length - b.department.length,
  //   },
  //   {
  //     title: "Designation",
  //     dataIndex: "designation",
  //     sorter: (a, b) => a.designation.length - b.designation.length,
  //   },
  //   {
  //     title: "Joining Date",
  //     dataIndex: "joiningdate",
  //     sorter: (a, b) => a.joiningdate.length - b.joiningdate.length,
  //   },
  //   {
  //     title: "DOB",
  //     dataIndex: "dob",
  //     sorter: (a, b) => a.dob.length - b.dob.length,
  //   },
  //   {
  //     title: "Martial Status",
  //     dataIndex: "martialstatus",
  //     sorter: (a, b) => a.martialstatus.length - b.martialstatus.length,
  //   },
  //   {
  //     title: "Gender",
  //     dataIndex: "gender",
  //     sorter: (a, b) => a.gender.length - b.gender.length,
  //   },
  //   {
  //     title: "Terminated Date",
  //     dataIndex: "terminateddate",
  //     sorter: (a, b) => a.terminateddate.length - b.terminateddate.length,
  //   },
  //   {
  //     title: "Relieving Date",
  //     dataIndex: "relievingdate",
  //     sorter: (a, b) => a.relievingdate.length - b.relievingdate.length,
  //   },
  //   {
  //     title: "Salary",
  //     dataIndex: "salary",
  //     sorter: (a, b) => a.salary.length - b.salary.length,
  //   },

  //   {
  //     title: "Address",
  //     dataIndex: "address",
  //     sorter: (a, b) => a.address.length - b.address.length,
  //   },

  //   {
  //     title: "Contact Number",
  //     dataIndex: "contactnumber",
  //     sorter: (a, b) => a.contactnumber.length - b.contactnumber.length,
  //   },

  //   {
  //     title: "Emercency Contact Details",
  //     dataIndex: "emergencycontactdetails",
  //     sorter: (a, b) =>
  //       a.emergencycontactdetails.length - b.emergencycontactdetails.length,
  //   },
  //   {
  //     title: "Experience",
  //     dataIndex: "experience",
  //     sorter: (a, b) => a.experience.length - b.experience.length,
  //   },
  //   {
  //     title: "Status",
  //     render: () => (
  //       <button className="btn btn-outline-success btn-sm">Active</button>
  //     ),
  //   },
  // ];
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="table-responsive">
          <Table
            className="table-striped"
            style={{ overflowX: "auto" }}
            columns={columns}
            dataSource={data}
            rowKey={(record) => record.id}
          />
        </div>
      </div>
    </div>
  );
};

export default RecordTable;
