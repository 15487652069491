import store from "../../store";
import {
  bkmvFile,
  iniFile,
  accounts,
  trialBalanceClassifications,
} from "../../openFormat";
import * as FileStructures from "./filesStructures";
import { arrayToObject } from "./arraysLibrary";

// BKMV FILE
export const bkmvReader = (text) => {
  const data = text.split("\r\n");
  const created = {};
  for (let key in FileStructures.AhidCodes.BKMVDATA) {
    let object = FileStructures.AhidCodes.BKMVDATA[key];

    let list = data.filter((item) => item.startsWith(key));
    created[object.name] = {
      name: object.name,
      title: object.description,
      data: retreiveData(list, structuresList(object.name)),
    };
  }

  store.dispatch(bkmvFile(created));
  const accountsObject = arrayToObject(created.Accounts.data, "accountId");
  store.dispatch(accounts(accountsObject));
  trialClassification(created.Accounts.data);
};

const retreiveData = (arr, structure) => {
  if (!structure) return [];
  const list = [];
  arr.forEach((line) => {
    const record = {};
    structure.forEach((item) => {
      let val;
      if (item.dev_type === "string")
        val = line.substring(item.from - 1, item.to).trim();
      else if (item.dev_type === "int")
        val = line.substring(item.from - 1, item.to).trim() * 1;
      else if (item.dev_type === "decimal") {
        let sign = line.substring(item.from - 1, item.from) === "+" ? 1 : -1;
        val = (line.substring(item.from, item.to).trim() * sign) / item.devider;
      } else {
        val =
          (line.substring(item.from - 1, item.to).trim() * 1) / item.devider;
      }

      record[item.name] = val;
    });
    list.push(record);
  });

  return list;
};

const structuresList = (name) => {
  switch (name) {
    case "File_Opening":
      return FileStructures.BKMVDATA_FileOpeningStructure;
    case "Accounting_Transactions":
      return FileStructures.BKMVDATA_FileAccountingTransactionsStructue;
    case "Accounts":
      return FileStructures.BKMVDATA_FileAccountStructure;
    case "Headers":
      return FileStructures.BKMVDATA_FileHeaderStructure;
    case "Details":
      return FileStructures.BKMVDATA_FileDetailsStructure;
    case "Receipt_Details":
      return FileStructures.BKMVDATA_FileReceiptStructure;
    case "Stock":
      return FileStructures.BKMVDATA_FileStockStructure;
    case "Closing":
      return FileStructures.BKMVDATA_FileClosingStructure;
    default:
      return null;
  }
};

const trialClassification = (arr) => {
  const list = {};
  arr.forEach((item) => {
    if (!list[item.trialBalanceCode])
      list[item.trialBalanceCode] = item.trialBalanceDescription;
  });
  store.dispatch(trialBalanceClassifications(list));
};

// INI FILE
export const iniReader = (text) => {
  console.log(text);
  const dataArr = text.split("\r\n");
  const header = dataArr[0];
  const records = dataArr.slice(1, dataArr.length);

  const headerList = retreiveIniHeader(header);
  const recordsList = retreiveIniRecords(records);

  store.dispatch(iniFile({ headerList, recordsList }));
};

const retreiveIniHeader = (data) => {
  const list = {};
  FileStructures.INI_FileHeaderStructure.forEach((item) => {
    const val =
      item.type === "נומרי"
        ? data.substring(item.start - 1, item.end).trim() * 1
        : data.substring(item.start - 1, item.end).trim();

    list[item.name] = val;
  });
  return list;
};

const retreiveIniRecords = (arr) => {
  const structure = FileStructures.INI_FileSummaryRecordsStructure;
  const list = {};

  arr.forEach((item) => {
    const code = item.substring(structure[0].start - 1, structure[0].end);
    if (code)
      list[code] =
        item.substring(structure[1].start - 1, structure[1].end).trim() * 1;
  });

  return list;
};
