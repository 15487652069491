import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";

const SearchFilter = ({ data, current, isNavigate, setCurrent }) => {
  const navigate = useNavigate();
  const [list, setList] = useState([]);

  useEffect(() => {
    if (data?.length) {
      const arr = [];
      data.forEach((account) => {
        arr.push({
          value: account.accountId,
          label: `${account.accountId} - ${account.accountName}`,
        });
      });
      setList(arr);
    }
  }, [data]);

  const handleChangeSelection = (item) => {
    isNavigate
      ? navigate(`/general-ledger/${item.value.toString()}`)
      : setCurrent(item.value.toString());
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };

  return (
    <>
      <div className="row filter-row">
        <div className="col-sm-6 col-md-3 col-lg-6 col-xl-6 col-12">
          <div className="input-block form-focus select-focus">
            <Select
              options={list}
              placeholder="--إختر--"
              styles={customStyles}
              onChange={(item) => handleChangeSelection(item)}
            />
            <label className="focus-label">اسم الحساب</label>
          </div>
        </div>

        <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
          <Link to="#" className="btn btn-success btn-block w-100">
            {" "}
            Search{" "}
          </Link>
        </div>
      </div>
    </>
  );
};

export default SearchFilter;
