/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import {  Avatar_male, Avatar_female } from "../../../../../Routes/ImagePath";
import { Link } from "react-router-dom";
import ProfileTab from "../../Employees/ProfileTab";
import Breadcrumbs from "../../../../../components/Breadcrumbs";
import { useSelector } from "react-redux";
import { getDate } from "../../../../../utils/openformat/numbers";
import structures from "../../../../../utils/s126/structure126.json";
import { useTranslation } from "react-i18next";
import { FaFilePdf } from "react-icons/fa";
import Header10 from "../../records/record10Header/Header10";
import Form106Header from "./Form106Header";
import AttendanceComponent from "../../Employees/EmployeeAttendace/AttendanceComponent";
import WarningModal from "../../../../../components/modelpopup/WarningModal";
const EmployeeProfile106 = () => {
  const employee = useSelector((state) => state.salary).currentEmployee;


  const data = structures.type10.concat(structures.type20);
  const { t, i18n } = useTranslation();
  // Function to get item based on current language
  const getItemByLanguage = (key) => {
    const currentLanguage = i18n.language; // Get current language
    const item = data.find(item => item.key === key);
    return item ? item[currentLanguage] : ''; // Return translation based on current language
  };

  
  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle={t("form106")}
            title="الرئيسية"
            subtitle="بروفايل"
            modal="#warning-alert-modal"
            
          />
          <Form106Header />
          <div className="card mb-0">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="profile-view">
                    <div className="profile-img-wrap bg-none">
                      <div className="profile-img">
                        <Link to="#">
                          <img src={employee.gender==1?Avatar_male:Avatar_female} alt="User Image" />
                        </Link>
                      </div>
                    </div>
                    <div className="profile-basic">
                      <div className="row">
                        <div className="col-md-5">
                          <div className="profile-info-left">
                            <h3 className="user-name m-t-0 mb-0">
                              {employee.firstName} {employee.lastName}
                            </h3>
                            <h6 className="text-muted ">{employee.role}</h6>
                            <small className="text-muted">
                            {getItemByLanguage("factoryWorkerNumber")} : <span className="text-primary">{employee.factoryWorkerNumber}</span>
                            </small>
                            <div className="staff-id mb-2">
                            {getItemByLanguage('idNumber')}:  <span className="text-primary">{employee.idNumber}</span>
                            </div>
                            <div className="small doj text-muted">
                              {getItemByLanguage("startDate")} : <span className="text-primary">{getDate(employee.startDate)}</span>
                            </div>
                            <div className="staff-id mb-2">
                            {getItemByLanguage('eilatFreeTradeZoneEmployeeStatus')}: <span className="text-primary">{employee.eilatFreeTradeZoneEmployeeStatus
                            }</span>
                            </div>
                            <div className="staff-msg">
                              <Link className="btn btn-custom" to="#"  data-bs-toggle="modal"  data-bs-target="#warning-alert-modal">
                                طباعة كشف 106 <FaFilePdf size={20} />
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-7">
                          <ul className="personal-info">
                            {/* <li>
                              <div className="title w-40">{getItemByLanguage("phone")}:</div>
                              <div className="text text-primary">
                                <Link to={`tel:${employee.phone}`}>
                                  {employee.phone?employee.phone:"--"}
                                </Link>
                              </div>
                            </li> */}
                            {/* <li>
                              <div className="title">{getItemByLanguage("email")}</div>
                              <div className="text">
                                <Link to={`mailto:${employee.email}`}>
                                  {employee.email}
                                </Link>
                              </div>
                            </li> */}
                            <li>
                              <div className="title w-40">{getItemByLanguage('dob')}:</div>
                              <div className="text text-primary">{getDate(employee.dob)}</div>
                            </li>
                            <li>
                              <div className="title w-40">{getItemByLanguage('address')}:</div>
                              <div className="text text-primary">{employee.address}</div>
                            </li>
                            <li>
                              <div className="title w-40">{getItemByLanguage('passportNumber')}:</div>
                              <div className="text text-primary" >{employee.passportNumber?employee.passportNumber:"--"}</div>
                            </li>
                            <li>
                              <div className="title w-40">{getItemByLanguage('foreingCountry')}:</div>
                              <div className="text text-primary">{employee.foreingCountry?employee.foreingCountry:"--"}</div>
                            </li>
                            <li>
                              <div className="title w-40">{getItemByLanguage('gender')}:</div>
                              <div className="text text-primary">{employee.gender}</div>
                            </li>
                            <li>
                              <div className="title w-40">{getItemByLanguage('deductionFileNumber')}:</div>
                              <div className="text text-primary">{employee.deductionFileNumber}</div>
                            </li>
                            <li>
                              <div className="title w-40">{getItemByLanguage('blDeductionsPortfolioNumber')}:</div>
                              <div className="text text-primary">{employee.blDeductionsPortfolioNumber}</div>
                            </li>
                            {/* <li>
                              <div className="title">Reports to:</div>
                              <div className="text">
                                <div className="avatar-box">
                                  <div className="avatar avatar-xs">
                                    <img src={Avatar_16} alt="User Image" />
                                  </div>
                                </div>
                                <Link to="profile">
                                  {employee.supervisor.name}
                                </Link>
                              </div>
                            </li> */}
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* <div className="pro-edit">
                      <Link
                        data-bs-target="#profile_info"
                        data-bs-toggle="modal"
                        className="edit-icon"
                        to="#"
                      >
                        <i className="fa-solid fa-pencil"></i>
                      </Link>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card tab-box">
            <div className="row user-tabs">
              <div className="col-lg-12 col-md-12 col-sm-12 line-tabs">
                <ul className="nav nav-tabs nav-tabs-bottom">
                  <li className="nav-item">
                    <Link
                      to="#emp_profile"
                      data-bs-toggle="tab"
                      className="nav-link active"
                    >
                      عرض الكل
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="#emp_profile"
                      data-bs-toggle="tab"
                      className="nav-link "
                    >
                      بروفايل
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="#emp_projects"
                      data-bs-toggle="tab"
                      className="nav-link"
                    >
                      المدفوعات
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="#bank_statutory"
                      data-bs-toggle="tab"
                      className="nav-link"
                    >
                     صناديق الادخار
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="#bank_statutory"
                      data-bs-toggle="tab"
                      className="nav-link"
                    >
                     تعويضات نهاية الخدمة
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="#bank_statutory"
                      data-bs-toggle="tab"
                      className="nav-link"
                    >
                     مخصصات
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="#bank_statutory"
                      data-bs-toggle="tab"
                      className="nav-link"
                    >
                     اعفاءات و تزكيات
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="#bank_statutory"
                      data-bs-toggle="tab"
                      className="nav-link"
                    >
                     الاقتطاعات
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="#emp_assets"
                      data-bs-toggle="tab"
                      className="nav-link"
                    >
                       أخرى
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* Profile Info Tab */}
          <ProfileTab getItemByLanguage={getItemByLanguage} />
          <AttendanceComponent data={[employee]}/>
          <div className={i18n.language=="en"?"card dir-ltr":"card"}>
            <div className="card-body">
              <div className="row">
                <div className="col-sm-10 m-b-20">
                 <p>{t("paymentAgreement")}</p>
                </div>
                <div className="col-sm-2 m-b-20 ">
                 <p className="signature">{t("signature")}  ________________</p>
                </div>
              </div>

            </div>
          </div>
          <WarningModal />
        </div>
      </div>
    </>
  );
};

export default EmployeeProfile106;
