import React from 'react'
import {
    Bar,
    BarChart,
    CartesianGrid,
    Legend,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
  } from "recharts";

const GraphChartSalary = ({data}) => {
  return (
    <div>
    <div className="row">
      <div className="col-md-12">
        <div className="row">
          {data.map((item,index)=>(
            <SingleChart data={item.data} title={item.title} keys={item.translations} key={index}/>
            // <h2>{item.title}</h2>
          ))}
        </div>
      </div>
    </div>
  </div>
  )
}

export default GraphChartSalary

const SingleChart=({data,title,keys})=>{
    return <div className="col-md-6 text-center">
    <div className="card">
      <div className="card-body">
        <h3 className="card-title">{title}</h3>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            data={data}
            margin={{
              top: 5,
              right: 5,
              left: 5,
              bottom: 5,
            }}
          >
            <CartesianGrid />
            <XAxis dataKey={keys[0]} />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey={keys[1]} fill="#ff9b44" />
            <Bar dataKey={keys[2]} fill="#fc6075" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  </div>
  }