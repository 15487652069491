import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import EmployeeListFilter from "../../Employees/EmployeeListFilter";

const BudgetTable = ({columns,data}) => {
  const [list,setList]=useState([])

  useEffect(()=>{
    setList(data)
  },[])

  const handleSearch=(text)=>{
    if(!text) {setList(data)

      return
    }
    const filtered=data.filter(item=>item.firstName.includes(text) || item.lastName.includes(text) ||item.idNumber.includes(text))
    setList(filtered)
  }

  return (
    <>
    <EmployeeListFilter search={handleSearch}/>
      <Table
        className=" table-striped page-a-table "
        style={{ overflowX: "auto" }}
        columns={columns}
        dataSource={list}
        rowKey={(record) => record.id}
      />
    </>
  );
};

export default BudgetTable;
