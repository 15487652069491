import React, { useEffect, useState } from "react";
import { Table } from "antd";
import Breadcrumbs from "../../../components/Breadcrumbs";
import SearchBox from "../../../components/SearchBox";
import { useSelector } from "react-redux";
import {
  formatNumbers,
  sumArrayProperty,
} from "../../../utils/openformat/numbers";
import { useTranslation } from "react-i18next";
import { DocumnetTypes } from "../../../utils/openformat/filesStructures";
import OpenFormatHeader from "./OpenFormatHeader";
const OpenFormatDocumentsIndex = () => {
  const [dataList, setDataList] = useState([]);
  const { t } = useTranslation();
  const ahid = useSelector((state) => state.ahid);

  useEffect(() => {
    createIndex();
  }, []);

  const columns = [
    {
      title: "معرف المستند",
      dataIndex: "openFormatDocNum",
      sorter: (a, b) => a.openFormatDocNum - b.openFormatDocNum,
    },
    {
      title: "الوصف عربي",
      dataIndex: "arabicDocName",
      sorter: (a, b) => a.arabicDocName < b.arabicDocName,
    },
    {
      title: "عبري",
      dataIndex: "hebrewDocName",
      sorter: (a, b) => a.hebrewDocName < b.hebrewDocName,
    },
    {
      title: "إنجليزي",
      dataIndex: "englishDocName",
      sorter: (a, b) => a.englishDocName < b.englishDocName,
    },
    {
      title: "العدد",
      dataIndex: "count",
      sorter: (a, b) => a.count < b.count,
    },
    {
      title: "المجموع",
      dataIndex: "totalNoVat",
      render: (text) => (
        <span className="table-avatar">{formatNumbers(text)}</span>
      ),
      sorter: (a, b) => a.totalNoVat < b.totalNoVat,
    },
    {
      title: "المجموع شامل ض.ق.م",
      dataIndex: "totalWithVat",
      render: (text) => (
        <span className="table-avatar">{formatNumbers(text)}</span>
      ),
      sorter: (a, b) => a.totalWithVat < b.totalWithVat,
    },
  ];

  const createIndex = () => {
    const data = ahid.bkmvFile?.Headers?.data;

    if (data?.length) {
      const list = [];
      DocumnetTypes.forEach((item) => {
        const documents = data.filter(
          (document) => document.documentType === item.openFormatDocNum
        );
        list.push({
          ...item,
          count: documents?.length,
          totalNoVat: sumArrayProperty(documents, "totalAfterDiscountNoVat"),
          totalWithVat: sumArrayProperty(documents, "totalWithVat"),
        });
      });

      setDataList(list);
    }
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs
            maintitle={t("documentsIndex")}
            title={t("dashboard")}
            subtitle={t("documentsIndex")}
          />
          {/* /Page Header */}
          {/* <div className="row">
            <div className="card card-header">
              <h2 className="card-title mb-0">
                {ahid.iniFile?.headerList?.businessName}
              </h2>
              <h4 className="card-title mb-0">
                {ahid.iniFile?.headerList?.Identification}
              </h4>
            </div>
          </div> */}
          <OpenFormatHeader />
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0 data-table-card">
                <div className="card-header">
                  <h4 className="card-title mb-0">قائمة أنواع المستندات</h4>
                  {/* <p className="card-text">
                    This is the most basic example of the datatables with zero
                    configuration. Use the <code>.datatable</code> class to
                    initialize datatables.
                  </p> */}
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    {/* <SearchBox /> */}
                    <Table
                      pagination={false}
                      className="table-stripped"
                      columns={columns}
                      dataSource={
                        dataList?.length > 0
                          ? dataList?.map((item) => ({
                              ...item,
                              key: item.openFormatDocNum,
                            }))
                          : []
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OpenFormatDocumentsIndex;
