const balancesheetAccounts = [
  {
    group_id: 0,
    group_id2: 0,
    cardNumber: 0,
    displayName: "غير مفند",
    group_name_AR: "غير مفند",
    group_name_EN: "Not Categorized",
    group_name_HE: "לא מסווג",
    category: 3,
    group_bs: 0,
    group_code: 1,
    position: 1,
    debit_credit: 1,
    period_id: 4
  },
  {
    group_id: 1000,
    group_id2: 201,
    cardNumber: 201,
    displayName: "إجمالي الموجودات  المتداولة",
    group_name_AR: "إجمالي الموجودات  المتداولة",
    group_name_EN: "Total Current Assets",
    group_name_HE: "רכוש שוטף  ",
    category: 3,
    group_bs: 0,
    group_code: 1,
    position: 1,
    debit_credit: 1,
    group_name_eng: 4
  },
  {
    group_id: 2000,
    group_id2: 150,
    cardNumber: 150,
    displayName: "أوراق مالية",
    group_name_AR: "أوراق مالية",
    group_name_EN: "Securities",
    group_name_HE: "ניירות ערך ",
    category: 3,
    group_bs: 0,
    group_code: 1,
    position: 1,
    debit_credit: 1,
    group_name_eng: 4
  },
  {
    group_id: 2000,
    group_id2: 152,
    cardNumber: 152,
    displayName: "الجرد :",
    group_name_AR: "الموجودات  المتداولة : الجرد",
    group_name_EN: "Inventory",
    group_name_HE: "מלאי  ",
    category: 3,
    group_bs: 0,
    group_code: 1,
    position: 1,
    debit_credit: 1,
    group_name_eng: 4
  },
  {
    group_id: 2000,
    group_id2: 154,
    cardNumber: 154,
    displayName: "قروض قصيرة الأجل :",
    group_name_AR: "قروض قصيرة الأجل",
    group_name_EN: "Short-term loans",
    group_name_HE: "הלוואות לזמן קצר  ",
    category: 3,
    group_bs: 0,
    group_code: 1,
    position: 1,
    debit_credit: 1,
    group_name_eng: 4
  },

  {
    group_id: 2000,
    group_id2: 155,
    cardNumber: 155,
    displayName: "ضرائب مؤجلة قصيرة الأجل",
    group_name_AR: "ضرائب مؤجلة قصيرة الأجل",
    group_name_EN: "Short-term deferred taxes",
    group_name_HE: "מסים נדחים לזמן  קצר  ",
    category: 3,
    group_bs: 0,
    group_code: 1,
    position: 1,
    debit_credit: 1,
    group_name_eng: 4
  },
  {
    group_id: 2000,
    group_id2: 157,
    cardNumber: 157,
    displayName: "مدينون وأرصدة مدينة",
    group_name_AR: "مدينون وأرصدة مدينة",
    group_name_EN: "Accounts receivable and Debt  balances",
    group_name_HE: "חייבים ויתרות חובה   ",
    category: 3,
    group_bs: 0,
    group_code: 1,
    position: 1,
    debit_credit: 1,
    group_name_eng: 4
  },
  {
    group_id: 2000,
    group_id2: 158,
    cardNumber: 158,
    displayName: " الزبائن ",
    group_name_AR: " الموجودات  المتداولة :  الزبائن   ",
    group_name_EN: "Customers",
    group_name_HE: "לקוחות ",
    category: 3,
    group_bs: 0,
    group_code: 1,
    position: 1,
    debit_credit: 1,
    group_name_eng: 4
  },
  {
    group_id: 1000,
    group_id2: 159,
    cardNumber: 158,
    displayName: " النقد وما في حكمه",
    group_name_AR: " النقد وما في حكمه",
    group_name_EN: "Cash and Cash Equivalents",
    group_name_HE: " מזומנים ושווי מזומנים ",
    category: 3,
    group_bs: 0,
    group_code: 1,
    position: 1,
    debit_credit: 1,
    group_name_eng: 4
  },
  {
    group_id: 2000,
    group_id2: 210,
    cardNumber: 210,
    displayName: "استثمارات قصيرة الأجل",
    group_name_AR: "استثمارات قصيرة الأجل",
    group_name_EN: "Shor Term Investment",
    group_name_HE: "השקעות לזמן קצר",
    category: 3,
    group_bs: 0,
    group_code: 1,
    position: 1,
    debit_credit: 1,
    group_name_eng: 4
  },
  {
    group_id: 3000,
    group_id2: 100,
    cardNumber: 100,
    displayName: "موجودات ثابتة",
    group_name_AR: "موجودات ثابتة",
    group_name_EN: "Fixed Assets",
    group_name_HE: "רכוש קבוע",
    category: 1,
    group_bs: 0,
    group_code: 1,
    position: 1,
    debit_credit: 1,
    group_name_eng: 4
  },
  {
    group_id: 4000,
    group_id2: 110,
    cardNumber: 110,
    displayName: "قروض طويلة الأجل",
    group_name_AR: "قروض طويلة الأجل",
    group_name_EN: "Long Term Loans",
    group_name_HE: "הלוואות לזמן ארוך",
    category: 1,
    group_bs: 0,
    group_code: 1,
    position: 1,
    debit_credit: 1,
    group_name_eng: 4
  },
  {
    group_id: 5000,
    group_id2: 115,
    cardNumber: 115,
    displayName: "نفقات مقدمة طويلة الأجل ",
    group_name_AR: "نفقات مقدمة طويلة الأجل ",
    group_name_EN: "Long-term prepaid expenses",
    group_name_HE: "הוצאות מראש לזמן  ארון ",
    category: 1,
    group_bs: 0,
    group_code: 1,
    position: 1,
    debit_credit: 1,
    group_name_eng: 4
  },
  {
    group_id: 5000,
    group_id2: 120,
    cardNumber: 120,
    displayName: "الاستثمارات في الشركات المملوكة , مضمنه وموحدة ",
    group_name_AR: "الاستثمارات في الشركات المملوكة , مضمنه وموحدة ",
    group_name_EN: "Investments in held companies are included and consolidated",
    group_name_HE: "השקעות בחברות מוחזקות  כלולות ומאוחדות ",
    category: 1,
    group_bs: 0,
    group_code: 1,
    position: 1,
    debit_credit: 1,
    group_name_eng: 4
  },
  {
    group_id: 5000,
    group_id2: 122,
    cardNumber: 122,
    displayName: "الاستثمارات في شركات أخرى بما في ذلك الأوراق المالية القابلة للتسويق والمحتفظ بها لفترة طويلة ",
    group_name_AR: "الاستثمارات في شركات أخرى بما في ذلك الأوراق المالية القابلة للتسويق والمحتفظ بها لفترة طويلة ",
    group_name_EN: "Investments in other companies including marketable securities held for a long ",
    group_name_HE: "השקעות בחברות אחרות כולל ניירות ערך סחירים מוחזקים לזמן ארוך  ",
    category: 1,
    group_bs: 0,
    group_code: 1,
    position: 1,
    debit_credit: 1,
    group_name_eng: 4
  },
  {
    group_id: 7000,
    group_id2: 125,
    cardNumber: 125,
    displayName: "ضرائب مؤجلة طويلة الأجل",
    group_name_AR: "ضرائب مؤجلة طويلة الأجل",
    group_name_EN: "Long-term deferred taxes",
    group_name_HE: "מסים נדחים לזמן  ארוך ",
    category: 1,
    group_bs: 0,
    group_code: 1,
    position: 1,
    debit_credit: 1,
    group_name_eng: 4
  },
  {
    group_id: 7000,
    group_id2: 130,
    cardNumber: 130,
    displayName: "موجوادت أخرى ومصاريف مؤجلة",
    group_name_AR: "موجوادت أخرى ومصاريف مؤجلة",
    group_name_EN: "Other Assets and Long Term Expenses",
    group_name_HE: "רכוש אחר והוצאות נדחות",
    category: 1,
    group_bs: 0,
    group_code: 1,
    position: 1,
    debit_credit: 1,
    group_name_eng: 4
  },
  {
    group_id: 9000,
    group_id2: 401,
    cardNumber: 401,
    displayName: "مطلوبات متداولة",
    group_name_AR: "مطلوبات متداولة",
    group_name_EN: "Current Liabilities",
    group_name_HE: "התחייבויות שוטפות",
    category: 2,
    group_bs: 1,
    group_code: 2,
    position: 1,
    debit_credit: 2,
    group_name_eng: 4
  },
  {
    group_id: 9000,
    group_id2: 402,
    cardNumber: 402,
    displayName: "الالتزامات للموردين ومقدمي الخدمة ",
    group_name_AR: "الالتزامات للموردين ومقدمي الخدمة ",
    group_name_EN: "Obligations to suppliers and service providers",
    group_name_HE: "התחייבויות לספקים ונותני שירותים",
    category: 2,
    group_bs: 1,
    group_code: 2,
    position: 1,
    debit_credit: 2,
    group_name_eng: 4
  },
  {
    group_id: 9000,
    group_id2: 403,
    cardNumber: 403,
    displayName: " دائنين وارصدة دائنة  ",
    group_name_AR: " دائنين وارصدة دائنة  ",
    group_name_EN: "Creditors and other credit balances",
    group_name_HE: "זכאים ויתרות  זכות  ",
    category: 2,
    group_bs: 1,
    group_code: 2,
    position: 1,
    debit_credit: 2,
    group_name_eng: 4
  },
  {
    group_id: 9000,
    group_id2: 405,
    cardNumber: 405,
    displayName: " مخصصات الضرائب المؤجلة  قصيرة الأمد   ",
    group_name_AR: "مخصصات الضرائب المؤجلة  قصيرة الأمد  ",
    group_name_EN: "Short-term deferred tax provisions",
    group_name_HE: "עתודות למסים נדחים לזמן קצר ",
    category: 2,
    group_bs: 1,
    group_code: 2,
    position: 1,
    debit_credit: 2,
    group_name_eng: 4
  },
  {
    group_id: 9000,
    group_id2: 408,
    cardNumber: 408,
    displayName: "إجمالي المطلوبات المتداولة",
    group_name_AR: "إجمالي المطلوبات المتداولة",
    group_name_EN: "Total Current Liabilities",
    group_name_HE: "סך ההתחייבויות השוטפות ",
    category: 2,
    group_bs: 1,
    group_code: 2,
    position: 1,
    debit_credit: 2,
    group_name_eng: 4
  },
  {
    group_id: 10000,
    group_id2: 410,
    cardNumber: 410,
    displayName: "مطلوبات طويلة الأجل",
    group_name_AR: "مطلوبات طويلة الأجل",
    group_name_EN: "Long Term Laibilities",
    group_name_HE: "התחייבויות לזמן ארוך",
    category: 2,
    group_bs: 1,
    group_code: 2,
    position: 1,
    debit_credit: 2,
    group_name_eng: 4
  },
  {
    group_id: 11000,
    group_id2: 415,
    cardNumber: 415,
    displayName: "مخصصات",
    group_name_AR: "مخصصات",
    group_name_EN: "Reserves",
    group_name_HE: "עתודות",
    category: 2,
    group_bs: 1,
    group_code: 2,
    position: 1,
    debit_credit: 2,
    group_name_eng: 4
  },
  {
    group_id: 12000,
    group_id2: 417,
    cardNumber: 417,
    displayName: "لالتزامات الناجمة عن إنهاء العلاقة بين صاحب العمل والعامل",
    group_name_AR: "لالتزامات الناجمة عن إنهاء العلاقة بين صاحب العمل والعامل",
    group_name_EN: "Reserve for Net Retirement Compensation",
    group_name_HE: "עתודה לפיצויי פרישה נטו",
    category: 2,
    group_bs: 1,
    group_code: 2,
    position: 1,
    debit_credit: 2,
    group_name_eng: 4
  },
  {
    group_id: 13000,
    group_id2: 420,
    cardNumber: 420,
    displayName: "مخصصات الضرائب المؤجلة  طويلة الأمد ",
    group_name_AR: "مخصصات الضرائب المؤجلة  طويلة الأمد ",
    group_name_EN: "Long-term deferred tax provisions",
    group_name_HE: "עתודות למסים נדחים לזמן ארוך ",
    category: 2,
    group_bs: 1,
    group_code: 2,
    position: 1,
    debit_credit: 2,
    group_name_eng: 4
  },
  {
    group_id: 14000,
    group_id2: 425,
    cardNumber: 425,
    displayName: "منحة استثمارية",
    group_name_AR: "منحة استثمارية",
    group_name_EN: "Inverstment Grant",
    group_name_HE: "מענק השקעה",
    category: 2,
    group_bs: 1,
    group_code: 2,
    position: 1,
    debit_credit: 2,
    group_name_eng: 4
  },
  {
    group_id: 16000,
    group_id2: 600,
    cardNumber: 600,
    displayName: "رأس مال",
    group_name_AR: "رأس مال",
    group_name_EN: "Equity",
    group_name_HE: "הון",
    category: 4,
    group_bs: 1,
    group_code: 3,
    position: 1,
    debit_credit: 2,
    group_name_eng: 4
  },
  {
    group_id: 16500,
    group_id2: 605,
    cardNumber: 605,
    displayName: "صناديق",
    group_name_AR: "صناديق",
    group_name_EN: "Funds",
    group_name_HE: "קרנות",
    category: 4,
    group_bs: 1,
    group_code: 3,
    position: 1,
    debit_credit: 2,
    group_name_eng: 4
  },
  {
    group_id: 17000,
    group_id2: 690,
    cardNumber: 690,
    displayName: "أرباح متراكمة لبداية السنة",
    group_name_AR: "أرباح متراكمة لبداية السنة",
    group_name_EN: "Opening Balance Earnings",
    group_name_HE: "עודפים יתרה לתחילת השנה",
    category: 4,
    group_bs: 1,
    group_code: 4,
    position: 1,
    debit_credit: 2,
    group_name_eng: 4
  },
  {
    group_id: 20000,
    group_id2: 700,
    cardNumber: 700,
    displayName: "إيرادات",
    group_name_AR: "إيرادات",
    group_name_EN: "Income from Services",
    group_name_HE: "הכנסות",
    category: 5,
    group_bs: 2,
    group_code: 6,
    position: 1,
    debit_credit: 2,
    group_name_eng: 4
  },
  {
    group_id: 28000,
    group_id2: 705,
    cardNumber: 705,
    displayName: "مبيعات",
    group_name_AR: "مبيعات",
    group_name_EN: "Income from Goods",
    group_name_HE: "מכירות",
    category: 5,
    group_bs: 2,
    group_code: 6,
    position: 1,
    debit_credit: 2,
    group_name_eng: 4
  },
  {
    group_id: 34000,
    group_id2: 710,
    cardNumber: 710,
    displayName: "كلفة مبيعات",
    group_name_AR: "كلفة مبيعات",
    group_name_EN: "Cost of Income",
    group_name_HE: "עלות העבוד והמכירות",
    category: 5,
    group_bs: 2,
    group_code: 7,
    position: 1,
    debit_credit: 1,
    group_name_eng: 4
  },
  {
    group_id: 34000,
    group_id2: 712,
    cardNumber: 712,
    displayName: "تكاليف الإنتاج",
    group_name_AR: "تكاليف الإنتاج",
    group_name_EN: "Cost of Income",
    group_name_HE: "עלות העבוד והמכירות",
    category: 5,
    group_bs: 2,
    group_code: 7,
    position: 1,
    debit_credit: 1,
    group_name_eng: 4
  },
  {
    group_id: 34000,
    group_id2: 715,
    cardNumber: 715,
    displayName: "مصروفات البحث والتطوير",
    group_name_AR: "مصروفات البحث والتطوير",
    group_name_EN: "Cost of Income",
    group_name_HE: "עלות העבוד והמכירות",
    category: 5,
    group_bs: 2,
    group_code: 7,
    position: 1,
    debit_credit: 1,
    group_name_eng: 4
  },
  {
    group_id: 37000,
    group_id2: 801,
    cardNumber: 801,
    displayName: "مصاريف البيع",
    group_name_AR: "مصاريف البيع",
    group_name_EN: "Income Expenses",
    group_name_HE: "הוצאות מכירה",
    category: 5,
    group_bs: 2,
    group_code: 7,
    position: 1,
    debit_credit: 1,
    group_name_eng: 4
  },
  {
    group_id: 38000,
    group_id2: 805,
    cardNumber: 805,
    displayName: "مصاريف إدارية وأخرى",
    group_name_AR: "مصاريف إدارية وأخرى",
    group_name_EN: "Management Expenses",
    group_name_HE: "הוצאות הנהלה וכלליות",
    category: 5,
    group_bs: 2,
    group_code: 7,
    position: 1,
    debit_credit: 1,
    group_name_eng: 4
  },
  {
    group_id: 40000,
    group_id2: 830,
    cardNumber: 830,
    displayName: "مصاريف تمويل - إيرادات",
    group_name_AR: "مصاريف تمويل - إيرادات",
    group_name_EN: "Financial Income - Expenses",
    group_name_HE: "הוצאות/הכנסות מימון",
    category: 5,
    group_bs: 2,
    group_code: 7,
    position: 1,
    debit_credit: 1,
    group_name_eng: 4
  },
  {
    group_id: 40000,
    group_id2: 832,
    cardNumber: 832,
    displayName: "  إيرادات التمويل",
    group_name_AR: "  إيرادات التمويل",
    group_name_EN: "Financial Income ",
    group_name_HE: "הכנסות מימון",
    category: 5,
    group_bs: 2,
    group_code: 7,
    position: 1,
    debit_credit: 1,
    group_name_eng: 4
  },
  {
    group_id: 43000,
    group_id2: 850,
    cardNumber: 850,
    displayName: "مصاريف أخرى - إيرادات أخرى",
    group_name_AR: "مصاريف أخرى - إيرادات أخرى",
    group_name_EN: "Other Expenses - Income",
    group_name_HE: "הכנסות/הוצאות אחרות",
    category: 5,
    group_bs: 2,
    group_code: 7,
    position: 1,
    debit_credit: 1,
    group_name_eng: 4
  },
  {
    group_id: 43000,
    group_id2: 858,
    cardNumber: 858,
    displayName: "إجمالي الربح / الخسارة قبل الضرائب",
    group_name_AR: "إجمالي الربح / الخسارة قبل الضرائب",
    group_name_EN: "Net Income Befor Taxes",
    group_name_HE: "רווח נקי לפני מיסים ",
    category: 5,
    group_bs: 2,
    group_code: 7,
    position: 1,
    debit_credit: 1,
    group_name_eng: 4
  },
  {
    group_id: 44000,
    group_id2: 860,
    cardNumber: 860,
    displayName: "مصاريف ضريبة الدخل",
    group_name_AR: "مصاريف ضريبة الدخل",
    group_name_EN: "Income tax Expenses",
    group_name_HE: "מיסים על הכנסה",
    category: 5,
    group_bs: 2,
    group_code: 7,
    position: 1,
    debit_credit: 1,
    group_name_eng: 4
  },
  {
    group_id: 44000,
    group_id2: 865,
    cardNumber: 865,
    displayName: "صافي  الربح / الخسارة بعد  الضرائب",
    group_name_AR: "صافي  الربح / الخسارة بعد  الضرائب",
    group_name_EN: "Net Income After Tax ",
    group_name_HE: "רווח נקי לאחר  מיסים ",
    category: 5,
    group_bs: 2,
    group_code: 7,
    position: 1,
    debit_credit: 1,
    group_name_eng: 4
  },
  {
    group_id: 45000,
    group_id2: 870,
    cardNumber: 870,
    displayName: "أرباح رأس مالية",
    group_name_AR: "أرباح رأس مالية",
    group_name_EN: "Capital Gains",
    group_name_HE: "ריווחי אקוויטי",
    category: 5,
    group_bs: 2,
    group_code: 7,
    position: 1,
    debit_credit: 1,
    group_name_eng: 4
  },
  {
    group_id: 57000,
    group_id2: 880,
    cardNumber: 880,
    displayName: "توزيع الأرباح",
    group_name_AR: "توزيع الأرباح",
    group_name_EN: "Purpose of the Profits",
    group_name_HE: "יעוד הרווחים",
    category: 5,
    group_bs: 2,
    group_code: 5,
    position: 1,
    debit_credit: 1,
    period_id: 4
  },
  {
    group_id: 9999,
    group_id2: 699,
    cardNumber: 9999,
    displayName: "إجمالي المطلوبات ورأس المال",
    group_name_AR: "إجمالي المطلوبات ورأس المال",
    group_name_EN: "Total liabilities and capital",
    group_name_HE: "סך התחייבויות והון",
    category: 6,
    group_bs: 2,
    group_code: 5,
    position: 1,
    debit_credit: 1,
    period_id: 4
  },
  {
    group_id: 3999,
    group_id2: 399,
    cardNumber: 3999,
    displayName: "إجمالي الأصول الثابتة والمتداولة",
    group_name_AR: "إجمالي الأصول الثابتة والمتداولة",
    group_name_EN: "Total fixed and current assets",
    group_name_HE: "סך הרכוש הקבוע והשוטף",
    category: 6,
    group_bs: 2,
    group_code: 5,
    position: 1,
    debit_credit: 1,
    period_id: 4
  }
]
// function to sort the data in objects each object contain data array => contain objects with the same category ==> [
// {
//   category: 3,
//     data: [{ obj1 }, { obj2 }, { obj3 }]
// },
// {
//   category: 1,
//     data: [{ obj1 }, { obj2 }, { obj3 }]
// }]
const sortAndGroupByCategory = (data) => {
  const sortedData = data.sort((a, b) => a.category - b.category);
  const groupedData = [];

  sortedData.forEach((item) => {
    const { category } = item;
    const existingGroup = groupedData.find((group) => group.category === category);

    if (existingGroup) {
      existingGroup.data.push(item);
    } else {
      groupedData.push({ category, data: [item] });
    }
  });

  return groupedData;
};

const balancesheetAccountsByCategory = sortAndGroupByCategory(balancesheetAccounts);

export {balancesheetAccounts,balancesheetAccountsByCategory}