import React from 'react'
import { useSelector } from 'react-redux';
import { arrayToObject126 } from "../../../../../utils/s126/arrays126";
const Header10 = () => {
  const salary = useSelector((state) => state.salary);
  const companyData = arrayToObject126(salary.type10)
  const type40Data = arrayToObject126(salary.type40[0])
  // console.log("Type40?:",type40Data)

  return (

      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-sm-4 m-b-20">
                  {/* <img src={Applogo} className="inv-logo" alt="Logo" /> */}
                  <ul className="list-unstyled mb-0">
                    <li> {companyData.employerName}</li>
                    {/* <li className='text-primary'>{companyData.taxFileNumber}</li> */}
                    {/* <li> {type40Data.companyNumber}</li> */}
                    <li>{companyData.street} , {companyData.city}</li>
                    <li> </li>

                   
                  </ul>
                </div>
                <div className="col-sm-4 m-b-20">
                  <ul className="list-unstyled mb-0 text-center">
                    <li><span  className='text-primary'>{companyData.taxYear}</span></li>
                    <li> {type40Data.companyNumber} , {companyData.taxFileNumber}</li>
                  </ul>
                </div>
                <div className="col-sm-4 m-b-20">
                  <ul className="list-unstyled mb-0 text-left">
                    <li> {type40Data.phoneNumber}</li>
                    <li> {type40Data.email}</li>
                  </ul>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
  )
}

export default Header10
