import { Table } from "antd";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { formatNumbers, getDate } from "../../../utils/openformat/numbers";
import { useDispatch, useSelector } from "react-redux";
import { currentDocument } from "../../../openFormat";

const InvoiceTable = ({ data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const ahid = useSelector((state) => state.ahid).bkmvFile || [];

  console.log(ahid);
  const handleViewDocument = (e, documentNumber) => {
    e.preventDefault();
    const header = data.filter(
      (item) => item.documentNumber == documentNumber
    )[0];

    let details;
    if (header.documentType === 320 || header.documentType === 400) {
      details = ahid?.Receipt_Details?.data.filter(
        (item) => item.documentNumber == documentNumber
      );
    } else
      details = ahid?.Details?.data.filter(
        (item) => item.documentNumber == documentNumber
      );
    dispatch(currentDocument({ header, details }));

    navigate("/fin/invoice-view");
  };

  const columns = [
    {
      title: "#",
      dataIndex: "num",
      sorter: (a, b) => a.num - b.num,
    },
    {
      title: "رقم الفاتورة",
      dataIndex: "documentNumber",
      render: (text) => (
        <Link onClick={(e) => handleViewDocument(e, text)}>#{text}</Link>
      ),
      sorter: (a, b) => a.documentNumber.length - b.documentNumber.length,
    },
    {
      title: "الزبون",
      dataIndex: "custVendName",
      sorter: (a, b) => a.custVendName.length - b.custVendName.length,
    },

    {
      title: "التاريخ",
      dataIndex: "documentDate",
      render: (text) => getDate(text),
      sorter: (a, b) => a.documentDate.length - b.documentDate.length,
    },
    {
      title: "تاريخ الاستحقاق",
      dataIndex: "valueDate",
      render: (text) => getDate(text),
      sorter: (a, b) => a.valueDate.length - b.valueDate.length,
    },
    {
      title: "المبلغ",
      dataIndex: "totalWithVat",
      render: (text) => <span>₪ {formatNumbers(text * 1)}</span>,
      sorter: (a, b) => a.totalWithVat - b.totalWithVat,
    },
  ];

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="table-responsive">
          <Table
            columns={columns}
            dataSource={data?.length > 0 ? data : []}
            rowKey={(record) => record.id}
            pagination={false}
            className="table table-striped custom-table mb-0"
          />
        </div>
      </div>
    </div>
  );
};

export default InvoiceTable;
