import React, { useEffect, useState } from "react";
import { Table } from "antd";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import TicketModelPopup from "../../../components/modelpopup/TicketModelPopup";
import Breadcrumbs from "../../../components/Breadcrumbs";
import TicketFilter from "../../../components/TicketFilter";
import { useSelector } from "react-redux";
import {
  formatNumbers,
  sumArrayProperties,
} from "../../../utils/openformat/numbers";
import { useTranslation } from "react-i18next";
import OpenFormatHeader from "./OpenFormatHeader";
const OpenFormatAccounts = () => {
  const { t } = useTranslation();
  const [totals, setTotals] = useState([]);
  const [data, setData] = useState([]);
  const ahid = useSelector((state) => state.ahid);
  //   const data = useSelector((state) => state.ahid).bkmvFile.Accounts.data;
  //   console.log(data);

  useEffect(() => {
    totalToUI();
  }, []);

  const totalToUI = () => {
    let arr = ahid.bkmvFile.Accounts.data;
    const list = [];
    arr.forEach((element) => {
      let object = {
        ...element,
        endTotal: element.starterAmount + element.debit - element.credit,
      };
      list.push(object);
    });
    setData(list);
    getTotals(list);
  };

  const getTotals = (list) => {
    const sums = sumArrayProperties(list, ["starterAmount", "credit", "debit"]);

    const totals = [
      {
        title: "مجموع الأرصدة الافتتاحية",
        value: formatNumbers(sums.starterAmount),
        percentage: "",
      },
      {
        title: "مجموع المدين",
        value: formatNumbers(sums.debit),
        percentage: "",
      },
      {
        title: "مجموع الدائن",
        value: formatNumbers(sums.credit),
        percentage: "",
      },
      {
        title: "الرصيد",
        value: formatNumbers(sums.starterAmount + sums.debit - sums.credit),
        percentage: "",
      },
    ];

    setTotals(totals);
  };

  const columns = [
    {
      title: "الكود",
      dataIndex: "code",
      sorter: (a, b) => a.code < b.code,
    },
    {
      title: "رقم السجل",
      dataIndex: "recordId",
      sorter: (a, b) => a.recordId < b.recordId,
    },
    {
      title: "رقم الحساب",
      dataIndex: "accountId",
      sorter: (a, b) => a.accountId - b.accountId,
    },
    {
      title: "مشتغل مرخص",
      dataIndex: "custVendAuthorizedDealer",
      sorter: (a, b) => a.custVendAuthorizedDealer - b.custVendAuthorizedDealer,
    },

    {
      title: "اسم الحساب",
      dataIndex: "accountName",
      sorter: (a, b) => a.accountName < b.accountName,
    },
    {
      title: "تصنيف الحساب",
      dataIndex: "trialBalanceCode",

      sorter: (a, b) => a.trialBalanceCode - b.trialBalanceCode,
    },
    {
      title: "اسم التصنيف",
      dataIndex: "trialBalanceDescription",

      sorter: (a, b) => a.trialBalanceDescription < b.trialBalanceDescription,
    },
    {
      title: "رصيد أول المدة",
      dataIndex: "starterAmount",
      render: (text) => (
        <span className="table-avatar">{formatNumbers(text)}</span>
      ),
      sorter: (a, b) => a.starterAmount - b.starterAmount,
    },

    {
      title: "مدين",
      dataIndex: "debit",
      render: (text) => (
        <span className="table-avatar">{formatNumbers(text)}</span>
      ),
      sorter: (a, b) => a.debit - b.debit,
    },
    {
      title: "دائن",
      dataIndex: "credit",
      render: (text) => (
        <span className="table-avatar">{formatNumbers(text)}</span>
      ),
      sorter: (a, b) => a.debit - b.debit,
    },
    {
      title: "الرصيد",
      dataIndex: "endTotal",
      render: (text) => (
        <span className="table-avatar">{formatNumbers(text)}</span>
      ),
      sorter: (a, b) => a.debit - b.debit,
    },
    {
      title: "الدولة",
      dataIndex: "custVendCountry",

      sorter: (a, b) => a.custVendCountry < b.custVendCountry,
    },
    {
      title: "المدينة",
      dataIndex: "custVendCity",

      sorter: (a, b) => a.custVendCity < b.custVendCity,
    },
    {
      title: "الشارع",
      dataIndex: "custVendStreet",

      sorter: (a, b) => a.custVendStreet < b.custVendStreet,
    },
    {
      title: "رقم المنزل",
      dataIndex: "custVendHouse",

      sorter: (a, b) => a.custVendHouse < b.custVendHouse,
    },
    {
      title: "الرمز البريدي",
      dataIndex: "custVendPostalCode",

      sorter: (a, b) => a.custVendPostalCode < b.custVendPostalCode,
    },
    // {
    //   title: "Priority",
    //   dataIndex: "priority",
    //   render: () => (
    //     <div className="dropdown action-label">
    //       <Link
    //         className="btn btn-white btn-sm btn-rounded dropdown-toggle"
    //         to="#"
    //         data-bs-toggle="dropdown"
    //         aria-expanded="false">
    //         <i className="far fa-dot-circle text-danger" /> High{" "}
    //       </Link>
    //       <div className="dropdown-menu dropdown-menu-right">
    //         <Link className="dropdown-item" to="#">
    //           <i className="far fa-dot-circle text-danger" /> High
    //         </Link>
    //         <Link className="dropdown-item" to="#">
    //           <i className="far fa-dot-circle text-warning" /> Medium
    //         </Link>
    //         <Link className="dropdown-item" to="#">
    //           <i className="far fa-dot-circle text-success" /> Low
    //         </Link>
    //       </div>
    //     </div>
    //   ),
    //   sorter: (a, b) => a.priority.length - b.priority.length,
    // },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   render: () => (
    //     <div className="dropdown action-label text-center">
    //       <Link
    //         className="btn btn-white btn-sm btn-rounded dropdown-toggle"
    //         to="#"
    //         data-bs-toggle="dropdown"
    //         aria-expanded="false">
    //         <i className="far fa-dot-circle text-danger" /> New
    //       </Link>
    //       <div className="dropdown-menu dropdown-menu-right">
    //         <Link className="dropdown-item" to="#">
    //           <i className="far fa-dot-circle text-info" /> Open
    //         </Link>
    //         <Link className="dropdown-item" to="#">
    //           <i className="far fa-dot-circle text-info" /> Reopened
    //         </Link>
    //         <Link className="dropdown-item" to="#">
    //           <i className="far fa-dot-circle text-danger" /> On Hold
    //         </Link>
    //         <Link className="dropdown-item" to="#">
    //           <i className="far fa-dot-circle text-success" /> Closed
    //         </Link>
    //         <Link className="dropdown-item" to="#">
    //           <i className="far fa-dot-circle text-success" /> In Progress
    //         </Link>
    //         <Link className="dropdown-item" to="#">
    //           <i className="far fa-dot-circle text-danger" /> Cancelled
    //         </Link>
    //       </div>
    //     </div>
    //   ),
    //   sorter: (a, b) => a.status.length - b.status.length,
    // },
    // {
    //   title: "Action",
    //   render: () => (
    //     <div className="dropdown dropdown-action text-end">
    //       <Link
    //         to="#"
    //         className="action-icon dropdown-toggle"
    //         data-bs-toggle="dropdown"
    //         aria-expanded="false">
    //         <i className="material-icons">more_vert</i>
    //       </Link>
    //       <div className="dropdown-menu dropdown-menu-right">
    //         <Link
    //           className="dropdown-item"
    //           to="#"
    //           data-bs-toggle="modal"
    //           data-bs-target="#edit_ticket">
    //           <i className="fa fa-pencil m-r-5" /> Edit
    //         </Link>
    //         <Link
    //           className="dropdown-item"
    //           to="#"
    //           data-bs-toggle="modal"
    //           data-bs-target="#delete">
    //           <i className="fa fa-trash m-r-5" /> Delete
    //         </Link>
    //       </div>
    //     </div>
    //   ),
    //   sorter: true,
    // },
  ];

  return (
    <>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs
            maintitle={t("accounts")}
            title={t("dashboard")}
            subtitle={t("accounts")}
            modal="#add_ticket"
            name="Add Ticket"
          />
          <OpenFormatHeader />
          <div className="row">
            <div className="col-md-12">
              <div className="card-group m-b-30">
                {totals?.map((total, index) => (
                  <div className="card" key={index}>
                    <div className="card-body">
                      <div className="d-flex justify-content-between mb-3">
                        <div>
                          <span className="d-block">{total.title}</span>
                        </div>
                        <div>
                          <span
                            className={
                              total.percentage.includes("-")
                                ? "text-danger"
                                : "text-success"
                            }>
                            {total.percentage}
                          </span>
                        </div>
                      </div>
                      <h3 className="mb-3">{total.value}</h3>
                      <div className="progress mb-2" style={{ height: "5px" }}>
                        <div
                          className="progress-bar bg-primary"
                          role="progressbar"
                          style={{ width: "70%" }}
                          aria-valuenow={40}
                          aria-valuemin={0}
                          aria-valuemax={100}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <TicketFilter />

          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <Table
                  className="table-striped"
                  rowKey={(record) => record.id}
                  style={{ overflowX: "auto" }}
                  columns={columns}
                  dataSource={data?.length > 0 ? data : []}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <TicketModelPopup />
      <DeleteModal Name="Delete Ticket" />
    </>
  );
};

export default OpenFormatAccounts;
