import React from "react";
import Breadcrumbs from "../../../../../components/Breadcrumbs";
import { useTranslation } from "react-i18next";

const PLanalysis = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs
            maintitle={t("profitAndLossAnalysis")}
            title="Dashboard"
            subtitle="Blank Page"
             modal="#warning-alert-modal"
          />
          {/* /Page Header */}
          {/* Content Starts */}
       {/* Rounded Button */}
       <div className="card card-buttons">
            <div className="card-body">
              <button type="button" className="btn btn-rounded btn-outline-primary me-1">
                مصاريف الرواتب
              </button>
              <button type="button" className="btn btn-rounded btn-outline-warning me-1">
              مصاريف الاستهلاك
              </button>
              <button type="button" className="btn btn-rounded btn-outline-danger me-1">
                مصاريف التمويل
              </button>
              <button type="button" className="btn btn-rounded btn-outline-secondary me-1">
                Secondary
              </button>
              <button type="button" className="btn btn-rounded btn-outline-success me-1">
                Success
              </button>
              <button type="button" className="btn btn-rounded btn-outline-info me-1">
                Info
              </button>
              <button type="button" className="btn btn-rounded btn-outline-dark me-1">
                Dark
              </button>
              <button type="button" className="btn btn-rounded btn-outline-light me-1">
                اظهار الكل
              </button>
            </div>
          </div>
          {/* /Rounded Button */}
          {/* /Content End */}
        </div>
        {/* /Page Content */}
      </div>
    </>
  );
};
export default PLanalysis;
