/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Applogo } from "../../../Routes/ImagePath";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import { useDispatch } from "react-redux";
import { resetFunctionwithlogin } from "../../../components/ResetFunction";
import { useSelector } from "react-redux";
import useAuthentication from "../../../hooks/useAuthentication";

const Login = () => {
  const details = localStorage.getItem("loginDetails");
  const user = useSelector((state) => state.user).value;

  const { signin, getCurrentUser } = useAuthentication();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    localStorage.setItem("colorschema", "dark");
    localStorage.setItem("layout", "vertical");
    localStorage.setItem("layoutwidth", "fixed");
    localStorage.setItem("layoutpos", "fluid");
    localStorage.setItem("topbartheme", "dark");
    localStorage.setItem("layoutSized", "lg");
    localStorage.setItem("layoutStyling", "default");
    localStorage.setItem("layoutSidebarStyle", "dark");

    // navigate("/admin-dashboard");
    // navigate("/ft-intro");

    const result = await signin(email.trim().toLowerCase(), password);
    setMessage(result);
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  useEffect(() => {
    if (user && user.isVerified) navigate("/ft-intro");
    else if (user && !user.isVerified)
      setMessage("إفحص البريد الإلكتروني من أجل تفعيل الحساب.");
  }, [user]);

  const [eye, seteye] = useState(true);

  const onEyeClick = () => {
    seteye(!eye);
  };

  return (
    <div>
      <div className="account-page">
        <div className="main-wrapper">
          <div className="account-content">
            <Link to="/ft-intro" className="btn btn-primary apply-btn">
              FinTech Light
            </Link>
            <div className="container">
              {/* Account Logo */}
              <div className="account-logo">
                <Link to="/admin-dashboard">
                  <img src={Applogo} alt="Dreamguy's Technologies" />
                </Link>
              </div>
              {/* /Account Logo */}
              <div className="account-box">
                <div className="account-wrapper">
                  <h3 className="account-title">تسجيل الدخول</h3>
                  <p className="account-subtitle">الدخول الى نظام Fintrack </p>
                  {/* Account Form */}
                  <div>
                    <form onSubmit={handleSubmit}>
                      <div className="input-block mb-4">
                        <label className="col-form-label">
                          البريد الالكتروني
                        </label>

                        <input
                          className={`form-control`}
                          type="text"
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                          autoComplete="true"
                          placeholder="user@fintrack.expert"
                        />

                        <span className="text-danger"> </span>
                      </div>
                      <div className="input-block mb-4">
                        <div className="row">
                          <div className="col">
                            <label className="col-form-label">
                              كلمة المرور
                            </label>
                          </div>
                          <div className="col-auto">
                            <Link className="text-muted" to="/forgot-password">
                              نسيت كلمة المرور؟
                            </Link>
                          </div>
                        </div>
                        <div style={{ position: "relative" }}>
                          <input
                            className={`form-control `}
                            type="password"
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                            autoComplete="off"
                          />
                          <span
                            style={{
                              position: "absolute",
                              left: "5%",
                              top: "30%",
                            }}
                            onClick={onEyeClick}
                            className={`fa-solid ${
                              eye ? "fa-eye-slash" : "fa-eye"
                            } `}
                          />
                        </div>
                        <span className="text-danger"> </span>
                      </div>
                      <div className="input-block text-center">
                        <button
                          className="btn btn-primary account-btn"
                          type="submit"
                        >
                          تسجيل الدخول
                        </button>
                      </div>
                    </form>
                    <div className="account-footer">
                      {message && <p className="text-danger">{message}</p>}
                      <p>
                        ليس لديك حساب؟{" "}
                        <Link to="/register">انشاء حساب جديد</Link>
                      </p>
                    </div>
                  </div>
                  {/* /Account Form */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
