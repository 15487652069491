const file6111 = [
  {
    group_name_AR: "المبيعات محلية",
    displayName: "المبيعات محلية",
    details6111: 1010,
    cardNumber: 1010,
    id: 1010,
    category: "الإيرادات من مبيعات الخدمات",
    finalReportsCode: 28000,
    finalReportsCode2: 700,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "إيرادات من بيع المباني كمقاول بناء",
    displayName: "إيرادات من بيع المباني كمقاول بناء",
    details6111: 1015,
    cardNumber: 1015,
    id: 1015,
    category: "الإيرادات من مبيعات الخدمات",
    finalReportsCode: 28000,
    finalReportsCode2: 700,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "المبيعات في الخارج",
    displayName: "المبيعات في الخارج",
    details6111: 1020,
    cardNumber: 1020,
    id: 1020,
    category: "الإيرادات من مبيعات الخدمات",
    finalReportsCode: 28000,
    finalReportsCode2: 700,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "إيرادات من تنفيذ الأعمال كمقاول منفذ",
    displayName: "إيرادات من تنفيذ الأعمال كمقاول منفذ",
    details6111: 1025,
    cardNumber: 1025,
    id: 1025,
    category: "الإيرادات من مبيعات الخدمات",
    finalReportsCode: 28000,
    finalReportsCode2: 700,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "الدخل من تقديم الخدمات محلية",
    displayName: "الدخل من تقديم الخدمات محلية",
    details6111: 1030,
    cardNumber: 1030,
    id: 1030,
    category: "الإيرادات من مبيعات الخدمات",
    finalReportsCode: 20000,
    finalReportsCode2: 700,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "الدخل من تقديم الخدمات في الخارج",
    displayName: "الدخل من تقديم الخدمات في الخارج",
    details6111: 1040,
    cardNumber: 1040,
    id: 1040,
    category: "الإيرادات من مبيعات الخدمات",
    finalReportsCode: 20000,
    finalReportsCode2: 700,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "إيرادات من أطراف ذات صلة في البلاد",
    displayName: "إيرادات من أطراف ذات صلة في البلاد",
    details6111: 1051,
    cardNumber: 1051,
    id: 1051,
    category: "الإيرادات من مبيعات الخدمات",
    finalReportsCode: 20000,
    finalReportsCode2: 700,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "إيرادات من أطراف ذات صلة في الخارج",
    displayName: "إيرادات من أطراف ذات صلة في الخارج",
    details6111: 1052,
    cardNumber: 1052,
    id: 1051,
    category: "الإيرادات من مبيعات الخدمات",
    finalReportsCode: 20000,
    finalReportsCode2: 700,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "إيرادات أخرى ",
    displayName: "إيرادات أخرى ",
    details6111: 1090,
    cardNumber: 1090,
    id: 1090,
    category: "الإيرادات من مبيعات الخدمات",
    finalReportsCode: 20000,
    finalReportsCode2: 700,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "إجمالي الدخل والرسوم وتقديم الخدمات",
    displayName: "إجمالي الدخل والرسوم وتقديم الخدمات",
    details6111: 1000,
    cardNumber: 1000,
    id: 1000,
    category: "الإيرادات من مبيعات الخدمات",
    finalReportsCode: 20000,
    finalReportsCode2: 700,
    isTotalGrp: 1,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "الأجور وبدلات الإنتاج ",
    displayName: "الأجور وبدلات الإنتاج ",
    details6111: 1306,
    cardNumber: 1306,
    id: 1306,
    category: "تكلفة المبيعات وتقديم الخدمات",
    finalReportsCode: 34000,
    finalReportsCode2: 710,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 1,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مخصصات الرواتب ",
    displayName: "مخصصات الرواتب ",
    details6111: 1307,
    cardNumber: 1307,
    id: 1307,
    category: "تكلفة المبيعات وتقديم الخدمات",
    finalReportsCode: 34000,
    finalReportsCode2: 710,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 1,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مصروفات رواتب مقابل خيارات الموظفين ",
    displayName: "مصروفات رواتب مقابل خيارات الموظفين ",
    details6111: 1308,
    cardNumber: 1308,
    id: 1308,
    category: "تكلفة المبيعات وتقديم الخدمات",
    finalReportsCode: 34000,
    finalReportsCode2: 710,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 1,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مصاريف عمالة خارجية و مقاولون  ",
    displayName: "مصاريف عمالة خارجية و مقاولون  ",
    details6111: 1310,
    cardNumber: 1310,
    id: 1310,
    category: "تكلفة المبيعات وتقديم الخدمات",
    finalReportsCode: 34000,
    finalReportsCode2: 710,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مشتريات بضاعة محلية ",
    displayName: "مشتريات بضاعة محلية ",
    details6111: 1320,
    cardNumber: 1320,
    id: 1320,
    category: "تكلفة المبيعات وتقديم الخدمات",
    finalReportsCode: 34000,
    finalReportsCode2: 710,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مشتريات مواد خام ",
    displayName: "مشتريات مواد خام ",
    details6111: 1330,
    cardNumber: 1330,
    id: 1330,
    category: "تكلفة المبيعات وتقديم الخدمات",
    finalReportsCode: 34000,
    finalReportsCode2: 710,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مشتريات الاستيراد و تكلفة الاستيراد ",
    displayName: "مشتريات الاستيراد و تكلفة الاستيراد ",
    details6111: 1340,
    cardNumber: 1340,
    id: 1340,
    category: "تكلفة المبيعات وتقديم الخدمات",
    finalReportsCode: 34000,
    finalReportsCode2: 710,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "فروق سعر الصرف للمشتريات بالعملة الأجنبية",
    displayName: "فروق سعر الصرف للمشتريات بالعملة الأجنبية",
    details6111: 1350,
    cardNumber: 1350,
    id: 1350,
    category: "تكلفة المبيعات وتقديم الخدمات",
    finalReportsCode: 34000,
    finalReportsCode2: 710,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مصاريف شكاوى كلفة المبيعات ",
    displayName: "مصاريف شكاوى كلفة المبيعات ",
    details6111: 1360,
    cardNumber: 1360,
    id: 1360,
    category: "تكلفة المبيعات وتقديم الخدمات",
    finalReportsCode: 34000,
    finalReportsCode2: 710,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مشتريات من أطراف ذات صلة",
    displayName: "مشتريات من أطراف ذات صلة",
    details6111: 1370,
    cardNumber: 1370,
    id: 1370,
    category: "تكلفة المبيعات وتقديم الخدمات",
    finalReportsCode: 34000,
    finalReportsCode2: 710,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مشتريات من أطراف ذات صلة  محلية  ",
    displayName: "مشتريات من أطراف ذات صلة  محلية  ",
    details6111: 1371,
    cardNumber: 1371,
    id: 1371,
    category: "تكلفة المبيعات وتقديم الخدمات",
    finalReportsCode: 34000,
    finalReportsCode2: 710,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: " مشتريات من أطراف ذات صلة في الخارج",
    displayName: " مشتريات من أطراف ذات صلة في الخارج",
    details6111: 1372,
    cardNumber: 1372,
    id: 1372,
    category: "تكلفة المبيعات وتقديم الخدمات",
    finalReportsCode: 34000,
    finalReportsCode2: 710,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "تكاليف أخرى ",
    displayName: "تكاليف أخرى ",
    details6111: 1390,
    cardNumber: 1390,
    id: 1390,
    category: "تكلفة المبيعات وتقديم الخدمات",
    finalReportsCode: 34000,
    finalReportsCode2: 710,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "المخزون في بداية الفترة ",
    displayName: "المخزون في بداية الفترة ",
    details6111: 1400,
    cardNumber: 1400,
    id: 1400,
    category: "تكلفة المبيعات وتقديم الخدمات",
    finalReportsCode: 34000,
    finalReportsCode2: 710,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "المخزون في نهاية الفترة ",
    displayName: "المخزون في نهاية الفترة ",
    details6111: 1450,
    cardNumber: 1450,
    id: 1450,
    category: "تكلفة المبيعات وتقديم الخدمات",
    finalReportsCode: 34000,
    finalReportsCode2: 710,
    isTotalGrp: 0,
    isPositiveDclear: 0,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "التكلفة الإجمالية للمبيعات وتقديم الخدمات",
    displayName: "التكلفة الإجمالية للمبيعات وتقديم الخدمات",
    details6111: 1300,
    cardNumber: 1300,
    id: 1300,
    category: "تكلفة المبيعات وتقديم الخدمات",
    finalReportsCode: 34000,
    finalReportsCode2: 710,
    isTotalGrp: 1,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "المخزون في بداية الفترة ",
    displayName: "المخزون في بداية الفترة ",
    details6111: 2005,
    cardNumber: 2005,
    id: 2005,
    category: "تكاليف الإنتاج",
    finalReportsCode: 34000,
    finalReportsCode2: 712,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مصروفات رواتب مقابل خيارات الموظفين ",
    displayName: "مصروفات رواتب مقابل خيارات الموظفين ",
    details6111: 2006,
    cardNumber: 2006,
    id: 2006,
    category: "تكاليف الإنتاج",
    finalReportsCode: 34000,
    finalReportsCode2: 712,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 1,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مصروفات رواتب",
    displayName: "مصروفات رواتب",
    details6111: 2011,
    cardNumber: 2011,
    id: 2011,
    category: "تكاليف الإنتاج",
    finalReportsCode: 34000,
    finalReportsCode2: 712,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 1,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مخصصات الرواتب ",
    displayName: "مخصصات الرواتب ",
    details6111: 2012,
    cardNumber: 2012,
    id: 2012,
    category: "تكاليف الإنتاج",
    finalReportsCode: 34000,
    finalReportsCode2: 712,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 1,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "تكاليف الأراضي والتطوير لدى المقاول",
    displayName: "تكاليف الأراضي والتطوير لدى المقاول",
    details6111: 2015,
    cardNumber: 2015,
    id: 2010,
    category: "تكاليف الإنتاج",
    finalReportsCode: 34000,
    finalReportsCode2: 712,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "الأجور وبدلات الإنتاج",
    displayName: "الأجور وبدلات الإنتاج",
    details6111: 2010,
    cardNumber: 2010,
    id: 2010,
    category: "تكاليف الإنتاج",
    finalReportsCode: 34000,
    finalReportsCode2: 712,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مصاريف عمالة خارجية و مقاولون ",
    displayName: "مصاريف عمالة خارجية و مقاولون ",
    details6111: 2020,
    cardNumber: 2020,
    id: 2020,
    category: "تكاليف الإنتاج",
    finalReportsCode: 34000,
    finalReportsCode2: 712,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مصاريف المسئولية والمطالبات ",
    displayName: "مصاريف المسئولية والمطالبات ",
    details6111: 2025,
    cardNumber: 2025,
    id: 2025,
    category: "تكاليف الإنتاج",
    finalReportsCode: 34000,
    finalReportsCode2: 712,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مصاريف المصنع",
    displayName: "مصاريف المصنع",
    details6111: 2030,
    cardNumber: 2030,
    id: 2030,
    category: "تكاليف الإنتاج",
    finalReportsCode: 34000,
    finalReportsCode2: 712,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مصاريف أدوات  مستهلكة ",
    displayName: "مصاريف أدوات  مستهلكة ",
    details6111: 2035,
    cardNumber: 2035,
    id: 2035,
    category: "تكاليف الإنتاج",
    finalReportsCode: 34000,
    finalReportsCode2: 712,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مصاريف النقل والتخزين",
    displayName: "مصاريف النقل والتخزين",
    details6111: 2040,
    cardNumber: 2040,
    id: 2040,
    category: "تكاليف الإنتاج",
    finalReportsCode: 34000,
    finalReportsCode2: 712,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مصاريف التعبئة",
    displayName: "مصاريف التعبئة",
    details6111: 2045,
    cardNumber: 2045,
    id: 2045,
    category: "تكاليف الإنتاج",
    finalReportsCode: 34000,
    finalReportsCode2: 712,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مصاريف الصيانة والإصلاح",
    displayName: "مصاريف الصيانة والإصلاح",
    details6111: 2050,
    cardNumber: 2050,
    id: 2050,
    category: "تكاليف الإنتاج",
    finalReportsCode: 34000,
    finalReportsCode2: 712,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "نفقات البحث والتطوير",
    displayName: "نفقات البحث والتطوير",
    details6111: 2055,
    cardNumber: 2055,
    id: 2055,
    category: "تكاليف الإنتاج",
    finalReportsCode: 34000,
    finalReportsCode2: 712,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "تأمين",
    displayName: "تأمين",
    details6111: 2060,
    cardNumber: 2060,
    id: 2060,
    category: "تكاليف الإنتاج",
    finalReportsCode: 34000,
    finalReportsCode2: 712,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "صيانة السيارات ونقلها",
    displayName: "صيانة السيارات ونقلها",
    details6111: 2065,
    cardNumber: 2065,
    id: 2065,
    category: "تكاليف الإنتاج",
    finalReportsCode: 34000,
    finalReportsCode2: 712,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "تكاليف صيانة المركبات والنقل باستثناء الوقود",
    displayName: "تكاليف صيانة المركبات والنقل باستثناء الوقود",
    details6111: 2066,
    cardNumber: 2066,
    id: 2066,
    category: "تكاليف الإنتاج",
    finalReportsCode: 34000,
    finalReportsCode2: 712,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: " تكاليف الوقود قبل الاسترداد",
    displayName: " تكاليف الوقود قبل الاسترداد",
    details6111: 2067,
    cardNumber: 2067,
    id: 2067,
    category: "تكاليف الإنتاج",
    finalReportsCode: 34000,
    finalReportsCode2: 712,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "استرداد ضريبة البلو على الوقود",
    displayName: "استرداد ضريبة البلو على الوقود",
    details6111: 2068,
    cardNumber: 2068,
    id: 2068,
    category: "تكاليف الإنتاج",
    finalReportsCode: 34000,
    finalReportsCode2: 712,
    isTotalGrp: 0,
    isPositiveDclear: 0,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "رسوم الإيجار وإدارة الممتلكات",
    displayName: "رسوم الإيجار وإدارة الممتلكات",
    details6111: 2070,
    cardNumber: 2070,
    id: 2070,
    category: "تكاليف الإنتاج",
    finalReportsCode: 34000,
    finalReportsCode2: 712,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "الضرائب والرسوم",
    displayName: "الضرائب والرسوم",
    details6111: 2075,
    cardNumber: 2075,
    id: 2075,
    category: "تكاليف الإنتاج",
    finalReportsCode: 34000,
    finalReportsCode2: 712,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "استهلاك الإنتاج",
    displayName: "استهلاك الإنتاج",
    details6111: 2080,
    cardNumber: 2080,
    id: 2080,
    category: "تكاليف الإنتاج",
    finalReportsCode: 34000,
    finalReportsCode2: 712,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 1,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "ملابس العمل",
    displayName: "ملابس العمل",
    details6111: 2085,
    cardNumber: 2085,
    id: 2085,
    category: "تكاليف الإنتاج",
    finalReportsCode: 34000,
    finalReportsCode2: 712,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "تكاليف الإنتاج الأخرى",
    displayName: "تكاليف الإنتاج الأخرى",
    details6111: 2090,
    cardNumber: 2090,
    id: 2090,
    category: "تكاليف الإنتاج",
    finalReportsCode: 34000,
    finalReportsCode2: 712,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مخزون نهاية الفترة",
    displayName: "مخزون نهاية الفترة",
    details6111: 2095,
    cardNumber: 2095,
    id: 2095,
    category: "تكاليف الإنتاج",
    finalReportsCode: 34000,
    finalReportsCode2: 712,
    isTotalGrp: 0,
    isPositiveDclear: 0,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "إجمالي تكاليف الإنتاج",
    displayName: "إجمالي تكاليف الإنتاج",
    details6111: 2000,
    cardNumber: 2000,
    id: 2000,
    category: "تكاليف الإنتاج",
    finalReportsCode: 34000,
    finalReportsCode2: 712,
    isTotalGrp: 1,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مصروفات رواتب",
    displayName: "مصروفات رواتب",
    details6111: 2510,
    cardNumber: 2510,
    id: 2510,
    category: "مصروفات البحث والتطوير",
    finalReportsCode: 34000,
    finalReportsCode2: 715,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 1,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مخصصات الرواتب ",
    displayName: "مخصصات الرواتب ",
    details6111: 2520,
    cardNumber: 2520,
    id: 2520,
    category: "مصروفات البحث والتطوير",
    finalReportsCode: 34000,
    finalReportsCode2: 715,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 1,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مصروفات رواتب مقابل خيارات الموظفين ",
    displayName: "مصروفات رواتب مقابل خيارات الموظفين ",
    details6111: 2530,
    cardNumber: 2530,
    id: 2530,
    category: "مصروفات البحث والتطوير",
    finalReportsCode: 34000,
    finalReportsCode2: 715,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 1,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مصاريف عمالة خارجية ",
    displayName: "مصاريف عمالة خارجية ",
    details6111: 2540,
    cardNumber: 2540,
    id: 2540,
    category: "مصروفات البحث والتطوير",
    finalReportsCode: 34000,
    finalReportsCode2: 715,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "استهلاك",
    displayName: "استهلاك",
    details6111: 2550,
    cardNumber: 2550,
    id: 2550,
    category: "مصروفات البحث والتطوير",
    finalReportsCode: 34000,
    finalReportsCode2: 715,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 1,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "تكاليف صيانة المركبات",
    displayName: "تكاليف صيانة المركبات",
    details6111: 2560,
    cardNumber: 2560,
    id: 2560,
    category: "مصروفات البحث والتطوير",
    finalReportsCode: 34000,
    finalReportsCode2: 715,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مكافآت البحث والتطوير ",
    displayName: "مكافآت البحث والتطوير ",
    details6111: 2570,
    cardNumber: 2570,
    id: 2570,
    category: "مصروفات البحث والتطوير",
    finalReportsCode: 34000,
    finalReportsCode2: 715,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "تكاليف أخرى  ",
    displayName: "تكاليف أخرى  ",
    details6111: 2590,
    cardNumber: 2590,
    id: 2590,
    category: "مصروفات البحث والتطوير",
    finalReportsCode: 34000,
    finalReportsCode2: 715,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "إجمالي مصروفات البحث والتطوير",
    displayName: "إجمالي مصروفات البحث والتطوير",
    details6111: 2500,
    cardNumber: 2500,
    id: 2500,
    category: "مصروفات البحث والتطوير",
    finalReportsCode: 34000,
    finalReportsCode2: 715,
    isTotalGrp: 1,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مصاريف الرواتب (تشغيلية)",
    displayName: "مصاريف الرواتب (تشغيلية)",
    details6111: 3010,
    cardNumber: 3010,
    id: 3010,
    category: "مصاريف البيع الدعاية والترويج ",
    finalReportsCode: 37000,
    finalReportsCode2: 801,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 1,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مخصصات الرواتب ",
    displayName: "مخصصات الرواتب ",
    details6111: 3011,
    cardNumber: 3011,
    id: 3011,
    category: "مصاريف البيع الدعاية والترويج ",
    finalReportsCode: 37000,
    finalReportsCode2: 801,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 1,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مخصصات الرواتب ",
    displayName: "مخصصات الرواتب ",
    details6111: 3012,
    cardNumber: 3012,
    id: 3012,
    category: "مصاريف البيع الدعاية والترويج ",
    finalReportsCode: 37000,
    finalReportsCode2: 801,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 1,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مصروفات رواتب مقابل خيارات الموظفين ",
    displayName: "مصروفات رواتب مقابل خيارات الموظفين ",
    details6111: 3013,
    cardNumber: 3013,
    id: 3013,
    category: "مصاريف البيع الدعاية والترويج ",
    finalReportsCode: 37000,
    finalReportsCode2: 801,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 1,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مصاريف النقل والتخزين",
    displayName: "مصاريف النقل والتخزين",
    details6111: 3015,
    cardNumber: 3015,
    id: 3015,
    category: "مصاريف البيع الدعاية والترويج ",
    finalReportsCode: 37000,
    finalReportsCode2: 801,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مصاريف مقاولين الدعاية  ",
    displayName: "مصاريف مقاولين الدعاية  ",
    details6111: 3020,
    cardNumber: 3020,
    id: 3020,
    category: "مصاريف البيع الدعاية والترويج ",
    finalReportsCode: 37000,
    finalReportsCode2: 801,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "تأمين",
    displayName: "تأمين",
    details6111: 3025,
    cardNumber: 3025,
    id: 3025,
    category: "مصاريف البيع الدعاية والترويج ",
    finalReportsCode: 37000,
    finalReportsCode2: 801,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "العمولات والمكافآت للوكلاء المستقلين",
    displayName: "العمولات والمكافآت للوكلاء المستقلين",
    details6111: 3030,
    cardNumber: 3030,
    id: 3030,
    category: "مصاريف البيع الدعاية والترويج  الدعاية والترويج ",
    finalReportsCode: 37000,
    finalReportsCode2: 801,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "الإتاوات (حقوق التوزيع)",
    displayName: "الإتاوات (حقوق التوزيع)",
    details6111: 3040,
    cardNumber: 3040,
    id: 3040,
    category: "مصاريف البيع الدعاية والترويج  الدعاية والترويج ",
    finalReportsCode: 37000,
    finalReportsCode2: 801,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مصاريف التعبئة",
    displayName: "مصاريف التعبئة",
    details6111: 3045,
    cardNumber: 3045,
    id: 3045,
    category: "مصاريف البيع الدعاية والترويج  الدعاية والترويج ",
    finalReportsCode: 37000,
    finalReportsCode2: 801,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مصاريف الصيانة والاصلاح",
    displayName: "مصاريف الصيانة والاصلاح",
    details6111: 3050,
    cardNumber: 3050,
    id: 3050,
    category: "مصاريف البيع الدعاية والترويج  الدعاية والترويج ",
    finalReportsCode: 37000,
    finalReportsCode2: 801,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "نفقات البحث والتطوير",
    displayName: "نفقات البحث والتطوير",
    details6111: 3055,
    cardNumber: 3055,
    id: 3055,
    category: "مصاريف البيع الدعاية والترويج ",
    finalReportsCode: 37000,
    finalReportsCode2: 801,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مصاريف السفر",
    displayName: "مصاريف السفر",
    details6111: 3060,
    cardNumber: 3060,
    id: 3060,
    category: "مصاريف البيع الدعاية والترويج ",
    finalReportsCode: 37000,
    finalReportsCode2: 801,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "صيانة السيارات ونقلها",
    displayName: "صيانة السيارات ونقلها",
    details6111: 3065,
    cardNumber: 3065,
    id: 3065,
    category: "مصاريف البيع الدعاية والترويج ",
    finalReportsCode: 37000,
    finalReportsCode2: 801,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "تكاليف صيانة المركبات والنقل باستثناء الوقود",
    displayName: "تكاليف صيانة المركبات والنقل باستثناء الوقود",
    details6111: 3066,
    cardNumber: 3066,
    id: 3066,
    category: "مصاريف البيع الدعاية والترويج ",
    finalReportsCode: 37000,
    finalReportsCode2: 801,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: " تكاليف الوقود قبل الاسترداد",
    displayName: " تكاليف الوقود قبل الاسترداد",
    details6111: 3067,
    cardNumber: 3067,
    id: 3067,
    category: "مصاريف البيع الدعاية والترويج ",
    finalReportsCode: 37000,
    finalReportsCode2: 801,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "استرداد ضريبة البلو على الوقود",
    displayName: "استرداد ضريبة البلو على الوقود",
    details6111: 3068,
    cardNumber: 3068,
    id: 3068,
    category: "مصاريف البيع الدعاية والترويج ",
    finalReportsCode: 37000,
    finalReportsCode2: 801,
    isTotalGrp: 0,
    isPositiveDclear: 0,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "رسوم الإيجار وإدارة الممتلكات",
    displayName: "رسوم الإيجار وإدارة الممتلكات",
    details6111: 3070,
    cardNumber: 3070,
    id: 3070,
    category: "مصاريف البيع الدعاية والترويج ",
    finalReportsCode: 37000,
    finalReportsCode2: 801,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "المناقصات والمعارض والمعارض",
    displayName: "المناقصات والمعارض والمعارض",
    details6111: 3075,
    cardNumber: 3075,
    id: 3075,
    category: "مصاريف البيع الدعاية والترويج ",
    finalReportsCode: 37000,
    finalReportsCode2: 801,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "استهلاك",
    displayName: "استهلاك",
    details6111: 3080,
    cardNumber: 3080,
    id: 3080,
    category: "مصاريف البيع الدعاية والترويج ",
    finalReportsCode: 37000,
    finalReportsCode2: 801,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 1,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "ملابس العمل",
    displayName: "ملابس العمل",
    details6111: 3085,
    cardNumber: 3085,
    id: 3085,
    category: "مصاريف البيع الدعاية والترويج ",
    finalReportsCode: 37000,
    finalReportsCode2: 801,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "الكهرباء والماء",
    displayName: "الكهرباء والماء",
    details6111: 3090,
    cardNumber: 3090,
    id: 3090,
    category: "مصاريف البيع الدعاية والترويج ",
    finalReportsCode: 37000,
    finalReportsCode2: 801,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "الرسوم وبطاقات الائتمان",
    displayName: "الرسوم وبطاقات الائتمان",
    details6111: 3100,
    cardNumber: 3100,
    id: 3100,
    category: "مصاريف البيع الدعاية والترويج ",
    finalReportsCode: 37000,
    finalReportsCode2: 801,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "الدعاية والإعلان والترويج للمبيعات",
    displayName: "الدعاية والإعلان والترويج للمبيعات",
    details6111: 3120,
    cardNumber: 3120,
    id: 3120,
    category: "مصاريف البيع الدعاية والترويج ",
    finalReportsCode: 37000,
    finalReportsCode2: 801,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "صافي مصاريف دعاية أخرى ",
    displayName: "صافي مصاريف دعاية أخرى ",
    details6111: 3190,
    cardNumber: 3190,
    id: 3190,
    category: "مصاريف البيع الدعاية والترويج ",
    finalReportsCode: 37000,
    finalReportsCode2: 801,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "إجمالي مصاريف البيع الدعاية والترويج ",
    displayName: "إجمالي مصاريف البيع الدعاية والترويج ",
    details6111: 3000,
    cardNumber: 3000,
    id: 3000,
    category: "مصاريف البيع الدعاية والترويج ",
    finalReportsCode: 37000,
    finalReportsCode2: 801,
    isTotalGrp: 1,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "رواتب إدارية",
    displayName: "رواتب إدارية",
    details6111: 3510,
    cardNumber: 3510,
    id: 3510,
    category: "مصاريف ادارية وعمومية",
    finalReportsCode: 38000,
    finalReportsCode2: 805,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 1,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "رواتب إدارية",
    displayName: "رواتب إدارية",
    details6111: 3511,
    cardNumber: 3511,
    id: 3511,
    category: "مصاريف ادارية وعمومية",
    finalReportsCode: 38000,
    finalReportsCode2: 805,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 1,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مخصصات الرواتب ",
    displayName: "مخصصات الرواتب ",
    details6111: 3512,
    cardNumber: 3512,
    id: 3512,
    category: "مصاريف ادارية وعمومية",
    finalReportsCode: 38000,
    finalReportsCode2: 805,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 1,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مصروفات رواتب مقابل خيارات الموظفين ",
    displayName: "مصروفات رواتب مقابل خيارات الموظفين ",
    details6111: 3513,
    cardNumber: 3513,
    id: 3512,
    category: "مصاريف ادارية وعمومية",
    finalReportsCode: 38000,
    finalReportsCode2: 805,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 1,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "تأمين",
    displayName: "تأمين",
    details6111: 3515,
    cardNumber: 3515,
    id: 3515,
    category: "مصاريف ادارية وعمومية",
    finalReportsCode: 38000,
    finalReportsCode2: 805,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مصاريف مقاولي الباطن",
    displayName: "مصاريف مقاولي الباطن",
    details6111: 3520,
    cardNumber: 3520,
    id: 3520,
    category: "مصاريف ادارية وعمومية",
    finalReportsCode: 38000,
    finalReportsCode2: 805,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "العمولات والمكافآت للوكلاء",
    displayName: "العمولات والمكافآت للوكلاء",
    details6111: 3530,
    cardNumber: 3530,
    id: 3530,
    category: "مصاريف ادارية وعمومية",
    finalReportsCode: 38000,
    finalReportsCode2: 805,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "المصاريف والمواد الاستهلاكية",
    displayName: "المصاريف والمواد الاستهلاكية",
    details6111: 3535,
    cardNumber: 3535,
    id: 3535,
    category: "مصاريف ادارية وعمومية",
    finalReportsCode: 38000,
    finalReportsCode2: 805,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 1,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "خدمات مهنية",
    displayName: "خدمات مهنية",
    details6111: 3540,
    cardNumber: 3540,
    id: 3540,
    category: "مصاريف ادارية وعمومية",
    finalReportsCode: 38000,
    finalReportsCode2: 805,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مصاريف التعبئة",
    displayName: "مصاريف التعبئة",
    details6111: 3545,
    cardNumber: 3545,
    id: 3545,
    category: "مصاريف ادارية وعمومية",
    finalReportsCode: 38000,
    finalReportsCode2: 805,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مصاريف الصيانة والاصلاح",
    displayName: "مصاريف الصيانة والاصلاح",
    details6111: 3550,
    cardNumber: 3550,
    id: 3550,
    category: "مصاريف ادارية وعمومية",
    finalReportsCode: 38000,
    finalReportsCode2: 805,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "نفقات البحث والتطوير",
    displayName: "نفقات البحث والتطوير",
    details6111: 3555,
    cardNumber: 3555,
    id: 3555,
    category: "مصاريف ادارية وعمومية",
    finalReportsCode: 38000,
    finalReportsCode2: 805,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مصاريف السفر",
    displayName: "مصاريف السفر",
    details6111: 3560,
    cardNumber: 3560,
    id: 3560,
    category: "مصاريف ادارية وعمومية",
    finalReportsCode: 38000,
    finalReportsCode2: 805,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "صيانة السيارات ونقلها",
    displayName: "صيانة السيارات ونقلها",
    details6111: 3565,
    cardNumber: 3565,
    id: 3565,
    category: "مصاريف ادارية وعمومية",
    finalReportsCode: 38000,
    finalReportsCode2: 805,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "تكاليف صيانة المركبات والنقل باستثناء الوقود",
    displayName: "تكاليف صيانة المركبات والنقل باستثناء الوقود",
    details6111: 3566,
    cardNumber: 3566,
    id: 3566,
    category: "مصاريف ادارية وعمومية",
    finalReportsCode: 38000,
    finalReportsCode2: 805,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: " تكاليف الوقود قبل الاسترداد",
    displayName: " تكاليف الوقود قبل الاسترداد",
    details6111: 3567,
    cardNumber: 3567,
    id: 3567,
    category: "مصاريف ادارية وعمومية",
    finalReportsCode: 38000,
    finalReportsCode2: 805,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "استرداد ضريبة البلو على الوقود",
    displayName: "استرداد ضريبة البلو على الوقود",
    details6111: 3568,
    cardNumber: 3568,
    id: 3568,
    category: "مصاريف ادارية وعمومية",
    finalReportsCode: 38000,
    finalReportsCode2: 805,
    isTotalGrp: 0,
    isPositiveDclear: 0,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "رسوم الإيجار وإدارة الممتلكات",
    displayName: "رسوم الإيجار وإدارة الممتلكات",
    details6111: 3570,
    cardNumber: 3570,
    id: 3570,
    category: "مصاريف ادارية وعمومية",
    finalReportsCode: 38000,
    finalReportsCode2: 805,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "الضرائب والرسوم",
    displayName: "الضرائب والرسوم",
    details6111: 3575,
    cardNumber: 3575,
    id: 3575,
    category: "مصاريف ادارية وعمومية",
    finalReportsCode: 38000,
    finalReportsCode2: 805,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "استهلاك",
    displayName: "استهلاك",
    details6111: 3580,
    cardNumber: 3580,
    id: 3580,
    category: "مصاريف ادارية وعمومية",
    finalReportsCode: 38000,
    finalReportsCode2: 805,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 1,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "الكهرباء والماء",
    displayName: "الكهرباء والماء",
    details6111: 3590,
    cardNumber: 3590,
    id: 3590,
    category: "مصاريف ادارية وعمومية",
    finalReportsCode: 38000,
    finalReportsCode2: 805,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "الصيانة والنظافة",
    displayName: "الصيانة والنظافة",
    details6111: 3595,
    cardNumber: 3595,
    id: 3595,
    category: "مصاريف ادارية وعمومية",
    finalReportsCode: 38000,
    finalReportsCode2: 805,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مصاريف تعليم مهني",
    displayName: "مصاريف تعليم مهني",
    details6111: 3600,
    cardNumber: 3600,
    id: 3600,
    category: "مصاريف ادارية وعمومية",
    finalReportsCode: 38000,
    finalReportsCode2: 805,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "الديون مستوفاة وخاسرة",
    displayName: "الديون مستوفاة وخاسرة",
    details6111: 3610,
    cardNumber: 3610,
    id: 3610,
    category: "مصاريف ادارية وعمومية",
    finalReportsCode: 38000,
    finalReportsCode2: 805,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "الدعاية والإعلان والترويج للمبيعات",
    displayName: "الدعاية والإعلان والترويج للمبيعات",
    details6111: 3620,
    cardNumber: 3620,
    id: 3620,
    category: "مصاريف ادارية وعمومية",
    finalReportsCode: 38000,
    finalReportsCode2: 805,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "التكريم والهبات والتبرعات والغرامات",
    displayName: "التكريم والهبات والتبرعات والغرامات",
    details6111: 3625,
    cardNumber: 3625,
    id: 3625,
    category: "مصاريف ادارية وعمومية",
    finalReportsCode: 38000,
    finalReportsCode2: 805,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مصاريف مستحقة لأطراف ذات علاقة",
    displayName: "مصاريف مستحقة لأطراف ذات علاقة",
    details6111: 3630,
    cardNumber: 3630,
    id: 3630,
    category: "مصاريف ادارية وعمومية",
    finalReportsCode: 38000,
    finalReportsCode2: 805,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مصاريف بدل أطراف ذات صلة في البلاد",
    displayName: "مصاريف بدل أطراف ذات صلة في البلاد",
    details6111: 3631,
    cardNumber: 3631,
    id: 3631,
    category: "مصاريف ادارية وعمومية",
    finalReportsCode: 38000,
    finalReportsCode2: 805,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مصاريف بدل أطراف ذات صلة في الخارج",
    displayName: "مصاريف بدل أطراف ذات صلة في الخارج",
    details6111: 3632,
    cardNumber: 3632,
    id: 3630,
    category: "مصاريف ادارية وعمومية",
    finalReportsCode: 38000,
    finalReportsCode2: 805,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "الرسوم الادارية",
    displayName: "الرسوم الادارية",
    details6111: 3640,
    cardNumber: 3640,
    id: 3640,
    category: "مصاريف ادارية وعمومية",
    finalReportsCode: 38000,
    finalReportsCode2: 805,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "نفقات البريد والاتصالات",
    displayName: "نفقات البريد والاتصالات",
    details6111: 3650,
    cardNumber: 3650,
    id: 3650,
    category: "مصاريف ادارية وعمومية",
    finalReportsCode: 38000,
    finalReportsCode2: 805,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "السفر للخارج",
    displayName: "السفر للخارج",
    details6111: 3660,
    cardNumber: 3660,
    id: 3660,
    category: "مصاريف ادارية وعمومية",
    finalReportsCode: 38000,
    finalReportsCode2: 805,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "نفقات قانونية",
    displayName: "نفقات قانونية",
    details6111: 3665,
    cardNumber: 3665,
    id: 3665,
    category: "مصاريف ادارية وعمومية",
    finalReportsCode: 38000,
    finalReportsCode2: 805,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "نفقات قانونية",
    displayName: "نفقات قانونية",
    details6111: 3670,
    cardNumber: 3670,
    id: 3670,
    category: "مصاريف ادارية وعمومية",
    finalReportsCode: 38000,
    finalReportsCode2: 805,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مصاريف مكتبية",
    displayName: "مصاريف مكتبية",
    details6111: 3680,
    cardNumber: 3680,
    id: 3680,
    category: "مصاريف ادارية وعمومية",
    finalReportsCode: 38000,
    finalReportsCode2: 805,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "ملابس العمل",
    displayName: "ملابس العمل",
    details6111: 3685,
    cardNumber: 3685,
    id: 3685,
    category: "مصاريف ادارية وعمومية",
    finalReportsCode: 38000,
    finalReportsCode2: 805,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "صافي الفرق",
    displayName: "صافي الفرق",
    details6111: 3690,
    cardNumber: 3690,
    id: 3690,
    category: "مصاريف ادارية وعمومية",
    finalReportsCode: 38000,
    finalReportsCode2: 805,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "إجمالي المصاريف الإدارية والعمومية",
    displayName: "إجمالي المصاريف الإدارية والعمومية",
    details6111: 3500,
    cardNumber: 3500,
    id: 3500,
    category: "مصاريف ادارية وعمومية",
    finalReportsCode: 38000,
    finalReportsCode2: 805,
    isTotalGrp: 1,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "للشركات المصرفية",
    displayName: "للشركات المصرفية",
    details6111: 5010,
    cardNumber: 5010,
    id: 5010,
    category: "نفقات مالية",
    finalReportsCode: 40000,
    finalReportsCode2: 830,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 1,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مصاريف تمويل بدل أطراف ذات صلة في البلاد",
    displayName: "مصاريف تمويل بدل أطراف ذات صلة في البلاد",
    details6111: 5020,
    cardNumber: 5020,
    id: 5020,
    category: "نفقات مالية",
    finalReportsCode: 40000,
    finalReportsCode2: 830,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 1,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مصاريف تمويل بدل أطراف ذات صلة في الخارج",
    displayName: "مصاريف تمويل بدل أطراف ذات صلة في الخارج",
    details6111: 5025,
    cardNumber: 5025,
    id: 5025,
    category: "نفقات مالية",
    finalReportsCode: 40000,
    finalReportsCode2: 830,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 1,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "بالعملة الأجنبية",
    displayName: "بالعملة الأجنبية",
    details6111: 5030,
    cardNumber: 5030,
    id: 5030,
    category: "نفقات مالية",
    finalReportsCode: 40000,
    finalReportsCode2: 830,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 1,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "فيما يتعلق بالموردين والمستفيدين المفهرس بالعملة الأجنبية",
    displayName: "فيما يتعلق بالموردين والمستفيدين المفهرس بالعملة الأجنبية",
    details6111: 5040,
    cardNumber: 5040,
    id: 5040,
    category: "نفقات مالية",
    finalReportsCode: 40000,
    finalReportsCode2: 830,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 1,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "المكاسب / الخسائر من الأوراق المالية المتداولة من إعادة تقييم",
    displayName: "المكاسب / الخسائر من الأوراق المالية المتداولة من إعادة تقييم",
    details6111: 5050,
    cardNumber: 5050,
    id: 5050,
    category: "نفقات مالية",
    finalReportsCode: 40000,
    finalReportsCode2: 830,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 1,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مصاريف تمويل بدل دين ضريبي",
    displayName: "مصاريف تمويل بدل دين ضريبي",
    details6111: 5051,
    cardNumber: 5051,
    id: 5051,
    category: "نفقات مالية",
    finalReportsCode: 40000,
    finalReportsCode2: 830,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 1,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مصاريف تمويل أخرى ",
    displayName: "مصاريف تمويل أخرى ",
    details6111: 5090,
    cardNumber: 5090,
    id: 5090,
    category: "نفقات مالية",
    finalReportsCode: 40000,
    finalReportsCode2: 830,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 1,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "إجمالي  مصاريف التمويل ",
    displayName: "إجمالي  مصاريف التمويل  ",
    details6111: 5000,
    cardNumber: 5000,
    id: 5000,
    category: "نفقات مالية",
    finalReportsCode: 40000,
    finalReportsCode2: 830,
    isTotalGrp: 1,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 1,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "من الشركات المصرفية",
    displayName: "من الشركات المصرفية",
    details6111: 5110,
    cardNumber: 5110,
    id: 5110,
    category: "دخل التمويل",
    finalReportsCode: 40000,
    finalReportsCode2: 832,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 1,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "ايرادات تمويل بدل أطراف ذات صلة في البلاد",
    displayName: "ايرادات تمويل بدل أطراف ذات صلة في البلاد",
    details6111: 5121,
    cardNumber: 5121,
    id: 5121,
    category: "دخل التمويل",
    finalReportsCode: 40000,
    finalReportsCode2: 832,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 1,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "ايرادات تمويل بدل أطراف ذات صلة في الخارج",
    displayName: "ايرادات تمويل بدل أطراف ذات صلة في الخارج",
    details6111: 5122,
    cardNumber: 5122,
    id: 5122,
    category: "دخل التمويل",
    finalReportsCode: 40000,
    finalReportsCode2: 832,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 1,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "من الأطراف ذات العلاقة",
    displayName: "من الأطراف ذات العلاقة",
    details6111: 5120,
    cardNumber: 5120,
    id: 5120,
    category: "دخل التمويل",
    finalReportsCode: 40000,
    finalReportsCode2: 832,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 1,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "من اختلافات البوابة",
    displayName: "من اختلافات البوابة",
    details6111: 5130,
    cardNumber: 5130,
    id: 5130,
    category: "دخل التمويل",
    finalReportsCode: 40000,
    finalReportsCode2: 832,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 1,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "المكاسب من الأوراق المالية المتداولة من إعادة تقييم",
    displayName: "المكاسب من الأوراق المالية المتداولة من إعادة تقييم",
    details6111: 5150,
    cardNumber: 5150,
    id: 5150,
    category: "دخل التمويل",
    finalReportsCode: 40000,
    finalReportsCode2: 832,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 1,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "إيرادات من فوائد من مدينين  من بيع مخزون/أصول/ عقارات",
    displayName: "إيرادات من فوائد من مدينين  من بيع مخزون/أصول/ عقارات",
    details6111: 5160,
    cardNumber: 5160,
    id: 5160,
    category: "دخل التمويل",
    finalReportsCode: 40000,
    finalReportsCode2: 832,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 1,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: " أخرى",
    displayName: " أخرى",
    details6111: 5190,
    cardNumber: 5190,
    id: 5190,
    category: "دخل التمويل",
    finalReportsCode: 40000,
    finalReportsCode2: 832,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 1,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "إجمالي دخل التمويل",
    displayName: "إجمالي دخل التمويل",
    details6111: 5100,
    cardNumber: 5100,
    id: 5100,
    category: "دخل التمويل",
    finalReportsCode: 40000,
    finalReportsCode2: 832,
    isTotalGrp: 1,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 1,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مكاسب / خسارة رأس المال من بيع الأصول الثابتة",
    displayName: "مكاسب / خسارة رأس المال من بيع الأصول الثابتة",
    details6111: 5210,
    cardNumber: 5210,
    id: 5210,
    category: "صافي الدخل (المصروفات) الأخرى.",
    finalReportsCode: 43000,
    finalReportsCode2: 850,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مكاسب / خسارة رأس المال الأخرى",
    displayName: "مكاسب / خسارة رأس المال الأخرى",
    details6111: 5220,
    cardNumber: 5220,
    id: 5220,
    category: "صافي الدخل (المصروفات) الأخرى.",
    finalReportsCode: 43000,
    finalReportsCode2: 850,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "الدخل من أتعاب الإدارة",
    displayName: "الدخل من أتعاب الإدارة",
    details6111: 5230,
    cardNumber: 5230,
    id: 5230,
    category: "صافي الدخل (المصروفات) الأخرى.",
    finalReportsCode: 43000,
    finalReportsCode2: 850,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "الدخل من أتعاب الإدارة من الأطراف ذات العلاقة",
    displayName: "الدخل من أتعاب الإدارة من الأطراف ذات العلاقة",
    details6111: 5235,
    cardNumber: 5235,
    id: 5235,
    category: "صافي الدخل (المصروفات) الأخرى.",
    finalReportsCode: 43000,
    finalReportsCode2: 850,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "الدخل من أتعاب الإدارة من الأطراف ذات العلاقة في البلاد",
    displayName: "الدخل من أتعاب الإدارة من الأطراف ذات العلاقة في البلاد",
    details6111: 5236,
    cardNumber: 5236,
    id: 5236,
    category: "صافي الدخل (المصروفات) الأخرى.",
    finalReportsCode: 43000,
    finalReportsCode2: 850,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "الدخل من أتعاب الإدارة من الأطراف ذات العلاقة في الخارج",
    displayName: "الدخل من أتعاب الإدارة من الأطراف ذات العلاقة في الخارج",
    details6111: 5237,
    cardNumber: 5237,
    id: 5237,
    category: "صافي الدخل (المصروفات) الأخرى.",
    finalReportsCode: 43000,
    finalReportsCode2: 850,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مخصصات انخفاض القيمة",
    displayName: "مخصصات انخفاض القيمة",
    details6111: 5240,
    cardNumber: 5240,
    id: 5240,
    category: "صافي الدخل (المصروفات) الأخرى.",
    finalReportsCode: 43000,
    finalReportsCode2: 850,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "توزيعات الارباح",
    displayName: "توزيعات الارباح",
    details6111: 5250,
    cardNumber: 5250,
    id: 5250,
    category: "صافي الدخل (المصروفات) الأخرى.",
    finalReportsCode: 43000,
    finalReportsCode2: 850,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "إيرادات من محفزات",
    displayName: "إيرادات من محفزات",
    details6111: 5260,
    cardNumber: 5260,
    id: 5260,
    category: "صافي الدخل (المصروفات) الأخرى.",
    finalReportsCode: 43000,
    finalReportsCode2: 850,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "إيرادات من هبات ",
    displayName: "إيرادات من هبات ",
    details6111: 5270,
    cardNumber: 5270,
    id: 5270,
    category: "صافي الدخل (المصروفات) الأخرى.",
    finalReportsCode: 43000,
    finalReportsCode2: 850,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: " أخرى",
    displayName: " أخرى",
    details6111: 5290,
    cardNumber: 5290,
    id: 5290,
    category: "صافي الدخل (المصروفات) الأخرى.",
    finalReportsCode: 43000,
    finalReportsCode2: 850,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "إجمالي صافي الدخل (المصروفات) الأخرى.",
    displayName: "إجمالي صافي الدخل (المصروفات) الأخرى.",
    details6111: 5200,
    cardNumber: 5200,
    id: 5200,
    category: "صافي الدخل (المصروفات) الأخرى.",
    finalReportsCode: 43000,
    finalReportsCode2: 850,
    isTotalGrp: 1,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "خسارات راس مالية من بيع أصول ثابتة",
    displayName: "خسارات راس مالية من بيع أصول ثابتة",
    details6111: 5310,
    cardNumber: 5310,
    id: 5310,
    category: "المصاريف الاخرى",
    finalReportsCode: 43000,
    finalReportsCode2: 850,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "خسارات راس مالية اخرى",
    displayName: "خسارات راس مالية اخرى",
    details6111: 5320,
    cardNumber: 5320,
    id: 5320,
    category: "المصاريف الاخرى",
    finalReportsCode: 43000,
    finalReportsCode2: 850,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مخصصات لخفض القيمة",
    displayName: "مخصصات لخفض القيمة",
    details6111: 5330,
    cardNumber: 5330,
    id: 5330,
    category: "المصاريف الاخرى",
    finalReportsCode: 43000,
    finalReportsCode2: 850,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: " الاخرى",
    displayName: " الاخرى",
    details6111: 5390,
    cardNumber: 5390,
    id: 5390,
    category: "المصاريف الاخرى",
    finalReportsCode: 43000,
    finalReportsCode2: 850,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "إجمالي المصاريف الاخرى",
    displayName: "إجمالي المصاريف الاخرى",
    details6111: 5300,
    cardNumber: 5300,
    id: 5300,
    category: "المصاريف الاخرى",
    finalReportsCode: 43000,
    finalReportsCode2: 850,
    isTotalGrp: 1,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "أرباح مشتركة",
    displayName: "أرباح مشتركة",
    details6111: 5510,
    cardNumber: 5510,
    id: 5510,
    category: "أرباح / خسائر مشتركة",
    finalReportsCode: 43000,
    finalReportsCode2: 850,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "الخسائر المشتركة",
    displayName: "الخسائر المشتركة",
    details6111: 5520,
    cardNumber: 5520,
    id: 5520,
    category: "أرباح / خسائر مشتركة",
    finalReportsCode: 43000,
    finalReportsCode2: 850,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "إجمالي الأرباح / الخسائر المشتركة",
    displayName: "إجمالي الأرباح / الخسائر المشتركة",
    details6111: 5500,
    cardNumber: 5500,
    id: 5500,
    category: "أرباح / خسائر مشتركة",
    finalReportsCode: 43000,
    finalReportsCode2: 850,
    isTotalGrp: 1,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "إجمالي الربح / الخسارة قبل الضرائب",
    displayName: "إجمالي الربح / الخسارة قبل الضرائب",
    details6111: 6666,
    cardNumber: 6666,
    id: 6666,
    category: "إجمالي الربح / الخسارة قبل الضرائب",
    finalReportsCode: 43000,
    finalReportsCode2: 858,
    isTotalGrp: 1,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "الضرائب للسنة الحالية",
    displayName: "الضرائب للسنة الحالية",
    details6111: 5610,
    cardNumber: 5610,
    id: 5610,
    category: "ضرائب الدخل",
    finalReportsCode: 44000,
    finalReportsCode2: 860,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "الضرائب المؤجلة",
    displayName: "الضرائب المؤجلة",
    details6111: 5620,
    cardNumber: 5620,
    id: 5620,
    category: "ضرائب الدخل",
    finalReportsCode: 44000,
    finalReportsCode2: 860,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "ضرائب السنوات السابقة",
    displayName: "ضرائب السنوات السابقة",
    details6111: 5630,
    cardNumber: 5630,
    id: 5630,
    category: "ضرائب الدخل",
    finalReportsCode: 44000,
    finalReportsCode2: 860,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مجموع الضرائب على الدخل",
    displayName: "مجموع الضرائب على الدخل",
    details6111: 5600,
    cardNumber: 5600,
    id: 5600,
    category: "ضرائب الدخل",
    finalReportsCode: 44000,
    finalReportsCode2: 860,
    isTotalGrp: 1,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "صافي الربح / الخسارة بعد الضرائب",
    displayName: "صافي الربح / الخسارة بعد الضرائب",
    details6111: 5699,
    cardNumber: 5699,
    id: 5699,
    category: "صافي الربح / الخسارة بعد الضرائب",
    finalReportsCode: 44000,
    finalReportsCode2: 865,
    isTotalGrp: 1,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "توزيعات الأرباح",
    displayName: "توزيعات الأرباح",
    details6111: 5710,
    cardNumber: 5710,
    id: 5710,
    category: "تخصيص الأرباح",
    finalReportsCode: 57000,
    finalReportsCode2: 880,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "إجمالي توزيع الأرباح",
    displayName: "إجمالي توزيع الأرباح",
    details6111: 5700,
    cardNumber: 5700,
    id: 5700,
    category: "تخصيص الأرباح",
    finalReportsCode: 57000,
    finalReportsCode2: 880,
    isTotalGrp: 1,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "ربح / خسارة حقوق الملكية",
    displayName: "ربح / خسارة حقوق الملكية",
    details6111: 5810,
    cardNumber: 5810,
    id: 5810,
    category: "ربح / خسارة حقوق الملكية",
    finalReportsCode: 57000,
    finalReportsCode2: 880,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "إجمالي ربح / خسارة حقوق الملكية",
    displayName: "إجمالي ربح / خسارة حقوق الملكية",
    details6111: 5800,
    cardNumber: 5800,
    id: 5800,
    category: "ربح / خسارة حقوق الملكية",
    finalReportsCode: 57000,
    finalReportsCode2: 880,
    isTotalGrp: 1,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "النقد عملة محلية ",
    displayName: "النقد عملة محلية ",
    details6111: 7110,
    cardNumber: 7110,
    id: 7110,
    category: "النقد والنقد المعادل",
    finalReportsCode: 1000,
    finalReportsCode2: 159,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 1,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "النقد عملة أجنبية ",
    displayName: "النقد عملة أجنبية ",
    details6111: 7120,
    cardNumber: 7120,
    id: 7120,
    category: "النقد والنقد المعادل",
    finalReportsCode: 1000,
    finalReportsCode2: 159,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 1,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "ودائع قصيرة الأجل",
    displayName: "ودائع قصيرة الأجل",
    details6111: 7150,
    cardNumber: 7150,
    id: 7150,
    category: "النقد والنقد المعادل",
    finalReportsCode: 1000,
    finalReportsCode2: 159,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 1,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "إجمالي النقد والنقد المعادل",
    displayName: "إجمالي النقد والنقد المعادل",
    details6111: 7100,
    cardNumber: 7100,
    id: 7100,
    category: "النقد والنقد المعادل",
    finalReportsCode: 1000,
    finalReportsCode2: 159,
    isTotalGrp: 1,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 1,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "السندات الحكومية",
    displayName: "السندات الحكومية",
    details6111: 7210,
    cardNumber: 7210,
    id: 7210,
    category: "الأوراق المالية القابلة للتسويق",
    finalReportsCode: 1000,
    finalReportsCode2: 150,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 1,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "السندات الحكومية مظهرة بقيمة مخفضة",
    displayName: "السندات الحكومية مظهرة بقيمة مخفضة",
    details6111: 7215,
    cardNumber: 7215,
    id: 7215,
    category: "الأوراق المالية القابلة للتسويق",
    finalReportsCode: 1000,
    finalReportsCode2: 150,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 1,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "سندات الشركات",
    displayName: "سندات الشركات",
    details6111: 7220,
    cardNumber: 7220,
    id: 7220,
    category: "الأوراق المالية القابلة للتسويق",
    finalReportsCode: 1000,
    finalReportsCode2: 150,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 1,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "السندات الحكومية مظهرة بقيمة عادلة",
    displayName: "السندات الحكومية مظهرة بقيمة عادلة",
    details6111: 7225,
    cardNumber: 7225,
    id: 7225,
    category: "الأوراق المالية القابلة للتسويق",
    finalReportsCode: 1000,
    finalReportsCode2: 150,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 1,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "صناديق الاستثمار",
    displayName: "صناديق الاستثمار",
    details6111: 7230,
    cardNumber: 7230,
    id: 7230,
    category: "الأوراق المالية القابلة للتسويق",
    finalReportsCode: 1000,
    finalReportsCode2: 150,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 1,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "استثمار بأسهم شركات محلية",
    displayName: "استثمار بأسهم شركات محلية",
    details6111: 7240,
    cardNumber: 7240,
    id: 7240,
    category: "الأوراق المالية القابلة للتسويق",
    finalReportsCode: 1000,
    finalReportsCode2: 150,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 1,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "الأوراق المالية الأخرى القابلة للتداول",
    displayName: "الأوراق المالية الأخرى القابلة للتداول",
    details6111: 7290,
    cardNumber: 7290,
    id: 7290,
    category: "الأوراق المالية القابلة للتسويق",
    finalReportsCode: 1000,
    finalReportsCode2: 150,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 1,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "الأوراق المالية الأخرى الغير قابلة للتداول",
    displayName: "الأوراق المالية الأخرى الغير قابلة للتداول",
    details6111: 7295,
    cardNumber: 7295,
    id: 7295,
    category: "الأوراق المالية القابلة للتسويق",
    finalReportsCode: 1000,
    finalReportsCode2: 150,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 1,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "إجمالي الأوراق المالية القابلة للتداول",
    displayName: "إجمالي الأوراق المالية القابلة للتداول",
    details6111: 7200,
    cardNumber: 7200,
    id: 7200,
    category: "الأوراق المالية القابلة للتسويق",
    finalReportsCode: 1000,
    finalReportsCode2: 150,
    isTotalGrp: 1,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 1,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "الزبائن  محلية",
    displayName: "الزبائن  محلية",
    details6111: 7310,
    cardNumber: 7310,
    id: 7310,
    category: "الزبائن",
    finalReportsCode: 1000,
    finalReportsCode2: 158,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 1,
    isPayable: 0
  },
  {
    group_name_AR: "الزبائن في الخارج",
    displayName: "الزبائن في الخارج",
    details6111: 7320,
    cardNumber: 7320,
    id: 7320,
    category: "الزبائن",
    finalReportsCode: 1000,
    finalReportsCode2: 158,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 1,
    isPayable: 0
  },
  {
    group_name_AR: "كمبيالات و شيكات برسم التحصيل ",
    displayName: "كمبيالات و شيكات برسم التحصيل ",
    details6111: 7330,
    cardNumber: 7330,
    id: 7330,
    category: "الزبائن",
    finalReportsCode: 1000,
    finalReportsCode2: 158,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 1,
    isPayable: 0
  },
  {
    group_name_AR: "إيرادات مستحقة برسم التحصيل ",
    displayName: "إيرادات مستحقة برسم التحصيل ",
    details6111: 7350,
    cardNumber: 7350,
    id: 7350,
    category: "الزبائن",
    finalReportsCode: 1000,
    finalReportsCode2: 158,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 1,
    isPayable: 0
  },
  {
    group_name_AR: "بطاقات الائتمان",
    displayName: "بطاقات الائتمان",
    details6111: 7360,
    cardNumber: 7360,
    id: 7360,
    category: "الزبائن",
    finalReportsCode: 1000,
    finalReportsCode2: 158,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 1,
    isPayable: 0
  },
  {
    group_name_AR: "مخصص الديون المشكوك في تحصيلها",
    displayName: "مخصص الديون المشكوك في تحصيلها",
    details6111: 7380,
    cardNumber: 7380,
    id: 7380,
    category: "الزبائن",
    finalReportsCode: 1000,
    finalReportsCode2: 158,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 1,
    isPayable: 0
  },
  {
    group_name_AR: "زبائن آخرين",
    displayName: "زبائن آخرين",
    details6111: 7390,
    cardNumber: 7390,
    id: 7390,
    category: "الزبائن",
    finalReportsCode: 1000,
    finalReportsCode2: 158,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 1,
    isPayable: 0
  },
  {
    group_name_AR: "إجمالي  الزبائن",
    displayName: "إجمالي  الزبائن",
    details6111: 7300,
    cardNumber: 7300,
    id: 7300,
    category: "الزبائن",
    finalReportsCode: 1000,
    finalReportsCode2: 158,
    isTotalGrp: 1,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 1,
    isPayable: 0
  },
  {
    group_name_AR: "السلفيات للموردين وغيرهم",
    displayName: "السلفيات للموردين وغيرهم",
    details6111: 7410,
    cardNumber: 7410,
    id: 7410,
    category: "أرصدة الذمم المدينة والدائنة",
    finalReportsCode: 1000,
    finalReportsCode2: 157,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 1,
    isPayable: 0
  },
  {
    group_name_AR: "النفقات مقدما",
    displayName: "النفقات مقدما",
    details6111: 7420,
    cardNumber: 7420,
    id: 7420,
    category: "أرصدة الذمم المدينة والدائنة",
    finalReportsCode: 1000,
    finalReportsCode2: 157,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 1,
    isPayable: 0
  },
  {
    group_name_AR: "سلف ضريبة الدخل مطروحًا منها المخصصات",
    displayName: "سلف ضريبة الدخل مطروحًا منها المخصصات",
    details6111: 7430,
    cardNumber: 7430,
    id: 7430,
    category: "أرصدة الذمم المدينة والدائنة",
    finalReportsCode: 1000,
    finalReportsCode2: 157,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 1,
    isPayable: 0
  },
  {
    group_name_AR: "المؤسسات الحكومية الأخرى",
    displayName: "المؤسسات الحكومية الأخرى",
    details6111: 7440,
    cardNumber: 7440,
    id: 7440,
    category: "أرصدة الذمم المدينة والدائنة",
    finalReportsCode: 1000,
    finalReportsCode2: 157,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 1,
    isPayable: 0
  },
  {
    group_name_AR: "المساهمون (مباشرة)",
    displayName: "المساهمون (مباشرة)",
    details6111: 7450,
    cardNumber: 7450,
    id: 7450,
    category: "أرصدة الذمم المدينة والدائنة",
    finalReportsCode: 1000,
    finalReportsCode2: 157,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 1,
    isPayable: 0
  },
  {
    group_name_AR: "اطراف ذات صلة",
    displayName: "اطراف ذات صلة",
    details6111: 7460,
    cardNumber: 7460,
    id: 7460,
    category: "أرصدة الذمم المدينة والدائنة",
    finalReportsCode: 1000,
    finalReportsCode2: 157,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 1,
    isPayable: 0
  },
  {
    group_name_AR: "أطراف ذات صلة في البلاد",
    displayName: "أطراف ذات صلة في البلاد",
    details6111: 7461,
    cardNumber: 7461,
    id: 7461,
    category: "أرصدة الذمم المدينة والدائنة",
    finalReportsCode: 1000,
    finalReportsCode2: 157,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 1,
    isPayable: 0
  },
  {
    group_name_AR: "أطراف ذات صلة في الخارج",
    displayName: "أطراف ذات صلة في الخارج",
    details6111: 7462,
    cardNumber: 7462,
    id: 7462,
    category: "أرصدة الذمم المدينة والدائنة",
    finalReportsCode: 1000,
    finalReportsCode2: 157,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 1,
    isPayable: 0
  },
  {
    group_name_AR: "سلف للموظفين ",
    displayName: "سلف للموظفين ",
    details6111: 7470,
    cardNumber: 7470,
    id: 7470,
    category: "أرصدة الذمم المدينة والدائنة",
    finalReportsCode: 1000,
    finalReportsCode2: 157,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 1,
    isPayable: 0
  },
  {
    group_name_AR: "إجمالي الذمم المدينة والأرصدة الدائنة",
    displayName: "إجمالي الذمم المدينة والأرصدة الدائنة",
    details6111: 7400,
    cardNumber: 7400,
    id: 7400,
    category: "أرصدة الذمم المدينة والدائنة",
    finalReportsCode: 1000,
    finalReportsCode2: 157,
    isTotalGrp: 1,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 1,
    isPayable: 0
  },
  {
    group_name_AR: "ذمم مدينة وأرصدة دائنة أخرى",
    displayName: "ذمم مدينة وأرصدة دائنة أخرى",
    details6111: 7590,
    cardNumber: 7590,
    id: 7590,
    category: "أرصدة الذمم المدينة والدائنة",
    finalReportsCode: 1000,
    finalReportsCode2: 99,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 1,
    isPayable: 0
  },
  
  {
    group_name_AR: "للموظفين",
    displayName: "للموظفين",
    details6111: 7610,
    cardNumber: 7610,
    id: 7610,
    category: "الضرائب مؤجلة مؤقتًا",
    finalReportsCode: 1000,
    finalReportsCode2: 155,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "ايراد برسم التحصيل ",
    displayName: "ايراد برسم التحصيل ",
    details6111: 7620,
    cardNumber: 7620,
    id: 7620,
    category: "الضرائب مؤجلة مؤقتًا",
    finalReportsCode: 1000,
    finalReportsCode2: 155,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "ضرائب أخرى قصيرة الأجل",
    displayName: "ضرائب أخرى قصيرة الأجل",
    details6111: 7690,
    cardNumber: 7690,
    id: 7690,
    category: "الضرائب مؤجلة مؤقتًا",
    finalReportsCode: 1000,
    finalReportsCode2: 155,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مجموع الضرائب مؤجلة مؤقتًا",
    displayName: "مجموع الضرائب مؤجلة مؤقتًا",
    details6111: 7600,
    cardNumber: 7600,
    id: 7600,
    category: "الضرائب مؤجلة مؤقتًا",
    finalReportsCode: 1000,
    finalReportsCode2: 155,
    isTotalGrp: 1,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "قروض لأطراف ذات علاقة",
    displayName: "قروض لأطراف ذات علاقة",
    details6111: 7710,
    cardNumber: 7710,
    id: 7710,
    category: "قروض قصيرة الأجل",
    finalReportsCode: 1000,
    finalReportsCode2: 154,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "قروض لأطراف ذات علاقة في البلاد",
    displayName: "قروض لأطراف ذات علاقة في البلاد",
    details6111: 7711,
    cardNumber: 7711,
    id: 7711,
    category: "قروض قصيرة الأجل",
    finalReportsCode: 1000,
    finalReportsCode2: 154,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "قروض لأطراف ذات علاقة في الخارج",
    displayName: "قروض لأطراف ذات علاقة في الخارج",
    details6111: 7712,
    cardNumber: 7712,
    id: 7712,
    category: "قروض قصيرة الأجل",
    finalReportsCode: 1000,
    finalReportsCode2: 154,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "قروض للآخرين",
    displayName: "قروض للآخرين",
    details6111: 7720,
    cardNumber: 7720,
    id: 7720,
    category: "قروض قصيرة الأجل",
    finalReportsCode: 1000,
    finalReportsCode2: 154,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "إجمالي القروض قصيرة الأجل",
    displayName: "إجمالي القروض قصيرة الأجل",
    details6111: 7700,
    cardNumber: 7700,
    id: 7700,
    category: "قروض قصيرة الأجل",
    finalReportsCode: 1000,
    finalReportsCode2: 154,
    isTotalGrp: 1,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مخزون مساحات تجارية وصناعية  للبيع ",
    displayName: "مخزون مساحات تجارية وصناعية  للبيع ",
    details6111: 7805,
    cardNumber: 7805,
    id: 7805,
    category: "المخزون (الميزانية العمومية)",
    finalReportsCode: 1000,
    finalReportsCode2: 152,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "المخزون في مستودعات الشركة",
    displayName: "المخزون في مستودعات الشركة",
    details6111: 7810,
    cardNumber: 7810,
    id: 7810,
    category: "المخزون (الميزانية العمومية)",
    finalReportsCode: 1000,
    finalReportsCode2: 152,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مخزون شقق /  بيوت ",
    displayName: "مخزون شقق /  بيوت ",
    details6111: 7815,
    cardNumber: 7815,
    id: 7815,
    category: "المخزون (الميزانية العمومية)",
    finalReportsCode: 1000,
    finalReportsCode2: 152,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "المخزون في المستودعات الجمركية",
    displayName: "المخزون في المستودعات الجمركية",
    details6111: 7820,
    cardNumber: 7820,
    id: 7820,
    category: "المخزون (الميزانية العمومية)",
    finalReportsCode: 1000,
    finalReportsCode2: 152,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مخزون مباني واعمال تعهدات  تحت الانشاء  تكلفة مباشرة",
    displayName: "مخزون مباني واعمال تعهدات  تحت الانشاء  تكلفة مباشرة",
    details6111: 7825,
    cardNumber: 7825,
    id: 7825,
    category: "المخزون (الميزانية العمومية)",
    finalReportsCode: 1000,
    finalReportsCode2: 152,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مخزون بالطريق ",
    displayName: "مخزون بالطريق ",
    details6111: 7830,
    cardNumber: 7830,
    id: 7830,
    category: "المخزون (الميزانية العمومية)",
    finalReportsCode: 1000,
    finalReportsCode2: 152,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مخزون  برسم البيع  ",
    displayName: "مخزون  برسم البيع  ",
    details6111: 7840,
    cardNumber: 7840,
    id: 7840,
    category: "المخزون (الميزانية العمومية)",
    finalReportsCode: 1000,
    finalReportsCode2: 152,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مخزون  قيد التصنيع   ",
    displayName: "مخزون  قيد التصنيع   ",
    details6111: 7850,
    cardNumber: 7850,
    id: 7850,
    category: "المخزون (الميزانية العمومية)",
    finalReportsCode: 1000,
    finalReportsCode2: 152,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "جرد المواد الخام",
    displayName: "جرد المواد الخام",
    details6111: 7860,
    cardNumber: 7860,
    id: 7860,
    category: "المخزون (الميزانية العمومية)",
    finalReportsCode: 1000,
    finalReportsCode2: 152,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "اعمال تعهدات  تحت الانشاء تشمل المصاريف المرسملة",
    displayName: "اعمال تعهدات  تحت الانشاء تشمل المصاريف المرسملة",
    details6111: 7870,
    cardNumber: 7870,
    id: 7870,
    category: "المخزون (الميزانية العمومية)",
    finalReportsCode: 1000,
    finalReportsCode2: 152,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مخزون آخر",
    displayName: "مخزون آخر",
    details6111: 7890,
    cardNumber: 7890,
    id: 7890,
    category: "المخزون (الميزانية العمومية)",
    finalReportsCode: 1000,
    finalReportsCode2: 152,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "إجمالي المخزون (الميزانية العمومية)",
    displayName: "إجمالي المخزون (الميزانية العمومية)",
    details6111: 7800,
    cardNumber: 7800,
    id: 7800,
    category: "المخزون (الميزانية العمومية)",
    finalReportsCode: 1000,
    finalReportsCode2: 152,
    isTotalGrp: 1,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "إجمالي الموجودات  المتداولة",
    displayName: "إجمالي الموجودات  المتداولة",
    details6111: 7000,
    cardNumber: 7000,
    id: 7000,
    category: "المخزون (الميزانية العمومية)",
    finalReportsCode: 1000,
    finalReportsCode2: 201,
    isTotalGrp: 1,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "الأرض والمباني",
    displayName: "الأرض والمباني",
    details6111: 8010,
    cardNumber: 8010,
    id: 8010,
    category: "ممتلكات ثابتة",
    finalReportsCode: 3000,
    finalReportsCode2: 100,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 1,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "تعديلات فى الايجار",
    displayName: "تعديلات فى الايجار",
    details6111: 8020,
    cardNumber: 8020,
    id: 8020,
    category: "ممتلكات ثابتة",
    finalReportsCode: 3000,
    finalReportsCode2: 100,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 1,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "تقييم عقارات للاستثمار مقابل ربح/خسارة",
    displayName: "تقييم عقارات للاستثمار مقابل ربح/خسارة",
    details6111: 8025,
    cardNumber: 8025,
    id: 8025,
    category: "ممتلكات ثابتة",
    finalReportsCode: 3000,
    finalReportsCode2: 100,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 1,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "الآلات والمعدات",
    displayName: "الآلات والمعدات",
    details6111: 8030,
    cardNumber: 8030,
    id: 8030,
    category: "ممتلكات ثابتة",
    finalReportsCode: 3000,
    finalReportsCode2: 100,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 1,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مركبات",
    displayName: "مركبات",
    details6111: 8040,
    cardNumber: 8040,
    id: 8040,
    category: "ممتلكات ثابتة",
    finalReportsCode: 3000,
    finalReportsCode2: 100,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 1,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "أجهزة الكمبيوتر ومعدات معالجة البيانات",
    displayName: "أجهزة الكمبيوتر ومعدات معالجة البيانات",
    details6111: 8050,
    cardNumber: 8050,
    id: 8050,
    category: "ممتلكات ثابتة",
    finalReportsCode: 3000,
    finalReportsCode2: 100,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 1,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "الأثاث والاكسسوارات",
    displayName: "الأثاث والاكسسوارات",
    details6111: 8060,
    cardNumber: 8060,
    id: 8060,
    category: "ممتلكات ثابتة",
    finalReportsCode: 3000,
    finalReportsCode2: 100,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 1,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "المخزون الأساسي",
    displayName: "المخزون الأساسي",
    details6111: 8080,
    cardNumber: 8080,
    id: 8080,
    category: "ممتلكات ثابتة",
    finalReportsCode: 3000,
    finalReportsCode2: 100,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 1,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "الأصول الثابتة الأخرى",
    displayName: "الأصول الثابتة الأخرى",
    details6111: 8090,
    cardNumber: 8090,
    id: 8090,
    category: "ممتلكات ثابتة",
    finalReportsCode: 3000,
    finalReportsCode2: 100,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 1,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "التكاليف اللاحقة",
    displayName: "التكاليف اللاحقة",
    details6111: 8095,
    cardNumber: 8095,
    id: 8095,
    category: "ممتلكات ثابتة",
    finalReportsCode: 3000,
    finalReportsCode2: 100,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 1,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "تقدير تكلفة الإزالة والتخلص من النفايات",
    displayName: "تقدير تكلفة الإزالة والتخلص من النفايات",
    details6111: 8100,
    cardNumber: 8100,
    id: 8100,
    category: "ممتلكات ثابتة",
    finalReportsCode: 3000,
    finalReportsCode2: 100,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 1,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "رسملة التكاليف التمويلية",
    displayName: "رسملة التكاليف التمويلية",
    details6111: 8105,
    cardNumber: 8105,
    id: 8105,
    category: "ممتلكات ثابتة",
    finalReportsCode: 3000,
    finalReportsCode2: 100,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 1,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "الاستهلاك المتراكم للمباني",
    displayName: "الاستهلاك المتراكم للمباني",
    details6111: 8110,
    cardNumber: 8110,
    id: 8110,
    category: "ممتلكات ثابتة",
    finalReportsCode: 3000,
    finalReportsCode2: 100,
    isTotalGrp: 0,
    isPositiveDclear: 0,
    isBalanceSheet: 1,
    isDepreciationExpense: 1,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 1,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "الاستهلاك المتراكم لتحسينات العقارات المستأجرة",
    displayName: "الاستهلاك المتراكم لتحسينات العقارات المستأجرة",
    details6111: 8120,
    cardNumber: 8120,
    id: 8120,
    category: "ممتلكات ثابتة",
    finalReportsCode: 3000,
    finalReportsCode2: 100,
    isTotalGrp: 0,
    isPositiveDclear: 0,
    isBalanceSheet: 1,
    isDepreciationExpense:1,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 1,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "الاستهلاك المتراكم للآلات والمعدات",
    displayName: "الاستهلاك المتراكم للآلات والمعدات",
    details6111: 8130,
    cardNumber: 8130,
    id: 8130,
    category: "ممتلكات ثابتة",
    finalReportsCode: 3000,
    finalReportsCode2: 100,
    isTotalGrp: 0,
    isPositiveDclear: 0,
    isBalanceSheet: 1,
    isDepreciationExpense: 1,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 1,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مركبات الاستهلاك المتراكم",
    displayName: "مركبات الاستهلاك المتراكم",
    details6111: 8140,
    cardNumber: 8140,
    id: 8140,
    category: "ممتلكات ثابتة",
    finalReportsCode: 3000,
    finalReportsCode2: 100,
    isTotalGrp: 0,
    isPositiveDclear: 0,
    isBalanceSheet: 1,
    isDepreciationExpense: 1,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 1,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "الاستهلاك المتراكم لأجهزة الكمبيوتر ومعدات معالجة البيانات",
    displayName: "الاستهلاك المتراكم لأجهزة الكمبيوتر ومعدات معالجة البيانات",
    details6111: 8150,
    cardNumber: 8150,
    id: 8150,
    category: "ممتلكات ثابتة",
    finalReportsCode: 3000,
    finalReportsCode2: 100,
    isTotalGrp: 0,
    isPositiveDclear: 0,
    isBalanceSheet: 1,
    isDepreciationExpense: 1,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 1,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "أثاث واكسسوارات الاستهلاك المتراكم",
    displayName: "أثاث واكسسوارات الاستهلاك المتراكم",
    details6111: 8160,
    cardNumber: 8160,
    id: 8160,
    category: "ممتلكات ثابتة",
    finalReportsCode: 3000,
    finalReportsCode2: 100,
    isTotalGrp: 0,
    isPositiveDclear: 0,
    isBalanceSheet: 1,
    isDepreciationExpense: 1,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 1,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "إعادة تقييم  الأصول الثابتة مقابل صندوق رأس المال  حقوق الملكية",
    displayName: "إعادة تقييم  الأصول الثابتة مقابل صندوق رأس المال  حقوق الملكية",
    details6111: 8170,
    cardNumber: 8170,
    id: 8170,
    category: "ممتلكات ثابتة",
    finalReportsCode: 3000,
    finalReportsCode2: 100,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 1,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مخصص انخفاض القيمة",
    displayName: "مخصص انخفاض القيمة",
    details6111: 8180,
    cardNumber: 8180,
    id: 8180,
    category: "ممتلكات ثابتة",
    finalReportsCode: 3000,
    finalReportsCode2: 100,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 1,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "الاستهلاك المتراكم للأصول الثابتة الأخرى",
    displayName: "الاستهلاك المتراكم للأصول الثابتة الأخرى",
    details6111: 8190,
    cardNumber: 8190,
    id: 8190,
    category: "ممتلكات ثابتة",
    finalReportsCode: 3000,
    finalReportsCode2: 100,
    isTotalGrp: 0,
    isPositiveDclear: 0,
    isBalanceSheet: 1,
    isDepreciationExpense: 1,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 1,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مجموع الأصول الثابتة",
    displayName: "مجموع الأصول الثابتة",
    details6111: 8000,
    cardNumber: 8000,
    id: 8000,
    category: "ممتلكات ثابتة",
    finalReportsCode: 3000,
    finalReportsCode2: 100,
    isTotalGrp: 1,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 1,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "لجمع القروض",
    displayName: "لجمع القروض",
    details6111: 8210,
    cardNumber: 8210,
    id: 8210,
    category: "النفقات مقدما لفترة طويلة",
    finalReportsCode: 7000,
    finalReportsCode2: 115,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "للتأجير على المدى الطويل",
    displayName: "للتأجير على المدى الطويل",
    details6111: 8220,
    cardNumber: 8220,
    id: 8220,
    category: "النفقات مقدما لفترة طويلة",
    finalReportsCode: 3000,
    finalReportsCode2: 115,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "آحرون",
    displayName: "آحرون",
    details6111: 8290,
    cardNumber: 8290,
    id: 8290,
    category: "النفقات مقدما لفترة طويلة",
    finalReportsCode: 7000,
    finalReportsCode2: 115,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "إجمالي المصاريف مقدمًا لفترة طويلة",
    displayName: "إجمالي المصاريف مقدمًا لفترة طويلة",
    details6111: 8200,
    cardNumber: 8200,
    id: 8200,
    category: "النفقات مقدما لفترة طويلة",
    finalReportsCode: 7000,
    finalReportsCode2: 115,
    isTotalGrp: 1,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "استثمارات",
    displayName: "استثمارات",
    details6111: 8310,
    cardNumber: 8310,
    id: 8310,
    category: "استثمارات في شركات مملوكة",
    finalReportsCode: 5000,
    finalReportsCode2: 120,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "الاستثمارات المعروضة حسب قيمة الميزانية العمومية",
    displayName: "الاستثمارات المعروضة حسب قيمة الميزانية العمومية",
    details6111: 8315,
    cardNumber: 8315,
    id: 8315,
    category: "استثمارات في شركات مملوكة",
    finalReportsCode: 5000,
    finalReportsCode2: 120,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "حصة الشركة في الربح / الخسارة المتراكمة",
    displayName: "حصة الشركة في الربح / الخسارة المتراكمة",
    details6111: 8320,
    cardNumber: 8320,
    id: 8320,
    category: "استثمارات في شركات مملوكة",
    finalReportsCode: 5000,
    finalReportsCode2: 120,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "الاستثمارات المعروضة حسب القيمة العادلة",
    displayName: "الاستثمارات المعروضة حسب القيمة العادلة",
    details6111: 8325,
    cardNumber: 8325,
    id: 8325,
    category: "استثمارات في شركات مملوكة",
    finalReportsCode: 5000,
    finalReportsCode2: 120,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "الأرباح الموزعة",
    displayName: "الأرباح الموزعة",
    details6111: 8330,
    cardNumber: 8330,
    id: 8330,
    category: "استثمارات في شركات مملوكة",
    finalReportsCode: 5000,
    finalReportsCode2: 120,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "الاستثمارات المعروضة حسب التكلفة ",
    displayName: "الاستثمارات المعروضة حسب التكلفة ",
    details6111: 8335,
    cardNumber: 8335,
    id: 8335,
    category: "استثمارات في شركات مملوكة",
    finalReportsCode: 5000,
    finalReportsCode2: 120,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "قروض",
    displayName: "قروض",
    details6111: 8340,
    cardNumber: 8340,
    id: 8340,
    category: "استثمارات في شركات مملوكة",
    finalReportsCode: 5000,
    finalReportsCode2: 120,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "سندات الملكية",
    displayName: "سندات الملكية",
    details6111: 8350,
    cardNumber: 8350,
    id: 8350,
    category: "استثمارات في شركات مملوكة",
    finalReportsCode: 5000,
    finalReportsCode2: 120,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "إجمالي الاستثمارات في الشركات المملوكة",
    displayName: "إجمالي الاستثمارات في الشركات المملوكة",
    details6111: 8300,
    cardNumber: 8300,
    id: 8300,
    category: "استثمارات في شركات مملوكة",
    finalReportsCode: 5000,
    finalReportsCode2: 120,
    isTotalGrp: 1,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "استثمارات في شركات أخرى",
    displayName: "استثمارات في شركات أخرى",
    details6111: 8410,
    cardNumber: 8410,
    id: 8410,
    category: "استثمارات في شركات أخرى بما في ذلك الأوراق المالية القابلة للتداول المحتفظ بها لفترة طويلة",
    finalReportsCode: 5000,
    finalReportsCode2: 122,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "قروض",
    displayName: "قروض",
    details6111: 8420,
    cardNumber: 8420,
    id: 8420,
    category: "استثمارات في شركات أخرى بما في ذلك الأوراق المالية القابلة للتداول المحتفظ بها لفترة طويلة",
    finalReportsCode: 5000,
    finalReportsCode2: 122,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "إجمالي الاستثمارات في شركات أخرى",
    displayName: "إجمالي الاستثمارات في شركات أخرى",
    details6111: 8430,
    cardNumber: 8430,
    id: 8430,
    category: "استثمارات في شركات أخرى بما في ذلك الأوراق المالية القابلة للتداول المحتفظ بها لفترة طويلة",
    finalReportsCode: 5000,
    finalReportsCode2: 122,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "إجمالي الاستثمارات",
    displayName: "إجمالي الاستثمارات",
    details6111: 8440,
    cardNumber: 8440,
    id: 8440,
    category: "استثمارات في شركات أخرى بما في ذلك الأوراق المالية القابلة للتداول المحتفظ بها لفترة طويلة",
    finalReportsCode: 5000,
    finalReportsCode2: 122,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "سندات الملكية",
    displayName: "سندات الملكية",
    details6111: 8450,
    cardNumber: 8450,
    id: 8450,
    category: "استثمارات في شركات أخرى بما في ذلك الأوراق المالية القابلة للتداول المحتفظ بها لفترة طويلة",
    finalReportsCode: 5000,
    finalReportsCode2: 122,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "الاستثمار في شراكة",
    displayName: "الاستثمار في شراكة",
    details6111: 8460,
    cardNumber: 8460,
    id: 8460,
    category: "استثمارات في شركات أخرى بما في ذلك الأوراق المالية القابلة للتداول المحتفظ بها لفترة طويلة",
    finalReportsCode: 5000,
    finalReportsCode2: 122,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "إجمالي الاستثمارات الأخرى",
    displayName: "إجمالي الاستثمارات الأخرى",
    details6111: 8490,
    cardNumber: 8490,
    id: 8490,
    category: "استثمارات في شركات أخرى بما في ذلك الأوراق المالية القابلة للتداول المحتفظ بها لفترة طويلة",
    finalReportsCode: 5000,
    finalReportsCode2: 122,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "إجمالي الاستثمارات في الشركات الأخرى بما في ذلك الأوراق المالية القابلة للتداول المحتفظ بها لفترة طويلة",
    displayName: "إجمالي الاستثمارات في الشركات الأخرى بما في ذلك الأوراق المالية القابلة للتداول المحتفظ بها لفترة طويلة",
    details6111: 8400,
    cardNumber: 8400,
    id: 8400,
    category: "استثمارات في شركات أخرى بما في ذلك الأوراق المالية القابلة للتداول المحتفظ بها لفترة طويلة",
    finalReportsCode: 5000,
    finalReportsCode2: 122,
    isTotalGrp: 1,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "بدل  مخصص تعويض نهاية الخدمة ",
    displayName: "بدل  مخصص تعويض نهاية الخدمة ",
    details6111: 8510,
    cardNumber: 8510,
    id: 8510,
    category: "الضرائب مؤجلة لفترة طويلة",
    finalReportsCode: 7000,
    finalReportsCode2: 125,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "للأصول الثابتة",
    displayName: "للأصول الثابتة",
    details6111: 8520,
    cardNumber: 8520,
    id: 8520,
    category: "الضرائب مؤجلة لفترة طويلة",
    finalReportsCode: 7000,
    finalReportsCode2: 125,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "اخر",
    displayName: "اخر",
    details6111: 8590,
    cardNumber: 8590,
    id: 8590,
    category: "الضرائب مؤجلة لفترة طويلة",
    finalReportsCode: 7000,
    finalReportsCode2: 125,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "إجمالي الضرائب مؤجلة لفترة طويلة",
    displayName: "إجمالي الضرائب مؤجلة لفترة طويلة",
    details6111: 8500,
    cardNumber: 8500,
    id: 8500,
    category: "الضرائب مؤجلة لفترة طويلة",
    finalReportsCode: 7000,
    finalReportsCode2: 125,
    isTotalGrp: 1,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "سمعة",
    displayName: "سمعة",
    details6111: 8610,
    cardNumber: 8610,
    id: 8610,
    category: "المصاريف المؤجلة وأشياء أخرى",
    finalReportsCode: 7000,
    finalReportsCode2: 130,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "المصاريف الأساسية",
    displayName: "المصاريف الأساسية",
    details6111: 8620,
    cardNumber: 8620,
    id: 8620,
    category: "المصاريف المؤجلة وأشياء أخرى",
    finalReportsCode: 7000,
    finalReportsCode2: 130,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "براءات الاختراع وحقوق التأليف والنشر",
    displayName: "براءات الاختراع وحقوق التأليف والنشر",
    details6111: 8630,
    cardNumber: 8630,
    id: 8630,
    category: "المصاريف المؤجلة وأشياء أخرى",
    finalReportsCode: 7000,
    finalReportsCode2: 130,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "إعادة تقييم  أصول غير مادية مقابل صندوق رأس المال  ",
    displayName: "إعادة تقييم  أصول غير مادية مقابل صندوق رأس المال  ",
    details6111: 8640,
    cardNumber: 8640,
    id: 8640,
    category: "المصاريف المؤجلة وأشياء أخرى",
    finalReportsCode: 7000,
    finalReportsCode2: 130,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "آحرون",
    displayName: "آحرون",
    details6111: 8690,
    cardNumber: 8690,
    id: 8690,
    category: "المصاريف المؤجلة وأشياء أخرى",
    finalReportsCode: 7000,
    finalReportsCode2: 130,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "أصول غيرمتداولة معروضة للبيع",
    displayName: "أصول غيرمتداولة معروضة للبيع",
    details6111: 8710,
    cardNumber: 8710,
    id: 9710,
    category: "المصاريف المؤجلة وأشياء أخرى",
    finalReportsCode: 7000,
    finalReportsCode2: 130,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "إجمالي المصاريف المؤجلة والممتلكات الأخرى",
    displayName: "إجمالي المصاريف المؤجلة والممتلكات الأخرى",
    details6111: 8600,
    cardNumber: 8600,
    id: 8600,
    category: "المصاريف المؤجلة وأشياء أخرى",
    finalReportsCode: 7000,
    finalReportsCode2: 130,
    isTotalGrp: 1,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "إجمالي الأصول الثابتة والمتداولة",
    displayName: "إجمالي الأصول الثابتة والمتداولة",
    details6111: 8888,
    cardNumber: 8888,
    id: 8888,
    category: "المصاريف المؤجلة وأشياء أخرى",
    finalReportsCode: 7000,
    finalReportsCode2: 399,
    isTotalGrp: 1,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 1,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "بنوك مكشوفة ",
    displayName: "بنوك مكشوفة ",
    details6111: 9110,
    cardNumber: 9110,
    id: 9110,
    category: "المطلوبات المتداولة البنوك والقروض",
    finalReportsCode: 9000,
    finalReportsCode2: 401,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 1
  },
  {
    group_name_AR: "قروض قصيرة الأجل",
    displayName: "قروض قصيرة الأجل",
    details6111: 9120,
    cardNumber: 9120,
    id: 9120,
    category: "المطلوبات المتداولة البنوك والقروض",
    finalReportsCode: 9000,
    finalReportsCode2: 401,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 1
  },
  {
    group_name_AR: "الخصوم المتداولة على القروض طويلة الأجل",
    displayName: "الخصوم المتداولة على القروض طويلة الأجل",
    details6111: 9130,
    cardNumber: 9130,
    id: 9130,
    category: "المطلوبات المتداولة البنوك والقروض",
    finalReportsCode: 9000,
    finalReportsCode2: 401,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 1
  },
  {
    group_name_AR: "قروض من المقيمين الأجانب",
    displayName: "قروض من المقيمين الأجانب",
    details6111: 9140,
    cardNumber: 9140,
    id: 9140,
    category: "المطلوبات المتداولة البنوك والقروض",
    finalReportsCode: 9000,
    finalReportsCode2: 401,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 1
  },
  {
    group_name_AR: "قروض لأطراف ذات علاقة ",
    displayName: "قروض لأطراف ذات علاقة ",
    details6111: 9150,
    cardNumber: 9150,
    id: 9150,
    category: "المطلوبات المتداولة البنوك والقروض",
    finalReportsCode: 9000,
    finalReportsCode2: 401,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 1
  },
  {
    group_name_AR: "قروض لأطراف ذات علاقة في البلاد",
    displayName: "قروض لأطراف ذات علاقة في البلاد",
    details6111: 9151,
    cardNumber: 9151,
    id: 9151,
    category: "المطلوبات المتداولة البنوك والقروض",
    finalReportsCode: 9000,
    finalReportsCode2: 401,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 1
  },
  {
    group_name_AR: "قروض لأطراف ذات علاقة في الخارج ",
    displayName: "قروض لأطراف ذات علاقة في الخارج ",
    details6111: 9152,
    cardNumber: 9152,
    id: 9152,
    category: "المطلوبات المتداولة البنوك والقروض",
    finalReportsCode: 9000,
    finalReportsCode2: 401,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 1
  },
  {
    group_name_AR: "الاقتراض من الآخرين",
    displayName: "الاقتراض من الآخرين",
    details6111: 9190,
    cardNumber: 9190,
    id: 9190,
    category: "المطلوبات المتداولة البنوك والقروض",
    finalReportsCode: 9000,
    finalReportsCode2: 401,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 1
  },
  {
    group_name_AR: "إجمالي البنوك والقروض",
    displayName: "إجمالي البنوك والقروض",
    details6111: 9100,
    cardNumber: 9100,
    id: 9100,
    category: "المطلوبات المتداولة البنوك والقروض",
    finalReportsCode: 9000,
    finalReportsCode2: 401,
    isTotalGrp: 1,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 1
  },
  
  {
    group_name_AR: "الموردين محلية",
    displayName: "الموردين محلية",
    details6111: 9210,
    cardNumber: 9210,
    id: 9210,
    category: "الموردين ومقدمي الخدمات",
    finalReportsCode: 9000,
    finalReportsCode2: 402,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 1
  },
  {
    group_name_AR: "الموردين في الخارج",
    displayName: "الموردين في الخارج",
    details6111: 9220,
    cardNumber: 9220,
    id: 9220,
    category: "الموردين ومقدمي الخدمات",
    finalReportsCode: 9000,
    finalReportsCode2: 402,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 1
  },
  {
    group_name_AR: "الفواتير والشيكات المستحقة الدفع",
    displayName: "الفواتير والشيكات المستحقة الدفع",
    details6111: 9230,
    cardNumber: 9230,
    id: 9230,
    category: "الموردين ومقدمي الخدمات",
    finalReportsCode: 9000,
    finalReportsCode2: 402,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 1
  },
  {
    group_name_AR: "الموردين ومقدمي الخدمات الآخرين",
    displayName: "الموردين ومقدمي الخدمات الآخرين",
    details6111: 9290,
    cardNumber: 9290,
    id: 9290,
    category: "الموردين ومقدمي الخدمات",
    finalReportsCode: 9000,
    finalReportsCode2: 402,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 1
  },
  {
    group_name_AR: "إجمالي الموردين ومقدمي الخدمات",
    displayName: "إجمالي الموردين ومقدمي الخدمات",
    details6111: 9200,
    cardNumber: 9200,
    id: 9200,
    category: "الموردين ومقدمي الخدمات",
    finalReportsCode: 9000,
    finalReportsCode2: 402,
    isTotalGrp: 1,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 1
  },
  {
    group_name_AR: "موظفين دائنة ",
    displayName: "موظفين دائنة ",
    details6111: 9310,
    cardNumber: 9310,
    id: 9310,
    category: "دائنين وارصدة دائنة",
    finalReportsCode: 9000,
    finalReportsCode2: 403,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 1
  },
  {
    group_name_AR: " الإجازة والنقاهة",
    displayName: " الإجازة والنقاهة",
    details6111: 9320,
    cardNumber: 9320,
    id: 9320,
    category: "دائنين وارصدة دائنة",
    finalReportsCode: 9000,
    finalReportsCode2: 403,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 1
  },
  {
    group_name_AR: "مؤسسات للموظفين",
    displayName: "مؤسسات للموظفين",
    details6111: 9330,
    cardNumber: 9330,
    id: 9330,
    category: "دائنين وارصدة دائنة",
    finalReportsCode: 9000,
    finalReportsCode2: 403,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 1
  },
  {
    group_name_AR: "مخصصات ضريبة الدخل مطروحًا منها السلفيات",
    displayName: "مخصصات ضريبة الدخل مطروحًا منها السلفيات",
    details6111: 9340,
    cardNumber: 9340,
    id: 9340,
    category: "دائنين وارصدة دائنة",
    finalReportsCode: 9000,
    finalReportsCode2: 403,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 1
  },
  
  {
    group_name_AR: "المؤسسات الحكومية الأخرى",
    displayName: "المؤسسات الحكومية الأخرى",
    details6111: 9350,
    cardNumber: 9350,
    id: 9350,
    category: "دائنين وارصدة دائنة",
    finalReportsCode: 9000,
    finalReportsCode2: 403,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 1
  },
  {
    group_name_AR: "إيرادات ايجار مدفوعة مقدماً دائنة ",
    displayName: "إيرادات ايجار مدفوعة مقدماً دائنة ",
    details6111: 9360,
    cardNumber: 9360,
    id: 9360,
    category: "دائنين وارصدة دائنة",
    finalReportsCode: 9000,
    finalReportsCode2: 403,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 1
  },
  {
    group_name_AR: "سلفيات زبائن دائنة ",
    displayName: "سلفيات زبائن دائنة ",
    details6111: 9370,
    cardNumber: 9370,
    id: 9370,
    category: "دائنين وارصدة دائنة",
    finalReportsCode: 9000,
    finalReportsCode2: 403,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 1
  },
  {
    group_name_AR: "دخل آخر مقدما",
    displayName: "دخل آخر مقدما",
    details6111: 9410,
    cardNumber: 9410,
    id: 9410,
    category: "دائنين وارصدة دائنة",
    finalReportsCode: 9000,
    finalReportsCode2: 403,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 1
  },
  {
    group_name_AR: "مصاريف للدفع",
    displayName: "مصاريف للدفع",
    details6111: 9420,
    cardNumber: 9420,
    id: 9420,
    category: "دائنين وارصدة دائنة",
    finalReportsCode: 9000,
    finalReportsCode2: 403,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 1
  },
  {
    group_name_AR: "اطراف ذات صلة",
    displayName: "اطراف ذات صلة",
    details6111: 9430,
    cardNumber: 9430,
    id: 9430,
    category: "دائنين وارصدة دائنة",
    finalReportsCode: 9000,
    finalReportsCode2: 403,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 1
  },
  {
    group_name_AR: "أطراف ذات علاقة في البلاد",
    displayName: "أطراف ذات علاقة في البلاد",
    details6111: 9431,
    cardNumber: 9431,
    id: 9431,
    category: "دائنين وارصدة دائنة",
    finalReportsCode: 9000,
    finalReportsCode2: 403,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 1
  },
  {
    group_name_AR: "أطراف ذات علاقة في الخارج",
    displayName: "أطراف ذات علاقة في الخارج",
    details6111: 9432,
    cardNumber: 9432,
    id: 9432,
    category: "دائنين وارصدة دائنة",
    finalReportsCode: 9000,
    finalReportsCode2: 403,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 1
  },
  {
    group_name_AR: "أصحاب اسهم دائنين ",
    displayName: "أصحاب اسهم دائنين ",
    details6111: 9435,
    cardNumber: 9435,
    id: 9435,
    category: "دائنين وارصدة دائنة",
    finalReportsCode: 9000,
    finalReportsCode2: 403,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 1
  },
  {
    group_name_AR: "توفير الضمان والإصلاحات",
    displayName: "توفير الضمان والإصلاحات",
    details6111: 9440,
    cardNumber: 9440,
    id: 9440,
    category: "دائنين وارصدة دائنة",
    finalReportsCode: 9000,
    finalReportsCode2: 403,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 1
  },
  {
    group_name_AR: "مخصصات المطالبات",
    displayName: "مخصصات المطالبات",
    details6111: 9450,
    cardNumber: 9450,
    id: 9450,
    category: "دائنين وارصدة دائنة",
    finalReportsCode: 12000,
    finalReportsCode2: 403,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 1
  },
  {
    group_name_AR: "المخصصات التابعة الأخرى",
    displayName: "المخصصات التابعة الأخرى",
    details6111: 9460,
    cardNumber: 9460,
    id: 9460,
    category: "دائنين وارصدة دائنة",
    finalReportsCode: 9000,
    finalReportsCode2: 403,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 1
  },
  {
    group_name_AR: "المكافآت والمنح للدفع",
    displayName: "المكافآت والمنح للدفع",
    details6111: 9470,
    cardNumber: 9470,
    id: 9470,
    category: "دائنين وارصدة دائنة",
    finalReportsCode: 9000,
    finalReportsCode2: 403,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 1
  },
  {
    group_name_AR: "توزيعات أرباح على الدفع",
    displayName: "توزيعات أرباح على الدفع",
    details6111: 9480,
    cardNumber: 9480,
    id: 9480,
    category: "دائنين وارصدة دائنة",
    finalReportsCode: 9000,
    finalReportsCode2: 403,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 1
  },
  {
    group_name_AR: "المستحقات الأخرى والمستحقات المتبقية",
    displayName: "المستحقات الأخرى والمستحقات المتبقية",
    details6111: 9490,
    cardNumber: 9490,
    id: 9490,
    category: "دائنين وارصدة دائنة",
    finalReportsCode: 9000,
    finalReportsCode2: 403,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 1
  },
  {
    group_name_AR: "مجموع المستحقات المؤهلة والمتبقية",
    displayName: "مجموع المستحقات المؤهلة والمتبقية",
    details6111: 9400,
    cardNumber: 9400,
    id: 9400,
    category: "دائنين وارصدة دائنة",
    finalReportsCode: 12000,
    finalReportsCode2: 403,
    isTotalGrp: 1,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 1
  },
  {
    group_name_AR: "للموظفين",
    displayName: "للموظفين",
    details6111: 9510,
    cardNumber: 9510,
    id: 9510,
    category: " المطلوبات المتداولة ",
    finalReportsCode: 9000,
    finalReportsCode2: 405,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 1
  },
  {
    group_name_AR: "بدل خسارات مدورة ",
    displayName: "بدل خسارات مدورة ",
    details6111: 9520,
    cardNumber: 9520,
    id: 9520,
    category: "احتياطي للضرائب المؤجلة لفترة قصيرة",
    finalReportsCode: 9000,
    finalReportsCode2: 405,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 1
  },
  {
    group_name_AR: "الضرائب مؤجلة لفترة وجيزة ، والبعض الآخر",
    displayName: "الضرائب مؤجلة لفترة وجيزة ، والبعض الآخر",
    details6111: 9530,
    cardNumber: 9530,
    id: 9530,
    category: "احتياطي للضرائب المؤجلة لفترة قصيرة",
    finalReportsCode: 9000,
    finalReportsCode2: 405,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 1
  },
  {
    group_name_AR: "إجمالي الاحتياطي للضرائب المؤجلة لفترة قصيرة",
    displayName: "إجمالي الاحتياطي للضرائب المؤجلة لفترة قصيرة",
    details6111: 9500,
    cardNumber: 9500,
    id: 9500,
    category: "احتياطي للضرائب المؤجلة لفترة قصيرة",
    finalReportsCode: 9000,
    finalReportsCode2: 405,
    isTotalGrp: 1,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 1
  },
  {
    group_name_AR: "إجمالي  المطلوبات المتداولة",
    displayName: "إجمالي  المطلوبات المتداولة",
    details6111: 9000,
    cardNumber: 9000,
    id: 9000,
    category: "احتياطي للضرائب المؤجلة لفترة قصيرة",
    finalReportsCode: 9000,
    finalReportsCode2: 408,
    isTotalGrp: 1,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 1
  },
  {
    group_name_AR: "تقدير تكلفة الإزالة والتخلص من النفايات",
    displayName: "تقدير تكلفة الإزالة والتخلص من النفايات",
    details6111: 9605,
    cardNumber: 9605,
    id: 9605,
    category: "مطلوبات طويلة  الأجل",
    finalReportsCode: 10000,
    finalReportsCode2: 410,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 1
  },
  {
    group_name_AR: "البنوك",
    displayName: "البنوك",
    details6111: 9610,
    cardNumber: 9610,
    id: 9610,
    category: "مطلوبات طويلة  الأجل",
    finalReportsCode: 10000,
    finalReportsCode2: 410,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 1
  },
  {
    group_name_AR: "باستثناء المطلوبات المتداولة على القروض طويلة الأجل",
    displayName: "باستثناء المطلوبات المتداولة على القروض طويلة الأجل",
    details6111: 9620,
    cardNumber: 9620,
    id: 9620,
    category: "مطلوبات طويلة  الأجل",
    finalReportsCode: 10000,
    finalReportsCode2: 410,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 1
  },
  {
    group_name_AR: "قروض من جهات ذات علاقة",
    displayName: "قروض من جهات ذات علاقة",
    details6111: 9630,
    cardNumber: 9630,
    id: 9630,
    category: "مطلوبات طويلة  الأجل",
    finalReportsCode: 10000,
    finalReportsCode2: 410,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 1
  },
  {
    group_name_AR: "قروض من جهات ذات علاقة في البلاد",
    displayName: "قروض من جهات ذات علاقة في البلاد",
    details6111: 9631,
    cardNumber: 9631,
    id: 9631,
    category: "مطلوبات طويلة  الأجل",
    finalReportsCode: 10000,
    finalReportsCode2: 410,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 1
  },
  {
    group_name_AR: "قروض من جهات ذات علاقة في الخارج",
    displayName: "قروض من جهات ذات علاقة في الخارج",
    details6111: 9632,
    cardNumber: 9632,
    id: 9632,
    category: "مطلوبات طويلة  الأجل",
    finalReportsCode: 10000,
    finalReportsCode2: 410,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 1
  },
  {
    group_name_AR: "قروض من المقيمين الأجانب",
    displayName: "قروض من المقيمين الأجانب",
    details6111: 9640,
    cardNumber: 9640,
    id: 9640,
    category: "مطلوبات طويلة  الأجل",
    finalReportsCode: 10000,
    finalReportsCode2: 410,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 1
  },
  {
    group_name_AR: "قروض طويلة الأجل من الآخرين",
    displayName: "قروض طويلة الأجل من الآخرين",
    details6111: 9650,
    cardNumber: 9650,
    id: 9650,
    category: "مطلوبات طويلة  الأجل",
    finalReportsCode: 10000,
    finalReportsCode2: 410,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 1
  },
  {
    group_name_AR: "سندات الملكية",
    displayName: "سندات الملكية",
    details6111: 9660,
    cardNumber: 9660,
    id: 9660,
    category: "مطلوبات طويلة  الأجل",
    finalReportsCode: 10000,
    finalReportsCode2: 410,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 1
  },
  {
    group_name_AR: "سندات",
    displayName: "سندات",
    details6111: 9670,
    cardNumber: 9670,
    id: 9670,
    category: "مطلوبات طويلة  الأجل",
    finalReportsCode: 10000,
    finalReportsCode2: 410,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 1
  },
  {
    group_name_AR: "أخرى",
    displayName: "أخرى",
    details6111: 9690,
    cardNumber: 9690,
    id: 9690,
    category: "مطلوبات طويلة  الأجل",
    finalReportsCode: 10000,
    finalReportsCode2: 410,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 1
  },
  {
    group_name_AR: "إجمالي المطلوبات طويلة الأجل",
    displayName: "إجمالي المطلوبات طويلة الأجل",
    details6111: 9600,
    cardNumber: 9600,
    id: 9600,
    category: "مطلوبات طويلة  الأجل",
    finalReportsCode: 10000,
    finalReportsCode2: 410,
    isTotalGrp: 1,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 1
  },
  {
    group_name_AR: "مخصص تعويض نهاية الخدمة",
    displayName: "مخصص تعويض نهاية الخدمة",
    details6111: 9710,
    cardNumber: 9710,
    id: 9710,
    category: "الالتزامات الناجمة عن إنهاء العلاقة بين صاحب العمل والعامل",
    finalReportsCode: 12000,
    finalReportsCode2: 417,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 1
  },
  {
    group_name_AR: "المخصصة للتعويض",
    displayName: "المخصصة للتعويض",
    details6111: 9720,
    cardNumber: 9720,
    id: 9720,
    category: "الالتزامات الناجمة عن إنهاء العلاقة بين صاحب العمل والعامل",
    finalReportsCode: 12000,
    finalReportsCode2: 417,
    isTotalGrp: 0,
    isPositiveDclear: 0,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 1
  },
  {
    group_name_AR: "بدل عدم أخذ أيام مرضية",
    displayName: "بدل عدم أخذ أيام مرضية",
    details6111: 9730,
    cardNumber: 9730,
    id: 9730,
    category: "الالتزامات الناجمة عن إنهاء العلاقة بين صاحب العمل والعامل",
    finalReportsCode: 12000,
    finalReportsCode2: 417,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 1
  },
  {
    group_name_AR: "بدل إجازة ",
    displayName: "بدل إجازة ",
    details6111: 9740,
    cardNumber: 9740,
    id: 9740,
    category: "الالتزامات الناجمة عن إنهاء العلاقة بين صاحب العمل والعامل",
    finalReportsCode: 12000,
    finalReportsCode2: 417,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 1
  },
  {
    group_name_AR: "مخصصات اخرى",
    displayName: "مخصصات اخرى",
    details6111: 9790,
    cardNumber: 9790,
    id: 9790,
    category: "الالتزامات الناجمة عن إنهاء العلاقة بين صاحب العمل والعامل",
    finalReportsCode: 12000,
    finalReportsCode2: 417,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 1
  },
  {
    group_name_AR: "إجمالي الخصوم بسبب إنهاء العلاقة بين صاحب العمل والعامل",
    displayName: "إجمالي الخصوم بسبب إنهاء العلاقة بين صاحب العمل والعامل",
    details6111: 9700,
    cardNumber: 9700,
    id: 9700,
    category: "الالتزامات الناجمة عن إنهاء العلاقة بين صاحب العمل والعامل",
    finalReportsCode: 12000,
    finalReportsCode2: 417,
    isTotalGrp: 1,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 1
  },
  {
    group_name_AR: "بدل تعويض نهاية الخدمة",
    displayName: "بدل تعويض نهاية الخدمة",
    details6111: 9810,
    cardNumber: 9810,
    id: 9810,
    category: "احتياطي للضرائب المؤجلة طويلة الأجل",
    finalReportsCode: 12000,
    finalReportsCode2: 420,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 1
  },
   {
    group_name_AR: "موجودات ثابتة",
    displayName: "موجودات ثابتة",
    details6111: 9820,
    cardNumber: 9820,
    id: 9820,
    category: "احتياطي للضرائب المؤجلة طويلة الأجل",
    finalReportsCode: 12000,
    finalReportsCode2: 420,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 1
  },
  {
    group_name_AR: "أخرى",
    displayName: "أخرى",
    details6111: 9890,
    cardNumber: 9890,
    id: 9890,
    category: "احتياطي للضرائب المؤجلة طويلة الأجل",
    finalReportsCode: 12000,
    finalReportsCode2: 420,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 1
  },
  {
    group_name_AR: "إجمالي الاحتياطي للضرائب المؤجلة طويلة الأجل",
    displayName: "إجمالي الاحتياطي للضرائب المؤجلة طويلة الأجل",
    details6111: 9800,
    cardNumber: 9800,
    id: 9800,
    category: "احتياطي للضرائب المؤجلة طويلة الأجل",
    finalReportsCode: 11000,
    finalReportsCode2: 420,
    isTotalGrp: 1,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 1
  },
  {
    group_name_AR: "رأس المال السهمي ، رأس مال صاحب العمل ، رأس مال الشراكة",
    displayName: "رأس المال السهمي ، رأس مال صاحب العمل ، رأس مال الشراكة",
    details6111: 9910,
    cardNumber: 9910,
    id: 9910,
    category: "رأس المال",
    finalReportsCode: 16000,
    finalReportsCode2: 600,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 1,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "علاوة على الأسهم",
    displayName: "علاوة على الأسهم",
    details6111: 9920,
    cardNumber: 9920,
    id: 9920,
    category: "رأس المال",
    finalReportsCode: 16000,
    finalReportsCode2: 600,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 1,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "صناديق رأس المال",
    displayName: "صناديق رأس المال",
    details6111: 9930,
    cardNumber: 9930,
    id: 9930,
    category: "رأس المال",
    finalReportsCode: 16000,
    finalReportsCode2: 600,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 1,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "إيصالات الأسهم",
    displayName: "إيصالات الأسهم",
    details6111: 9940,
    cardNumber: 9940,
    id: 9940,
    category: "رأس المال",
    finalReportsCode: 16000,
    finalReportsCode2: 600,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 1,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "الإيصالات بسبب الخيارات",
    displayName: "الإيصالات بسبب الخيارات",
    details6111: 9950,
    cardNumber: 9950,
    id: 9950,
    category: "رأس المال",
    finalReportsCode: 16000,
    finalReportsCode2: 600,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 1,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "الاستحواذ الذاتي على الأوراق المالية",
    displayName: "الاستحواذ الذاتي على الأوراق المالية",
    details6111: 9960,
    cardNumber: 9960,
    id: 9960,
    category: "رأس المال",
    finalReportsCode: 16000,
    finalReportsCode2: 600,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 1,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "الربح (الخسارة) المتراكم.",
    displayName: "الربح (الخسارة) المتراكم.",
    details6111: 9980,
    cardNumber: 9980,
    id: 9980,
    category: "رأس المال",
    finalReportsCode: 16000,
    finalReportsCode2: 600,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 1,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "اجمالي رأس المال",
    displayName: "اجمالي رأس المال",
    details6111: 9900,
    cardNumber: 9900,
    id: 9900,
    category: "رأس المال",
    finalReportsCode: 16000,
    finalReportsCode2: 600,
    isTotalGrp: 1,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 1,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "إجمالي المطلوبات ورأس المال",
    displayName: "إجمالي المطلوبات ورأس المال",
    details6111: 9999,
    cardNumber: 9999,
    id: 9999,
    category: "إجمالي المطلوبات ورأس المال",
    finalReportsCode: 9999,
    finalReportsCode2: 699,
    isTotalGrp: 1,
    isPositiveDclear: 1,
    isBalanceSheet: 1,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "الربح / الخسارة حسب تقرير الربح والخسارة",
    displayName: "الربح / الخسارة حسب تقرير الربح والخسارة",
    details6111: 100,
    cardNumber: 100,
    id: 100,
    category: "بيانات تقرير تسوية الضرائب",
    finalReportsCode: 99,
    finalReportsCode2: 690,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "المصاريف الزائدة",
    displayName: "المصاريف الزائدة",
    details6111: 110,
    cardNumber: 110,
    id: 110,
    category: "بيانات تقرير تسوية الضرائب",
    finalReportsCode: 99,
    finalReportsCode2: 99,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "الإهلاك في الكتب",
    displayName: "الإهلاك في الكتب",
    details6111: 120,
    cardNumber: 120,
    id: 120,
    category: "بيانات تقرير تسوية الضرائب",
    finalReportsCode: 99,
    finalReportsCode2: 99,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "الاستهلاك لأغراض الضريبة",
    displayName: "الاستهلاك لأغراض الضريبة",
    details6111: 130,
    cardNumber: 130,
    id: 130,
    category: "بيانات تقرير تسوية الضرائب",
    finalReportsCode: 99,
    finalReportsCode2: 99,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 1,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "انخفاض / زيادة في مخصص الديون المشكوك في تحصيلها",
    displayName: "انخفاض / زيادة في مخصص الديون المشكوك في تحصيلها",
    details6111: 140,
    cardNumber: 140,
    id: 140,
    category: "بيانات تقرير تسوية الضرائب",
    finalReportsCode: 99,
    finalReportsCode2: 99,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "النقص / الزيادة في بدل الإجازة والنقاهة",
    displayName: "النقص / الزيادة في بدل الإجازة والنقاهة",
    details6111: 150,
    cardNumber: 150,
    id: 150,
    category: "بيانات تقرير تسوية الضرائب",
    finalReportsCode: 99,
    finalReportsCode2: 99,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "النقص / الزيادة في صافي الاحتياطي لتعويضاتهم",
    displayName: "النقص / الزيادة في صافي الاحتياطي لتعويضاتهم",
    details6111: 160,
    cardNumber: 160,
    id: 160,
    category: "بيانات تقرير تسوية الضرائب",
    finalReportsCode: 99,
    finalReportsCode2: 99,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "خسارة رأس المال / خسارة رأس المال من بيع الأصول الثابتة",
    displayName: "خسارة رأس المال / خسارة رأس المال من بيع الأصول الثابتة",
    details6111: 170,
    cardNumber: 170,
    id: 170,
    category: "بيانات تقرير تسوية الضرائب",
    finalReportsCode: 99,
    finalReportsCode2: 99,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "الخسائر / المكاسب المتراكمة من الأوراق المالية المتداولة",
    displayName: "الخسائر / المكاسب المتراكمة من الأوراق المالية المتداولة",
    details6111: 180,
    cardNumber: 180,
    id: 180,
    category: "بيانات تقرير تسوية الضرائب",
    finalReportsCode: 99,
    finalReportsCode2: 99,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "سيتم تنسيق المصاريف الأخرى",
    displayName: "سيتم تنسيق المصاريف الأخرى",
    details6111: 190,
    cardNumber: 190,
    id: 190,
    category: "بيانات تقرير تسوية الضرائب",
    finalReportsCode: 99,
    finalReportsCode2: 99,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "يتم تنسيق الإيرادات الأخرى",
    displayName: "يتم تنسيق الإيرادات الأخرى",
    details6111: 200,
    cardNumber: 200,
    id: 200,
    category: "بيانات تقرير تسوية الضرائب",
    finalReportsCode: 99,
    finalReportsCode2: 99,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "حصة في أرباح / خسائر الشراكة في الدفاتر",
    displayName: "حصة في أرباح / خسائر الشراكة في الدفاتر",
    details6111: 300,
    cardNumber: 300,
    id: 300,
    category: "بيانات تقرير تسوية الضرائب",
    finalReportsCode: 99,
    finalReportsCode2: 99,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "حصة في أرباح / خسائر الشراكة للأغراض الضريبية",
    displayName: "حصة في أرباح / خسائر الشراكة للأغراض الضريبية",
    details6111: 350,
    cardNumber: 350,
    id: 350,
    category: "بيانات تقرير تسوية الضرائب",
    finalReportsCode: 99,
    finalReportsCode2: 99,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "إجمالي الدخل الخاضع للضريبة وفقاً لأحكام قانون التنسيق",
    displayName: "إجمالي الدخل الخاضع للضريبة وفقاً لأحكام قانون التنسيق",
    details6111: 400,
    cardNumber: 400,
    id: 400,
    category: "بيانات تقرير تسوية الضرائب",
    finalReportsCode: 99,
    finalReportsCode2: 99,
    isTotalGrp: 1,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "الاستقطاع بسبب التضخم",
    displayName: "الاستقطاع بسبب التضخم",
    details6111: 410,
    cardNumber: 410,
    id: 410,
    category: "تنفيذ أحكام قانون التنسيق",
    finalReportsCode: 99,
    finalReportsCode2: 99,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "إضافة بسبب التضخم",
    displayName: "إضافة بسبب التضخم",
    details6111: 420,
    cardNumber: 420,
    id: 420,
    category: "تنفيذ أحكام قانون التنسيق",
    finalReportsCode: 99,
    finalReportsCode2: 99,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "حسم الاستهلاك",
    displayName: "حسم الاستهلاك",
    details6111: 430,
    cardNumber: 430,
    id: 430,
    category: "تنفيذ أحكام قانون التنسيق",
    finalReportsCode: 99,
    finalReportsCode2: 99,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 1,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مكاسب رأس المال الحقيقي من الأرباح الرأسمالية وفقًا للمادة 6 (ز) من قانون التنسيق",
    displayName: "مكاسب رأس المال الحقيقي من الأرباح الرأسمالية وفقًا للمادة 6 (ز) من قانون التنسيق",
    details6111: 440,
    cardNumber: 440,
    id: 440,
    category: "تنفيذ أحكام قانون التنسيق",
    finalReportsCode: 99,
    finalReportsCode2: 99,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "آثار أخرى لقانون التنسيق",
    displayName: "آثار أخرى لقانون التنسيق",
    details6111: 490,
    cardNumber: 490,
    id: 490,
    category: "تنفيذ أحكام قانون التنسيق",
    finalReportsCode: 99,
    finalReportsCode2: 99,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "إجمالي الدخل / الخسارة الخاضعة للضريبة (انتقل إلى الحقول المناسبة في النموذجين 1301 و 1214)",
    displayName: "إجمالي الدخل / الخسارة الخاضعة للضريبة (انتقل إلى الحقول المناسبة في النموذجين 1301 و 1214)",
    details6111: 500,
    cardNumber: 500,
    id: 500,
    category: "تنفيذ أحكام قانون التنسيق",
    finalReportsCode: 99,
    finalReportsCode2: 99,
    isTotalGrp: 1,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "الدخل الخاضع للضريبة بسعر خاص",
    displayName: "الدخل الخاضع للضريبة بسعر خاص",
    details6111: 510,
    cardNumber: 510,
    id: 510,
    category: "بيانات إضافية",
    finalReportsCode: 99,
    finalReportsCode2: 99,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "كسب رأس المال الحقيقي",
    displayName: "كسب رأس المال الحقيقي",
    details6111: 520,
    cardNumber: 520,
    id: 520,
    category: "بيانات إضافية",
    finalReportsCode: 99,
    finalReportsCode2: 99,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "مكاسب رأس المال التضخمية",
    displayName: "مكاسب رأس المال التضخمية",
    details6111: 530,
    cardNumber: 530,
    id: 530,
    category: "بيانات إضافية",
    finalReportsCode: 99,
    finalReportsCode2: 99,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "التقدير العقاري",
    displayName: "التقدير العقاري",
    details6111: 540,
    cardNumber: 540,
    id: 540,
    category: "بيانات إضافية",
    finalReportsCode: 99,
    finalReportsCode2: 99,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "خسارة رأس المال للتحويل",
    displayName: "خسارة رأس المال للتحويل",
    details6111: 550,
    cardNumber: 550,
    id: 550,
    category: "بيانات إضافية",
    finalReportsCode: 99,
    finalReportsCode2: 99,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "الخصم بسبب التضخم المرحّل",
    displayName: "الخصم بسبب التضخم المرحّل",
    details6111: 560,
    cardNumber: 560,
    id: 560,
    category: "بيانات إضافية",
    finalReportsCode: 99,
    finalReportsCode2: 99,
    isTotalGrp: 0,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  },
  {
    group_name_AR: "يتم نقل الاختلاف الحقيقي",
    displayName: "يتم نقل الاختلاف الحقيقي",
    details6111: 570,
    cardNumber: 570,
    id: 570,
    category: "بيانات إضافية",
    finalReportsCode: 99,
    finalReportsCode2: 99,
    isTotalGrp: 1,
    isPositiveDclear: 1,
    isBalanceSheet: 0,
    isDepreciationExpense: 0,
    isActive: 0,
    iSalaryExpense: 0,
    isFinanceExpense: 0,
    isFixedAsset: 0,
    isCapital: 0,
    isCash: 0,
    isReceivable: 0,
    isPayable: 0
  }
]

;

const document6111ByCategory = [];

for (const obj of file6111) {
  const { category, ...rest } = obj;

  const existingCategory = document6111ByCategory.find((item) => item.category === category);

  if (existingCategory) {
      existingCategory.data.push(rest);
  } else {
      document6111ByCategory.push({ category, data: [rest] });
  }
}

const sortFile6111ByFinalReportsCode = (file6111) => {
  const result = [];
  const finalReportsCodeMap = {};

  for (const obj of file6111) {
      const { finalReportsCode2, ...rest } = obj;

      if (finalReportsCodeMap[finalReportsCode2]) {
          finalReportsCodeMap[finalReportsCode2].data.push(rest);
      } else {
          finalReportsCodeMap[finalReportsCode2] = { finalReportsCode2, data: [rest] };
      }
  }

  // Push the grouped objects into the result array
  for (const finalReportsCode in finalReportsCodeMap) {
      result.push(finalReportsCodeMap[finalReportsCode]);
  }

  return result;
};

const document6111ByFinalReportsCode1 = sortFile6111ByFinalReportsCode(file6111);

/// adding key categorycardNumber to all objects in the same category
/// adding key categorycode to all objects in the same category
const addCategoryCode = (data) => {
  // Create a mapping to keep track of the category cardNumber
  // Create a mapping to keep track of the category codes
  const categoryCodes = {};
  // Loop through the data and add the "categorycardNumber" key to each object
  // Loop through the data and add the "categorycode" key to each object
  for (const item of data) {

      const { category } = item;
      if (!(category in categoryCodes)) {
          // If the category is not in the mapping, assign a new cardNumber starting from 1
          // If the category is not in the mapping, assign a new code starting from 1
          const nextCode = Object.keys(categoryCodes).length + 1;
          categoryCodes[category] = nextCode;
      }
      // Add the "categorycode" key to the object with the current code
      // Add the "categorycardNumber" key to the object with the current cardNumber
      // Add the "categorycode" key to the object with the current code
      item.categorycardNumber = categoryCodes[category];
      item.categorycode = categoryCodes[category];
  }
  // Return the modified data
  return data;
};

// Assuming `file6111` is the array you provided earlier
const document6111 = addCategoryCode(file6111);

// console.log("document6111",document6111)
// console.log("document6111ByCategory",document6111ByCategory)
// console.log("document6111ByFinalReportsCode1",document6111ByFinalReportsCode1)


// Export both the modified array and the original data array
export { document6111, document6111ByCategory, document6111ByFinalReportsCode1 ,file6111};
