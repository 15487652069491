import React, { useEffect, useState } from "react";
import { Table } from "antd";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import TicketModelPopup from "../../../components/modelpopup/TicketModelPopup";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { useSelector } from "react-redux";
import {
  formatNumbers,
  getDate,
  sumArrayProperties,
} from "../../../utils/openformat/numbers";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { arrayToObject } from "../../../utils/openformat/arraysLibrary";
import SearchFilter from "./components/SearchFilter";
import TransactionPopup from "./components/TransationPopup";
import OpenFormatHeader from "../OpenForamt/OpenFormatHeader";
const GeneralLedger = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [totals, setTotals] = useState([]);
  const [currentAccount, setCurrentAccount] = useState(null);
  const [data, setData] = useState([]);
  const [selectedTransatcion, setSelectedTransaction] = useState({});
  const ahid = useSelector((state) => state.ahid);
  const accounts = ahid.accounts;
  // console.log(accounts);

  useEffect(() => {
    if (id) {
      totalToUI(id);
      setCurrentAccount(accounts[id]);
    } else {
      setCurrentAccount(null);
      setTotals([]);
      setData([]);
    }
  }, [id]);

  const totalToUI = (accountId) => {
    let max = 0,
      min = 9999999;

    let arr =
      ahid.bkmvFile?.Accounting_Transactions?.data?.filter(
        (item) => item.recordAccount == accountId
      ) || [];
    const list = [];

    let lineTotal = accounts[id]?.starterAmount;
    let totalDebit = 0,
      totalCredit = 0;

    arr.forEach((element) => {
      let debit = 0,
        credit = 0;
      element.actionSign == 2
        ? (credit = element.actionTotal * -1)
        : (debit = element.actionTotal);

      if (element.actionTotal < min) min = element.actionTotal;
      if (element.actionTotal > max) max = element.actionTotal;

      lineTotal += debit + credit;
      totalCredit += credit;
      totalDebit += debit;

      let object = {
        ...element,
        debit,
        credit,
        endTotal: element.starterAmount + debit - credit,
        counterAccountName: accounts[element.counterAccount]?.accountName,
        lineTotal,
      };
      list.push(object);
    });
    setData(list);

    getTotals(
      accounts[id]?.starterAmount,
      totalCredit,
      totalDebit,
      lineTotal,
      list.length,
      min,
      max
    );
  };

  const getTotals = (opening, credit, debit, total, length, min, max) => {
    const totals = [
      {
        title: "الرصيد الافتتاحي",
        value: formatNumbers(opening),
        percentage: "",
      },
      {
        title: "مجموع المدين",
        value: formatNumbers(debit),
        percentage: "",
      },
      {
        title: "مجموع الدائن",
        value: formatNumbers(credit),
        percentage: "",
      },
      {
        title: "الرصيد",
        value: formatNumbers(total),
        percentage: "",
      },
      {
        title: "عدد الحركات",
        value: formatNumbers(length),
        percentage: "",
      },
      {
        title: "المعدل",
        value: formatNumbers(Math.abs(total) / length),
        percentage: "",
      },
      {
        title: "أكبر قيمة",
        value: formatNumbers(max),
        percentage: "",
      },
      {
        title: "أصغر قيمة",
        value: formatNumbers(min),
        percentage: "",
      },
    ];

    setTotals(totals);
  };

  const handleTransactionFullView = (transaction) => {
    const ledger = [];
    const list = ahid.bkmvFile?.Accounting_Transactions?.data?.filter(
      (item) =>
        item.recordNumber == transaction.recordNumber &&
        item.portion == transaction.portion
    );
    list.forEach((item) => {
      ledger.push({ ...item, name: accounts[item.recordAccount].accountName });
    });

    console.log(ledger);
    setSelectedTransaction({ transaction, ledger });
  };

  const columns = [
    {
      title: "رقم الرزمة",
      dataIndex: "portion",
      sorter: (a, b) => a.portion - b.portion,
    },
    {
      title: "رقم الحركة",
      dataIndex: "recordNumber",
      sorter: (a, b) => a.portion - b.portion,
      render: (text, item) => (
        <Link
          to="#"
          onClick={() => handleTransactionFullView(item)}
          data-bs-toggle="modal"
          data-bs-target="#transaction"
        >
          {text}
        </Link>
      ),
    },
    {
      title: "رقم الحساب المضاد",
      dataIndex: "counterAccount",
      render: (text) => (
        <Link to={`/general-ledger/${text}`} className="table-avatar">
          {text}
        </Link>
      ),
      sorter: (a, b) => a.counterAccount - b.counterAccount,
    },

    {
      title: "اسم الحساب المضاد",
      dataIndex: "counterAccountName",
      sorter: (a, b) => a.debit < b.debit,
    },
    {
      title: "مدين",
      dataIndex: "debit",
      render: (text) => formatNumbers(text * 1),
      sorter: (a, b) => a.credit - b.credit,
    },
    {
      title: "دائن",
      dataIndex: "credit",
      render: (text) => formatNumbers(text * 1),
      sorter: (a, b) => a.trialBalanceDescription < b.trialBalanceDescription,
    },
    {
      title: "رصيد",
      dataIndex: "lineTotal",
      render: (text) => formatNumbers(text * 1),
      sorter: (a, b) => a.starterAmount - b.starterAmount,
    },

    {
      title: "التاريخ",
      dataIndex: "entryDate",
      render: (text) => getDate(text),
    },
    {
      title: "تاريخ القيمة",
      dataIndex: "date",
      render: (text) => getDate(text),
    },
    {
      title: "السند 1",
      dataIndex: "reference",
    },
    {
      title: "السند 2",
      dataIndex: "reference2",
    },
    {
      title: "التفاصيل",
      dataIndex: "details",
    },
  ];

  return (
    <>
      <TransactionPopup transaction={selectedTransatcion} />
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs
            maintitle={t("generalLedger")}
            title={t("dashboard")}
            subtitle={t("generalLedger")}
            modal="#transaction"
            name="Add Ticket"
          />
          <OpenFormatHeader />
          <div className="row">
            <div className="col-md-12">
              <div className="card-group m-b-30 row">
                {totals?.map((total, index) => (
                  <div className="col-3" key={index}>
                    <div className="card">
                      <div className="card-body">
                        <div className="d-flex justify-content-between mb-3">
                          <div>
                            <span className="d-block">{total.title}</span>
                          </div>
                          <div>
                            <span
                              className={
                                total.percentage.includes("-")
                                  ? "text-danger"
                                  : "text-success"
                              }
                            >
                              {total.percentage}
                            </span>
                          </div>
                        </div>
                        <h3 className="mb-3">{total.value}</h3>
                        <div
                          className="progress mb-2"
                          style={{ height: "5px" }}
                        >
                          <div
                            className="progress-bar bg-primary"
                            role="progressbar"
                            style={{ width: "70%" }}
                            aria-valuenow={40}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {!id ? (
            <SearchFilter
              data={ahid.bkmvFile?.Accounts?.data}
              current={id}
              setCurrent={setCurrentAccount}
              isNavigate
            />
          ) : (
            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
              <Link
                to="/general-ledger"
                className="btn btn-success btn-block w-100"
              >
                {" "}
                بحث{" "}
              </Link>
            </div>
          )}

          {currentAccount && (
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between mb-3 ">
                  <div>
                    <span className="d-block">
                      {" "}
                      رقم الحساب: {currentAccount.accountId}
                    </span>
                  </div>
                </div>
                <h3 className="mb-3 ">الحساب: {currentAccount.accountName}</h3>
              </div>
            </div>
          )}

          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <Table
                  className="table-striped"
                  rowKey={(record) => record.id}
                  style={{ overflowX: "auto" }}
                  columns={columns}
                  dataSource={data?.length > 0 ? data : []}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <TicketModelPopup />
      <DeleteModal Name="Delete Ticket" />
    </>
  );
};

export default GeneralLedger;
