import React from 'react'
import { useSelector } from 'react-redux';
import { arrayToObject126 } from "../../../../../utils/s126/arrays126";
import { useTranslation } from 'react-i18next';
const Form106Header = () => {
  const salary = useSelector((state) => state.salary);
  const companyData = arrayToObject126(salary.type10)
  const type40Data = arrayToObject126(salary.type40[0])
  const {t} = useTranslation();
  // console.log("Type40?:",type40Data)

  return (

      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-sm-4 m-b-20">
                  {/* <img src={Applogo} className="inv-logo" alt="Logo" /> */}
                  <ul className="list-unstyled mb-0">
                    <li> {companyData.employerName}</li>
                    {/* <li className='text-primary'>{companyData.taxFileNumber}</li> */}
                    {/* <li> {type40Data.companyNumber}</li> */}
                    <li>{companyData.street} , {companyData.city}</li>
                    <li> </li>

                   
                  </ul>
                </div>
                <div className="col-sm-5 m-b-20">
                  <ul className="list-unstyled mb-0 text-center">
                    <li><h3  className='text-primary'>{t("form106Title1")}</h3></li>
                    <li> <h4>{t("form106Title2")}  <span className='text-primary'>{companyData.taxYear}</span> {t("taxYear")} ( {t("form106")})</h4></li>
                  </ul>
                </div>
                <div className="col-sm-3 m-b-20">
                  <ul className="list-unstyled mb-0 text-left">
                    <li>   {companyData.taxFileNumber} </li>
                    <li>  {type40Data.phoneNumber}</li>
                  </ul>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
  )
}

export default Form106Header
