import React from 'react'
import { Table } from "antd";
const AttendanceComponent = ({data}) => {

    const columns = [
        {
          title: "الاسم / الشهر",
          dataIndex: "name",
          sorter: (a, b) => a.name.length - b.name.length,
        },
        {
            title:'1',
            dataIndex:"1",
            render:(text,record)=>(
                <span>{text==true?<i className="fa fa-check text-success" />: <i className="fa fa-close text-danger" />}</span>
            )
        },
        {
            title:'2',
            dataIndex:"2",
            render:(text,record)=>(
                <span>{text==true?<i className="fa fa-check text-success" />: <i className="fa fa-close text-danger" />}</span>
            )
        },
        {
            title:'3',
            dataIndex:"3",
            render:(text,record)=>(
                <span>{text==true?<i className="fa fa-check text-success" />: <i className="fa fa-close text-danger" />}</span>
            )
        },
        {
            title:'4',
            dataIndex:"4",
            render:(text,record)=>(
                <span>{text==true?<i className="fa fa-check text-success" />: <i className="fa fa-close text-danger" />}</span>
            )
        },
        {
            title:'5',
            dataIndex:"5",
            render:(text,record)=>(
                <span>{text==true?<i className="fa fa-check text-success" />: <i className="fa fa-close text-danger" />}</span>
            )
        },
        {
            title:'6',
            dataIndex:"6",
            render:(text,record)=>(
                <span>{text==true?<i className="fa fa-check text-success" />: <i className="fa fa-close text-danger" />}</span>
            )
        },
        {
            title:'7',
            dataIndex:"7",
            render:(text,record)=>(
                <span>{text==true?<i className="fa fa-check text-success" />: <i className="fa fa-close text-danger" />}</span>
            )
        },
        {
            title:'8',
            dataIndex:"8",
            render:(text,record)=>(
                <span>{text==true?<i className="fa fa-check text-success" />: <i className="fa fa-close text-danger" />}</span>
            )
        },
        {
            title:'9',
            dataIndex:"9",
            render:(text,record)=>(
                <span>{text==true?<i className="fa fa-check text-success" />: <i className="fa fa-close text-danger" />}</span>
            )
        },
        {
            title:'10',
            dataIndex:"10",
            render:(text,record)=>(
                <span>{text==true?<i className="fa fa-check text-success" />: <i className="fa fa-close text-danger" />}</span>
            )
        },
        {
            title:'11',
            dataIndex:"11",
            render:(text,record)=>(
                <span>{text==true?<i className="fa fa-check text-success" />: <i className="fa fa-close text-danger" />}</span>
            )
        },
        {
            title:'12',
            dataIndex:"12",
            render:(text,record)=>(
                <span>{text==true?<i className="fa fa-check text-success" />: <i className="fa fa-close text-danger" />}</span>
            )
        },
      ];
console.log(data)
  return (
   
    <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">

                <Table
                  columns={columns}
                  dataSource={data || []}
                  className="table-striped"
                  rowKey={(record) => record.name}
                />
              </div>
            </div>
          </div>
  )
}

export default AttendanceComponent
