
import React, { Component } from 'react';
class CutOffs extends Component {
   render() {
      return (
        <div className=""> 
     
        {/* Page Content */}
        <div className="">
          {/* Page Header */}
         
          {/* /Page Header */}
        
           
  
              <div className="table-responsive">
                <table className="table table-striped custom-table mb-0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>مدفوعات </th>
                      <th>تزكية خاصة</th>
                      <th>خاضع للضريبة</th>
                      {/* <th>استغلال</th>
                      <th className="text-right">رصيد</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="holiday-completed">
                      <td>1</td>
                      <td>خاضع للضريبة </td>
                      <td> </td>
                      <td></td>
                     
                    </tr>
                    <tr className="holiday-completed">
                      <td>2</td>
                      <td>معفى من الضريبة</td>
                      <td></td>
                      <td></td>
                      
                    </tr>
                    <tr className="holiday-completed">
                      <td>3</td>
                      <td>ضريبة دخل</td>
                      <td> </td>
                      <td></td>
                     
                    </tr>
                    <tr className="holiday-completed">
                      <td>4</td>
                      <td>تأمين وطني </td>
                      <td> </td>
                      <td></td>
                      
                    </tr>
                    <tr className="holiday-completed">
                      <td>5</td>
                      <td>ادخار </td>
                      <td> </td>
                      <td></td>
                      
                    </tr>
                    <tr className="holiday-completed">
                      <td>6</td>
                      <td>خصم بند 47 </td>
                      <td> </td>
                      <td></td>
                     
                    </tr>
                    <tr className="holiday-completed">
                      <td>7</td>
                      <td>استكمال مهني  </td>
                      <td> </td>
                      <td></td>
                      
                    </tr>
                  
                  </tbody>
                </table>
              </div>
            </div>
          
      
        {/* /Page Content */}
        
      </div>
        );
   }
}

export default CutOffs;
