import { createSlice } from "@reduxjs/toolkit";
import { arrayToObject126 } from "./utils/s126/arrays126";

const initialState = {
  type10:[],
  type20: {},
  type30: {},
  type40: {},
  type50: {},
  currentEmployee:null
};

export const salary126Slice = createSlice({
  name: "salary",
  initialState,
  reducers: {
    upload126: (state, action) => {
      // state.type10 = arrayToObject126(action.payload.type10.data[0]);
      state.type10 = action.payload.type10.data[0];
      state.type20 = action.payload.type20.data;
      state.type30 = action.payload.type30.data;
      state.type40 = action.payload.type40.data;
      state.type50 = action.payload.type50.data;
    },
    currentEmployee:(state,action)=>{
      state.currentEmployee=action.payload
    }
  },
});

export const {
  upload126,currentEmployee
} = salary126Slice.actions;
export default salary126Slice.reducer;
