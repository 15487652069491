import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../../../components/Breadcrumbs";
import DeleteModal from "../../../../../components/modelpopup/deletePopup";
import BudgetTable from "./BudgetTable";
import AddModal from "./AddModal";
import EditBudgetModal from "./EditBudgetModal";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { compensationRecordTotals, employeeCompensationPart, employeesCompensationPart, employerCompensationPart, employersCompensationPart, getCostPercentage, getNetSalary, getTotalCost, multiArrayToObject126, salaryCostTotals, structureArrayToObject } from "../../../../../utils/s126/arrays126";
import structure from '../../../../../utils/s126/structure126.json'
import { formatNumbers } from "../../../../../utils/openformat/numbers";
import { sumArrayByProperty } from "../../../../../utils/openformat/arraysLibrary";
import Header10 from "../../records/record10Header/Header10";

const CompensationAllowances = () => {
  const { t, i18n } = useTranslation()
  const salary = useSelector((state) => state.salary);
  const type20 = salary.type20?.length > 0 ? multiArrayToObject126(salary.type20) : []
  const structure20 = structureArrayToObject(structure.type20)
  const [data, setData] = useState([])

  useEffect(() => {
    setData(type20)
  }, [])

  const columns = [
    {
      title: structure20.factoryWorkerNumber[i18n.language],
      dataIndex: "factoryWorkerNumber",
      sorter: (a, b) => a.leavetype.length - b.leavetype.length,
    },

    {
      title: structure20.firstName[i18n.language],
      dataIndex: "firstName",
      // sorter: (a, b) => a.from.length - b.from.length,
    },
    {
      title: structure20.lastName[i18n.language],
      dataIndex: "lastName",
      // sorter: (a, b) => a.noofdays.length - b.noofdays.length,
    },
    {
      title: structure20.idNumber[i18n.language],
      dataIndex: "idNumber",
      // sorter: (a, b) => a.noofdays.length - b.noofdays.length,
    },
    {
      title: structure20.basicSalaryAnnuity[i18n.language],
      dataIndex: "basicSalaryAnnuity",
      render: (text) => <span>{formatNumbers(text * 1)}</span>,

      // sorter: (a, b) => a.to.length - b.to.length,
    },

    {
      title: structure20.pensionEmployerContribution[i18n.language],
      dataIndex: "pensionEmployerContribution",
      render: (text) => <span>{formatNumbers(text * 1)}</span>,

      // sorter: (a, b) => a.noofdays.length - b.noofdays.length,
    },
    {
      title: structure20.employeeDisabilityInsurance[i18n.language],
      dataIndex: "employeeDisabilityInsurance",
      render: (text) => <span>{formatNumbers(text * 1)}</span>,

      // sorter: (a, b) => a.noofdays.length - b.noofdays.length,
    },
    {
      title: structure20.employeeEducationContribution[i18n.language],
      dataIndex: "employeeEducationContribution",
      render: (text) => <span>{formatNumbers(text * 1)}</span>,

      // sorter: (a, b) => a.noofdays.length - b.noofdays.length,
    },
    {
      title: structure20.cpfEmployerContribution[i18n.language],
      dataIndex: "cpfEmployerContribution",
      render: (text) => <span>{formatNumbers(text * 1)}</span>,

      // sorter: (a, b) => a.noofdays.length - b.noofdays.length,
    },
    {
      title: structure20.employerContributionToPensionFundCompensationComponent[i18n.language],
      dataIndex: "employerContributionToPensionFundCompensationComponent",
      render: (text) => <span>{formatNumbers(text * 1)}</span>,

      // sorter: (a, b) => a.noofdays.length - b.noofdays.length,
    },
    {
      title: structure20.employerEducationContribution[i18n.language],
      dataIndex: "employerEducationContribution",
      render: (text) => <span>{formatNumbers(text * 1)}</span>,

      // sorter: (a, b) => a.noofdays.length - b.noofdays.length,
    },
    {
      title: structure20.employerDisabilityInsurance[i18n.language],
      dataIndex: "employerDisabilityInsurance",
      render: (text) => <span>{formatNumbers(text * 1)}</span>,

      // sorter: (a, b) => a.noofdays.length - b.noofdays.length,
    },
    {
      title: "مساهمة الموظف",
      dataIndex: "",
      render: (text, record) => <span>{formatNumbers(employeeCompensationPart(record))}</span>,

      // sorter: (a, b) => a.noofdays.length - b.noofdays.length,
    },
    {
      title: "مساهمة المشغل",
      dataIndex: "",
      render: (text, record) => <span>{formatNumbers(employerCompensationPart(record))}</span>,

      // sorter: (a, b) => a.noofdays.length - b.noofdays.length,
    },
    {
      title: "مجموع الموظف والمشغل",
      dataIndex: "",
      render: (text, record) => <span>{formatNumbers(compensationRecordTotals(record))}</span>,

      // sorter: (a, b) => a.noofdays.length - b.noofdays.length,
    },
  ];

  // netSalary,totalEmployer,totalSalary,totalsCost
  const statsData = [
    {
      id: 1,
      title: "مجموع أساس المخصصات",
      value: formatNumbers(sumArrayByProperty(type20, "basicSalaryAnnuity")),
      subText: "شيكل",
    },

    {
      id: 2,
      title: "مجموع الرواتب",
      value: formatNumbers(sumArrayByProperty(type20, "normalTax")),
      subText: "شيكل",
    },

    {
      id: 3,
      title: "عدد الموظفين",
      value: type20.length,
      col:2
    },

    {
      id: 4,
      title: "عدد الموظفين الذين تدفع عنهم مخصصات",
      value: type20.filter(item=>item.basicSalaryAnnuity*1 > 0).length,
      col:2
    },
    {
      id: 14,
      title: "عدد الموظفين الذين لم تدفع لهم مخصصات",
      value: type20.filter(item=>item.basicSalaryAnnuity*1 ==0).length,
      col:2
    },
    {
      id: 5,
      title: "مجموع مخصصات التقاعد - مساهمة الموظفين",
      value: formatNumbers(sumArrayByProperty(type20, "pensionEmployerContribution")),
      subText: "شيكل",
    },

    {
      id: 6,
      title: "مجموع تأمين العجز - مساهمة الموظفين",
      value: formatNumbers(sumArrayByProperty(type20, "employeeDisabilityInsurance")),
      subText: "شيكل",
    },

    {
      id: 7,
      title: "مجموع التعليم المستمر - مساهمة الموظفين",
      value: formatNumbers(sumArrayByProperty(type20, "employeeEducationContribution")),
      subText: "شيكل",
    },

    {
      id: 8,
      title: "إجمالي مساهمة الموظفين",
      value: formatNumbers(employeesCompensationPart(type20)),
      subText: "شيكل",
    },
    {
      id: 9,
      title: "مجموع مخصصات التقاعد - مساهمة المشغل",
      value: formatNumbers(sumArrayByProperty(type20, "cpfEmployerContribution")),
      subText: "شيكل",
      // col:2
    },

    {
      id: 10,
      title: "مجموع مخصصات نهاية الخدمة - مساهمة المشغل",
      value: formatNumbers(sumArrayByProperty(type20, "employerContributionToPensionFundCompensationComponent")),
      subText: "شيكل",
      // col:2
    },

    {
      id: 11,
      title: "مجموع تأمين العجز - مساهمة المشغل",
      value: formatNumbers(sumArrayByProperty(type20, "employerDisabilityInsurance")),
      subText: "شيكل",
      col:2
    },
    {
      id: 12,
      title: "مجموع التعليم المستمر - مساهمة المشغل",
      value: formatNumbers(sumArrayByProperty(type20, "employerEducationContribution")),
      subText: "شيكل",
      col:2
    },
    {
      id: 13,
      title: "إجمالي مساهمة المشغل",
      value: formatNumbers(employersCompensationPart(type20)),
      subText: "شيكل",
      col:2
    },
  ];

  const handleSearch = (text) => {
    if (!text) {
      setData(type20)

      return
    }
    const filtered = type20.filter(item => item.firstName.includes(text) || item.lastName.includes(text) || item.idNumber.includes(text))
    setData(filtered)
  }

  const handleFilter = (value) => {
    switch (value) {
      case 1:
        setData(type20)
        return
      case 2:
        setData(type20.filter(item => item.basicSalaryAnnuity * 1 > 0))
        return
      case 3:
        setData(type20.filter(item => item.basicSalaryAnnuity * 1 == 0))
        return
    }
  }

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle={t("CompensationAllowances")}
            title="الرئيسية"
            subtitle={t("CompensationAllowances")}
          />
          <Header10 />
          <div className="row">
            {statsData.map((stat, index) => (
              <div className={`${!stat?.col?"col-md-3":`col-md-${stat.col}`} d-flex`} key={index}>
                <div className="stats-info w-100">
                  <h6>{stat.title}</h6>
                  {stat.subText ? (
                    <>
                      <h4 className="text-primary">
                        {stat.value} <span>{stat.subText}</span>
                      </h4>
                      {stat?.value2 && <p className="text-info" style={{ fontSize: '0.8em' }}>{stat.value2} <span>شهريا</span></p>}
                    </>
                  ) : (
                    <h4 className="text-primary">{stat.value}</h4>
                  )}
                </div>
              </div>
            ))}
          </div>

          <BudgetTable columns={columns} data={data} handleSearch={handleSearch} handleFilter={handleFilter}/>
        </div>
      </div>
      <AddModal />
      <EditBudgetModal />
      <DeleteModal Name="Delete" />
    </>
  );
};

export default CompensationAllowances;
