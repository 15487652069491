export const getDate = (data) => {
  if (!data) return "";
  let date = data + "";
  date = `${date.substring(0, 4)}/${date.substring(4, 6)}/${date.substring(
    6,
    8
  )}`;
  return date;
};

export const getDate2=(data)=>{
  if (!data) return "";
  let date = data + "";
  date = `${date.substring(0, 2)}/${date.substring(2, 4)}/${date.substring(
    4,
    8
  )}`;
  return date;
}

export const getTime = (data) => {
  if (!data) return "";
  let time = data + "";
  time = `${time.substring(0, 2)}:${time.substring(2, 4)}`;
  return time;
};

export const formatNumbers = (num) => {
  // console.log("Type of number is: ", typeof(num))
  // if (typeof num === 'string') {
  //   return num;
  // }
  const fomatter = new Intl.NumberFormat("en-Us", {
    minimumFractionDigits: 2,
    maximumFractionDigits:2,
    style: "decimal",
  });
  return num >= 0 ? fomatter.format(num) : `(${fomatter.format(num * -1)})`;
};
export const formatNumbers4Digits = (num) => {
  const fomatter = new Intl.NumberFormat("en-Us", {
    minimumFractionDigits: 4,
    style: "decimal",
  });
  return fomatter.format(num);
};

export const sumArrayProperties = (arr, props) => {
  const obj = {};
  arr.forEach((item) => {
    props.forEach((prop) => {
      if (!obj.hasOwnProperty(prop)) obj[prop] = item[prop];
      else obj[prop] += item[prop];
    });
  });
  return obj;
};

export const sumArrayProperty = (arr, prop) => {
  let total = 0;
  arr.forEach((item) => {
    total += item[prop];
  });
  return total;
};

export const sumArrayPropertyByType = (arr, prop, type, typeProp) => {
  let total = 0;
  arr.forEach((item) => {
    if (item[typeProp] == type) total += item[prop];
  });
  return total;
};

export const formatPhone = (number) => {
  if (!number) return "(000)-0000-000";
  const phone = `(${number.substring(0, 4)})-${number.substring(
    4,
    8
  )}-${number.substring(8, number.length)}`;
  return phone;
};

export const getMonthFromNumber = (num) => {
  let date = num.toString();

  return date.substring(4, 6) * 1;
};
