import React, { useState, useEffect } from 'react'
import { BiShekel, BiArrowToLeft } from "react-icons/bi";

export default function TbAllSystemAccounts({ headers, data, total, totalPages, currentPage, handlePageChange }) {

    let dataHeader = ["#", "رقم الفئة", "اسم الفئة"]

    const TableHeader = () => {
        return (
            <thead>
                <tr>
                    {headers.map((header, i) => (
                        <th key={i} className='fs-2  ' onClick={() => { header.sort() }}>{header.icon} {header.title}</th>
                    ))}
                </tr>
            </thead>
        )
    }

    const TableBody = () => {
        return (
            <>
                {
                    data.map((obj, i) => (
                        <tbody key={i} className='col-md-6  '>
                            <tr key={i} >
                                {/* <td className='fs-4'></td> */}
                                <th className='fs-5 text-primary pt-4'> {i + 1} - {obj.category} </th>
                            </tr>
                            <div className='card-body '>
                                <table className="table table-striped custom-table   ">
                                    <NestTableHeader />
                                    <NestTableBody data={obj.data} />
                                </table>
                            </div>
                        </tbody>
                    ))
                }
                <tfoot>

                </tfoot>
            </>
        )
    }

    const NestTableHeader = () => {
        return (
            <>
                <thead>
                    <tr className='' >
                        {dataHeader.map((header, i) => (
                            <th className='text-primary ' key={i}>{header}</th>
                        ))}
                    </tr>
                </thead>
            </>
        )
    }
    const NestTableBody = (data) => {
        return (
            <tbody>
                {data.data.map((obj, i) => (
                    <tr key={i}>
                        <td >{i + 1}</td>
                        <td >{obj.cardNumber}</td>
                        <td >{obj.displayName}</td>
                        <td >{obj.finalReportsCode2}</td>
                    </tr>
                ))}
            </tbody>
        )
    }

    // Render the table with itemsToDisplay
    const Pagination = () => {
        const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);

        return (
            <nav className='d-flex'>
                <h4 className='m-2'> الصفحات <BiArrowToLeft size={25} /></h4>
                <ul className="pagination">
                    {pageNumbers.map((pageNumber) => (
                        <li key={pageNumber} className={`page-item ${currentPage === pageNumber ? 'active' : ''}`}>
                            <button className="page-link" onClick={() => handlePageChange(pageNumber)}>
                                {pageNumber}
                            </button>
                        </li>
                    ))}
                </ul>
            </nav>
        );
    };

    return (
       <div className='card col-md-8'>
        
         <div className='card-body  '>
            <div className="table-responsive ">
                <table className="table datatable " >
                    {/* <TableHeader /> */}
                    <TableBody />
                </table>
            </div>
            <div className='mt-2'>
                <Pagination />
            </div>
        </div>
       </div>
    )
}
