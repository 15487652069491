
import React, { useState, useEffect } from 'react'
import Breadcrumbs from '../../../../components/Breadcrumbs';
import { balancesheetAccounts } from "../DeatiledIndex6111/balancesheetAccounts";
import { document6111ByFinalReportsCode1 } from '../Document6111';
import TbBranchesBalaceSheet from '../../../../components/Tables/TbBranchesBalaceSheet';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
export default function ProfitAndLoss() {
    const { t } = useTranslation();
    
    const data = useSelector((state) => state.balance).data6111;
    const data2 = useSelector((state) => state.balance).data6111PreviousYear;

    const [hideZeros,setHideZeros]=useState(false)
    const [hidePercentage,setHidePercentage]=useState(false)
    const [displayData,setDisplayData]=useState([])
    let headersByYears= data2.length?["#", "رقم الفئة", "اسم الفئة", "السنة الحالية", "السنة المُقارنة"]:["#", "رقم الفئة", "اسم الفئة", "السنة الحالية"]
    let headers = !hidePercentage?[...headersByYears,"%"]:headersByYears

    const getHeaders=()=>{
        if(data2?.fullBalanceObj && !hidePercentage) return ["#", "رقم الفئة", "اسم الفئة", "السنة الحالية","%", "السنة المُقارنة","%"]
        else if(data2?.fullBalanceObj && hidePercentage) return ["#", "رقم الفئة", "اسم الفئة", "السنة الحالية", "السنة المُقارنة"]
        else if(!data2?.fullBalanceObj && !hidePercentage) return ["#", "رقم الفئة", "اسم الفئة", "السنة الحالية", "%"]
        else return ["#", "رقم الفئة", "اسم الفئة", "السنة الحالية"]
    }

    useEffect(()=>{
        handleDispalyAll()
    },[])

  
    const handleDispalyAll=()=>{
        const list= document6111ByFinalReportsCode1.filter(obj => obj.finalReportsCode2 >= 700) 
        setDisplayData(list)
    }

    const handletFilter=(key)=>{
        const list= document6111ByFinalReportsCode1.filter(obj => obj.finalReportsCode2 >= 700 ) 
        const tmpList=[]
        list.forEach(item=>{
            let filtered= item.data.filter(el=>el[key]==1)
            tmpList.push({
                data:filtered,
                finalReportsCode2:item.finalReportsCode2
            })
        })
        setDisplayData(tmpList)
    }

    const getTitle = (category) => {
        switch (category) {
            case 1: return "موجودات متداولة";
            case 2: return "مطلوبات";
            case 3: return "موجودات ثابتة";
            case 4: return "رأس المال";
            case 5: return "بيان أرباح وخسائر";
            default: return " "
        }
    }

    const getAccountName = (number) => {
        const result = balancesheetAccounts.find((obj) => obj.group_id2 === number)
        return result ? result.group_name_AR : " "
    }

    return (
        <div className="page-wrapper ">

            {/* Page Content */}
            <div className="content container-fluid">
                <Breadcrumbs
                    maintitle={t("profitAndLoss")}
                    title="الرئيسية"
                    subtitle={t("profitAndLoss")}
                     modal="#warning-alert-modal"
                />
                <div className=" card">
                    <div className='card-body'>
                    <div className='row'>
                            <div className="col-md-2 d-flex align-items-center">
                                <input type='checkbox' className='m-2 ' checked={hideZeros} onChange={(e)=>setHideZeros(e.target.checked)}/>
                                <h4>إخفاء أرصدة صفرية</h4>
                            </div>
                            <div className="col-md-2">
                                <h4>الميزانية العمومية لشركة</h4>
                            </div>
                            <div className="col-md-3">
                                <h4 className="text-primary">
                                    {data.companyName}
                                </h4>
                                <h4 className="text-primary">
                                    {data2.companyName}
                                </h4>
                            </div>
                            <div className="col-md-2">
                                <div className=" ">
                                    <h4>للسنة المالية المنتهية ب </h4>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <h4 className="text-primary">
                                    31-12-{data.taxYear}
                                </h4>
                                <h4 className="text-primary">
                                    31-12-{data2.taxYear}
                                </h4>

                            </div>
                            <div className="col-md-2 pt-4 d-flex align-items-center">
                                <input type='checkbox' className='m-2 ' checked={hidePercentage} onChange={(e)=>setHidePercentage(e.target.checked)}/>
                                <h4>إخفاء النسب المئوية</h4>
                            </div>
                            <div className='col-md-10'></div>
                        </div>
                    </div>
 {/* Rounded Button */}
 <div className="card card-buttons">
            <div className="card-body">
              <button type="button" className="btn btn-rounded btn-outline-primary me-1" onClick={()=>handletFilter("iSalaryExpense")}>
                مصاريف الرواتب
              </button>
              <button type="button" className="btn btn-rounded btn-outline-warning me-1" onClick={()=>handletFilter("isDepreciationExpense")}>
              مصاريف الاستهلاك
              </button>
              <button type="button" className="btn btn-rounded btn-outline-danger me-1" onClick={()=>handletFilter("isFinanceExpense")}>
                مصاريف التمويل
              </button>
              <button type="button" className="btn btn-rounded btn-outline-secondary me-1">
                Secondary
              </button>
              <button type="button" className="btn btn-rounded btn-outline-success me-1">
                Success
              </button>
              <button type="button" className="btn btn-rounded btn-outline-info me-1">
                Info
              </button>
              <button type="button" className="btn btn-rounded btn-outline-dark me-1">
                Dark
              </button>
              <button type="button" className="btn btn-rounded btn-outline-light me-1" onClick={handleDispalyAll}>
                اظهار الكل
              </button>
            </div>
          </div>
          {/* /Rounded Button */}
                </div>
                <div className="row ">
                    {displayData.map((obj, i) => (
                            obj.finalReportsCode2 != 99 && obj.finalReportsCode2>690&&<div
                             key={i}
                              className= "col-md-12 " >
                                <TbBranchesBalaceSheet data={obj.data} title={obj.finalReportsCode2 + " " + getAccountName(obj.finalReportsCode2)} headers={getHeaders()} hideZeros={hideZeros} hidePercentage={hidePercentage} percentageTotal={data.fullBalanceObj["1000"]}   percentageTotal2={data2?.fullBalanceObj["1000"] || 1}  showPercentage={!hidePercentage}/>
                            </div>
                        ))
                    }
                </div>
                
            </div>
        </div>
    )
}
