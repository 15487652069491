import React, { useEffect, useState } from "react";
import { Table } from "antd";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import TicketModelPopup from "../../../components/modelpopup/TicketModelPopup";
import Breadcrumbs from "../../../components/Breadcrumbs";
import TicketFilter from "../../../components/TicketFilter";
import { useSelector } from "react-redux";
import {
  formatNumbers,
  getDate,
  getTime,
  sumArrayProperties,
} from "../../../utils/openformat/numbers";
import { useTranslation } from "react-i18next";
import OpenFormatHeader from "./OpenFormatHeader";
const OpenFormatDocuments = () => {
  const { t } = useTranslation();
  const [totals, setTotals] = useState([]);
  //   const [data, setData] = useState([]);
  const ahid = useSelector((state) => state.ahid);
  const data = useSelector((state) => state.ahid).bkmvFile?.Headers?.data || [];

  const columns = [
    {
      title: "الكود",
      dataIndex: "code",
      sorter: (a, b) => a.code < b.code,
    },
    {
      title: "رقم السجل",
      dataIndex: "recordId",
      sorter: (a, b) => a.recordId < b.recordId,
    },
    {
      title: "نوع المستند",
      dataIndex: "documentType",
      sorter: (a, b) => a.documentType < b.documentType,
    },
    {
      title: "رقم المستند",
      dataIndex: "documentNumber",
      sorter: (a, b) => a.documentNumber < b.documentNumber,
    },
    {
      title: "المشتغل المرخص زبون/مورد",
      dataIndex: "custVendAuthorizedDealer",
      sorter: (a, b) => a.custVendAuthorizedDealer < b.custVendAuthorizedDealer,
    },
    {
      title: "العنوان",
      dataIndex: "custVendName",
      sorter: (a, b) => a.custVendName - b.custVendName,
    },
    {
      title: "رقم الهاتف",
      dataIndex: "custVendPhone",
      sorter: (a, b) => a.custVendPhone - b.custVendPhone,
    },
    {
      title: "تاريخ المستند",
      dataIndex: "documentDate",
      render: (text) => <span className="table-avatar">{getDate(text)}</span>,
      sorter: (a, b) => a.documentDate - b.documentDate,
    },
    {
      title: "تاريخ طباعة المستند",
      dataIndex: "documentProductionDate",
      render: (text) => <span className="table-avatar">{getDate(text)}</span>,
      sorter: (a, b) => a.documentProductionDate - b.documentProductionDate,
    },
    {
      title: "وقت الطباعة",
      dataIndex: "documentTime",
      render: (text) => <span className="table-avatar">{getTime(text)}</span>,
      sorter: (a, b) => a.documentTime - b.documentTime,
    },
    {
      title: "المبلغ قبل خصم",
      dataIndex: "totalBeforeDiscount",
      render: (text) => (
        <span className="table-avatar">{formatNumbers(text)}</span>
      ),
      sorter: (a, b) => a.totalBeforeDiscount < b.totalBeforeDiscount,
    },
    {
      title: "مبلغ الخصم",
      dataIndex: "documentDiscount",
      render: (text) => (
        <span className="table-avatar">{formatNumbers(text)}</span>
      ),
      sorter: (a, b) => a.documentDiscount < b.documentDiscount,
    },
    {
      title: "المبلغ بعد الخصم",
      dataIndex: "totalAfterDiscountNoVat",
      render: (text) => (
        <span className="table-avatar">{formatNumbers(text)}</span>
      ),
      sorter: (a, b) => a.totalAfterDiscountNoVat < b.totalAfterDiscountNoVat,
    },
    {
      title: "ض.ق.م",
      dataIndex: "vatAmount",
      render: (text) => (
        <span className="table-avatar">{formatNumbers(text)}</span>
      ),
      sorter: (a, b) => a.vatAmount < b.vatAmount,
    },
    {
      title: "المبلغ شامل الضريبة",
      dataIndex: "totalWithVat",
      render: (text) => (
        <span className="table-avatar">{formatNumbers(text)}</span>
      ),
      sorter: (a, b) => a.totalWithVat < b.totalWithVat,
    },
  ];

  return (
    <>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs
            maintitle={t("documents")}
            title={t("dashboard")}
            subtitle={t("documents")}
            modal="#add_ticket"
            name="Add Document"
          />
          <OpenFormatHeader />
          <div className="row">
            <div className="col-md-12">
              <div className="card-group m-b-30">
                {totals?.map((total, index) => (
                  <div className="card" key={index}>
                    <div className="card-body">
                      <div className="d-flex justify-content-between mb-3">
                        <div>
                          <span className="d-block">{total.title}</span>
                        </div>
                        <div>
                          <span
                            className={
                              total.percentage.includes("-")
                                ? "text-danger"
                                : "text-success"
                            }>
                            {total.percentage}
                          </span>
                        </div>
                      </div>
                      <h3 className="mb-3">{total.value}</h3>
                      <div className="progress mb-2" style={{ height: "5px" }}>
                        <div
                          className="progress-bar bg-primary"
                          role="progressbar"
                          style={{ width: "70%" }}
                          aria-valuenow={40}
                          aria-valuemin={0}
                          aria-valuemax={100}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <TicketFilter />

          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <Table
                  className="table-striped"
                  rowKey={(record) => record.id}
                  style={{ overflowX: "auto" }}
                  columns={columns}
                  dataSource={data?.length > 0 ? data : []}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <TicketModelPopup />
      <DeleteModal Name="Delete Ticket" />
    </>
  );
};

export default OpenFormatDocuments;
