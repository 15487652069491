import React from "react";
import { Link } from "react-router-dom";
import PersonalInformationModelPopup from "../../../../components/modelpopup/PersonalInformationModelPopup";
import { ListItem, ProjectDetails, ListItemWithCode } from "./ProfileContent";
import { useSelector } from "react-redux";
import { getDate } from "../../../../utils/openformat/numbers";
import { BiShow } from "react-icons/bi";
import { formatNumbers } from "../../../../utils/openformat/numbers";
const ProfileTab = ({ getItemByLanguage }) => {
  const employee = useSelector((state) => state.salary).currentEmployee;
  // console.log("maritalStatusChangeDate:", employee.maritalStatusChangeDate)
  const socialStatusInfo = [
    { id: 1, title: getItemByLanguage("maritalStatus"), text: employee.maritalStatus },
    { id: 2, title: getItemByLanguage("maritalStatusChangeDate"), text: getDate(employee.maritalStatusChangeDate) },
    { id: 3, title: getItemByLanguage("previousMaritalStatus"), text: employee.previousMaritalStatus },
    { id: 4, title: getItemByLanguage("husbandIdNumber"), text: employee.husbandIdNumber },
    { id: 5, title: getItemByLanguage("husbandFirstName"), text: employee.husbandFirstName },
    { id: 6, title: getItemByLanguage("husbandDob"), text: getDate(employee.husbandDob) },
    { id: 7, title: getItemByLanguage("spouseIncome"), text: employee.spouseIncome, code: "21" },
  ];

  const JobInfo = [
    { id: 1, title: getItemByLanguage("jobType"), text: employee.jobType },
    { id: 2, title: getItemByLanguage("workMonths"), text: employee.workMonths },
    { id: 3, title: getItemByLanguage("totalWorkMonths"), text: employee.totalWorkMonths },
    { id: 4, title: getItemByLanguage("expenses"), text: formatNumbers(employee.expenses) },
    { id: 5, title: getItemByLanguage("numberOfMonths2"), text: employee.numberOfMonths2 },
    // { id: "" , title:"" , text:"" },
  ];

  // const secondaryContactData = [
  //   { id: 1, title: "Name", text: "Karen Wills" },
  //   { id: 2, title: "Relationship", text: "Brother" },
  //   { id: 3, title: "Phone", text: "9876543210, 9876543210" },
  // ];
  const paymentsData = [
    { id: 10, title: getItemByLanguage("totalSalary"), text: formatNumbers(employee.totalSalary) },
    { id: 24, title: getItemByLanguage("vehicleUseValue"), text: formatNumbers(employee.vehicleUseValue) },
    { id: 23, title: getItemByLanguage("normalTax"), text: formatNumbers(employee.normalTax) },
    { id: 14, title: getItemByLanguage("basicSalaryAnnuity"), text: formatNumbers(employee.basicSalaryAnnuity), code: "244/245" },
    { id: 1, title: getItemByLanguage("overTimePayments"), text: formatNumbers(employee.overTimePayments) },
    { id: 2, title: getItemByLanguage("workCapacityInsuranceTax"), text: formatNumbers(employee.workCapacityInsuranceTax) },
    { id: 3, title: getItemByLanguage("annuityTax"), text: formatNumbers(employee.annuityTax) },
    { id: 4, title: getItemByLanguage("continuingEducationFundTax"), text: formatNumbers(employee.continuingEducationFundTax) },
    { id: 5, title: getItemByLanguage("specialRateTax"), text: formatNumbers(employee.specialRateTax) },
    { id: 6, title: getItemByLanguage("advancePaySabbatical"), text: formatNumbers(employee.advancePaySabbatical) },
    { id: 7, title: getItemByLanguage("reducedTaxWages"), text: formatNumbers(employee.reducedTaxWages) },
    { id: 8, title: getItemByLanguage("normalTaxWages"), text: formatNumbers(employee.normalTaxWages) },
    { id: 9, title: getItemByLanguage("exemptTax"), text: formatNumbers(employee.exemptTax) },
    { id: 11, title: getItemByLanguage("exemptTaxIncome"), text: formatNumbers(employee.exemptTaxIncome) },
    { id: 12, title: getItemByLanguage("compensationAmount"), text: formatNumbers(employee.compensationAmount) },
    { id: 13, title: getItemByLanguage("womenLaborLawSalaryAnuinty"), text: formatNumbers(employee.womenLaborLawSalaryAnuinty), code: "244/245" },
    { id: 15, title: getItemByLanguage("employerTaxExemptPayments"), text: formatNumbers(employee.employerTaxExemptPayments) },
    { id: 16, title: getItemByLanguage("payrollTaxExemptPayments"), text: formatNumbers(employee.payrollTaxExemptPayments) },
    { id: 17, title: getItemByLanguage("considerationAmount"), text: formatNumbers(employee.considerationAmount) },
    { id: 18, title: getItemByLanguage("capitalizationDate"), text: getDate(employee.capitalizationDate) },
    { id: 19, title: getItemByLanguage("capitalizationYears"), text: employee.capitalizationYears },
    { id: 20, title: getItemByLanguage("fullPensionBeforeCapitalization"), text: employee.fullPensionBeforeCapitalization },
    { id: 21, title: getItemByLanguage("foreignWorkerWorkPermit"), text: employee.foreignWorkerWorkPermit },
    { id: 22, title: getItemByLanguage("employeeFilledElectronicForm101"), text: employee.employeeFilledElectronicForm101 },
  ];
  const deductionInfo = [
    { id: 17, title: getItemByLanguage("totalIncomeTaxDeducted"), text: formatNumbers(employee.totalIncomeTaxDeducted), code: "42" },
    { id: 2, title: getItemByLanguage("employeeCompensationAmount"), text: formatNumbers(employee.employeeCompensationAmount), code: "135" },
    { id: 3, title: getItemByLanguage("employeeLifeInsurance"), text: formatNumbers(employee.employeeLifeInsurance), code: "36" },
    { id: 4, title: getItemByLanguage("employeeResearchParticipationExpenses"), text: formatNumbers(employee.employeeResearchParticipationExpenses), code: "5" },
    { id: 5, title: getItemByLanguage("specialDeduction"), text: formatNumbers(employee.specialDeduction) },
    { id: 6, title: getItemByLanguage("selfEmployedPension"), text: formatNumbers(employee.selfEmployedPension), code: "268" },
    { id: 7, title: getItemByLanguage("survivorsInsurance"), text: formatNumbers(employee.survivorsInsurance), code: "210/140" },
    { id: 8, title: getItemByLanguage("seamenDeduction"), text: formatNumbers(employee.seamenDeduction) },
    { id: 9, title: getItemByLanguage("dateOfImmigration"), text: getDate(employee.dateOfImmigration) },
    { id: 10, title: getItemByLanguage("divorcedAlimony"), text: formatNumbers(employee.divorcedAlimony), code: "28" },
    { id: 11, title: getItemByLanguage("youngSpouse"), text: employee.youngSpouse },
    { id: 12, title: getItemByLanguage("sharedHousehold"), text: employee.sharedHousehold, code: "26" },
    { id: 13, title: getItemByLanguage("servicePeriod"), text: employee.servicePeriod, code: "24" },
    { id: 14, title: getItemByLanguage("specialTaxRate"), text: employee.specialTaxRate },
    { id: 15, title: getItemByLanguage("employeeMarginalTax"), text: employee.employeeMarginalTax },
    { id: 16, title: getItemByLanguage("employerTaxReturnPrepared"), text: employee.employerTaxReturnPrepared },
    { id: 18, title: getItemByLanguage("additionalHighIncomeTax"), text: formatNumbers(employee.additionalHighIncomeTax) },
    { id: 19, title: getItemByLanguage("organizationalTreatmentFee"), text: employee.organizationalTreatmentFee },
    { id: 20, title: getItemByLanguage("pensionExemptionPercentage"), text: employee.pensionExemptionPercentage },
    { id: 21, title: getItemByLanguage("pensionExemptionPercentage2"), text: employee.pensionExemptionPercentage2 },
    { id: 22, title: getItemByLanguage("eilatFreeTradeZoneBenefitAmount"), text: formatNumbers(employee.eilatFreeTradeZoneBenefitAmount) },
    { id: 1, title: getItemByLanguage("employeeDeduction47a"), text: formatNumbers(employee.employeeDeduction47a), code: "030/089" },
  ];
  const creditInfo = [
    { id: 1, title: getItemByLanguage("paymentToCoverExpenses"), text: formatNumbers(employee.paymentToCoverExpenses) },
    { id: 2, title: getItemByLanguage("newImmigrant"), text: employee.newImmigrant },
    { id: 3, title: getItemByLanguage("creditPointsRedsident"), text: employee.creditPointsRedsident, code: "20" },
    { id: 4, title: getItemByLanguage("creditPointWoman"), text: employee.creditPointWoman, code: "20" },
    { id: 5, title: getItemByLanguage("creditPointsImmigrants1"), text: employee.creditPointsImmigrants1 },
    { id: 6, title: getItemByLanguage("creditPointsImmigrants2"), text: employee.creditPointsImmigrants2 },
    { id: 7, title: getItemByLanguage("creditPointsApproved"), text: formatNumbers(employee.creditPointsApproved), },
    { id: 8, title: getItemByLanguage("creditPointsSingleParent"), text: formatNumbers(employee.creditPointsSingleParent), code: "26" },
    { id: 9, title: getItemByLanguage("creditPointsChildSupport"), text: formatNumbers(employee.creditPointsChildSupport), code: "29" },
    { id: 10, title: getItemByLanguage("creditPointsAcademicDegree"), text: formatNumbers(employee.creditPointsAcademicDegree) },
    { id: 11, title: getItemByLanguage("degreeReceivingDate"), text: getDate(employee.degreeReceivingDate) },
    { id: 12, title: getItemByLanguage("creditPointsInvalidRelative"), text: formatNumbers(employee.creditPointsInvalidRelative), code: "23" },
    { id: 13, title: getItemByLanguage("serviceDateOfRelease"), text: getDate(employee.serviceDateOfRelease), code: "224" },
    { id: 14, title: getItemByLanguage("creditPointsDischargedSoldier"), text: formatNumbers(employee.creditPointsDischargedSoldier) },
    { id: 15, title: getItemByLanguage("dischargedSoldierSeveralTaxYearMonths"), text: employee.dischargedSoldierSeveralTaxYearMonths },
    { id: 16, title: getItemByLanguage("creditPointsGivenForTaxYear"), text: formatNumbers(employee.creditPointsGivenForTaxYear) },
    { id: 17, title: getItemByLanguage("creditPointsAmountInShekels"), text: formatNumbers(employee.creditPointsAmountInShekels) },
    { id: 18, title: getItemByLanguage("taxUnits"), text: employee.taxUnits },
    { id: 19, title: getItemByLanguage("creditGivenAccordingDegree"), text: employee.creditGivenAccordingDegree },
    { id: 20, title: getItemByLanguage("foreignWorkerBranchCode"), text: employee.foreignWorkerBranchCode },
    { id: 21, title: getItemByLanguage("employeeIdentificationCode"), text: employee.employeeIdentificationCode },

  ];
  const pensionData = [
    { id: 5, title: getItemByLanguage("basicSalaryEducationContinue"), text: formatNumbers(employee.basicSalaryEducationContinue) },
    { id: 3, title: getItemByLanguage("womenLaborLawSalaryEducation"), text: formatNumbers(employee.womenLaborLawSalaryEducation) },
    { id: 4, title: getItemByLanguage("pensionEmployerContribution"), text: formatNumbers(employee.pensionEmployerContribution), code: "45" },
    { id: 1, title: getItemByLanguage("cpfEmployerContribution"), text: formatNumbers(employee.cpfEmployerContribution), code: "248/249" },
    { id: 6, title: getItemByLanguage("employerEducationContribution"), text: formatNumbers(employee.employerEducationContribution) },
    { id: 7, title: getItemByLanguage("employeeEducationContribution"), text: formatNumbers(employee.employeeEducationContribution) },
    { id: 2, title: getItemByLanguage("pensionEmployerFunds"), text: employee.pensionEmployerFunds },
    { id: 8, title: getItemByLanguage("basicSalaryWorkCapacityInsurance"), text: formatNumbers(employee.basicSalaryWorkCapacityInsurance) },
    { id: 9, title: getItemByLanguage("employerDisabilityInsurance"), text: formatNumbers(employee.employerDisabilityInsurance) },
    { id: 10, title: getItemByLanguage("employeeDisabilityInsurance"), text: formatNumbers(employee.employeeDisabilityInsurance) },
    { id: 11, title: getItemByLanguage("employerWorkCapacityContribution"), text: employee.employerWorkCapacityContribution },
    { id: 12, title: getItemByLanguage("employerContributionToPensionFundCompensationComponent"), text: formatNumbers(employee.employerContributionToPensionFundCompensationComponent ), code: "248/249" },
    { id: 13, title: getItemByLanguage("employerContributionPensionFundCompletionObligation"), text: employee.employerContributionPensionFundCompletionObligation },
  ];
  const childrendata = [
    { id: 1, title: getItemByLanguage("childrenBornInTaxYear"), text: employee.childrenBornInTaxYear },
    { id: 2, title: getItemByLanguage("childrenBetween1To5"), text: employee.childrenBetween1To5 },
    { id: 3, title: getItemByLanguage("childrenBetween6To12"), text: employee.childrenBetween6To12 },
    { id: 4, title: getItemByLanguage("childrenBetween13To17"), text: employee.childrenBetween13To17 },
    { id: 5, title: getItemByLanguage("children18Years"), text: employee.children18Years },
    { id: 6, title: getItemByLanguage("childrenBornInTaxYear2"), text: employee.childrenBetween1To5_2 },
    { id: 8, title: getItemByLanguage("childrenBetween6To12_2"), text: employee.childrenBetween6To12_2 },
    { id: 9, title: getItemByLanguage("childrenOfOneParentMarkedInField1"), text: employee.childrenOfOneParentMarkedInField1 },
    { id: 10, title: getItemByLanguage("childrenOfOneParentMarkedInField2"), text: employee.childrenOfOneParentMarkedInField2 },
    { id: 11, title: getItemByLanguage("childrenOfOneParentMarkedInField3"), text: employee.childrenOfOneParentMarkedInField3 },
    { id: 12, title: getItemByLanguage("childrenOfOneParentMarkedInField5"), text: employee.childrenOfOneParentMarkedInField5 },
    { id: 13, title: getItemByLanguage("childrenOfOneParentMarkedInField6"), text: employee.childrenOfOneParentMarkedInField6 },
  ];
  const nationalInsuranceData = [
    { id: 4, title: getItemByLanguage("nationalInsuranceMaxPayments"), text: formatNumbers(employee.nationalInsuranceMaxPayments) },
    { id: 11, title: getItemByLanguage("employerContributionToBILAmount"), text: formatNumbers(employee.employerContributionToBILAmount) },
    { id: 2, title: getItemByLanguage("nationalInsuranceExemptHealthTax"), text: formatNumbers(employee.nationalInsuranceExemptHealthTax) },
    { id: 3, title: getItemByLanguage("employeeHealthInsurancePremiums"), text: formatNumbers(employee.employeeHealthInsurancePremiums) },
    { id: 5, title: getItemByLanguage("nationalInsuranceExceedingPayments"), text: formatNumbers(employee.nationalInsuranceExceedingPayments) },
    { id: 6, title: getItemByLanguage("disabilityPaymentSource"), text: (employee.disabilityPaymentSource) },
    { id: 8, title: getItemByLanguage("allowanceType"), text: (employee.allowanceType) },
    { id: 9, title: getItemByLanguage("nationalInsuranceEmployerType"), text: (employee.nationalInsuranceEmployerType) },
    { id: 10, title: getItemByLanguage("nationalInsuranceControlStatus"), text: (employee.nationalInsuranceControlStatus) },
    { id: 12, title: getItemByLanguage("blDeductionsPortfolioNumber"), text: employee.blDeductionsPortfolioNumber },
    { id: 14, title: getItemByLanguage("grossDifferencesPreviousYears"), text: formatNumbers(employee.grossDifferencesPreviousYears) },
    { id: 15, title: getItemByLanguage("paymentDifferencesAssociationYear"), text: formatNumbers(employee.paymentDifferencesAssociationYear) },
    { id: 16, title: getItemByLanguage("additionalSalary"), text: formatNumbers(employee.additionalSalary) },
    { id: 17, title: getItemByLanguage("basicSalaryIsraeliWorkerAbroad"), text: formatNumbers(employee.basicSalaryIsraeliWorkerAbroad) },
    { id: 18, title: getItemByLanguage("housingExpensesIsraeliWorkerAbroad"), text: formatNumbers(employee.housingExpensesIsraeliWorkerAbroad) },
    { id: 19, title: getItemByLanguage("medicalExpensesAbroad"), text: formatNumbers(employee.medicalExpensesAbroad) },
    { id: 20, title: getItemByLanguage("tuitionExpensesAbroad"), text: formatNumbers(employee.tuitionExpensesAbroad) },
    { id: 21, title: getItemByLanguage("ESHELExpensesCountryOfService"), text: formatNumbers(employee.ESHELExpensesCountryOfService) },
    { id: 22, title: getItemByLanguage("ESHELExpensesAbroad"), text: formatNumbers(employee.ESHELExpensesAbroad) },
    { id: 23, title: getItemByLanguage("homelandVisitExpenses"), text: formatNumbers(employee.homelandVisitExpenses) },
    { id: 1, title: getItemByLanguage("nationalInsuranceExemptPayments"), text: formatNumbers(employee.nationalInsuranceExemptPayments) },
  ];
  const familyInfoData = [
    {
      id: 1,
      name: "Leo",
      relationship: "Brother",
      dob: "Feb 16th, 2019",
      phone: "9876543210",
    },
  ];

  const educationData = [
    {
      id: 1,
      name: "International College of Arts and Science (UG)",
      description: "Bsc Computer Science",
      time: "2020 - 2023",
    },
    {
      id: 2,
      name: "International College of Arts and Science (PG)",
      description: "Msc Computer Science",
      time: "2021 - 2023",
    },
    // Add more education info data as needed
  ];
  const experienceData = [
    {
      id: 1,
      name: "Web Designer at Zen Corporation",
      time: "Jan 2023 - Present (5 years 2 months)",
    },
    {
      id: 2,
      name: "Web Designer at Ron-tech",
      time: "Jan 2023 - Present (5 years 2 months)",
    },
    {
      id: 3,
      name: "Web Designer at Dalt Technology",
      time: "2023 2023 - Present (5 years 2 months)",
    },
    // Add more experience info data as needed
  ];

  return (
    <>
      <div className="tab-content">
        <div
          id="emp_profile"
          className="pro-overview tab-pane fade show active"
        >
          <div className="row">
            <div className="col-md-6 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body">
                  <h3 className="card-title  ">
                    معلومات عن الحالة الاجتماعية {" "}
                    {/* <Link
                      to="#"
                      className="edit-icon"
                      data-bs-toggle="modal"
                      data-bs-target="#personal_info_modal"
                    >
                      <i className="fa fa-pencil" />
                    </Link> */}
                  </h3>
                  <ul className="personal-info">
                    {socialStatusInfo.map((item, index) => (
                      <ListItemWithCode
                        id={item.id}
                        key={index}
                        title={item.title}
                        text={item.text}
                        code={item.code}
                      />
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body">
                  <h3 className="card-title">
                    معلومات عن الوظيفة{" "}
                    {/* <Link
                      to="#"
                      className="edit-icon"
                      data-bs-toggle="modal"
                      data-bs-target="#emergency_contact_modal"
                    >
                      <i className="fa fa-pencil" />
                    </Link> */}
                  </h3>
                  {/* <h5 className="section-title">Primary</h5> */}
                  <ul className="personal-info">
                    {JobInfo.map((item, index) => (
                      <ListItemWithCode
                        id={item.id}
                        key={index}
                        title={item.title}
                        text={item.text}
                      />
                    ))}
                  </ul>
                  {/* <hr />
                  <h5 className="section-title">Secondary</h5>
                  <ul className="personal-info">
                    {secondaryContactData.map((item, index) => (
                      <ListItem
                        id={item.id}
                        key={index}
                        title={item.title}
                        text={item.text}
                      />
                    ))}
                  </ul> */}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body">
                  <h3 className="card-title">المدفوعات</h3>
                  <ul className="personal-info">
                    {paymentsData.map((item, index) => (
                      <ListItemWithCode
                        id={item.id}
                        key={index}
                        title={item.title}
                        text={item.text}
                        code={item.code}
                      />
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body">
                  <h3 className="card-title">
                    الاقتطاعات

                    <Link className="btn  float-left  btn-primary " to="#" 
                      data-bs-toggle="modal"
                      data-bs-target="#credit_info_modal">
                   <BiShow size={25} className="pl-3"/>   التزكيات 
                    </Link>
                  </h3>
                  <ul className="personal-info">
                    {deductionInfo.map((item, index) => (
                      <>
                        <ListItemWithCode
                          id={item.id}
                          key={index}
                          title={item.title}
                          text={item.text}
                          code={item.code}
                        />
                      </>
                    ))}
                  </ul>
                  {/* <div className="table-responsive">
                    <table className="table table-nowrap">
                      <thead>
                        <tr>
                          <th>الاسم</th>
                          <th>صلة القرابة</th>
                          <th>تاريخ  الميلاد</th>
                          <th>رقم الهوية</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {familyInfoData.map((item) => (
                          <tr key={item.id}>
                            <td>{item.name}</td>
                            <td>{item.relationship}</td>
                            <td>{item.dob}</td>
                            <td>{item.phone}</td>
                            <td className="text-end">
                              <div className="dropdown dropdown-action">
                                <Link
                                  aria-expanded="false"
                                  data-bs-toggle="dropdown"
                                  className="action-icon dropdown-toggle"
                                  to="#"
                                >
                                  <i className="material-icons">more_vert</i>
                                </Link>
                                <div className="dropdown-menu dropdown-menu-right">
                                  <Link to="#" className="dropdown-item">
                                    <i className="fa fa-pencil m-r-5" /> Edit
                                  </Link>
                                  <Link to="#" className="dropdown-item">
                                    <i className="fa fa-trash m-r-5" /> Delete
                                  </Link>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body">
                  <h3 className="card-title">صناديق الادخار</h3>
                  <ul className="personal-info">
                    {pensionData.map((item, index) => (
                      <ListItemWithCode
                        id={item.id}
                        key={index}
                        title={item.title}
                        text={item.text}
                        code={item.code}
                      />
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body">
                  <h3 className="card-title">معلومات التأمين الوطني</h3>
                  <ul className="personal-info">
                    {nationalInsuranceData.map((item, index) => (
                      <ListItemWithCode
                        id={item.id}
                        key={index}
                        title={item.title}
                        text={item.text}
                        code={item.code}
                      />
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body">
                  <h3 className="card-title">
                    معلومات الأولاد
                    {/* <Link
                      to="#"
                      className="edit-icon"
                      data-bs-toggle="modal"
                      data-bs-target="#family_info_modal"
                    >
                      <i className="fa fa-pencil" />
                    </Link> */}
                  </h3>
                  <ul className="personal-info">
                    {childrendata.map((item, index) => (
                      <>
                        <ListItemWithCode
                          id={item.id}
                          key={index}
                          title={item.title}
                          text={item.text}
                          code={item.code}
                        />
                      </>
                    ))}
                  </ul>
                  {/* <div className="table-responsive">
                    <table className="table table-nowrap">
                      <thead>
                        <tr>
                          <th>الاسم</th>
                          <th>صلة القرابة</th>
                          <th>تاريخ  الميلاد</th>
                          <th>رقم الهوية</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {familyInfoData.map((item) => (
                          <tr key={item.id}>
                            <td>{item.name}</td>
                            <td>{item.relationship}</td>
                            <td>{item.dob}</td>
                            <td>{item.phone}</td>
                            <td className="text-end">
                              <div className="dropdown dropdown-action">
                                <Link
                                  aria-expanded="false"
                                  data-bs-toggle="dropdown"
                                  className="action-icon dropdown-toggle"
                                  to="#"
                                >
                                  <i className="material-icons">more_vert</i>
                                </Link>
                                <div className="dropdown-menu dropdown-menu-right">
                                  <Link to="#" className="dropdown-item">
                                    <i className="fa fa-pencil m-r-5" /> Edit
                                  </Link>
                                  <Link to="#" className="dropdown-item">
                                    <i className="fa fa-trash m-r-5" /> Delete
                                  </Link>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        
          {/* <div className="row">
            <div className="col-md-6 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body">
                  <h3 className="card-title">
                    Education Informations{" "}
                    <Link
                      to="#"
                      className="edit-icon"
                      data-bs-toggle="modal"
                      data-bs-target="#education_info"
                    >
                      <i className="fa fa-pencil" />
                    </Link>
                  </h3>
                  <div className="experience-box">
                    <ul className="experience-list">
                      {educationData.map((item) => (
                        <li key={item.id}>
                          <div className="experience-user">
                            <div className="before-circle" />
                          </div>
                          <div className="experience-content">
                            <div className="timeline-content">
                              <Link to="/" className="name">
                                {item.name}
                              </Link>
                              <div>{item.description}</div>
                              <span className="time">{item.time}</span>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body">
                  <h3 className="card-title">
                    Experience{" "}
                    <Link
                      to="#"
                      className="edit-icon"
                      data-bs-toggle="modal"
                      data-bs-target="#experience_info"
                    >
                      <i className="fa fa-pencil" />
                    </Link>
                  </h3>
                  <div className="experience-box">
                    <ul className="experience-list">
                      {experienceData.map((item) => (
                        <li key={item.id}>
                          <div className="experience-user">
                            <div className="before-circle" />
                          </div>
                          <div className="experience-content">
                            <div className="timeline-content">
                              <Link to="/" className="name">
                                {item.name}
                              </Link>
                              <span className="time">{item.time}</span>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <ProjectDetails />
        {/* Bank Statutory Tab */}

        {/* Bank Statutory Tab */}
        {/*  Bank Tab */}
      </div>
      {/* Model Popup*/}
      <PersonalInformationModelPopup />
      {/* Family Info Modal */}
      <div
        id="credit_info_modal"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-primary">  التزكيات</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              
                <div className="form-scroll">
                  <div className="card">
                    <div className="card-body">
                     
                      <div className="row">
                        <div className="col-md-12">
                        <ul className="personal-info">
                    {creditInfo.map((item, index) => (
                      <ListItemWithCode
                        id={item.id}
                        key={index}
                        title={item.title}
                        text={item.text}
                        code={item.code}
                      />
                    ))}
                  </ul>
                        
                        </div>
                        
                       
                       
                      </div>
                    </div>
                  </div>
                </div>
                <div className="submit-section">
                  <button className="btn btn-primary submit-btn">اغلاق</button>
                </div>
              
            </div>
          </div>
        </div>
      </div>
      {/* /Family Info Modal */}
    </>
  );
};

export default ProfileTab;
