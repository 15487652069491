import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Applogo } from "../../../Routes/ImagePath";
import useAuthentication from "../../../hooks/useAuthentication";

const ForgotPassword = () => {
  const { resetPassword } = useAuthentication();

  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleReset = async (e) => {
    e.preventDefault();
    console.log(email);
    if (!email) {
      alert("يجب إدخال البريد الإلكتروني");
      return;
    }

    const msg = await resetPassword(email);
    setMessage(msg);
  };

  return (
    <div className="account-page">
      <div className="main-wrapper">
        <div className="account-content">
          <div className="container">
            <div className="account-logo">
              <Link to="/app/main/dashboard">
                <img src={Applogo} alt="Dreamguy's Technologies" />
              </Link>
            </div>
            <div className="account-box">
              <div className="account-wrapper">
                <h3 className="account-title">أنسيت كلمة المرور؟</h3>
                <p className="account-subtitle">
                  أدخل بريدك الإلكتروني لتعيين كلمة مرور جديدة
                </p>
                <form onSubmit={handleReset}>
                  <div className="input-block">
                    <label>البريد الإلكتروني</label>
                    <input
                      className="form-control"
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="input-block text-center">
                    <button
                      className="btn btn-primary account-btn"
                      type="submit"
                    >
                      إرسال
                    </button>
                  </div>
                  <div className="account-footer">
                    {!message ? (
                      <p>
                        هل تتذكر كلمة المرور؟ <Link to="/">دخول</Link>
                      </p>
                    ) : (
                      <p className="text-danger">{message}</p>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ForgotPassword;
