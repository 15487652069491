import React from 'react'
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
const OpenFormatHeader = () => {
  const ahid = useSelector((state) => state.ahid);
  const { t } = useTranslation();
// console.log("openFormatdata:", ahid)

  

  return (

      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-sm-4 m-b-20">
                  {/* <img src={Applogo} className="inv-logo" alt="Logo" /> */}
                  <ul className="list-unstyled mb-0">
                    <li>   {ahid.iniFile?.headerList?.businessName}</li>
                    {/* <li className='text-primary'>{companyData.taxFileNumber}</li> */}
                    <li>   {ahid.iniFile?.headerList?.Identification}</li>
                    <li> </li>

                   
                  </ul>
                </div>
                <div className="col-sm-4 m-b-20">
                  <ul className="list-unstyled mb-0 text-center">
                    <li><span  className='text-primary'>{ahid.iniFile?.headerList?.taxYear}</span></li>
                    {/* <li> {type40Data.companyNumber} , {companyData.taxFileNumber}</li> */}
                  </ul>
                </div>
                <div className="col-sm-4 m-b-20">
                  <ul className="list-unstyled mb-0 text-left">
                  <li>{ahid.iniFile?.headerList?.businessAddressStreet} , {ahid.iniFile?.headerList?.businessAddressCity}</li>
                    <li> {ahid.iniFile?.headerList?.businessAddressPostalCode}</li>
                    {/* <li> {type40Data.email}</li> */}
                  </ul>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
  )
}

export default OpenFormatHeader
