import React, { useState, useEffect } from 'react'
import { Helmet } from "react-helmet";
import Breadcrumbs from '../../../components/Breadcrumbs';
import { document6111ByCategory } from './Document6111'
import { useTranslation } from 'react-i18next';
import { FaSearch } from 'react-icons/fa';
import { Tooltip } from "react-tooltip";
import TbAllSystemAccounts from '../../../components/Tables/TbAllSystemAccounts';
import Header6111 from './Header611';
export default function Index6111() {
    const { t, i18n } = useTranslation();
    const [sortOrder, setSortOrder] = useState('asc');
    const [filtered, setFiltered] = useState(document6111ByCategory)
    // const [itemsPerPage, setItemsPerPage] = useState(10) // Number of items to display per page
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsToDisplay, setItemsToDisplay] = useState(document6111ByCategory)
    const [total, setTotal] = useState(0)
    const [inputValue, setInputValue] = useState("")
    const [focused, setFocused] = useState(false);



    const EmptyFunc = () => { }

    // const handleItemsPerPageChange = (event) => {
    //     const newItemsPerPage = parseInt(event.target.value, 10);
    //     setItemsPerPage(newItemsPerPage);
    // };

    // const calculateTotalPages = (totalItems) => {
    //     return Math.ceil(totalItems / itemsPerPage);
    // };

    // const handlePageChange = (pageNumber) => {
    //     setCurrentPage(pageNumber);
    // };

    const searchByTerm = (searchTerm, data) => {
        const results = [];
        data.forEach((item) => {
            const { category, data } = item;
            const filteredData = data.filter((obj) => {
                const { group_name_AR, displayName, details6111 } = obj;
                const searchTermLower = searchTerm.toLowerCase();
                const nameLower = group_name_AR.toLowerCase();
                const displaynameLower = displayName.toLowerCase();
                const codeLower = details6111.toString();
                return (
                    nameLower.includes(searchTermLower) ||
                    displaynameLower.includes(searchTermLower) ||
                    codeLower.includes(searchTermLower) ||
                    details6111 === parseInt(searchTerm, 10)
                );
            });
            if (filteredData.length > 0) {
                results.push({ category, data: filteredData });
            }
        });
        return results;
    };
    // useEffect(() => {
    //     const startIndex = (currentPage - 1) * itemsPerPage;
    //     const endIndex = startIndex + itemsPerPage;
    //     setItemsToDisplay((filtered.slice(startIndex, endIndex)));
    // }, [filtered, currentPage, itemsPerPage]);

    // useEffect(() => {
    //     setTotalPages(calculateTotalPages(filtered.length));
    //     setCurrentPage(1);
    // }, [itemsPerPage, filtered]);
    useEffect(() => {
        if (inputValue.length > 0) {
            const searchResults = searchByTerm(inputValue, document6111ByCategory);
            setItemsToDisplay(searchResults);
        } else {
            setItemsToDisplay(document6111ByCategory)
        }
    }, [inputValue])

    let headers = [
        // { title: "#", sort: EmptyFunc, icon: "" },
        // { title: "الفئة", sort: EmptyFunc, icon: <span className='privacy pointer-mk'> <CgArrowsExchangeAltV size={30} /></span> },
        // { title: "اسم الحساب", sort: EmptyFunc, icon: "" },
        // { title: "تاريخ المستند", sort: sortByDocDate, icon: <span className='privacy pointer-mk'> <CgArrowsExchangeAltV size={30} /></span> },
        // { title: "زبون/مورد", sort: sortByJvNumber, icon: <span className='privacy pointer-mk'> <CgArrowsExchangeAltV size={30} /></span> },
        // { title: "مدين", sort: sortByDebtor, icon: <span className='privacy pointer-mk'> <CgArrowsExchangeAltV size={30} /></span> },
        // { title: "دائن", sort: sortByCreditor, icon: <span className='privacy pointer-mk'> <CgArrowsExchangeAltV size={30} /></span> },
        // { title: "رصيد", sort: EmptyFunc, icon: <span className='privacy pointer-mk'></span> },
    ]
    const handleLabelClick = () => {
        setFocused(true);
    };

    const handleInputBlur = () => {
        if (inputValue === "") {
            setFocused(false);
        }
    };

    return (
        <div className="page-wrapper ">
            <div className="content container-fluid">
                <Breadcrumbs
                    maintitle={t("index611")}
                    title="الرئيسية"
                    modal="#warning-alert-modal"
                    subtitle={t("index611")}
                />
                {/* Page Header */}
                <Header6111 />
                <div className="row ">
                    <div className="col-md-8">
                        <div className="">
                            <div className="card">
                                <div className="card-header d-flex justify-content-between flex-wrap" id={"heading"}>
                                    <div className="col-sm-6 col-md-5">
                                        <div
                                            className={
                                                focused || inputValue !== ""
                                                    ? "input-block form-focus focused"
                                                    : "input-block form-focus"
                                            }
                                        >
                                            <input
                                                type="text"
                                                className="form-control floating mt-3"

                                                onFocus={handleLabelClick}
                                                onBlur={handleInputBlur}
                                                onChange={(e) => setInputValue(e.target.value)}
                                                value={inputValue}
                                            />
                                            <label className="focus-label with-icon " onClick={handleLabelClick}>
                                                ابحث من خلال رقم أو اسم الفئة
                                                <FaSearch className="search-icon " size={20} />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
          
                        </div>
                    </div>



                </div>

                <div className="tab-content ">
                    <div
                        id="emp_profile"
                        className="pro-overview tab-pane fade show active">
                        <div className='row'>
                            <TbAllSystemAccounts
                                headers={headers}
                                data={itemsToDisplay}
                                // total={total}
                                totalPages={totalPages}
                                currentPage={currentPage}
                            // handlePageChange={handlePageChange}
                            />
                        </div>
                    </div></div>
            </div>
            {/* /Page Content */}
            <Tooltip id="excel-tip" place="top" effect="solid">
                طباعة Excel
            </Tooltip>
        </div>
    )
}
