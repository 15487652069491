import React, { useEffect, useState } from "react";
import { Table } from "antd";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import TicketModelPopup from "../../../components/modelpopup/TicketModelPopup";
import Breadcrumbs from "../../../components/Breadcrumbs";
import TicketFilter from "../../../components/TicketFilter";
import { useSelector } from "react-redux";
import {
  formatNumbers,
  sumArrayProperties,
} from "../../../utils/openformat/numbers";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import AccountingFilter from "./components/AccountingFilter";
import accountingStructures from "../../../utils/openformat/accounting_structures.json";
import OpenFormatHeader from "../OpenForamt/OpenFormatHeader";
const TrialBalance = () => {
  const { t } = useTranslation();
  const [totals, setTotals] = useState([]);
  const [data, setData] = useState([]);
  const [display, setDisplay] = useState([]);
  const ahid = useSelector((state) => state.ahid);

  const [categories, setCategories] = useState([]);
  const [hideZeros, setHideZeors] = useState(false);
  //   const data = useSelector((state) => state.ahid).bkmvFile.Accounts.data;
  //   console.log(data);

  useEffect(() => {
    totalToUI();
    handleRecreateAccountingStructure();
  }, []);

  const handleRecreateAccountingStructure = () => {
    const arr = [];
    for (let key in accountingStructures.rivhit_classifications) {
      const item = accountingStructures.rivhit_classifications[key];
      arr.push({
        value: item.key,
        label: `${item.key} - ${item.ar}`,
      });
    }

    setCategories(arr);
  };

  const totalToUI = () => {
    let arr = ahid.bkmvFile?.Accounts?.data || [];
    const list = [];
    arr.forEach((element) => {
      let object = {
        ...element,
        endTotal: element.starterAmount + element.debit - element.credit,
      };
      list.push(object);
    });
    setData(list);
    setDisplay(list);
    getTotals(list);
  };

  const getTotals = (list) => {
    const sums = sumArrayProperties(list, ["starterAmount", "credit", "debit"]);

    const totals = [
      {
        title: "مجموع الأرصدة الافتتاحية",
        value: formatNumbers(sums?.starterAmount),
        percentage: "",
      },
      {
        title: "مجموع المدين",
        value: formatNumbers(sums?.debit),
        percentage: "",
      },
      {
        title: "مجموع الدائن",
        value: formatNumbers(sums?.credit),
        percentage: "",
      },
      {
        title: "الرصيد",
        value: formatNumbers(sums?.starterAmount + sums?.debit - sums?.credit),
        percentage: "",
      },
    ];

    setTotals(totals);
  };

  const columns = [
    {
      title: "رقم الحساب",
      dataIndex: "accountId",
      render: (text) => (
        <Link to={`/general-ledger/${text}`} className="table-avatar">
          {text}
        </Link>
      ),
      sorter: (a, b) => a.accountId - b.accountId,
    },

    {
      title: "اسم الحساب",
      dataIndex: "accountName",
      sorter: (a, b) => a.accountName < b.accountName,
    },
    {
      title: "تصنيف الحساب",
      dataIndex: "trialBalanceCode",

      sorter: (a, b) => a.trialBalanceCode - b.trialBalanceCode,
    },
    {
      title: "اسم التصنيف",
      dataIndex: "trialBalanceDescription",

      sorter: (a, b) => a.trialBalanceDescription < b.trialBalanceDescription,
    },
    {
      title: "رصيد أول المدة",
      dataIndex: "starterAmount",
      render: (text) => (
        <span className="table-avatar">{formatNumbers(text)}</span>
      ),
      sorter: (a, b) => a.starterAmount - b.starterAmount,
    },

    {
      title: "مدين",
      dataIndex: "debit",
      render: (text) => (
        <span className="table-avatar">{formatNumbers(text)}</span>
      ),
      sorter: (a, b) => a.debit - b.debit,
    },
    {
      title: "دائن",
      dataIndex: "credit",
      render: (text) => (
        <span className="table-avatar">{formatNumbers(text)}</span>
      ),
      sorter: (a, b) => a.debit - b.debit,
    },
    {
      title: "الرصيد",
      dataIndex: "endTotal",
      render: (text) => (
        <span className="table-avatar">{formatNumbers(text)}</span>
      ),
      sorter: (a, b) => a.debit - b.debit,
    },

    // {
    //   title: "Priority",
    //   dataIndex: "priority",
    //   render: () => (
    //     <div className="dropdown action-label">
    //       <Link
    //         className="btn btn-white btn-sm btn-rounded dropdown-toggle"
    //         to="#"
    //         data-bs-toggle="dropdown"
    //         aria-expanded="false">
    //         <i className="far fa-dot-circle text-danger" /> High{" "}
    //       </Link>
    //       <div className="dropdown-menu dropdown-menu-right">
    //         <Link className="dropdown-item" to="#">
    //           <i className="far fa-dot-circle text-danger" /> High
    //         </Link>
    //         <Link className="dropdown-item" to="#">
    //           <i className="far fa-dot-circle text-warning" /> Medium
    //         </Link>
    //         <Link className="dropdown-item" to="#">
    //           <i className="far fa-dot-circle text-success" /> Low
    //         </Link>
    //       </div>
    //     </div>
    //   ),
    //   sorter: (a, b) => a.priority.length - b.priority.length,
    // },
    {
      title: "الحالة",
      dataIndex: "status",
      render: () => (
        <div className="dropdown action-label text-center">
          <Link
            className="btn btn-white btn-sm btn-rounded dropdown-toggle"
            to="#"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="far fa-dot-circle text-danger" /> New
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <Link className="dropdown-item" to="#">
              <i className="far fa-dot-circle text-info" /> Open
            </Link>
            <Link className="dropdown-item" to="#">
              <i className="far fa-dot-circle text-info" /> Reopened
            </Link>
            <Link className="dropdown-item" to="#">
              <i className="far fa-dot-circle text-danger" /> On Hold
            </Link>
            <Link className="dropdown-item" to="#">
              <i className="far fa-dot-circle text-success" /> Closed
            </Link>
            <Link className="dropdown-item" to="#">
              <i className="far fa-dot-circle text-success" /> In Progress
            </Link>
            <Link className="dropdown-item" to="#">
              <i className="far fa-dot-circle text-danger" /> Cancelled
            </Link>
          </div>
        </div>
      ),
      sorter: (a, b) => a.status.length - b.status.length,
    },
    // {
    //   title: "Action",
    //   render: () => (
    //     <div className="dropdown dropdown-action text-end">
    //       <Link
    //         to="#"
    //         className="action-icon dropdown-toggle"
    //         data-bs-toggle="dropdown"
    //         aria-expanded="false">
    //         <i className="material-icons">more_vert</i>
    //       </Link>
    //       <div className="dropdown-menu dropdown-menu-right">
    //         <Link
    //           className="dropdown-item"
    //           to="#"
    //           data-bs-toggle="modal"
    //           data-bs-target="#edit_ticket">
    //           <i className="fa fa-pencil m-r-5" /> Edit
    //         </Link>
    //         <Link
    //           className="dropdown-item"
    //           to="#"
    //           data-bs-toggle="modal"
    //           data-bs-target="#delete">
    //           <i className="fa fa-trash m-r-5" /> Delete
    //         </Link>
    //       </div>
    //     </div>
    //   ),
    //   sorter: true,
    // },
  ];

  const handleFilterZero = (value) => {
    setHideZeors(value);
    if (value) setDisplay(data.filter((item) => item.endTotal != 0));
    else setDisplay(data);
  };

  const handleFilterByCategory = (categoryId) => {
    const arr = ahid.bkmvFile?.Accounts?.data.filter(
      (item) => item.trialBalanceCode == categoryId
    );
    const list = [];
    arr.forEach((element) => {
      let object = {
        ...element,
        endTotal: element.starterAmount + element.debit - element.credit,
      };
      list.push(object);
    });
    setData(list);
    setDisplay(list);
  };

  return (
    <>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs
            maintitle={t("trialBalance")}
            title={t("dashboard")}
            subtitle={t("trialBalance")}
            modal="#add_ticket"
            name="Add Ticket"
          />
          <OpenFormatHeader />
          <div className="row">
            <div className="col-md-12">
              <div className="card-group m-b-30">
                {totals?.map((total, index) => (
                  <div className="card" key={index}>
                    <div className="card-body">
                      <div className="d-flex justify-content-between mb-3">
                        <div>
                          <span className="d-block">{total.title}</span>
                        </div>
                        <div>
                          <span
                            className={
                              total.percentage.includes("-")
                                ? "text-danger"
                                : "text-success"
                            }
                          >
                            {total.percentage}
                          </span>
                        </div>
                      </div>
                      <h3 className="mb-3">{total.value}</h3>
                      <div className="progress mb-2" style={{ height: "5px" }}>
                        <div
                          className="progress-bar bg-primary"
                          role="progressbar"
                          style={{ width: "70%" }}
                          aria-valuenow={40}
                          aria-valuemin={0}
                          aria-valuemax={100}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <AccountingFilter
            categoryList={categories}
            setCurrentCategory={handleFilterByCategory}
            hideZeros={hideZeros}
            setHideZeors={handleFilterZero}
          />

          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <Table
                  className="table-striped"
                  rowKey={(record) => record.id}
                  style={{ overflowX: "auto" }}
                  columns={columns}
                  dataSource={display?.length > 0 ? display : []}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <TicketModelPopup />
      <DeleteModal Name="Delete Ticket" />
    </>
  );
};

export default TrialBalance;
