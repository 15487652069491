import {
  Avatar_01,
  Avatar_02,
  Avatar_03,
  Avatar_04,
  Avatar_05,
  Avatar_09,
  Avatar_10,
  Avatar_11,
  Avatar_12,
  Avatar_13,
  Avatar_16,
} from "../../Routes/ImagePath";

const defaultExport = {
  Salary: [
    {
      id: 1,
      name: "John Doe",
      position: "Web Designer",
      avatar: Avatar_02,
      profileLink: "profile",
      employeeId: "FT-0001",
      email: "johndoe@example.com",
      joiningDate: "1 Jan 2023",
      roles: "Web Designer",
      salary: "59698",
    },
    {
      id: 2,
      name: "Richard Miles",
      position: "Web Developer",
      avatar: Avatar_09,
      profileLink: "profile",
      employeeId: "FT-0002",
      email: "richardmiles@example.com",
      joiningDate: "1 Jan 2023",
      roles: "Web Developer",
      salary: "72000",
    },
    {
      id: 3,
      name: "John Smith",
      position: "Android Developer",
      avatar: Avatar_10,
      profileLink: "profile",
      employeeId: "FT-0003",
      email: "johnsmith@example.com",
      joiningDate: "1 Jan 2023",
      roles: "Android Developer",
      salary: "48200",
    },
    {
      id: 4,
      name: "Mike Litorus",
      position: "IOS Developer",
      avatar: Avatar_05,
      profileLink: "profile",
      employeeId: "FT-0004",
      email: "mikelitorus@example.com",
      joiningDate: "1 Jan 2023",
      roles: "IOS Developer",
      salary: "59698",
    },
    {
      name: "Wilmer Deluna",
      position: "Team Leader",
      avatar: Avatar_11,
      profileLink: "profile",
      employeeId: "FT-0005",
      email: "wilmerdeluna@example.com",
      joiningDate: "1 Jan 2023",
      roles: "Team Leader",
      salary: "43000",
    },
    {
      id: 5,
      name: "Jeffrey Warden",
      position: "Web Developer",
      avatar: Avatar_12,
      profileLink: "profile",
      employeeId: "FT-0006",
      email: "jeffreywarden@example.com",
      joiningDate: "1 Jan 2023",
      roles: "Web Developer",
      salary: "45000",
    },
    {
      id: 6,
      name: "Bernardo Galaviz",
      position: "Web Developer",
      avatar: Avatar_13,
      profileLink: "profile",
      employeeId: "FT-0007",
      email: "bernardogalaviz@example.com",
      joiningDate: "1 Jan 2014",
      roles: "Web Developer",
      salary: "38400",
    },
    {
      id: 7,
      name: "Lesley Grauer",
      position: "Team Leader",
      avatar: Avatar_01,
      profileLink: "profile",
      employeeId: "FT-0008",
      email: "lesleygrauer@example.com",
      joiningDate: "1 Jun 2015",
      roles: "Team Leader",
      salary: "75500",
    },
    {
      id: 8,
      name: "Jeffery Lalor",
      position: "Team Leader",
      avatar: Avatar_16,
      profileLink: "profile",
      employeeId: "FT-0009",
      email: "jefferylalor@example.com",
      joiningDate: "1 Jan 2023",
      roles: "Team Leader",
      salary: "73550",
    },
    {
      id: 9,
      name: "Loren Gatlin",
      position: "Android Developer",
      avatar: Avatar_04,
      profileLink: "profile",
      employeeId: "FT-0010",
      email: "lorengatlin@example.com",
      joiningDate: "1 Jan 2023",
      roles: "Android Developer",
      salary: "55000",
    },
    {
      id: 10,
      name: "Tarah Shropshire",
      position: "Android Developer",
      avatar: Avatar_03,
      profileLink: "profile",
      employeeId: "FT-0011",
      email: "tarahshropshire@example.com",
      joiningDate: "1 Jan 2023",
      roles: "Android Developer",
      salary: "92400",
    },
  ],
};

export default defaultExport;
