import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../../../components/Breadcrumbs";
import DeleteModal from "../../../../../components/modelpopup/deletePopup";
import BudgetTable from "./BudgetTable";
import AddModal from "./AddModal";
import EditBudgetModal from "./EditBudgetModal";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getCostPercentage, getNetSalary, getTotalCost, multiArrayToObject126, salaryCostTotals, structureArrayToObject } from "../../../../../utils/s126/arrays126";
import structure from '../../../../../utils/s126/structure126.json'
import { formatNumbers } from "../../../../../utils/openformat/numbers";
import Header10 from "../../records/record10Header/Header10";

const SalaryCost = () => {
  const {t,i18n}=useTranslation()
  const salary = useSelector((state) => state.salary);
  const type20=salary.type20?.length>0?multiArrayToObject126(salary.type20):[]
  const structure20=structureArrayToObject(structure.type20)
 
  const [totals,setTotals]=useState({})
 
console.log(structure20)
    
  useEffect(()=>{
    if(type20.length>0)
    {
      let totalss=salaryCostTotals(type20)
      setTotals(totalss)
    }
},[])
const columns = [
    {
        title: structure20.factoryWorkerNumber[i18n.language],
        dataIndex: "factoryWorkerNumber",
        sorter: (a, b) => a.leavetype.length - b.leavetype.length,
      },
  
      {
        title: structure20.firstName[i18n.language],
        dataIndex: "firstName",
        // sorter: (a, b) => a.from.length - b.from.length,
      },
      {
        title: structure20.lastName[i18n.language],
        dataIndex: "lastName",
        // sorter: (a, b) => a.noofdays.length - b.noofdays.length,
      },
      {
        title: structure20.idNumber[i18n.language],
        dataIndex: "idNumber",
        // sorter: (a, b) => a.noofdays.length - b.noofdays.length,
      },
      {
        title: "الراتب الصافي",
        dataIndex: "nationalInsuranceMaxPayments",
        render: (text,record) => <span>{formatNumbers(getNetSalary(record))}</span>,
  
        // sorter: (a, b) => a.to.length - b.to.length,
      },
  
      {
        title: structure20.normalTax[i18n.language],
        dataIndex: "normalTax",
        render: (text) => <span>{formatNumbers(text*1)}</span>,
  
        // sorter: (a, b) => a.noofdays.length - b.noofdays.length,
      },

  {
    title: structure20.employerContributionToBILAmount[i18n.language],
    dataIndex: "employerContributionToBILAmount",
    render: (text) => (
      <span className="table-avatar">
        {formatNumbers(text*1)}
      </span>
    ),
    // sorter: (a, b) => a.name.length - b.name.length,
  },
  {
    title: structure20.cpfEmployerContribution[i18n.language],
    dataIndex: "cpfEmployerContribution",
    render: (text) => <span>{formatNumbers(text*1) }</span>,

    // sorter: (a, b) => a.noofdays.length - b.noofdays.length,
  },
  {
    title: structure20.employerContributionToPensionFundCompensationComponent[i18n.language],
    dataIndex: "employerContributionToPensionFundCompensationComponent",
    render: (text) => <span>{formatNumbers(text*1) }</span>,

    // sorter: (a, b) => a.noofdays.length - b.noofdays.length,
  },
  {
    title: structure20.employerDisabilityInsurance[i18n.language],
    dataIndex: "employerDisabilityInsurance",
    render: (text) => <span>{formatNumbers(text*1) }</span>,

    // sorter: (a, b) => a.noofdays.length - b.noofdays.length,
  },
  {
    title: structure20.employerEducationContribution[i18n.language],
    dataIndex: "employerEducationContribution",
    render: (text) => <span>{formatNumbers(text*1) }</span>,

    // sorter: (a, b) => a.noofdays.length - b.noofdays.length,
  },

  {
    title: "التكلفة",
    dataIndex: "",
    render: (text,record) => <span>{formatNumbers(getTotalCost(record))}</span>,

    // sorter: (a, b) => a.leavetype.length - b.leavetype.length,
  },
  {
    title: "التكلفة %",
    dataIndex: "",
    render: (text,record) => <span>{`${formatNumbers(getCostPercentage(record))} %`}</span>,

    // sorter: (a, b) => a.leavetype.length - b.leavetype.length,
  },
  
];

// netSalary,totalEmployer,totalSalary,totalsCost
const statsData = [
  {
    id: 1,
    title: "صافي الرواتب",
    value: formatNumbers(totals?.netSalary),
    subText: "شيكل",
  },
  
  {
    id: 2,
    title: "إجمالي الرواتب",
    value: formatNumbers(totals?.totalSalary),
    subText: "شيكل",
  },
  
  {
    id: 3,
    title: "إجمالي تكلفة {تأمين وطني، تقاعد، تعليم مستمر}",
    value: formatNumbers(totals?.totalEmployer),
    subText: "شيكل",
  },
  {
    id: 4,
    title: "إجمالي التكلفة",
    value: formatNumbers(totals?.totalsCost),
    subText: "شيكل",
  },
  {
    id: 5,
    title: "معدل صافي الرواتب",
    value: formatNumbers(totals?.avgNetSalary ),
    value2:formatNumbers(totals?.avgNetSalary /12),
    subText: "شيكل",
  },
  {
    id: 6,
    title: "معدل إجمالي الرواتب",
    value: formatNumbers(totals?.avgTotalSalary),
    value2: formatNumbers(totals?.avgTotalSalary/12),
    subText: "شيكل",
  },
  
  {
    id: 7,
    title: "معدل التكلفة {تأمين وطني، تقاعد، تعليم مستمر}",
    value: formatNumbers(totals?.avgTotalEmployer),
    value2: formatNumbers(totals?.avgTotalEmployer/12),
    subText: "شيكل",
  },
  {
    id: 8,
    title: "عدد الموظفين",
    value: type20.length,
  },
];

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle={t("salaryCost")}
            title="الرئيسية"
            subtitle={t("salaryCost")}
          />
          <Header10 />
          <div className="row">
              {statsData.map((stat, index) => (
                <div className="col-md-3 d-flex" key={index}>
                  <div className="stats-info w-100">
                    <h6>{stat.title}</h6>
                    {stat.subText ? (
                      <>
                        <h4 className="text-primary">
                        {stat.value} <span>{stat.subText}</span>
                      </h4>
                      {stat?.value2 && <p className="text-info" style={{fontSize:'0.8em'}}>{stat.value2} <span>شهريا</span></p>}
                      </>
                    ) : (
                      <h4 className="text-primary">{stat.value}</h4>
                    )}
                  </div>
                </div>
              ))}
            </div>

          <BudgetTable columns={columns} data={type20}/>
        </div>
      </div>
      <AddModal />
      <EditBudgetModal />
      <DeleteModal Name="Delete" />
    </>
  );
};

export default SalaryCost;
