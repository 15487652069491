export const AhidCodes = {
  INI: {
    A000: {
      code: "A000",
      description: "רשומה מרכזת",
      length: 466,
    },
    B100: {
      code: "B100",
      description: 'סך רשומות מסוג תנועות הנה"ח',
      length: 19,
      name: "totalTransactions",
    },
    B110: {
      code: "B110",
      description: "סך רשומות מסוג חשבונות",
      length: 19,
      name: "totalAccounting",
    },
    C100: {
      code: "C100",
      description: "סך רשומות מסוג כותרת מסמך",
      length: 19,
      name: "totalHeaders",
    },
    D110: {
      code: "D110",
      description: "סך רשומות מסוג פרטי מסמך",
      length: 19,
      name: "totalDetails",
    },
    D120: {
      code: "D120",
      description: "סך רשומות מסוג פרטי קבלה",
      length: 19,
      name: "totalReceits",
    },
    M100: {
      code: "M100",
      description: "סך רשומות מסוג פריטים במלאי",
      length: 19,
      name: "totalStock",
    },
  },
  BKMVDATA: {
    A100: {
      code: "A100",
      description: "רשומת פתיחה",
      length: 95,
      name: "File_Opening",
    },
    B100: {
      code: "B100",
      description: 'תנועות בהנהח"ש',
      length: 317,
      name: "Accounting_Transactions",
    },
    B110: {
      code: "B110",
      description: "חשבון בהנהלת חשבונות",
      length: 376,
      name: "Accounts",
    },
    C100: {
      code: "C100",
      description: "כותרת מסמך",
      length: 444,
      name: "Headers",
    },
    D110: {
      code: "D110",
      description: "פרטי מסמך",
      length: 339,
      name: "Details",
    },
    D120: {
      code: "D120",
      description: "פרטי קבלה",
      length: 222,
      name: "Receipt_Details",
    },
    M100: {
      code: "M100",
      description: "פריט במלאי",
      length: 298,
      name: "Stock",
    },
    Z900: {
      code: "Z900",
      description: "רשומת סגירה",
      length: 110,
      name: "Closing",
    },
  },
};

export const INI_FileHeaderStructure = [
  {
    field: 1000,
    description: "קוד רשומה",
    type: "אלפאנומרי",
    end: 4,
    start: 1,
    length: 4,
    tech: "X(4)",
    required: "ח",
    notes: "A000",
    name: "Code",
  },
  {
    field: 1001,
    description: "לשימוש עתידי",
    type: "אלפאנומרי",
    end: 9,
    start: 5,
    length: 5,
    tech: "X(5)",
    required: "ח",
    name: "FutureUse1",
  },
  {
    field: 1002,
    description: "סך רשומות בקובץ BKMVDATA",
    type: "נומרי",
    end: 24,
    start: 10,
    length: 15,
    tech: "9(15)",
    required: "ח",
    notes: 'צריך להיות זהה לשדה "סך רשומות כולל" ברשומת סגירה',
    name: "RecordsCount",
  },
  {
    field: 1003,
    description: "מספר עוסק מורשה",
    type: "נומרי",
    end: 33,
    start: 25,
    length: 9,
    tech: "9(9)",
    required: "ח",
    notes: "מספר עוסק מורשה של בית העסק",
    name: "Identification",
  },
  {
    field: 1004,
    description: "מזהה ראשי",
    type: "נומרי",
    end: 48,
    start: 34,
    length: 15,
    tech: "9(15)",
    required: "ח",
    notes: "מספר מזהה קבוע ואחיד, ראה הבהרה 2 בנספח הבהרות",
    name: "fixedId",
  },
  {
    field: 1005,
    description: "קבוע מערכת",
    type: "אלפאנומרי",
    end: 56,
    start: 49,
    length: 8,
    tech: "X(8)",
    required: "ח",
    notes: "&OF1.31&",
    name: "fixesSystem",
  },
  {
    field: 1006,
    description: "מספר רישום התוכנה",
    type: "נומרי",
    end: 64,
    start: 57,
    length: 8,
    tech: "9(8)",
    required: "ח",
    notes: "מספר תעודת הרישום של התוכנה במערכת המס",
    name: "softwareId",
  },
  {
    field: 1007,
    description: "שם התוכנה",
    type: "אלפאנומרי",
    end: 84,
    start: 65,
    length: 20,
    tech: "X(20)",
    required: "ח",
    name: "softwareName",
  },
  {
    field: 1008,
    description: "מהדורת התוכנה",
    type: "אלפאנומרי",
    end: 104,
    start: 85,
    length: 20,
    tech: "X(20)",
    required: "ח",
    name: "softwareEdition",
  },
  {
    field: 1009,
    description: "מספר עמ' של יצרן התוכנה",
    type: "נומרי",
    end: 113,
    start: 105,
    length: 9,
    tech: "9(9)",
    required: "ח",
    name: "softwareIdentification",
  },
  {
    field: 1010,
    description: "שם יצרן התוכנה",
    type: "אלפאנומרי",
    end: 133,
    start: 114,
    length: 20,
    tech: "X(20)",
    required: "ח",
    name: "softwareDeveloperName",
  },
  {
    field: 1011,
    description: "סוג התוכנה",
    type: "נומרי",
    end: 134,
    start: 134,
    length: 1,
    tech: "9(1)",
    required: "ח",
    notes: "1= חד שנתי, 2 = רב שנתי",
    name: "softwareType",
  },
  {
    field: 1012,
    description: "נתיב מיקום שמירת הקבצים",
    type: "אלפאנומרי",
    end: 184,
    start: 135,
    length: 50,
    tech: "X(50)",
    required: "ח",
    notes: "ראה הסבר בהנחיות הכלליות",
    name: "filesPath",
  },
  {
    field: 1013,
    description: 'סוג הנהח"ש של התוכנה',
    type: "נומרי",
    end: 185,
    start: 185,
    length: 1,
    tech: "9(1)",
    required: "ח",
    notes: "0=לא רלוונטי, 1=חד צידית, 2=כפולה.",
    name: "softwareAccountingType",
  },
  {
    field: 1014,
    description: "איזון חשבונאי נדרש",
    type: "נומרי",
    end: 186,
    start: 186,
    length: 1,
    tech: "9(1)",
    required: "ח/ר",
    notes: "1=רמת התנועה, 2=רמת המנה. בהנהלת חשבונות כפולה חובה",
    name: "softwareRequiredAccountingBalance",
  },
  {
    field: 1015,
    description: "מספר חברה ברשם החברות",
    type: "נומרי",
    end: 195,
    start: 187,
    length: 9,
    tech: "9(9)",
    required: "ח/ר",
    notes: 'מספר ח"פ',
    name: "companyIdenfication",
  },
  {
    field: 1016,
    description: "מספר תיק ניכויים",
    type: "נומרי",
    end: 204,
    start: 196,
    length: 9,
    tech: "9(9)",
    required: "ח/ר",
    name: "deductionFileId",
  },
  {
    field: 1017,
    description: "שטח נתונים עתידי",
    type: "אלפאנומרי",
    end: 214,
    start: 205,
    length: 10,
    tech: "X(10)",
    required: "ח",
    name: "futereUse2",
  },
  {
    field: 1018,
    description: "שם העסק",
    type: "אלפאנומרי",
    end: 264,
    start: 215,
    length: 50,
    tech: "X(50)",
    required: "ח",
    name: "businessName",
  },
  {
    field: 1019,
    description: "מען העסק - רחוב",
    type: "אלפאנומרי",
    end: 314,
    start: 265,
    length: 50,
    tech: "X(50)",
    required: "ח/ר",
    name: "businessAddressStreet",
  },
  {
    field: 1020,
    description: "מען העסק - מס בית",
    type: "אלפאנומרי",
    end: 324,
    start: 315,
    length: 10,
    tech: "X(10)",
    required: "ח/ר",
    name: "businessAddressHouseNumber",
  },
  {
    field: 1021,
    description: "מען העסק - עיר",
    type: "אלפאנומרי",
    end: 354,
    start: 325,
    length: 30,
    tech: "X(30)",
    required: "ח/ר",
    name: "businessAddressCity",
  },
  {
    field: 1022,
    description: "מען העסק - מיקוד",
    type: "אלפאנומרי",
    end: 362,
    start: 355,
    length: 8,
    tech: "X(8)",
    required: "ח/ר",
    name: "businessAddressPostalCode",
  },
  {
    field: 1023,
    description: "שנת המס",
    type: "נומרי",
    end: 366,
    start: 363,
    length: 4,
    tech: "9(4)",
    required: "חמ",
    notes: "חובה למלא כאשר סוג התוכנה (שדה 1011 ) הוא חד שנתי",
    name: "taxYear",
  },
  {
    field: 1024,
    description: "טווח נתונים - תאריך התחלה/חיתוך",
    type: "נומרי",
    end: 374,
    start: 367,
    length: 8,
    tech: "9(8)",
    required: "חמ",
    notes: "חובה למלא כאשר סוג התוכנה (שדה 1011 ) הוא רב שנתי",
    name: "dataPeriodStartDate",
  },
  {
    field: 1025,
    description: "טווח נתונים - תאריך סיום /חיתוך",
    type: "נומרי",
    end: 382,
    start: 375,
    length: 8,
    tech: "9(8)",
    required: "חמ",
    notes: "חובה למלא כאשר סוג התוכנה (שדה 1011 ) הוא רב שנתי",
    name: "dataPeriodEndDate",
  },
  {
    field: 1026,
    description: "תאריך תחילת התהליך",
    type: "נומרי",
    end: 390,
    start: 383,
    length: 8,
    tech: "ח",
    notes: "YYYYMMDD בפורמט",
    name: "processStartDate",
  },
  {
    field: 1027,
    description: "שעת התחלת התהליך",
    type: "נומרי",
    end: 394,
    start: 391,
    length: 4,
    tech: "ח",
    notes: "HHMM",
    name: "processStartTime",
  },
  {
    field: 1028,
    description: "קוד שפה",
    type: "נומרי",
    end: 3995,
    start: 395,
    length: 3601,
    tech: "9(3601)",
    required: "ח",
    notes: "0 - עברית; 1 - ערבית; 2 - אחר",
    name: "languageCode",
  },
  {
    field: 1029,
    description: "סט תוים",
    type: "נומרי",
    end: 396,
    start: 396,
    length: 1,
    tech: "9(1)",
    required: "ח",
    notes: "1 =ISO-8859-8-i; 2 = CP-862",
    name: "characterSet",
  },
  {
    field: 1030,
    description: "שם תוכנת הכיווץ",
    type: "אלפאנומרי",
    end: 416,
    start: 397,
    length: 20,
    tech: "X(20)",
    required: "ח",
    notes: "שם התוכנה בה כווצו הקבצים",
    name: "shrinkingSoftwareName",
  },
  {
    field: 1032,
    description: "מטבע מוביל",
    type: "אלפאנומרי",
    end: 419,
    start: 417,
    length: 3,
    tech: "X(3)",
    required: "ח",
    notes: "בהעדר ערך ברירת המחדל היא ILS",
    name: "currency",
  },
  {
    field: 1034,
    description: "מידע על סניפים /ענפים",
    type: "נומרי",
    end: 420,
    start: 420,
    length: 1,
    tech: "9(1)",
    required: "ח",
    notes:
      "- בעסק יש סניפים /ענפים ; 0 - בעסק אין סניפים . ראה הבהרה 3 בנספח הבהרות.",
    name: "branchesInfo",
  },
  {
    field: 1035,
    description: "שטח לנתונים עתידיים",
    type: "אלפאנומרי",
    end: 466,
    start: 421,
    length: 46,
    tech: "X(46)",
    required: "ח",
    name: "futureUse3",
  },
];

export const INI_FileSummaryRecordsStructure = [
  {
    field: 1050,
    description: "קוד רשומה",
    type: "אלפאנומרי",
    end: 4,
    start: 1,
    length: 4,
    tech: "X(4)",
    required: "ח",
    notes: "בהתאם לסוג הרשומה שנסכמה",
  },
  {
    field: 1051,
    description: "סך רשומות",
    type: "נומרי",
    end: 19,
    start: 5,
    length: 15,
    tech: "9(15)",
    required: "ח",
    notes: "סיכום הרשומות שהופקו בסוג קוד זה",
  },
];

export const BKMVDATA_FileOpeningStructure = [
  {
    field: 1100,
    description: "קוד רשומה",
    type: "אלפאנומרי",
    end: 4,
    start: 1,
    length: 4,
    tech: "X(4)",
    required: "ח",
    notes: "A100",
  },
  {
    field: 1101,
    description: "מס רשומה בקובץ",
    type: "נומרי",
    end: 13,
    start: 5,
    length: 9,
    tech: "9(9)",
    required: "ח",
    notes: "מס רץ של הרשומה בקובץ BKMVDATA",
  },
  {
    field: 1102,
    description: "מספר עוסק מורשה",
    type: "נומרי",
    end: 22,
    start: 14,
    length: 9,
    tech: "9(9)",
    required: "ח",
    notes: "מספר עוסק מורשה של בית העסק",
  },
  {
    field: 1103,
    description: "מזהה ראשי",
    type: "נומרי",
    end: 37,
    start: 23,
    length: 15,
    tech: "9(15)",
    required: "ח",
    notes: "מספר מזהה קבוע ואחיד, ראה הבהרה 2 בנספח הבהרות",
  },
  {
    field: 1104,
    description: "קבוע מערכת",
    type: "אלפנומרי",
    end: 45,
    start: 38,
    length: 8,
    tech: "X(8)",
    required: "ח",
    notes: "&OF1.31&",
  },
  {
    field: 1105,
    description: "שטח לנתונים עתידיים",
    type: "אלפאנומרי",
    end: 95,
    start: 46,
    length: 50,
    tech: "X(50)",
    required: "ח",
    notes: "",
  },
];

export const BKMVDATA_FileClosingStructure = [
  {
    field: 1150,
    description: "קוד רשומה",
    type: "אלפאנומרי",
    end: 4,
    start: 1,
    length: 4,
    tech: "X(4)",
    required: "ח",
    notes: "Z900",
  },
  {
    field: 1151,
    description: "מס רשומה בקובץ",
    type: "נומרי",
    end: 13,
    start: 5,
    length: 9,
    tech: "9(9)",
    required: "ח",
    notes: "מס רץ של הרשומה בקובץ BKMVDATA",
  },
  {
    field: 1152,
    description: "מספר עוסק מורשה",
    type: "נומרי",
    end: 22,
    start: 14,
    length: 9,
    tech: "9(9)",
    required: "ח",
    notes: "מספר עוסק מורשה של בית העסק",
  },
  {
    field: 1153,
    description: "מזהה ראשי",
    type: "נומרי",
    end: 37,
    start: 23,
    length: 15,
    tech: "9(15)",
    required: "ח",
    notes: "מספר מזהה קבוע ואחיד (זהה לשדה 1004 ). ראה הבהרה 2 בנספח הבהרות.",
  },
  {
    field: 1154,
    description: "קבוע מערכת",
    type: "אלפנומרי",
    end: 45,
    start: 38,
    length: 8,
    tech: "X(8)",
    required: "ח",
    notes: "&OF1.31&",
  },
  {
    field: 1155,
    description: "סך רשומות כולל בקובץ",
    type: "נומרי",
    end: 60,
    start: 46,
    length: 15,
    tech: "9(15)",
    required: "ח",
    notes: "כל הרשומות כולל רשומת פתיחה וסגירה של הקובץ",
  },
  {
    field: 1156,
    description: "שטח לנתונים עתידיים",
    type: "אלפאנומרי",
    end: 110,
    start: 61,
    length: 5,
    tech: "X(50)",
    required: "ח",
    notes: "",
  },
];

export const BKMVDATA_FileHeaderStructure = [
  {
    notes: "C100",
    require_type: 1,
    tech: "X(4)",
    length: 4,
    from: 1,
    to: 4,
    type: "‫אלפאנומרי‬",
    he: "‫קוד רשומה‬",
    field: 1200,
    dev_type: "string",
    devider: "",
    name: "code",
    en: "Record code",
    ar: "رمز السجل",
    ui: 1,
  },
  {
    notes: "‫מס רץ של הרשומה בקובץ BKMVDATA‬",
    require_type: 1,
    tech: "9(9)",
    length: 9,
    from: 5,
    to: 13,
    type: "‫נומרי‬",
    he: "‫מס רשומה בקובץ‬",
    field: 1201,
    dev_type: "int",
    devider: "",
    name: "recordId",
    en: "Tax is recorded in the file",
    ar: "رقم السجل في الملف",
    ui: 1,
  },
  {
    notes: "‫מספר עוסק מורשה של בית העסק‬",
    require_type: 1,
    tech: "9(9)",
    length: 9,
    from: 14,
    to: 22,
    type: "‫נומרי‬",
    he: "‫מס עוסק מורשה‬",
    field: 1202,
    dev_type: "int",
    devider: "",
    name: "authorizedDealer",
    en: "Tax is an authorized dealer",
    ar: "المشتغل المرخص",
    ui: 1,
  },
  {
    notes: "‫ראה רשימת מסמכים בנספח מס 1.‬",
    require_type: 1,
    tech: "9(3)",
    length: 3,
    from: 23,
    to: 25,
    type: "‫נומרי‬",
    he: "‫סוג מסמך‬",
    field: 1203,
    dev_type: "int",
    devider: "",
    name: "documentType",
    en: "document type",
    ar: "نوع الوثيقة",
    ui: 1,
  },
  {
    require_type: 1,
    tech: "X(20)",
    length: 20,
    from: 26,
    to: 45,
    type: "‫אלפאנומרי‬",
    he: "‫מספר מסמך‬",
    field: 1204,
    dev_type: "string",
    devider: "",
    name: "documentNumber",
    en: "Document number",
    ar: "رقم المستند",
    ui: 1,
  },
  {
    notes: "‫בפורמט YYYYMMDD . ראה הבהרה 12‬",
    require_type: 1,
    tech: "9(8)",
    length: 8,
    from: 46,
    to: 53,
    type: "‫נומרי‬",
    he: "‫תאריך הפקת מסמך‬",
    field: 1205,
    dev_type: "int",
    devider: "",
    name: "documentProductionDate",
    en: "Document production date",
    ar: "تاريخ إصدار الوثيقة",
    ui: 1,
  },
  {
    notes: "HHMM 24H בפורמט",
    require_type: 2,
    tech: "9(4)",
    length: 4,
    from: 54,
    to: 57,
    type: "‫נומרי‬",
    he: "‫שעת הפקת מסמך‬",
    field: 1206,
    dev_type: "int",
    devider: "",
    name: "documentTime",
    en: "Document production time",
    ar: "وقت إصدار الوثيقة",
    ui: 1,
  },
  {
    notes: "‫חובה כאשר קוד מסמך בטווח 100-710 .‬",
    require_type: 3,
    tech: "X(50)",
    length: 50,
    from: 58,
    to: 107,
    type: "‫אלפאנומרי‬",
    he: "‫שם לקוח/ספק‬",
    field: 1207,
    dev_type: "string",
    devider: "",
    name: "custVendName",
    en: "Customer/supplier name",
    ar: "اسم العميل/المورد",
    ui: 1,
  },
  {
    notes: "‫במסמכי שיווק - לקוח; ברכש - ספק‬",
    require_type: 2,
    tech: "X(50)",
    length: 50,
    from: 108,
    to: 157,
    type: "‫אלפאנומרי‬",
    he: "‫מען הלקוח/ספק - רחוב‬",
    field: 1208,
    dev_type: "string",
    devider: "",
    name: "custVendStreet",
    en: "Address of the customer/supplier - Street",
    ar: "عنوان العميل/المورد - الشارع",
    ui: 1,
  },
  {
    require_type: 2,
    tech: "X(10)",
    length: 10,
    from: 158,
    to: 167,
    type: "‫אלפאנומרי‬",
    he: "‫מען הלקוח/ספק - מס בית‬",
    field: 1209,
    dev_type: "string",
    devider: "",
    name: "custVendHouse",
    en: "From the customer/supplier - house tax",
    ar: "عنوان العميل/المورد - ضريبة المنزل",
    ui: 1,
  },
  {
    require_type: 2,
    tech: "X(30)",
    length: 30,
    from: 168,
    to: 197,
    type: "‫אלפאנומרי‬",
    he: "‫מען הלקוח/ספק - עיר‬",
    field: 1210,
    dev_type: "string",
    devider: "",
    name: "custVendCity",
    en: "Address of the customer/supplier - City",
    ar: "عنوان العميل/المورد - المدينة",
    ui: 1,
  },
  {
    require_type: 2,
    tech: "X(8)",
    length: 8,
    from: 198,
    to: 205,
    type: "‫אלפאנומרי‬",
    he: "‫מען הלקוח/ספק - מיקוד‬",
    field: 1211,
    dev_type: "string",
    devider: "",
    name: "custVendPostalCode",
    en: "From the customer/supplier - zip code",
    ar: "عنوان العميل/المورد - الرمز البريدي",
    ui: 1,
  },
  {
    require_type: 2,
    tech: "X(30)",
    length: 30,
    from: 206,
    to: 235,
    type: "‫אלפאנומרי‬",
    he: "‫מען הלקוח/ספק - מדינה‬",
    field: 1212,
    dev_type: "string",
    devider: "",
    name: "custVendCountry",
    en: "From the customer/supplier - country",
    ar: "عنوان العميل/المورد - البلد",
    ui: 1,
  },
  {
    notes: '‫לפי קוד טבלת מדינות מצ"ב‬',
    require_type: 2,
    tech: "X(2)",
    length: 2,
    from: 236,
    to: 237,
    type: "‫אלפאנומרי‬",
    he: "‫מען הלקוח/ספק - קוד מדינה‬",
    field: 1213,
    dev_type: "string",
    devider: "",
    name: "custVendCountryCode",
    en: "Customer/supplier address - country code",
    ar: "عنوان العميل/المورد - رمز البلد",
    ui: 1,
  },
  {
    require_type: 2,
    tech: "X(15)",
    length: 15,
    from: 238,
    to: 252,
    type: "‫אלפאנומרי‬",
    he: "‫טלפון לקוח/ספק‬",
    field: 1214,
    dev_type: "string",
    devider: "",
    name: "custVendPhone",
    en: "Customer/supplier phone",
    ar: "هاتف العميل/المورد",
    ui: 1,
  },
  {
    require_type: 2,
    tech: "9(9)",
    length: 9,
    from: 253,
    to: 261,
    type: "‫נומרי‬",
    he: "‫מס עוסק מורשה לקוח/ספק‬",
    field: 1215,
    dev_type: "int",
    devider: "",
    name: "custVendAuthorizedDealer",
    en: "Authorized dealer tax customer/supplier",
    ar: "المشتغل المرخص للعميل/المورد",
    ui: 1,
  },
  {
    notes: "‫בפורמט YYYYMMDD . ראה הבהרה 12‬",
    require_type: 2,
    tech: "9(8)",
    length: 8,
    from: 262,
    to: 269,
    type: "‫נומרי‬",
    he: "‫תאריך ערך‬",
    field: 1216,
    dev_type: "int",
    devider: "",
    name: "valueDate",
    en: "value date",
    ar: "تاريخ القيمة",
    ui: 1,
  },
  {
    notes: "‫ימולא רק בחשבונית ייצוא‬",
    require_type: 2,
    tech: "X9(12)v99",
    length: 15,
    from: 270,
    to: 284,
    type: "‫אלפאנומרי‬",
    he: '‫סכום סופי‬ של המסמך במט"ח‬',
    field: 1217,
    dev_type: "decimal",
    devider: 100,
    name: "totalForeingCurrency",
    en: "Final amount of the document in foreign currency",
    ar: "المبلغ النهائي للوثيقة بالعملة الأجنبية",
    ui: 1,
  },
  {
    notes: "‫ימולא רק בחשבונית ייצוא, לפי טבלת‬‏\r\n‫קודים המופיעה להלן‬‏",
    require_type: 2,
    tech: "X(3)",
    length: 3,
    from: 285,
    to: 287,
    type: "‫אלפאנומרי‬",
    he: '‫קוד מט"ח‬',
    field: 1218,
    dev_type: "string",
    devider: "",
    name: "foreignCurrencyCode",
    en: "currency code",
    ar: "رمز العملة",
    ui: 1,
  },
  {
    require_type: 2,
    tech: "X9(12)v99",
    length: 15,
    from: 288,
    to: 302,
    type: "‫אלפאנומרי‬",
    he: "‫סכום המסמך לפני הנחת מסמך‬",
    field: 1219,
    dev_type: "decimal",
    devider: 100,
    name: "totalBeforeDiscount",
    en: "The amount of the document before placing a document",
    ar: "المجموع قبل الخصومات",
    ui: 1,
  },
  {
    notes: "‫ראה הבהרה 5 בנספח הבהרות‬",
    require_type: 2,
    tech: "X9(12)v99",
    length: 15,
    from: 303,
    to: 317,
    type: "‫אלפאנומרי‬",
    he: "‫הנחת מסמך‬",
    field: 1220,
    dev_type: "decimal",
    devider: 100,
    name: "documentDiscount",
    en: "Placing a document",
    ar: "الخصومات",
    ui: 1,
  },
  {
    require_type: 2,
    tech: "X9(12)v99",
    length: 15,
    from: 318,
    to: 332,
    type: "‫אלפאנומרי‬",
    he: '‫סכום המסמך לאחר‬ הנחות‬ ללא מע"מ‬',
    field: 1221,
    dev_type: "decimal",
    devider: 100,
    name: "totalAfterDiscountNoVat",
    en: "The amount of the document after discounts without VAT",
    ar: "المجموع لا يشمل الضريبة",
    ui: 1,
  },
  {
    require_type: 2,
    tech: "X9(12)v99",
    length: 15,
    from: 333,
    to: 347,
    type: "‫אלפאנומרי‬",
    he: '‫סכום המע"מ‬',
    field: 1222,
    dev_type: "decimal",
    devider: 100,
    name: "vatAmount",
    en: "VAT amount",
    ar: "قيمة الضريبة",
    ui: 1,
  },
  {
    notes: "‫בקבלה, סכום ללא הניכוי במקור‬",
    require_type: 2,
    tech: "X9(12)v99",
    length: 15,
    from: 348,
    to: 362,
    type: "‫אלפאנומרי‬",
    he: '‫סכום המסמך כולל מע"מ‬',
    field: 1223,
    dev_type: "decimal",
    devider: 100,
    name: "totalWithVat",
    en: "The amount of the document includes VAT",
    ar: "الإجمالي شامل الضريبة",
    ui: 1,
  },
  {
    notes: "‫בקבלה בלבד, אם נוכה. ראה הבהרה 4‬",
    require_type: 2,
    tech: "X9(9)v99",
    length: 12,
    from: 363,
    to: 374,
    type: "‫אלפאנומרי‬",
    he: "‫סכום הניכוי במקור‬",
    field: 1224,
    dev_type: "decimal",
    devider: 100,
    name: "withHoldingAmount",
    en: "The withholding amount",
    ar: "مبلغ خصم المصدر",
    ui: 1,
  },
  {
    notes:
      "‫חובה כאשר קוד מסמך בטווח 100-710 .‬ במסמכי שיווק - לקוח; ברכש - ספק‬",
    require_type: 3,
    tech: "X(15)",
    length: 15,
    from: 375,
    to: 389,
    type: "‫אלפאנומרי‬",
    he: "‫מפתח הלקוח אצל המוכר או‬ מפתח הספק אצל הקונה‬",
    field: 1225,
    dev_type: "string",
    devider: "",
    name: "custVendNumber",
    en: "The client's key at the seller's or the supplier's key at the buyer's",
    ar: "مفتاح العميل عند البائع أو مفتاح المورد عند المشتري",
    ui: 1,
  },
  {
    require_type: 2,
    tech: "X(10)",
    length: 10,
    from: 390,
    to: 399,
    type: "‫אלפאנומרי‬",
    he: "‫שדה התאמה‬",
    field: 1226,
    dev_type: "string",
    devider: "",
    name: "matchField",
    en: "Match field",
    ar: "حقل للربط",
    ui: 0,
  },
  {
    notes: "‫שדה מבוטל!‬",
    require_type: 1,
    tech: "X(0)",
    length: 0,
    from: 400,
    to: 400,
    type: "‫אלפאנומרי‬",
    he: "‫לנתונים עתידיים‬",
    field: 1227,
    dev_type: "string",
    devider: "",
    name: "futureUse1",
    en: "for future data",
    ar: "للبيانات المستقبلية",
    ui: 0,
  },
  {
    notes: "‫מסמך מבוטל = 1‬",
    require_type: 2,
    tech: "X(1)",
    length: 1,
    from: 400,
    to: 400,
    type: "‫אלפאנומרי‬",
    he: "‫מסמך מבוטל‬",
    field: 1228,
    dev_type: "string",
    devider: "",
    name: "canceledDocument",
    en: "A canceled document",
    ar: "وثيقة ملغاة",
    ui: 1,
  },
  {
    notes: "‫שדה מבוטל!‬",
    require_type: 1,
    tech: "X(0)",
    length: 0,
    from: 401,
    to: 401,
    type: "‫אלפאנומרי‬",
    he: "‫לנתונים עתידיים‬",
    field: 1229,
    dev_type: "string",
    devider: "",
    name: "futureUse2",
    en: "for future data",
    ar: "للبيانات المستقبلية",
    ui: 0,
  },
  {
    notes: "‫בפורמט YYYYMMDD . ראה הבהרה 12‬‏\r\n‫בנספח הבהרות.‬‏",
    require_type: 1,
    tech: "9(8)",
    length: 8,
    from: 401,
    to: 408,
    type: "‫נומרי‬",
    he: "‫תאריך המסמך‬",
    field: 1230,
    dev_type: "int",
    devider: "",
    name: "documentDate",
    en: "The date of the document",
    ar: "تاريخ الوثيقة",
    ui: 1,
  },
  {
    notes:
      "‫מספר הסניף/ענף כפי שמופיע ברישומי בית‬‏\r\n‫העסק; חובה כאשר ערך שדה 1034=1, ראה‬‏\r\n‫הבהרה 3 בנספח הבהרות‬‏",
    require_type: 3,
    tech: "X(7)",
    length: 7,
    from: 409,
    to: 415,
    type: "‫אלפאנומרי‬",
    he: "‫מזהה סניף/ענף‬",
    field: 1231,
    dev_type: "string",
    devider: "",
    name: "branchId",
    en: "Branch/Branch ID",
    ar: "معرف الفرع/الفرع",
    ui: 1,
  },
  {
    notes: "‫שדה מבוטל!‬",
    require_type: 1,
    tech: "X(0)",
    length: 0,
    from: 416,
    to: 416,
    type: "‫אלפאנומרי‬",
    he: "‫לנתונים עתידיים‬",
    field: 1232,
    dev_type: "string",
    devider: "",
    name: "futureUse3",
    en: "for future data",
    ar: "للبيانات المستقبلية",
    ui: 0,
  },
  {
    notes: "‫שם המשתמש של מבצע הפעולה.‬",
    require_type: 2,
    tech: "X(9)",
    length: 9,
    from: 416,
    to: 424,
    type: "‫אלפאנומרי‬",
    he: "‫מבצע הפעולה‬",
    field: 1233,
    dev_type: "string",
    devider: "",
    name: "user",
    en: "Performer of the action",
    ar: "المستخدم المنفذ",
    ui: 1,
  },
  {
    notes:
      "‫המספר הפנימי שמפיקה התוכנה לצורך קישור‬‏\r\n‫בין כותרת המסמך לשורות המסמך. ראה‬‏\r\n‫הבהרה 11 בנספח הבהרות.‬‏",
    require_type: 2,
    tech: "9(7)",
    length: 7,
    from: 425,
    to: 431,
    type: "‫נומרי‬",
    he: "‫שדה מקשר לשורה‬",
    field: 1234,
    dev_type: "int",
    devider: "",
    name: "fieldLinkToRow",
    en: "A field links to a row",
    ar: "رابط الحقل بصف",
    ui: 0,
  },
  {
    require_type: 1,
    tech: "X(13)",
    length: 13,
    from: 432,
    to: 444,
    type: "‫אלפאנומרי‬",
    he: "‫לנתונים עתידיים‬",
    field: 1235,
    dev_type: "string",
    devider: "",
    name: "futureUse4",
    en: "for future data",
    ar: "للبيانات المستقبلية",
    ui: 0,
  },
];

export const BKMVDATA_FileDetailsStructure = [
  {
    notes: "D110",
    require_type: 1,
    tech: "X(4)",
    length: 4,
    from: 1,
    to: 4,
    type: "‫אלפאנומרי‬‏",
    he: "‫קוד רשומה‬‏",
    field: 1250,
    dev_type: "string",
    devider: "",
    name: "code",
    en: "Record code",
    ar: "رمز السجل",
    ui: 1,
  },
  {
    notes: "‫מס רץ של הרשומה בקובץ BKMVDATA‬‏",
    require_type: 1,
    tech: "9(9)",
    length: 9,
    from: 5,
    to: 13,
    type: "‫נומרי‬‏",
    he: "‫מס רשומה בקובץ‬‏",
    field: 1251,
    dev_type: "int",
    devider: "",
    name: "recordId",
    en: "Tax is recorded in the file",
    ar: "رقم السجل في الملف",
    ui: 1,
  },
  {
    notes: "‫מספר עוסק מורשה של בית העסק‬‏",
    require_type: 1,
    tech: "9(9)",
    length: 9,
    from: 14,
    to: 22,
    type: "‫נומרי‬‏",
    he: "‫מס עוסק מורשה‬‏",
    field: 1252,
    dev_type: "int",
    devider: "",
    name: "authorizedDealer",
    en: "Tax authorized dealer",
    ar: "المشتغل المرخص",
    ui: 1,
  },
  {
    notes: "‫בהתאם לרשימת מסמכים בטבלה בנספח‬‏\r\n‫מספר 1‬‏",
    require_type: 1,
    tech: "9(3)",
    length: 3,
    from: 23,
    to: 25,
    type: "‫נומרי‬‏",
    he: "‫סוג המסמך‬‏",
    field: 1253,
    dev_type: "int",
    devider: "",
    name: "documentType",
    en: "The type of document",
    ar: "نوع الوثيقة",
    ui: 1,
  },
  {
    notes: "",
    require_type: 1,
    tech: "X(20)",
    length: 20,
    from: 26,
    to: 45,
    type: "‫אלפאנומרי‬‏",
    he: "‫מספר המסמך‬‏",
    field: 1254,
    dev_type: "string",
    devider: "",
    name: "documentNumber",
    en: "The document number",
    ar: "رقم الوثيقة",
    ui: 1,
  },
  {
    notes: "",
    require_type: 1,
    tech: "9(4)",
    length: 4,
    from: 46,
    to: 49,
    type: "‫נומרי‬‏",
    he: "‫מספר שורה במסמך‬‏",
    field: 1255,
    dev_type: "int",
    devider: "",
    name: "documentRow",
    en: "Document line number",
    ar: "رقم سطر الوثيقة",
    ui: 1,
  },
  {
    notes:
      "‫בנספח 1;‬‏\r\n‫בהתאם לרשימת מסמכים‬‏\r\n‫חובה כאשר המסמך מבוסס על מסמך‬‏\r\n‫אחר, ראה הבהרה 6 בנספח הבהרות‬‏",
    require_type: 2,
    tech: "9(3)",
    length: 3,
    from: 50,
    to: 52,
    type: "‫נומרי‬‏",
    he: "‫סוג מסמך בסיס‬‏",
    field: 1256,
    dev_type: "int",
    devider: "",
    name: "baseDocumentType",
    en: "Base document type",
    ar: "نوع الوثيقة الأساسية",
    ui: 1,
  },
  {
    notes:
      "‫בנספח 1;‬‏\r\n‫בהתאם לרשימת מסמכים‬‏\r\n‫חובה כאשר המסמך מבוסס על מסמך‬‏\r\n‫אחר, ראה הבהרה 6 בנספח הבהרות‬‏",
    require_type: 2,
    tech: "X(20)",
    length: 20,
    from: 53,
    to: 72,
    type: "‫אלפאנומרי‬‏",
    he: "‫מספר מסמך בסיס‬‏",
    field: 1257,
    dev_type: "string",
    devider: "",
    name: "baseDocumentId",
    en: "Base document number",
    ar: "رقم الوثيقة الأساسية",
    ui: 1,
  },
  {
    notes: "‫1 - שירות; 2 - מכר; 3 - שירות ומכר‬‏",
    require_type: 2,
    tech: "9(1)",
    length: 1,
    from: 73,
    to: 73,
    type: "‫נומרי‬‏",
    he: "‫סוג עסקה‬‏",
    field: 1258,
    dev_type: "int",
    devider: "",
    name: "dealType",
    en: "transaction type",
    ar: "نوع المعاملة",
    ui: 1,
  },
  {
    notes: "",
    require_type: 2,
    tech: "X(20)",
    length: 20,
    from: 74,
    to: 93,
    type: "‫אלפאנומרי‬‏",
    he: '‫מק"ט פנימי‬‏',
    field: 1259,
    dev_type: "string",
    devider: "",
    name: "internalSku",
    en: "Internal SKU",
    ar: "الرمز الداخلي",
    ui: 1,
  },
  {
    notes: "",
    require_type: 1,
    tech: "X(30)",
    length: 30,
    from: 94,
    to: 123,
    type: "‫אלפאנומרי‬‏",
    he: "‫תיאור הטובין שנמכר או‬‏ השירות שניתן‬‏",
    field: 1260,
    dev_type: "string",
    devider: "",
    name: "description",
    en: "Description of the goods sold or the service provided",
    ar: "وصف البضاعة المباعة أو الخدمة المقدمة",
    ui: 1,
  },
  {
    notes:
      "‫לגבי טובין המפורטים בנספח ג' בהוראה‬‏\r\n‫36 להוראות ניהול פנקסים‬‏",
    require_type: 2,
    tech: "X(50)",
    length: 50,
    from: 124,
    to: 173,
    type: "‫אלפאנומרי‬‏",
    he: "‫שם היצרן‬‏",
    field: 1261,
    dev_type: "string",
    devider: "",
    name: "producerName",
    en: "Manufacturer name",
    ar: "اسم المصنع",
    ui: 1,
  },
  {
    notes:
      "‫לגבי טובין המפורטים בנספח ג' בהוראה‬‏\r\n‫36 להוראות ניהול פנקסים‬‏",
    require_type: 2,
    tech: "X(30)",
    length: 30,
    from: 174,
    to: 203,
    type: "‫אלפאנומרי‬‏",
    he: "‫מספר סידורי של המוצר‬‏ המוטבע על המוצר על ידי‬‏ היצרן‬‏",
    field: 1262,
    dev_type: "string",
    devider: "",
    name: "productSerialNumber",
    en: "Serial number of the product stamped on the product by the manufacturer",
    ar: "الرقم التسلسلي للمنتج المختوم على المنتج من قبل الشركة المصنعة",
    ui: 1,
  },
  {
    notes:
      '‫כשמדובר ביחידה עם משמעות )לדוגמא ליטר,‬‏\r\n‫גרם, שעת עבודה( ירשם תיאור היחידה, אחרת‬‏\r\n‫תרשם המילה "יחידה"‬‏',
    require_type: 2,
    tech: "X(20)",
    length: 20,
    from: 204,
    to: 223,
    type: "‫אלפאנומרי‬‏",
    he: "‫תיאור יחידת המידה‬‏",
    field: 1263,
    dev_type: "string",
    devider: "",
    name: "unit",
    en: "Description of the unit of measure",
    ar: "وصف وحدة القياس",
    ui: 1,
  },
  {
    notes: "",
    require_type: 1,
    tech: "X9(12)v9999",
    length: 17,
    from: 224,
    to: 240,
    type: "‫אלפאנומרי‬‏",
    he: "‫הכמות‬‏",
    field: 1264,
    dev_type: "decimal",
    devider: 10000,
    name: "quantity",
    en: "the amount",
    ar: "المبلغ",
    ui: 1,
  },
  {
    notes: '‫סכום בש "ח‬‏',
    require_type: 2,
    tech: "X9(12)v99",
    length: 15,
    from: 241,
    to: 255,
    type: "‫אלפאנומרי‬‏",
    he: '‫מחיר ליחידה ללא מע"מ‬‏',
    field: 1265,
    dev_type: "decimal",
    devider: 100,
    name: "priceForUnit",
    en: "Price per unit without VAT",
    ar: "سعر الوحدة بدون ضريبة القيمة المضافة",
    ui: 1,
  },
  {
    notes: '‫סכום בש "ח. ראה הבהרה 5 בנספח‬‏\r\n‫הבהרות‬‏',
    require_type: 2,
    tech: "X9(12)v99",
    length: 15,
    from: 256,
    to: 270,
    type: "‫אלפאנומרי‬‏",
    he: "‫הנחת שורה‬‏",
    field: 1266,
    dev_type: "decimal",
    devider: 100,
    name: "rowDiscount",
    en: "Laying a line",
    ar: "وضع خط",
    ui: 1,
  },
  {
    notes: "‫הכמות בשורה * מחיר ליח' ללא מע\"מ‬‏\r\n‫בניכוי הנחת השורה‬‏",
    require_type: 2,
    tech: "X9(12)v99",
    length: 15,
    from: 271,
    to: 285,
    type: "‫אלפאנומרי‬‏",
    he: "‫סך סכום לשורה‬‏",
    field: 1267,
    dev_type: "decimal",
    devider: 100,
    name: "rowTotal",
    en: "Total amount per line",
    ar: "المبلغ الإجمالي لكل سطر",
    ui: 1,
  },
  {
    notes: "‫מספר המייצג אחוז ) 1550 (‬‏",
    require_type: 1,
    tech: "9(2)v99",
    length: 4,
    from: 286,
    to: 289,
    type: "‫נומרי‬‏",
    he: '‫שיעור המע"מ בשורה‬‏',
    field: 1268,
    dev_type: "float",
    devider: 100,
    name: "rowVatPercent",
    en: "The VAT rate is in line",
    ar: "معدل ضريبة القيمة المضافة يتماشى",
    ui: 1,
  },
  {
    notes: "‫שדה מבוטל!‬‏",
    require_type: 1,
    tech: "X(0)",
    length: 0,
    from: 290,
    to: 290,
    type: "‫אלפאנומרי‬‏",
    he: "‫שטח לנתונים עתידיים‬‏",
    field: 1269,
    dev_type: "string",
    devider: "",
    name: "futureUse1",
    en: "Space for future data",
    ar: "مساحة للبيانات المستقبلية",
    ui: 0,
  },
  {
    notes:
      "‫כפי שמופיע ברישומי בית‬‏\r\n‫מספר הסניף/ענף‬‏\r\n‫העסק; חובה כאשר ערך שדה 1034=1 , ראה‬‏\r\n‫הבהרה 3 בנספח הבהרות.‬‏",
    require_type: 3,
    tech: "X(7)",
    length: 7,
    from: 290,
    to: 296,
    type: "‫אלפאנומרי‬‏",
    he: "‫מזהה סניף/ענף‬‏",
    field: 1270,
    dev_type: "string",
    devider: "",
    name: "brachId",
    en: "Branch/Branch ID",
    ar: "معرف الفرع/الفرع",
    ui: 1,
  },
  {
    notes: "‫שדה מבוטל!‬‏",
    require_type: 1,
    tech: "X(0)",
    length: 0,
    from: 297,
    to: 297,
    type: "‫אלפאנומרי‬‏",
    he: "‫שטח לנתונים עתידיים‬‏",
    field: 1271,
    dev_type: "string",
    devider: "",
    name: "futureUse2",
    en: "Space for future data",
    ar: "مساحة للبيانات المستقبلية",
    ui: 0,
  },
  {
    notes: "‫בפורמט YYYYMMDD . ראה הבהרה 12‬‏\r\n‫בנספח הבהרות.‬‏",
    require_type: 1,
    tech: "9(8)",
    length: 8,
    from: 297,
    to: 304,
    type: "‫נומרי‬‏",
    he: "‫תאריך המסמך‬‏",
    field: 1272,
    dev_type: "int",
    devider: "",
    name: "documentDate",
    en: "The date of the document",
    ar: "تاريخ الوثيقة",
    ui: 1,
  },
  {
    notes:
      "‫המספר הפנימי שמפיקה התוכנה לצורך קישור‬‏\r\n‫בין שורות המסמך לכותרת המסמך.ראה‬‏\r\n‫הבהרה 11 בנספח הבהרות.‬‏",
    require_type: 2,
    tech: "9(7)",
    length: 7,
    from: 305,
    to: 311,
    type: "‫נומרי‬‏",
    he: "‫שדה מקשר לכותרת‬‏",
    field: 1273,
    dev_type: "int",
    devider: "",
    name: "rowLinkToDocument",
    en: "A link field to the title",
    ar: "حقل الارتباط إلى العنوان",
    ui: 0,
  },
  {
    notes:
      "‫של מ. בסיס כפי שמופיע‬‏\r\n‫מספר הסניף/ענף‬‏\r\n‫ברישומי בית העסק; חובה כאשר ערך שדה‬‏\r\n‫1034=1 , ראה הבהרות 3 ו 6 בנספח הבהרות.‬‏",
    require_type: 3,
    tech: "X(7)",
    length: 7,
    from: 312,
    to: 318,
    type: "‫אלפאנומרי‬‏",
    he: "‫מזהה סניף/ענף למסמך‬‏ בסיס‬‏",
    field: 1274,
    dev_type: "string",
    devider: "",
    name: "baseDocumentBrachId",
    en: "Branch/branch ID for base document",
    ar: "معرف الفرع/الفرع للمستند الأساسي",
    ui: 1,
  },
  {
    notes: "",
    require_type: 1,
    tech: "X(21)",
    length: 21,
    from: 319,
    to: 339,
    type: "‫אלפאנומרי‬‏",
    he: "‫שטח לנתונים עתידיים‬‏",
    field: 1275,
    dev_type: "string",
    devider: "",
    name: "futureUse3",
    en: "Space for future data",
    ar: "مساحة للبيانات المستقبلية",
    ui: 0,
  },
];

export const BKMVDATA_FileReceiptStructure = [
  {
    notes: "D120",
    require_type: 1,
    tech: "X(4)",
    length: 4,
    from: 1,
    to: 4,
    type: "‫אלפאנומרי‬‏",
    he: "‫קוד רשומה‬‏",
    field: 1300,
    dev_type: "string",
    devider: "",
    name: "code",
    en: "Record code",
    ar: "رمز السجل",
    ui: 1,
  },
  {
    notes: "‫מס רץ של הרשומה בקובץ BKMVDATA‬‏",
    require_type: 1,
    tech: "9(9)",
    length: 9,
    from: 5,
    to: 13,
    type: "‫נומרי‬‏",
    he: "‫מס רשומה בקובץ‬‏",
    field: 1301,
    dev_type: "int",
    devider: "",
    name: "recordId",
    en: "Tax is recorded in the file",
    ar: "رقم السجل في الملف",
    ui: 1,
  },
  {
    notes: "‫מספר עוסק מורשה של בית העסק‬‏",
    require_type: 1,
    tech: "9(9)",
    length: 9,
    from: 14,
    to: 22,
    type: "‫נומרי‬‏",
    he: "‫מס עוסק מורשה‬‏",
    field: 1302,
    dev_type: "int",
    devider: "",
    name: "authorizedDealer",
    en: "Tax authorized dealer",
    ar: "المشتغل المرخص",
    ui: 1,
  },
  {
    notes:
      "‫בהתאם לרשימת מסמכים בטבלה בנספח‬‏\r\n‫מספר 1 ,ראה הבהרה 1 בנספח הבהרות‬‏",
    require_type: 1,
    tech: "9(3)",
    length: 3,
    from: 23,
    to: 25,
    type: "‫נומרי‬‏",
    he: "‫סוג מסמך‬‏",
    field: 1303,
    dev_type: "int",
    devider: "",
    name: "documentType",
    en: "document type",
    ar: "نوع الوثيقة",
    ui: 1,
  },
  {
    notes: "",
    require_type: 1,
    tech: "X(20)",
    length: 20,
    from: 26,
    to: 45,
    type: "‫אלפאנומרי‬‏",
    he: "‫מספר מסמך‬‏",
    field: 1304,
    dev_type: "string",
    devider: "",
    name: "documentNumber",
    en: "Document number",
    ar: "رقم المستند",
    ui: 1,
  },
  {
    notes: "",
    require_type: 1,
    tech: "9(4)",
    length: 4,
    from: 46,
    to: 49,
    type: "‫נומרי‬‏",
    he: "‫מספר שורה במסמך‬‏",
    field: 1305,
    dev_type: "int",
    devider: "",
    name: "documentRow",
    en: "Document line number",
    ar: "رقم سطر الوثيقة",
    ui: 1,
  },
  {
    notes:
      "‫1- מזומן; 2 - המחאה; 3 - כ. אשראי; 4 - העב.‬‏\r\n‫בנקאית; 5 - תווי קניה; 6 -תלוש החלפה; 7 -‬‏\r\n‫שטר; 8-ה.קבע;9 -אחר‬‏",
    require_type: 1,
    tech: "9(1)",
    length: 1,
    from: 50,
    to: 50,
    type: "‫נומרי‬‏",
    he: "‫סוג אמצעי התשלום‬‏",
    field: 1306,
    dev_type: "int",
    devider: "",
    name: "paymentMethod",
    en: "The type of payment method",
    ar: "نوع طريقة الدفع",
    ui: 1,
  },
  {
    notes: "‫בהמחאה בלבד‬‏",
    require_type: 3,
    tech: "9(10)",
    length: 10,
    from: 51,
    to: 60,
    type: "‫נומרי‬‏",
    he: "‫מספר הבנק‬‏",
    field: 1307,
    dev_type: "int",
    devider: "",
    name: "bankCode",
    en: "Bank number",
    ar: "عدد البنوك",
    ui: 1,
  },
  {
    notes: "‫בהמחאה בלבד‬‏",
    require_type: 3,
    tech: "9(10)",
    length: 10,
    from: 61,
    to: 70,
    type: "‫נומרי‬‏",
    he: "‫מספר הסניף‬‏",
    field: 1308,
    dev_type: "int",
    devider: "",
    name: "bankBranchNumber",
    en: "branch number",
    ar: "رقم الفرع",
    ui: 1,
  },
  {
    notes: "‫בהמחאה בלבד‬‏",
    require_type: 3,
    tech: "9(15)",
    length: 15,
    from: 71,
    to: 85,
    type: "‫נומרי‬‏",
    he: "‫מספר חשבון‬‏",
    field: 1309,
    dev_type: "int",
    devider: "",
    name: "accountNumber",
    en: "Account Number",
    ar: "رقم حساب",
    ui: 1,
  },
  {
    notes: "‫בהמחאה בלבד‬‏",
    require_type: 3,
    tech: "9(10)",
    length: 10,
    from: 86,
    to: 95,
    type: "‫נומרי‬‏",
    he: "‫מספר המחאה‬‏",
    field: 1310,
    dev_type: "int",
    devider: "",
    name: "chequeNumber",
    en: "check number",
    ar: "رقم الشيك",
    ui: 1,
  },
  {
    notes: "‫בפורמט YYYYMMDD , בהמחאה או‬‏\r\n‫בכרטיס אשראי בלבד‬‏",
    require_type: 2,
    tech: "9(8)",
    length: 8,
    from: 96,
    to: 103,
    type: "‫נומרי‬‏",
    he: "‫תאריך הפירעון של ההמחאה /‬‏ התשלום‬‏",
    field: 1311,
    dev_type: "int",
    devider: "",
    name: "paymentDueDate",
    en: "The due date of the check / payment",
    ar: "تاريخ استحقاق الشيك / الدفع",
    ui: 1,
  },
  {
    notes: "",
    require_type: 1,
    tech: "X9(12)v99",
    length: 15,
    from: 104,
    to: 118,
    type: "‫אלפאנומרי‬‏",
    he: "‫סכום השורה‬‏",
    field: 1312,
    dev_type: "decimal",
    devider: 100,
    name: "rowTotal",
    en: "row sum",
    ar: "مجموع الصف",
    ui: 1,
  },
  {
    notes:
      "‫1-ישראכרט, 2-כאל, 3-דיינרס, 4- אמריקן‬‏\r\n‫אקספרס, 6-לאומי כארד‬‏",
    require_type: 2,
    tech: "9(1)",
    length: 1,
    from: 119,
    to: 119,
    type: "‫נומרי‬‏",
    he: "‫קוד החברה הסולקת‬‏",
    field: 1313,
    dev_type: "int",
    devider: "",
    name: "clearingCompCode",
    en: "The clearing company code",
    ar: "رمز شركة المقاصة",
    ui: 1,
  },
  {
    notes: "",
    require_type: 2,
    tech: "X(20)",
    length: 20,
    from: 120,
    to: 139,
    type: "‫אלפאנומרי‬‏",
    he: "‫שם הכרטיס הנסלק‬‏",
    field: 1314,
    dev_type: "string",
    devider: "",
    name: "cardName",
    en: "The name of the removed card",
    ar: "اسم البطاقة التي تمت إزالتها",
    ui: 1,
  },
  {
    notes: "‫1 - רגיל; 2 - תשלומים; 3 - קרדיט;4-חיוב נדחה;‬‏\r\n‫5-אחר‬‏",
    require_type: 2,
    tech: "9(1)",
    length: 1,
    from: 140,
    to: 140,
    type: "‫נומרי‬‏",
    he: "‫סוג עסקת האשראי‬‏",
    field: 1315,
    dev_type: "int",
    devider: "",
    name: "creditTransactionType",
    en: "The type of credit transaction",
    ar: "نوع المعاملة الائتمانية",
    ui: 1,
  },
  {
    notes: "‫שדה מבוטל!‬‏",
    require_type: 0,
    tech: "X(0)",
    length: 0,
    from: 141,
    to: 141,
    type: "‫אלפאנומרי‬‏",
    he: "‫סכום התשלום הראשון‬‏",
    field: 1316,
    dev_type: "string",
    devider: "",
    name: "firstPaymentAmount",
    en: "The amount of the first payment",
    ar: "مبلغ الدفعة الأولى",
    ui: 1,
  },
  {
    notes: "‫שדה מבוטל!‬‏",
    require_type: 0,
    tech: "X(0)",
    length: 0,
    from: 141,
    to: 141,
    type: "‫אלפאנומרי‬‏",
    he: "‫מספר תשלומים‬‏",
    field: 1317,
    dev_type: "string",
    devider: "",
    name: "numberOfPayments",
    en: "Number of payments",
    ar: "عدد الدفعات",
    ui: 1,
  },
  {
    notes: "‫שדה מבוטל!‬‏",
    require_type: 0,
    tech: "X(0)",
    length: 0,
    from: 141,
    to: 141,
    type: "‫אלפאנומרי‬‏",
    he: "‫סכום כל תשלום נוסף‬‏",
    field: 1318,
    dev_type: "string",
    devider: "",
    name: "additionalPaymentAmount",
    en: "The amount of any additional payment",
    ar: "مبلغ أي دفعة إضافية",
    ui: 1,
  },
  {
    notes: "‫שדה מבוטל!‬‏",
    require_type: 1,
    tech: "X(0)",
    length: 0,
    from: 141,
    to: 141,
    type: "‫אלפאנומרי‬‏",
    he: "‫שטח לנתונים עתידיים‬‏",
    field: 1319,
    dev_type: "string",
    devider: "",
    name: "futureUse1",
    en: "Space for future data",
    ar: "مساحة للبيانات المستقبلية",
    ui: 0,
  },
  {
    notes:
      "‫מספר הסניף/ענף כפי שמופיע ברישומי בית‬‏\r\n‫העסק; חובה כאשר ערכו של שדה 1034 הוא 1,‬‏\r\n‫ראה הבהרה 3 בנספח הבהרות‬‏",
    require_type: 3,
    tech: "X(7)",
    length: 7,
    from: 141,
    to: 147,
    type: "‫אלפאנומרי‬‏",
    he: "‫מזהה סניף/ענף‬‏",
    field: 1320,
    dev_type: "string",
    devider: "",
    name: "branchId",
    en: "Branch/Branch ID",
    ar: "معرف الفرع/الفرع",
    ui: 1,
  },
  {
    notes: "‫שדה מבוטל!‬‏",
    require_type: 1,
    tech: "X(0)",
    length: 0,
    from: 148,
    to: 148,
    type: "‫אלפאנומרי‬‏",
    he: "‫שטח לנתונים עתידיים‬‏",
    field: 1321,
    dev_type: "string",
    devider: "",
    name: "futureUse2",
    en: "Space for future data",
    ar: "مساحة للبيانات المستقبلية",
    ui: 0,
  },
  {
    notes: "‫בפורמט YYYYMMDD . ראה הבהרה 12‬‏\r\n‫בנספח הבהרות.‬‏",
    require_type: 1,
    tech: "9(8)",
    length: 8,
    from: 148,
    to: 155,
    type: "‫נומרי‬‏",
    he: "‫תאריך המסמך‬‏",
    field: 1322,
    dev_type: "int",
    devider: "",
    name: "documentDate",
    en: "The date of the document",
    ar: "تاريخ الوثيقة",
    ui: 1,
  },
  {
    notes:
      "‫המספר הפנימי שמפיקה התוכנה לצורך קישור‬‏\r\n‫בין שורות המסמך לכותרת המסמך.ראה הבהרה‬‏\r\n‫11 בנספח הבהרות.‬‏",
    require_type: 2,
    tech: "9(7)",
    length: 7,
    from: 156,
    to: 162,
    type: "‫נומרי‬‏",
    he: "‫שדה מקשר לכותרת‬‏",
    field: 1323,
    dev_type: "int",
    devider: "",
    name: "rowLinkToDocument",
    en: "A link field to the title",
    ar: "حقل الارتباط إلى العنوان",
    ui: 0,
  },
  {
    notes: "",
    require_type: 1,
    tech: "X(60)",
    length: 60,
    from: 163,
    to: 222,
    type: "‫אלפאנומרי‬‏",
    he: "‫שטח לנתונים עתידיים‬‏",
    field: 1324,
    dev_type: "string",
    devider: "",
    name: "futureUse3",
    en: "Space for future data",
    ar: "مساحة للبيانات المستقبلية",
    ui: 0,
  },
];

export const BKMVDATA_FileAccountingTransactionsStructue = [
  {
    notes: "B100",
    require_type: 1,
    tech: "X(4)",
    length: 4,
    from: 1,
    to: 4,
    type: "‫אלפאנומרי‬‏",
    he: "‫קוד רשומה‬‏",
    field: 1350,
    dev_type: "string",
    devider: "",
    name: "code",
    en: "Record code",
    ar: "رمز السجل",
    ui: 1,
  },
  {
    notes: "‫מס רץ של הרשומה בקובץ BKMVDATA‬‏",
    require_type: 1,
    tech: "9(9)",
    length: 9,
    from: 5,
    to: 13,
    type: "‫נומרי‬‏",
    he: "‫מס רשומה בקובץ‬‏",
    field: 1351,
    dev_type: "int",
    devider: "",
    name: "recordId",
    en: "Tax is recorded in the file",
    ar: "رقم السجل في الملف",
    ui: 1,
  },
  {
    notes: "‫מספר עוסק מורשה של בית העסק‬‏",
    require_type: 1,
    tech: "9(9)",
    length: 9,
    from: 14,
    to: 22,
    type: "‫נומרי‬‏",
    he: "‫מס עוסק מורשה‬‏",
    field: 1352,
    dev_type: "int",
    devider: "",
    name: "authorizedDealer",
    en: "Tax authorized dealer",
    ar: "المشتغل المرخص",
    ui: 1,
  },
  {
    notes: "‫ראה הבהרה 7 בנספח הבהרות.‬‏",
    require_type: 1,
    tech: "9(10)",
    length: 10,
    from: 23,
    to: 32,
    type: "‫נומרי‬‏",
    he: "‫מספר תנועה‬‏",
    field: 1353,
    dev_type: "int",
    devider: "",
    name: "recordNumber",
    en: "Traffic number",
    ar: "رقم الحركة",
    ui: 1,
  },
  {
    notes: "",
    require_type: 1,
    tech: "9(5)",
    length: 5,
    from: 33,
    to: 37,
    type: "‫נומרי‬‏",
    he: "‫מספר שורה בתנועה‬‏",
    field: 1354,
    dev_type: "int",
    devider: "",
    name: "recordRowNumber",
    en: "Line number in motion",
    ar: "رقم السطر للحركة",
    ui: 1,
  },
  {
    notes: "",
    require_type: 2,
    tech: "9(8)",
    length: 8,
    from: 38,
    to: 45,
    type: "‫נומרי‬‏",
    he: "‫מנה‬‏",
    field: 1355,
    dev_type: "int",
    devider: "",
    name: "portion",
    en: "Acconting portion",
    ar: "الحزء المحاسبي",
    ui: 1,
  },
  {
    notes: "",
    require_type: 2,
    tech: "X(15)",
    length: 15,
    from: 46,
    to: 60,
    type: "‫אלפאנומרי‬‏",
    he: "‫סוג תנועה‬‏",
    field: 1356,
    dev_type: "string",
    devider: "",
    name: "recordType",
    en: "type of traffic",
    ar: "نوع حركة المرور",
    ui: 1,
  },
  {
    notes:
      "‫בהתאם לטבלת עזר המפורטת בנספח מספר 1.‬‏\r\n‫אם רישום האסמכתא הוא תוצר של תהליך‬‏\r\n‫מובנה בתוכנה ולכן סוג המסמך ידוע.‬‏",
    require_type: 2,
    tech: "X(20)",
    length: 20,
    from: 61,
    to: 80,
    type: "‫אלפאנומרי‬‏",
    he: "‫אסמכתא‬‏",
    field: 1357,
    dev_type: "string",
    devider: "",
    name: "reference",
    en: "a reference",
    ar: "مرجع",
    ui: 1,
  },
  {
    require_type: 2,
    tech: "9(3)",
    length: 3,
    from: 81,
    to: 83,
    type: "‫נומרי‬‏",
    he: "‫סוג מסמך האסמכתא‬‏",
    field: 1358,
    dev_type: "int",
    devider: "",
    name: "referenceDocumentType",
    en: "The type of reference document",
    ar: "نوع الوثيقة المرجعية",
    ui: 1,
  },
  {
    notes:
      "‫בהתאם לטבלת עזר המפורטת בנספח מספר 1.‬‏\r\n‫אם רישום האסמכתא הוא תוצר של תהליך‬‏\r\n‫מובנה בתוכנה ולכן סוג המסמך ידוע.‬‏",
    require_type: 2,
    tech: "X(20)",
    length: 20,
    from: 84,
    to: 103,
    type: "‫אלפאנומרי‬‏",
    he: "‫אסמכתא 2‬‏",
    field: 1359,
    dev_type: "string",
    devider: "",
    name: "reference2",
    en: "Reference 2",
    ar: "المرجع 2",
    ui: 1,
  },
  {
    require_type: 2,
    tech: "9(3)",
    length: 3,
    from: 104,
    to: 106,
    type: "‫נומרי‬‏",
    he: "‫סוג מסמך האסמכתא‬‏",
    field: 1360,
    dev_type: "int",
    devider: "",
    name: "reference2DocumentType",
    en: "The type of reference document",
    ar: "نوع الوثيقة المرجعية",
    ui: 1,
  },
  {
    notes: "",
    require_type: 2,
    tech: "X(50)",
    length: 50,
    from: 107,
    to: 156,
    type: "‫אלפאנומרי‬‏",
    he: "‫פרטים‬‏",
    field: 1361,
    dev_type: "string",
    devider: "",
    name: "details",
    en: "Details",
    ar: "تفاصيل",
    ui: 1,
  },
  {
    notes: "‫בפורמט YYYYMMDD , ראה הבהרה 12‬‏\r\n‫בנספח הבהרות‬‏",
    require_type: 1,
    tech: "9(8)",
    length: 8,
    from: 157,
    to: 164,
    type: "‫נומרי‬‏",
    he: "‫תאריך‬‏",
    field: 1362,
    dev_type: "int",
    devider: "",
    name: "date",
    en: "date",
    ar: "تاريخ",
    ui: 1,
  },
  {
    notes: "‫בפורמט YYYYMMDD , ראה הבהרה 12‬‏\r\n‫בנספח הבהרות‬‏",
    require_type: 1,
    tech: "9(8)",
    length: 8,
    from: 165,
    to: 172,
    type: "‫נומרי‬‏",
    he: "‫תאריך ערך‬‏",
    field: 1363,
    dev_type: "int",
    devider: "",
    name: "valueDate",
    en: "value date",
    ar: "تاريخ القيمة",
    ui: 1,
  },
  {
    notes: "‫מפתח החשבון; לערך שדה זה חייבת להיות‬‏\r\n‫רשומה מסוג 110 ‏B‬‏",
    require_type: 1,
    tech: "X(15)",
    length: 15,
    from: 173,
    to: 187,
    type: "‫אלפאנומרי‬‏",
    he: "‫חשבון בתנועה‬‏",
    field: 1364,
    dev_type: "string",
    devider: "",
    name: "recordAccount",
    en: "account in motion",
    ar: "الحساب في الحركة",
    ui: 1,
  },
  {
    notes:
      "‫מפתח החשבון . בהנהלת חשבונות חד צידית‬‏\r\n‫בלבד, אם קיים . לערך שדה זה חייבת להיות‬‏\r\n‫רשומה מסוג 110 ‏B‬‏",
    require_type: 2,
    tech: "X(15)",
    length: 15,
    from: 188,
    to: 202,
    type: "‫אלפאנומרי‬‏",
    he: "‫חשבון נגדי‬‏",
    field: 1365,
    dev_type: "string",
    devider: "",
    name: "counterAccount",
    en: "Counter account",
    ar: "حساب العداد",
    ui: 1,
  },
  {
    notes: "‫1 - חובה, הוצאה; 2 - זכות, הכנסה‬‏",
    require_type: 1,
    tech: "9(1)",
    length: 1,
    from: 203,
    to: 203,
    type: "‫נומרי‬‏",
    he: "‫סימן הפעולה‬‏",
    field: 1366,
    dev_type: "int",
    devider: "",
    name: "actionSign",
    en: "action sign",
    ar: "علامة العمل",
    ui: 1,
  },
  {
    notes:
      "‫בהתאם לטבלת קוד מטבע בנספח מספר 2.‬‏\r\n‫מתייחס לסכום המופיע בשדה 1369‬‏",
    require_type: 2,
    tech: "X(3 )",
    length: 3,
    from: 204,
    to: 206,
    type: "‫אלפאנומרי‬‏",
    he: '‫קוד מטבע מט"ח‬‏',
    field: 1367,
    dev_type: "string",
    devider: "",
    name: "foreingCurrencyCode",
    en: "Forex currency code",
    ar: "رمز العملة",
    ui: 1,
  },
  {
    notes: "‫במטבע מוביל‬‏",
    require_type: 1,
    tech: "X9(12)v99",
    length: 15,
    from: 207,
    to: 221,
    type: "‫אלפאנומרי‬‏",
    he: "‫סכום הפעולה‬‏",
    field: 1368,
    dev_type: "decimal",
    devider: 100,
    name: "actionTotal",
    en: "the amount of the action",
    ar: "مبلغ القيد",
    ui: 1,
  },
  {
    notes: "",
    require_type: 2,
    tech: "X9(12)v99",
    length: 15,
    from: 222,
    to: 236,
    type: "‫אלפאנומרי‬‏",
    he: '‫סכום מט"ח‬‏',
    field: 1369,
    dev_type: "decimal",
    devider: 100,
    name: "foreingCurrencyAmount",
    en: "foreign currency amount",
    ar: "مبلغ العملة الأجنبية",
    ui: 1,
  },
  {
    notes: '‫עשוי לשמש לדוגמא לציון כמות ע"נ או קוד‬‏\r\n‫תמחיר‬‏',
    require_type: 2,
    tech: "X9(9)v99",
    length: 12,
    from: 237,
    to: 248,
    type: "‫אלפאנומרי‬‏",
    he: "‫שדה כמות‬‏",
    field: 1370,
    dev_type: "decimal",
    devider: 100,
    name: "quantity",
    en: "Quantity field",
    ar: "حقل الكمية",
    ui: 1,
  },
  {
    notes: '‫מספר זיהוי להתאמת שורות בתוך כרטיס,‬‏\r\n‫למשל התאמת עסקת חו"ז.‬‏',
    require_type: 2,
    tech: "X(10)",
    length: 10,
    from: 249,
    to: 258,
    type: "‫אלפאנומרי‬‏",
    he: "‫שדה התאמה 1‬‏",
    field: 1371,
    dev_type: "string",
    devider: "",
    name: "matchingField1",
    en: "Matching field 1",
    ar: "الحقل المطابق 1",
    ui: 0,
  },
  {
    notes:
      "‫מספר זיהוי להתאמת שורות בכרטיס לשורות‬‏\r\n‫בכרטיס אחר או כרטיס חיצוני, למשל התאמת‬‏\r\n‫בנק.‬‏",
    require_type: 2,
    tech: "X(10)",
    length: 10,
    from: 259,
    to: 268,
    type: "‫אלפאנומרי‬‏",
    he: "‫שדה התאמה 2‬‏",
    field: 1372,
    dev_type: "string",
    devider: "",
    name: "matchingField2",
    en: "Matching field 2",
    ar: "الحقل المطابق 2",
    ui: 0,
  },
  {
    notes: "‫שדה מבוטל!‬‏",
    require_type: 1,
    tech: "X(0)",
    length: 0,
    from: 269,
    to: 269,
    type: "‫אלפאנומרי‬‏",
    he: "‫שטח לנתונים‬‏\r\n‫עתידיים‬‏",
    field: 1373,
    dev_type: "string",
    devider: "",
    name: "futureUse1",
    en: "Space for future data",
    ar: "مساحة للبيانات المستقبلية",
    ui: 0,
  },
  {
    notes:
      "‫מספר הסניף/הענף כפי שמופיע ברישומי בית‬‏\r\n‫העסק; חובה כאשר ערכו של שדה 1034 הוא 1,‬‏\r\n‫ראה הבהרה 3 בנספח הבהרות‬‏",
    require_type: 3,
    tech: "X(7)",
    length: 7,
    from: 269,
    to: 275,
    type: "‫אלפאנומרי‬‏",
    he: "‫מזהה סניף/ענף‬‏",
    field: 1374,
    dev_type: "string",
    devider: "",
    name: "branchId",
    en: "Branch/Branch ID",
    ar: "معرف الفرع/الفرع",
    ui: 1,
  },
  {
    notes: "‫ראה הבהרה מספר 12 בנספח ההבהרות‬‏",
    require_type: 1,
    tech: "9(8)",
    length: 8,
    from: 276,
    to: 283,
    type: "‫נומרי‬‏",
    he: "‫תאריך הזנה‬‏",
    field: 1375,
    dev_type: "int",
    devider: "",
    name: "entryDate",
    en: "date of entry",
    ar: "تاريخ الدخول",
    ui: 1,
  },
  {
    notes: "‫שם המשתמש של מבצע הפעולה‬‏",
    require_type: 2,
    tech: "X(9)",
    length: 9,
    from: 284,
    to: 292,
    type: "‫אלפאנומרי‬‏",
    he: "‫מבצע פעולה‬‏",
    field: 1376,
    dev_type: "string",
    devider: "",
    name: "user",
    en: "performs an action",
    ar: "المستخدم",
    ui: 1,
  },
  {
    notes: "",
    require_type: 1,
    tech: "X(25)",
    length: 25,
    from: 293,
    to: 317,
    type: "‫אלפאנומרי‬‏",
    he: "‫שטח לנתונים‬‏ עתידיים‬‏",
    field: 1377,
    dev_type: "string",
    devider: "",
    name: "futureUse2",
    en: "Space for future data",
    ar: "مساحة للبيانات المستقبلية",
    ui: 0,
  },
];

export const BKMVDATA_FileAccountStructure = [
  {
    notes: "B110",
    require_type: 1,
    tech: "X(4)",
    length: 4,
    from: 1,
    to: 4,
    type: "‫אלפאנומרי‬‏",
    he: "‫קוד רשומה‬‏",
    field: 1400,
    dev_type: "string",
    devider: "",
    name: "code",
    en: "Record code",
    ar: "رمز السجل",
    ui: 1,
  },
  {
    notes: "‫מס רץ של הרשומה בקובץ‬‏",
    require_type: 1,
    tech: "9(9)",
    length: 9,
    from: 5,
    to: 13,
    type: "‫נומרי‬‏",
    he: "‫מס רשומה בקובץ‬‏",
    field: 1401,
    dev_type: "int",
    devider: "",
    name: "recordId",
    en: "Tax is recorded in the file",
    ar: "رقم السجل في الملف",
    ui: 1,
  },
  {
    notes: "‫מספר עוסק מורשה של בית העסק‬‏",
    require_type: 1,
    tech: "9(9)",
    length: 9,
    from: 14,
    to: 22,
    type: "‫נומרי‬‏",
    he: "‫מס עוסק מורשה‬‏",
    field: 1402,
    dev_type: "int",
    devider: "",
    name: "authorizedDealer",
    en: "Tax authorized dealer",
    ar: "المشتغل المرخص",
    ui: 1,
  },
  {
    notes: "‫ערך השדה חד חד ערכי‬‏",
    require_type: 1,
    tech: "X(15)",
    length: 15,
    from: 23,
    to: 37,
    type: "‫אלפאנומרי‬‏",
    he: "‫מפתח החשבון‬‏",
    field: 1403,
    dev_type: "string",
    devider: "",
    name: "accountId",
    en: "The account key",
    ar: "مفتاح الحساب",
    ui: 1,
  },
  {
    notes: "",
    require_type: 1,
    tech: "X(50)",
    length: 50,
    from: 38,
    to: 87,
    type: "‫אלפאנומרי‬‏",
    he: "‫שם החשבון‬‏",
    field: 1404,
    dev_type: "string",
    devider: "",
    name: "accountName",
    en: "The account name",
    ar: "اسم الحساب",
    ui: 1,
  },
  {
    notes: "",
    require_type: 1,
    tech: "X(15)",
    length: 15,
    from: 88,
    to: 102,
    type: "‫אלפאנומרי‬‏",
    he: "‫קוד מאזן בוחן‬‏",
    field: 1405,
    dev_type: "string",
    devider: "",
    name: "trialBalanceCode",
    en: "Trial balance code",
    ar: "رمز ميزان المراجعة",
    ui: 1,
  },
  {
    notes: "",
    require_type: 1,
    tech: "X(30)",
    length: 30,
    from: 103,
    to: 132,
    type: "‫אלפאנומרי‬‏",
    he: "‫תיאור קוד מאזן בוחן‬‏",
    field: 1406,
    dev_type: "string",
    devider: "",
    name: "trialBalanceDescription",
    en: "Trial balance code description",
    ar: "وصف رمز ميزان المراجعة",
    ui: 1,
  },
  {
    notes: "‫ברשומת חשבון של לקוח או ספק‬‏",
    require_type: 2,
    tech: "X(50)",
    length: 50,
    from: 133,
    to: 182,
    type: "‫אלפאנומרי‬‏",
    he: "‫מען הלקוח/ספק - רחוב‬‏",
    field: 1407,
    dev_type: "string",
    devider: "",
    name: "custVendStreet",
    en: "Address of the customer/supplier - street",
    ar: "عنوان العميل/المورد - الشارع",
    ui: 1,
  },
  {
    notes: "‫ברשומת חשבון של לקוח או ספק‬‏",
    require_type: 2,
    tech: "X(10)",
    length: 10,
    from: 183,
    to: 192,
    type: "‫אלפאנומרי‬‏",
    he: "‫מען הלקוח/ספק - מספר בית‬‏",
    field: 1408,
    dev_type: "string",
    devider: "",
    name: "custVendHouse",
    en: "Address of the customer/supplier - house number",
    ar: "عنوان العميل/المورد – رقم المنزل",
    ui: 1,
  },
  {
    notes: "‫ברשומת חשבון של לקוח או ספק‬‏",
    require_type: 2,
    tech: "X(30)",
    length: 30,
    from: 193,
    to: 222,
    type: "‫אלפאנומרי‬‏",
    he: "‫מען הלקוח/ספק - עיר‬‏",
    field: 1409,
    dev_type: "string",
    devider: "",
    name: "custVendCity",
    en: "Address of the customer/supplier - City",
    ar: "عنوان العميل/المورد - المدينة",
    ui: 1,
  },
  {
    notes: "‫ברשומת חשבון של לקוח או ספק‬‏",
    require_type: 2,
    tech: "X(8)",
    length: 8,
    from: 223,
    to: 230,
    type: "‫אלפאנומרי‬‏",
    he: "‫מען הלקוח/ספק - מיקוד‬‏",
    field: 1410,
    dev_type: "string",
    devider: "",
    name: "custVendPostalCode",
    en: "Address of the customer/supplier - zip code",
    ar: "عنوان العميل/المورد - الرمز البريدي",
    ui: 1,
  },
  {
    notes: "‫ברשומת חשבון של לקוח או ספק‬‏",
    require_type: 2,
    tech: "X(30)",
    length: 30,
    from: 231,
    to: 260,
    type: "‫אלפאנומרי‬‏",
    he: "‫מען הלקוח/ספק - מדינה‬‏",
    field: 1411,
    dev_type: "string",
    devider: "",
    name: "custVendCountry",
    en: "From the customer/supplier - country",
    ar: "من العميل/المورد - البلد",
    ui: 1,
  },
  {
    notes: "‫ברשומת חשבון של לקוח או ספק‬‏",
    require_type: 2,
    tech: "X(2)",
    length: 2,
    from: 261,
    to: 262,
    type: "‫אלפאנומרי‬‏",
    he: "‫קוד מדינה‬‏",
    field: 1412,
    dev_type: "string",
    devider: "",
    name: "custVendCountryCode",
    en: "Country code",
    ar: "الرقم الدولي",
    ui: 1,
  },
  {
    notes: "",
    require_type: 2,
    tech: "X(15)",
    length: 15,
    from: 263,
    to: 277,
    type: "‫אלפאנומרי‬‏",
    he: "‫חשבון מרכז‬‏",
    field: 1413,
    dev_type: "string",
    devider: "",
    name: "centralAccount",
    en: "central account",
    ar: "الحساب المركزي",
    ui: 1,
  },
  {
    notes:
      '‫שדה מחושב לפי תאריך החיתוך של‬‏\r\n‫הנתונים ) "+" - יתרת חובה; "-" -‬‏\r\n‫יתרת זכות (‬‏',
    require_type: 1,
    tech: "X9(12)v99",
    length: 15,
    from: 278,
    to: 292,
    type: "‫אלפאנומרי‬‏",
    he: "‫יתרת החשבון בתחילת החתך‬‏",
    field: 1414,
    dev_type: "decimal",
    devider: 100,
    name: "starterAmount",
    en: "The balance of the account at the beginning of the cut",
    ar: "رصيد الحساب في بداية الخفض",
    ui: 1,
  },
  {
    notes: "‫סך כספי של תנועות החובה ללא‬‏\r\n‫יתרת פתיחה‬‏",
    require_type: 1,
    tech: "X9(12)v99",
    length: 15,
    from: 293,
    to: 307,
    type: "‫אלפאנומרי‬‏",
    he: '‫סה"כ חובה‬‏',
    field: 1415,
    dev_type: "decimal",
    devider: 100,
    name: "debit",
    en: "Total mandatory",
    ar: "إجمالي إلزامي",
    ui: 1,
  },
  {
    notes: "‫סך כספי של תנועות הזכות ללא‬‏\r\n‫יתרת פתיחה‬‏",
    require_type: 1,
    tech: "X9(12)v99",
    length: 15,
    from: 308,
    to: 322,
    type: "‫אלפאנומרי‬‏",
    he: '‫סה"כ זכות‬‏',
    field: 1416,
    dev_type: "decimal",
    devider: 100,
    name: "credit",
    en: "Total right",
    ar: "حق كامل",
    ui: 1,
  },
  {
    notes: "‫בהתאם לטופס 6111; חובה כאשר‬‏\r\n‫הנישום חייב בדיווח כזה‬‏",
    require_type: 2,
    tech: "9(4)",
    length: 4,
    from: 323,
    to: 326,
    type: "‫נומרי‬‏",
    he: "‫קוד בסיווג החשבונאי‬‏",
    field: 1417,
    dev_type: "int",
    devider: "",
    name: "accountingClassificationCode",
    en: "Code in the accounting classification",
    ar: "الرمز في التصنيف المحاسبي",
    ui: 1,
  },
  {
    notes: "‫שדה מבוטל!‬‏\r\n‫ציון מספר עוסק מורשה של הספק‬‏",
    require_type: 1,
    tech: "X(0)",
    length: 0,
    from: 327,
    to: 327,
    type: "‫אלפאנומרי‬‏",
    he: "‫שטח לנתונים עתידיים‬‏",
    field: 1418,
    dev_type: "string",
    devider: "",
    name: "futureUse1",
    en: "Space for future data",
    ar: "مساحة للبيانات المستقبلية",
    ui: 0,
  },
  {
    notes: "‫חובה אם הנישום מנהל הנהלת‬‏\r\n‫חשבונות כפולה )קוד 2 בשדה 1013‬‏",
    require_type: 3,
    tech: "9(9)",
    length: 9,
    from: 327,
    to: 335,
    type: "‫נומרי‬‏",
    he: "‫מספר עוסק של ספק/לקוח‬‏",
    field: 1419,
    dev_type: "int",
    devider: "",
    name: "custVendAuthorizedDealer",
    en: "Supplier/customer dealer number",
    ar: "رقم تاجر المورد/العميل",
    ui: 1,
  },
  {
    notes: "‫שדה מבוטל!‬‏",
    require_type: 1,
    tech: "X(0)",
    length: 0,
    from: 336,
    to: 336,
    type: "‫אלפאנומרי‬‏",
    he: "‫שטח לנתונים עתידיים‬‏",
    field: 1420,
    dev_type: "string",
    devider: "",
    name: "futureUse2",
    en: "Space for future data",
    ar: "مساحة للبيانات المستقبلية",
    ui: 0,
  },
  {
    notes:
      "‫מספר הסניף/ענף כפי שמופיע ברישומי‬‏\r\n‫בית העסק; חובה כאשר ערכו של שדה‬‏\r\n‫1034 הוא 1 , ראה הבהרה 3 בנספח‬‏\r\n‫הבהרות‬‏",
    require_type: 3,
    tech: "X(7)",
    length: 7,
    from: 336,
    to: 342,
    type: "‫אלפאנומרי‬‏",
    he: "‫מזהה סניף/ענף‬‏",
    field: 1421,
    dev_type: "string",
    devider: "",
    name: "brachId",
    en: "Branch/Branch ID",
    ar: "معرف الفرع/الفرع",
    ui: 1,
  },
  {
    notes: "",
    require_type: 2,
    tech: "X9(12)v99",
    length: 15,
    from: 343,
    to: 357,
    type: "‫אלפאנומרי‬‏",
    he: '‫יתרת חשבון בתחילת חתך‬‏ במט"ח‬‏',
    field: 1422,
    dev_type: "decimal",
    devider: 100,
    name: "starterAmountForeignCurrency",
    en: "Account balance at the beginning of a cut in foreign currency",
    ar: "رصيد الحساب عند بداية انخفاض العملة الأجنبية",
    ui: 1,
  },
  {
    notes:
      "‫בהתאם לטבלת קוד מטבע בנספח‬‏\r\n‫מספר 2. מתייחס לסכום המופיע‬‏\r\n‫בשדה 1422‬‏",
    require_type: 2,
    tech: "X(3)",
    length: 3,
    from: 358,
    to: 360,
    type: "‫אלפאנומרי‬‏",
    he: '‫קוד מטבע יתרת החשבון‬‏ בתחילת חתך במט"ח‬‏',
    field: 1423,
    dev_type: "string",
    devider: "",
    name: "starterAmountForeignCurrencyCode",
    en: "The currency code of the account balance at the beginning of a cut in foreign currency",
    ar: "رمز العملة لرصيد الحساب في بداية خفض العملة الأجنبية",
    ui: 1,
  },
  {
    notes: "",
    require_type: 1,
    tech: "X(16)",
    length: 16,
    from: 361,
    to: 376,
    type: "‫אלפאנומרי‬‏",
    he: "‫שטח לנתונים עתידיים‬‏",
    field: 1424,
    dev_type: "string",
    devider: "",
    name: "futureUse3",
    en: "Space for future data",
    ar: "مساحة للبيانات المستقبلية",
    ui: 0,
  },
];

export const BKMVDATA_FileStockStructure = [
  {
    notes: "M100",
    require_type: 1,
    tech: "X(4)",
    length: 4,
    from: 1,
    to: 4,
    type: "‫אלפאנומרי‬‏",
    he: "‫קוד רשומה‬‏",
    field: 1450,
    dev_type: "string",
    devider: "",
    name: "code",
    en: "Record code",
    ar: "رمز السجل",
    ui: 1,
  },
  {
    notes: "‫מס רץ של הרשומה בקובץ‬‏",
    require_type: 1,
    tech: "9(9)",
    length: 9,
    from: 5,
    to: 13,
    type: "‫נומרי‬‏",
    he: "‫מס רשומה בקובץ‬‏",
    field: 1451,
    dev_type: "int",
    devider: "",
    name: "recordId",
    en: "Tax is recorded in the file",
    ar: "رقم السجل في الملف",
    ui: 1,
  },
  {
    notes: "‫מספר עוסק מורשה של בית העסק‬‏",
    require_type: 1,
    tech: "9(9)",
    length: 9,
    from: 14,
    to: 22,
    type: "‫נומרי‬‏",
    he: "‫מס עוסק מורשה‬‏",
    field: 1452,
    dev_type: "int",
    devider: "",
    name: "authorizedDealer",
    en: "Tax authorized dealer",
    ar: "المشتغل المرخص",
    ui: 1,
  },
  {
    notes: "",
    require_type: 2,
    tech: "X(20)",
    length: 20,
    from: 23,
    to: 42,
    type: "‫אלפאנומרי‬‏",
    he: '‫מק"ט פריט אוניברסלי‬‏',
    field: 1453,
    dev_type: "string",
    devider: "",
    name: "upcBarcode",
    en: "Universal item SKU",
    ar: "رمز المنتج العالمي",
    ui: 1,
  },
  {
    notes: "",
    require_type: 2,
    tech: "X(20)",
    length: 20,
    from: 43,
    to: 62,
    type: "‫אלפאנומרי‬‏",
    he: '‫מק"ט הספק/יצרן במסמכי רכש ',
    field: 1454,
    dev_type: "string",
    devider: "",
    name: "vendorInternalSKU",
    en: "Vendor/manufacturer SKU in procurement documents",
    ar: "رمز المنتج لدى المورد",
    ui: 1,
  },
  {
    notes: "‫ערך השדה חד חד ערכי‬‏",
    require_type: 1,
    tech: "X(20)",
    length: 20,
    from: 63,
    to: 82,
    type: "‫אלפאנומרי‬‏",
    he: '‫מק"ט פנימי‬‏',
    field: 1455,
    dev_type: "string",
    devider: "",
    name: "InternalSKU",
    en: "Internal SKU",
    ar: "الرمز الداخلي",
    ui: 1,
  },
  {
    notes: "",
    require_type: 1,
    tech: "X(50)",
    length: 50,
    from: 83,
    to: 132,
    type: "‫אלפאנומרי‬‏",
    he: "‫שם פריט‬‏",
    field: 1456,
    dev_type: "string",
    devider: "",
    name: "productName",
    en: "item name",
    ar: "اسم المنتج",
    ui: 1,
  },
  {
    notes: "",
    require_type: 2,
    tech: "X(10)",
    length: 10,
    from: 133,
    to: 142,
    type: "‫אלפאנומרי‬‏",
    he: "‫קוד מיון‬‏",
    field: 1457,
    dev_type: "string",
    devider: "",
    name: "sortCode",
    en: "Sort code",
    ar: "الكود البنكى",
    ui: 1,
  },
  {
    notes: "",
    require_type: 2,
    tech: "X(30)",
    length: 30,
    from: 143,
    to: 172,
    type: "‫אלפאנומרי‬‏",
    he: "‫תיאור קוד מיון‬‏",
    field: 1458,
    dev_type: "string",
    devider: "",
    name: "sortCodeDescription",
    en: "Sort code description",
    ar: "وصف رمز الفرز",
    ui: 1,
  },
  {
    notes:
      '‫כשמדובר ביחידה עם משמעות‬‏\r\n‫)לדוגמא : ליטר, גרם, שעת עבודה(‬‏\r\n‫ירשם תיאור היחידה, אחרת תרשם‬‏\r\n‫המילה "יחידה"‬‏',
    require_type: 1,
    tech: "X(20)",
    length: 20,
    from: 173,
    to: 192,
    type: "‫אלפאנומרי‬‏",
    he: "‫תיאור יחידת מידה‬‏",
    field: 1459,
    dev_type: "string",
    devider: "",
    name: "unit",
    en: "Description of unit of measure",
    ar: "وصف وحدة القياس",
    ui: 1,
  },
  {
    notes: "‫בכל המחסנים‬‏",
    require_type: 1,
    tech: "X9(9)v99",
    length: 12,
    from: 193,
    to: 204,
    type: "‫אלפאנומרי‬‏",
    he: "‫יתרת הפריט לתחילת‬‏ החתך‬‏",
    field: 1460,
    dev_type: "decimal",
    devider: 100,
    name: "starterQuantity",
    en: "The balance of the item for the beginning of the cut",
    ar: "رصيد الصنف لبداية الخفض",
    ui: 1,
  },
  {
    notes: "‫סך הכניסות בטווח , ללא יתרת פתיחה‬‏",
    require_type: 1,
    tech: "X9(9)v99",
    length: 12,
    from: 205,
    to: 216,
    type: "‫אלפאנומרי‬‏",
    he: "‫סך הכל כניסות‬‏",
    field: 1461,
    dev_type: "decimal",
    devider: 100,
    name: "totalIn",
    en: "Total entries",
    ar: "إجمالي الإدخالات",
    ui: 1,
  },
  {
    notes: "‫סך היציאות בטווח , ללא יתרת פתיחה‬‏",
    require_type: 1,
    tech: "X9(9)v99",
    length: 12,
    from: 217,
    to: 228,
    type: "‫אלפאנומרי‬‏",
    he: "‫סך הכל יציאות‬‏",
    field: 1462,
    dev_type: "decimal",
    devider: 100,
    name: "totalOut",
    en: "Total exits",
    ar: "إجمالي المخارج",
    ui: 1,
  },
  {
    notes: '‫סכום בש "ח, ראה הבהרה 9 בנספח‬‏\r\n‫הבהרות‬‏',
    require_type: 2,
    tech: "9(8)v99",
    length: 10,
    from: 229,
    to: 238,
    type: "‫נומרי‬‏",
    he: "‫מחיר עלות במלאי לסוף‬‏ תקופת החתך מחוץ‬‏ למחסני ערובה‬‏",
    field: 1463,
    dev_type: "float",
    devider: 100,
    name: "endCostOutside",
    en: "Cost price in stock at the end of the cut-off period outside of guarantee warehouses",
    ar: "سعر التكلفة في المخزون في نهاية فترة التخفيض خارج مستودعات الضمان",
    ui: 1,
  },
  {
    notes: '‫סכום בש "ח, ראה הבהרה 10 בנספח‬‏\r\n‫הבהרות‬‏',
    require_type: 2,
    tech: "9(8)v99",
    length: 10,
    from: 239,
    to: 248,
    type: "‫נומרי‬‏",
    he: "‫מחיר עלות במלאי לסוף‬‏ תקופת החתך במחסני‬‏ ערובה‬‏",
    field: 1464,
    dev_type: "float",
    devider: 100,
    name: "endCostInside",
    en: "Cost price in stock for the end of the cut-off period in bonded warehouses",
    ar: "سعر التكلفة في المخزون لنهاية فترة التوقف في المستودعات الجمركية",
    ui: 1,
  },
  {
    notes: "",
    require_type: 1,
    tech: "X(50)",
    length: 50,
    from: 249,
    to: 298,
    type: "‫אלפאנומרי‬‏",
    he: "‫שטח לנתונים עתידיים‬‏",
    field: 1465,
    dev_type: "string",
    devider: "",
    name: "futureUse",
    en: "Space for future data",
    ar: "مساحة للبيانات المستقبلية",
    ui: 0,
  },
];

export const DocumnetTypes = [
  {
    hebrewDocName: "הזמנה",
    openFormatDocNum: 100,
    arabicDocName: "طلب",
    englishDocName: "Purchase Order",
  },
  {
    hebrewDocName: "תעודת משלוח",
    openFormatDocNum: 200,
    arabicDocName: "شهادة الشحن",
    englishDocName: "Delivery Note",
  },
  {
    hebrewDocName: "תעודת משלוח סוכן",
    openFormatDocNum: 205,
    arabicDocName: "إشعار شحن الوكيل",
    englishDocName: "Agent Delivery Note",
  },
  {
    hebrewDocName: "תעודת החזרה",
    openFormatDocNum: 210,
    arabicDocName: "إشعار إرجاع",
    englishDocName: "Return Note",
  },
  {
    hebrewDocName: "חשבונית/חשבונית עסקה",
    openFormatDocNum: 300,
    arabicDocName: "فاتورة/فاتورة تجارية",
    englishDocName: "Invoice/Business Invoice",
  },
  {
    hebrewDocName: "חשבונית מס",
    openFormatDocNum: 305,
    arabicDocName: "فاتورة ضريبية",
    englishDocName: "Tax Invoice",
  },
  {
    hebrewDocName: "חשבונית ריכוז",
    openFormatDocNum: 310,
    arabicDocName: "فاتورة تجميع",
    englishDocName: "Consolidated Invoice",
  },
  {
    hebrewDocName: "חשבונית מס קבלה",
    openFormatDocNum: 320,
    arabicDocName: "إيصال فاتورة ضريبية",
    englishDocName: "Tax Invoice Receipt",
  },
  {
    hebrewDocName: "חשבונית מס זיכוי",
    openFormatDocNum: 330,
    arabicDocName: "إشعار رصيد فاتورة ضريبية",
    englishDocName: "Tax Invoice Credit Note",
  },
  {
    hebrewDocName: "חשבונית שיריון",
    openFormatDocNum: 340,
    arabicDocName: "فاتورة تسوية",
    englishDocName: "Settlement Invoice",
  },
  {
    hebrewDocName: "חשבונית סוכן",
    openFormatDocNum: 345,
    arabicDocName: "فاتورة الوكيل",
    englishDocName: "Agent Invoice",
  },
  {
    hebrewDocName: "קבלה",
    openFormatDocNum: 400,
    arabicDocName: "إيصال",
    englishDocName: "Receipt",
  },
  {
    hebrewDocName: "קבלה על תרומות",
    openFormatDocNum: 405,
    arabicDocName: "إيصال التبرعات",
    englishDocName: "Donation Receipt",
  },
  {
    hebrewDocName: "יציאה מקופה",
    openFormatDocNum: 410,
    arabicDocName: "صرف نقداً",
    englishDocName: "Cash Withdrawal",
  },
  {
    hebrewDocName: "הפקדת בנק",
    openFormatDocNum: 420,
    arabicDocName: "إيداع بنكي",
    englishDocName: "Bank Deposit",
  },
  {
    hebrewDocName: "הזמנת רכש",
    openFormatDocNum: 500,
    arabicDocName: "طلبية شراء",
    englishDocName: "Purchase Requisition",
  },
  {
    hebrewDocName: "תעודת משלוח רכש",
    openFormatDocNum: 600,
    arabicDocName: "إشعار شحن للشراء",
    englishDocName: "Purchase Delivery Note",
  },
  {
    hebrewDocName: "החזרת רכש",
    openFormatDocNum: 610,
    arabicDocName: "إشعار إرجاع للشراء",
    englishDocName: "Purchase Return",
  },
  {
    hebrewDocName: "חשבונית מס רכש",
    openFormatDocNum: 700,
    arabicDocName: "فاتورة ضريبية للشراء",
    englishDocName: "Purchase Tax Invoice",
  },
  {
    hebrewDocName: "זיכוי רכש",
    openFormatDocNum: 710,
    arabicDocName: "إشعار رصيد للشراء",
    englishDocName: "Purchase Credit Note",
  },
  {
    hebrewDocName: "יתרת פתיחה",
    openFormatDocNum: 800,
    arabicDocName: "رصيد افتتاحي",
    englishDocName: "Opening Balance",
  },
  {
    hebrewDocName: "כניסה כללית למלאי",
    openFormatDocNum: 810,
    arabicDocName: "إيصال القيود العامة للمخزون",
    englishDocName: "General Stock Entry",
  },
  {
    hebrewDocName: "יציאה כללית מהמלאי",
    openFormatDocNum: 820,
    arabicDocName: "إيصال القيود العامة للمخزون",
    englishDocName: "General Stock Exit",
  },
  {
    hebrewDocName: "העברה בין מחסנים",
    openFormatDocNum: 830,
    arabicDocName: "تحويل بين المستودعات",
    englishDocName: "Warehouse Transfer",
  },
  {
    hebrewDocName: "עדכון בעקבות ספירה",
    openFormatDocNum: 840,
    arabicDocName: "تحديث بناءً على العد",
    englishDocName: "Count Update",
  },
  {
    hebrewDocName: "דוח ייצור כניסה",
    openFormatDocNum: 900,
    arabicDocName: "تقرير دخول الإنتاج",
    englishDocName: "Production Entry Report",
  },
  {
    hebrewDocName: "דוח ייצור יציאה",
    openFormatDocNum: 910,
    arabicDocName: "تقرير خروج الإنتاج",
    englishDocName: "Production Exit Report",
  },
];
