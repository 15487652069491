import React, { useEffect, useState } from "react";
import { Avatar_male, Avatar_female,} from "../../../../../Routes/ImagePath";
import { Link, useNavigate } from "react-router-dom";
import { Table } from "antd";
import EmployeeListFilter from "../../Employees/EmployeeListFilter";
import Breadcrumbs from "../../../../../components/Breadcrumbs";
import AllEmployeeAddPopup from "../../../../../components/modelpopup/AllEmployeeAddPopup";
import DeleteModal from "../../../../../components/modelpopup/DeleteModal";
import SearchBox from "../../../../../components/SearchBox";
import { render } from "@testing-library/react";
import { getDate } from "../../../../../utils/openformat/numbers";
import { useSelector ,useDispatch} from "react-redux";
import { multiArray126ToAttendaceObject, multiArrayToObject126 } from "../../../../../utils/s126/arrays126";
import { currentEmployee } from "../../../../../salary126";
import Header10 from "../../records/record10Header/Header10";
import { useTranslation } from "react-i18next";

const EmployeesList106 = () => {
  const dispatch=useDispatch()
  const navigate=useNavigate()
  const salary = useSelector((state) => state.salary);
  const type20=salary.type20
  const {t} = useTranslation();
  const [data,setData]=useState([])
  const [allEmployees,setAllEmployees]=useState([])

  useEffect(()=>{
    if(type20.length>0){
      const dataList=multiArray126ToAttendaceObject(type20)
      setData(dataList)
      setAllEmployees(dataList)
    
    }
  },[type20])

  const handleSearch=(text)=>{
    if(!text) {setData(allEmployees)

      return
    }
    const list=allEmployees.filter(item=>item.firstName.includes(text) || item.lastName.includes(text) ||item.idNumber.includes(text))
    setData(list)
  }
  
  const handleEmployeeProfile=(emp)=>{
    dispatch(currentEmployee(emp))
    navigate("/126/employee-profile106")
  }
  const columns = [
    {
      title: "#",
      dataIndex: "employee_id",
      sorter: (a, b) => a.employee_id.length - b.employee_id.length,
      render:(text,record,index)=>(<span >{index+1}</span>)
    },
    {
      title: "الاسم",
      dataIndex: "firstName",
      render: (text, record) => (
        <span className="table-avatar" onClick={()=>handleEmployeeProfile(record)}>
          <Link to="#" className="avatar">
            <img alt="" src={record.gender==1? Avatar_male: Avatar_female} />
          </Link>
          <Link to={`#`}>
            {text} 
          </Link>
        </span>
      ),
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "العائلة",
      dataIndex: "lastName",
      sorter: (a, b) => a.employee_id.length - b.employee_id.length,
    },

    {
      title: "رقم الهوية",
      dataIndex: "idNumber",
      sorter: (a, b) => a.email.length - b.email.length,
    },

    {
      title: "تاريخ الميلاد",
      dataIndex: "dob",
      sorter: (a, b) => a.mobile.length - b.mobile.length,
      render:(text)=>(<span>{getDate(text)}</span>)
    },

    {
      title: "العنوان",
      dataIndex: "address",
      sorter: (a, b) => a.joindate.length - b.joindate.length,
    },
    // {
    //   title: "Role",
    //   sorter: true,
    //   render: () => (
    //     <div className="dropdown">
    //       <Link
    //         to="#"
    //         className="btn btn-white btn-sm btn-rounded dropdown-toggle"
    //         data-bs-toggle="dropdown"
    //         aria-expanded="false"
    //       >
    //         Web Developer{" "}
    //       </Link>
    //       <div className="dropdown-menu">
    //         <Link className="dropdown-item" to="#">
    //           Software Engineer
    //         </Link>
    //         <Link className="dropdown-item" to="#">
    //           Software Tester
    //         </Link>
    //         <Link className="dropdown-item" to="#">
    //           Frontend Developer
    //         </Link>
    //         <Link className="dropdown-item" to="#">
    //           UI/UX Developer
    //         </Link>
    //       </div>
    //     </div>
    //   ),
    // },
    // {
    //   title: "Action",
    //   sorter: true,
    //   render: () => (
    //     <div className="dropdown dropdown-action text-end">
    //       <Link
    //         to="#"
    //         className="action-icon dropdown-toggle"
    //         data-bs-toggle="dropdown"
    //         aria-expanded="false"
    //       >
    //         <i className="material-icons">more_vert</i>
    //       </Link>
    //       <div className="dropdown-menu dropdown-menu-right">
    //         <Link
    //           className="dropdown-item"
    //           to="#"
    //           data-bs-toggle="modal"
    //           data-bs-target="#edit_employee"
    //         >
    //           <i className="fa fa-pencil m-r-5" /> Edit
    //         </Link>
    //         <Link
    //           className="dropdown-item"
    //           to="#"
    //           data-bs-toggle="modal"
    //           data-bs-target="#delete_employee"
    //         >
    //           <i className="fa fa-trash m-r-5" /> Delete
    //         </Link>
    //       </div>
    //     </div>
    //   ),
    // },
  ];
  return (
    <div>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs
            maintitle={t("employeesList106")}
            title="Dashboard"
            subtitle="Employee"
            modal="#add_employee"
            name="Add Employee"
            Linkname="/126/employees"
            Linkname1="/126/employees-list"
          />
          {/* /Page Header */}
          <Header10 />
          <EmployeeListFilter  search={handleSearch}/>
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <SearchBox />
                <Table
                  className="table-striped"
                  columns={columns}
                  dataSource={data}
                  rowKey={(record) => record.id}
                />
              </div>
            </div>
          </div>
        </div>
        {/* /Page Content */}
        <AllEmployeeAddPopup />
        <DeleteModal Name="Delete Employee" />
      </div>
    </div>
  );
};

export default EmployeesList106;
