import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { formatNumbers } from "../../../../utils/openformat/numbers";
import { ListItemWithCode } from "../Employees/ProfileContent";
import { useTranslation } from "react-i18next";
const Record30and40 = () => {
  const salary = useSelector((state) => state.salary);
  const type30= (salary.type30[0])
  const type40 = (salary.type40[0])
  const type30data = type30.filter(item => item.ar !=="أصفار"&& item.ar!="حشو"&& item.ar!="حشو فارغ")
  const type40data = type40.filter(item => item.ar !=="أصفار"&& item.ar!="حشو"&& item.ar!="حشو فارغ")
  // console.log("type30:", type30data)
  const keywords = ["رقم", "السنة", "عدد","نوع","ثابت","معرف","اكتب","البريد"];

// Map through the items array and apply formatNumbers if conditions are met
const applyFormatToNumbers = (items, keywords) => {
  return items.map(item => {
    if ( !keywords.some(keyword => item.ar.includes(keyword))) {
      return {
        ...item,
        value: formatNumbers(item.value)
      };
    } else {
      return item;
    }
  });
};
// Apply format to items
const type40Formated = applyFormatToNumbers(type40data, keywords);
const type30Formated = applyFormatToNumbers(type30data, keywords);
  const {  i18n } = useTranslation();
 const currentLanguage = i18n.language;
  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="سجل 30 & 40 "
            title="سجلات"
            subtitle="30-40"
          />
          <div className="row">
            <div className="col-md-6 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body">
                  <h3 className="card-title ">
                    Record 30  {" "}
                    <Link
                      to="#"
                      className="edit-icon"
                      data-bs-toggle="modal"
                      data-bs-target="#personal_info_modal"
                    >
                      <i className="fa fa-pencil" />
                    </Link>
                  </h3>
                  <ul className="personal-info border-t pt-3">
                    {type30Formated&& type30Formated.map((item, index) => (
                      <ListItemWithCode
                        id={item.key}
                        key={index}
                        title={item[currentLanguage]}
                        text={ item.value}
                        code={item.code}
                      />
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body">
                  <h3 className="card-title">
                    Record 40{" "}
                    <Link
                      to="#"
                      className="edit-icon"
                      data-bs-toggle="modal"
                      data-bs-target="#emergency_contact_modal"
                    >
                      <i className="fa fa-pencil" />
                    </Link>
                  </h3>
                  <ul className="personal-info  border-t pt-3">
                    {type40Formated&&type40Formated.map((item, index) => (
                      <ListItemWithCode
                      id={item.key}
                      key={index}
                      title={item[currentLanguage]}
                      text={item.value}
                      code={item.code}
                      />
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Record30and40;
