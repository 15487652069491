import React, { Suspense, useEffect, useState } from "react";
import Breadcrumbs from "../../../components/Breadcrumbs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { Link } from "react-router-dom";
import InvoiceTable from "./invoiceTable";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import { useSelector } from "react-redux";
import { DocumnetTypes } from "../../../utils/openformat/filesStructures";
import OpenFormatHeader from "../OpenForamt/OpenFormatHeader";
// تمت طباعة هذا المستند باستخدام نظام  بـ FinTrack وهو ليس مستندًا أصليًا

const AhidInvoicesReports = () => {
  const [selectedDate, setSelectedDate] = useState();
  const [selectedDateTwo, setSelectedDateTwo] = useState();
  const [options, setOptions] = useState([]);
  const [title, setTitle] = useState("المستندات");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [isFocused, setIsFocused] = useState(false);
  const [dateTwo, setdateTwo] = useState(false);

  const documents =
    useSelector((state) => state.ahid).bkmvFile?.Headers?.data || [];

  useEffect(() => {
    setOptions(generateDocumentTypes());
    hahdleSelectOption();
  }, []);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleFocustwo = () => {
    setdateTwo(true);
  };
  const handleBlurtwo = () => {
    setdateTwo(false);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleDateChangeTwo = (date) => {
    setSelectedDateTwo(date);
  };

  const generateDocumentTypes = () => {
    const list = [];
    DocumnetTypes.forEach((item) =>
      list.push({
        value: item.openFormatDocNum,
        label: `${item.openFormatDocNum} - ${item.arabicDocName}`,
      })
    );
    return list;
  };

  const hahdleSelectOption = (option) => {
    setLoading(true);
    let arr;
    if (!option?.value) {
      arr = addIndexToArray(documents);
      setTitle("المستندات");
    } else {
      arr = addIndexToArray(
        documents.filter((item) => item.documentType == option.value)
      );
      setTitle(
        DocumnetTypes.filter((item) => item.openFormatDocNum == option.value)[0]
          .arabicDocName
      );
    }

    setData(arr);
    setLoading(false);
  };

  const addIndexToArray = (arr) => {
    const list = [];
    arr.forEach((item, index) => list.push({ ...item, num: index + 1 }));
    return list;
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle={title}
            title="Dashboard"
            subtitle={title}
            modal="#"
            name="Create Invoice"
            link="/create-invoice"
          />
          <OpenFormatHeader />
          <div className="row filter-row">
            <div className="col-sm-6 col-md-3">
              <div
                className={`input-block mb-3 form-focus ${
                  isFocused ? "focused" : ""
                }`}>
                <div className="cal-icon focused ">
                  <DatePicker
                    className="form-control floating datetimepicker"
                    selected={selectedDate}
                    onChange={handleDateChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />
                </div>
                <label className="focus-label">From</label>
              </div>
            </div>
            <div className="col-sm-6 col-md-3">
              <div
                className={`input-block mb-3 form-focus ${
                  dateTwo ? "focused" : ""
                }`}>
                <div className="cal-icon">
                  <DatePicker
                    className="form-control floating datetimepicker "
                    selected={selectedDateTwo}
                    onChange={handleDateChangeTwo}
                    onFocus={handleFocustwo}
                    onBlur={handleBlurtwo}
                  />
                </div>
                <label className="focus-label">To</label>
              </div>
            </div>
            <div className="col-sm-6 col-md-3">
              <div className="input-block mb-3 form-focus select-focus">
                <Select
                  placeholder="اختر نوع السند"
                  onChange={hahdleSelectOption}
                  options={options}
                  styles={customStyles}
                />
                <label className="focus-label">Status</label>
              </div>
            </div>
            <div className="col-sm-6 col-md-3">
              <Link to="#" className="btn btn-success w-100">
                Search
              </Link>
            </div>
          </div>

          {loading ? (
            <div
              className="spinner-border avatar-md text-primary m-2"
              role="status"
            />
          ) : (
            <InvoiceTable data={data} />
          )}
        </div>
      </div>
      <DeleteModal Name="Delete Invoice" />
    </>
  );
};

export default AhidInvoicesReports;
