
import React, { Component, useState } from 'react';
import Data from './output'
import { MassTax } from '../../../../Routes/ImagePath';
import Table22 from './Tables/Table22C';
import Table23 from './Tables/Table23C';
import Table24 from './Tables/Table24';
import { formatMoney } from "accounting-js";
// import Attendance from '../attendance';
export default function TaxData() {
	const [year, setYear] = useState(0)
	//
	let creditPointValue = Data.taxData[year].creditPoint
	//
	let studyFundEmployee = Math.round(Data.taxData[year].studyFund.employee.ceiling / 12)
	let studyFundSelfEmployee = Math.round(Data.taxData[year].studyFund.selfEmployed.ceiling / 12)
	//
	let houseRent = Data.taxData[year].massHousRent
	//
	let blindIncome = Data.taxData[year].massBlindIncome
	//
	let vatExempt = Data.taxData[year].massVatExempt

	return (

		<div className="page-wrapper">
		
			{/* Page Content */}
			<div className="content container-fluid">
				{/* Page Header */}
				<div className="page-header">
					<div className="row">
						<div className="col-sm-12">
							<h3 className="page-title"> حاسبات الضريبة       </h3>
							<ul className="breadcrumb">
								<li className="breadcrumb-item"><a href="/">الرئيسية </a></li>
							</ul>
						</div>
					</div>
				</div>
				{/* Account Logo */}
				<div className="account-logo center">
					{/* <a href="/"><img src={MassTax} alt="Mass Tax" /></a> */}
					<img src={MassTax} alt='MassPortal Taxes ' />
				</div>
				<div className="col-lg-10 mx-auto">

					<div className="col-md-12 ">
						<div className="card card-table mb-0">
							<div className="card-header">
								<h4 className="card-title mb-0 privacy">   معطيات لسنة
									<select

										onChange={(e) => setYear(e.target.value)}
										className="btn vit-select year-select "
									>
										{Data.taxData.map((year, index) => (
											<option className="text-dark year-text-dark" value={index} key={index}>
												{year.taxYear}
											</option>
										))}
									</select></h4>

							</div>
							<div className="card-body">
								<div className="table-responsive">
									<table className="table table-center mb-0">
										<thead className='text-center'>
											<tr >
												<th className='text-center table-tr-underline'>قيمة نقطة التزكية</th>
												<th className='text-center table-tr-underline'> حد أعلى راتب استكمال </th>
												<th className='text-center table-tr-underline'> حد أعلى راتب استكمال </th>
												<th className='text-center table-tr-underline'>دخل إيراد أجار بيتي </th>
												<th className='text-center table-tr-underline'>دورة صفقات    -  <span className='privacy'> سنوي </span> </th>
												<th className='text-center table-tr-underline'>حد أعلى دخل معفي  -  <span className='privacy'> شهري </span> </th>

											</tr>
											<tr>
												<th> سنة -  <span className='privacy'> {2023 - year} </span>    </th>
												<th> مهني موظف - <span className='privacy'> ش </span></th>
												<th> مهني مستقل - <span className='privacy'> ش </span></th>
												<th>معفى</th>
												<th>ملف معفي  <span className='privacy'> ض.ق.م </span></th>
												<th>أعمى<span className='privacy'> / </span> مقعد</th>

											</tr>
										</thead>
										<tbody>
											<tr className='text-center  privacy tr-givens'>
												<td className=''>{formatMoney((creditPointValue),{ symbol: "₪",  format: " %v %s" })}</td>
												<td>{studyFundEmployee}{formatMoney((creditPointValue),{ symbol: "₪",  format: " %v %s" })}</td>
												<td>{formatMoney((studyFundSelfEmployee),{ symbol: "₪",  format: " %v %s" })}</td>
												<td>{formatMoney((houseRent),{ symbol: "₪",  format: " %v %s" })}</td>
												<td>{formatMoney((vatExempt),{ symbol: "₪",  format: " %v %s" })}</td>
												<td>{formatMoney((blindIncome),{ symbol: "₪",  format: " %v %s" })}</td>
												{/* <td><a className="btn btn-primary btn-sm" href="">Change Plan</a></td> */}
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-12 ">
					<div className="row pt-4">
          <div className="col-sm-12 col-md-6">
            <Table24 />
          </div>
          <div className="col-sm-12 col-md-6">
            <Table23 />
          </div>
          <div className="col-dm-12 col-md-6 ">
            <Table22 />

          </div>
        </div>
					</div>
				</div>
			</div>
			
			
			{/* /Page Content */}
		</div>

	);
}

