import React from 'react'
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";

const Header6111 = () => {
  const data = useSelector((state) => state.balance).data6111;
  console.log("611Data: ", data)
  const { t } = useTranslation();
// console.log("openFormatdata:", ahid)
  return (

      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-sm-4 m-b-20">
                  {/* <img src={Applogo} className="inv-logo" alt="Logo" /> */}
                  <ul className="list-unstyled mb-0">
                    <li>   {data.companyName}</li>
                   

                   
                  </ul>
                </div>
                <div className="col-sm-4 m-b-20">
                  <ul className="list-unstyled mb-0 text-center">
                  <li className='text-primary'> <span className='text-white'> السنة : </span>  {data.taxYear}</li>

                  
                  </ul>
                </div>
                <div className="col-sm-4 m-b-20">
                  <ul className="list-unstyled mb-0 text-left">
                  <li className='text-primary'><span className='text-white'> ملف الاقتطاعات : </span>   {data.deductCaseNum}</li>
                  <li className='text-primary'> <span className='text-white'>  ملف الشركة:  </span>  {data.idNum}</li>
                  </ul>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
  )
}

export default Header6111
