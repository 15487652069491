
import React, { useState, useEffect } from 'react'
import Breadcrumbs from '../../../../components/Breadcrumbs';
import { balancesheetAccounts } from "./balancesheetAccounts";
import { document6111ByFinalReportsCode1 } from '../Document6111';
import TbBranchesBalaceSheet from '../../../../components/Tables/TbBranchesBalaceSheet';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Header6111 from '../Header611';
export default function DetailedIndex6111() {
    const { t } = useTranslation();
    const data = useSelector((state) => state.balance).data6111;
    const data2 = useSelector((state) => state.balance).data6111PreviousYear;
    const [hideZeros, setHideZeros] = useState(false)



    let headers = ["#", "رقم الفئة", "اسم الفئة", "المبلغ"]

    const getTitle = (category) => {
        switch (category) {
            case 1: return "موجودات متداولة";
            case 2: return "مطلوبات";
            case 3: return "موجودات ثابتة";
            case 4: return "رأس المال";
            case 5: return "بيان أرباح وخسائر";
            default: return " "
        }
    }

    const getAccountName = (number) => {
        const result = balancesheetAccounts.find((obj) => obj.group_id2 === number)
        return result ? result.group_name_AR : " "
    }

    return (
        <div className="page-wrapper ">

            {/* Page Content */}
            <div className="content container-fluid">
                <Breadcrumbs
                    maintitle={t("detailedIndex")}
                    title="الرئيسية"
                    subtitle={t("detailedIndex")}
                     modal="#warning-alert-modal"
                />
                <Header6111 />
                <div className=" card">
                    <div className='card-body'>
                        <div className='row'>
                            <div className="col-md-2" style={{ display: 'flex', gap: 5 }}>
                                <input type='checkbox' checked={hideZeros} onChange={(e) => setHideZeros(e.target.checked)} />
                                <h4>إخفاء أرصدة صفرية</h4>
                            </div>
                            <div className="col-md-2">
                                <h4>الميزانية العمومية لشركة</h4>
                            </div>
                            <div className="col-md-3">
                                <h4 className="text-primary">
                                    {data.companyName}
                                </h4>
                                <h4 className="text-primary">
                                    {data2.companyName}
                                </h4>
                            </div>
                            <div className="col-md-2">
                                <div className=" ">
                                    <h4>للسنة المالية المنتهية ب </h4>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <h4 className="text-primary">
                                    31-12-{data.taxYear}
                                </h4>
                                <h4 className="text-primary">
                                    31-12-{data2.taxYear}
                                </h4>

                            </div>
                        </div>
                    </div>

                </div>
                {/* <div className="row ">
                <hr className=" border-prim mb-4" />
                    
                    {
                        document6111ByFinalReportsCode1.map((obj, i) => (
                           <div
                             key={i}
                              className={obj.finalReportsCode2 == 5 ? "col-md-12 " : "col-md-6 "}>
                                <TbBranchesBalaceSheet data={obj.data} title={obj.finalReportsCode2 + " " + getAccountName(obj.finalReportsCode2)} headers={headers} hideZeros={hideZeros}/>
                            </div>
                        ))
                    }
                </div> */}
                <div className='row'>
                    <div className='col-md-6'>
                        <div className="row">
                            {
                                document6111ByFinalReportsCode1.map((obj, i) => (
                                    obj.finalReportsCode2 != 99 && obj.finalReportsCode2 <= 201 && <div
                                        key={i}
                                    >
                                        <TbBranchesBalaceSheet data={obj.data} title={obj.finalReportsCode2 + " " + getAccountName(obj.finalReportsCode2)} headers={headers} hideZeros={hideZeros} />
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className="row">
                            {
                                document6111ByFinalReportsCode1.map((obj, i) => (
                                    obj.finalReportsCode2 != 99 && obj.finalReportsCode2 >= 400 && obj.finalReportsCode2 <= 690 && <div
                                        key={i}
                                    >
                                        <TbBranchesBalaceSheet data={obj.data} title={obj.finalReportsCode2 + " " + getAccountName(obj.finalReportsCode2)} headers={headers} hideZeros={hideZeros} />
                                    </div>
                                ))
                            }
                        </div>
                    </div>


                </div>
                <div className='row'>
                    {
                        document6111ByFinalReportsCode1
                            // Filter the array to include only objects with finalReportsCode2 as 399 or 699
                            .filter(obj => obj.finalReportsCode2 === 399 || obj.finalReportsCode2 === 699)
                            .map((obj, i) => (
                                <div key={i} className="col-md-6 ">
                                    <TbBranchesBalaceSheet
                                        data={obj.data}
                                        title={obj.finalReportsCode2 + " " + getAccountName(obj.finalReportsCode2)}
                                        headers={headers}
                                        hideZeros={hideZeros}
                                    />
                                </div>
                            ))
                    }
                </div>

                <div className="row ">
                    <hr className=" border-prim mb-4" />

                    {
                        document6111ByFinalReportsCode1.map((obj, i) => (
                            obj.finalReportsCode2 != 99 && obj.finalReportsCode2 > 699 && <div
                                key={i}
                                className="col-md-12  ">
                                <TbBranchesBalaceSheet data={obj.data} title={obj.finalReportsCode2 + " " + getAccountName(obj.finalReportsCode2)} headers={headers} hideZeros={hideZeros} />
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}
