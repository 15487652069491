import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyAsZ2KZDIvgi32QWsxYk5--GhIscdHPT6w",
  authDomain: "fin-trackl.firebaseapp.com",
  projectId: "fin-trackl",
  storageBucket: "fin-trackl.appspot.com",
  messagingSenderId: "799217789710",
  appId: "1:799217789710:web:4c665947a2605e4cc0855f",
  measurementId: "G-DCFQY3JTCL",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);
// const functions = getFunctions(app);

export { db, auth, storage };
