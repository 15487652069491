import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import { useSelector } from "react-redux";
import { iniReader, bkmvReader } from "../../../../utils/openformat/readFile";
import { Link } from "react-router-dom";
import Select from "react-select";
import useToasts from "../../Accounting/components/Toasts";
import { file126Retreive } from "../../../../utils/s126/read126File";
import iconv from 'iconv-lite';
import { arrayToObject126 } from "../../../../utils/s126/arrays126";

const Record10 = () => {
  const { toastrSuccess, toastrError } = useToasts();
  const salary = useSelector((state) => state.salary);
  const companyData = salary.type10

  // console.log(arrayToObject126(companyData))
  return (
    <>
      <div className="page-wrapper">

        <div className="content container-fluid">

          <Breadcrumbs
            maintitle="سجل 10 "
            title="سجلات"
            subtitle="سجل 10"
          />

          {/* <div className="file-cont-wrap">
            <div className="file-cont-inner">
              <div className="file-cont-header">
                <span>نوع التكويد</span>
                <Select
                  placeholder="اختر نوع التكويد"
                  onChange={hahdleSelectOption}
                  options={options}
                  styles={customStyles}
                />
                <button
                  className="btn btn-primary "
                  onClick={handleUploadFiles}>
                  رفع الملفات
                </button>
              </div>
            </div>
          </div>
          <div className="file-cont-wrap">
            <div className="file-cont-inner">
              <div className="file-cont-header">
                <div className="file-options">
                  <Link
                    to="#"
                    id="file_sidebar_toggle"
                    className="file-sidebar-toggle">
                    <i className="fa-solid fa-bars" />
                  </Link>
                </div>
                <span>تحميل ملف 126</span>
                <div className="file-options">
                  <span className="btn-file">
                    <input
                      type="file"
                      className="upload"
                      onChange={(e) => setFile(e.target.files[0])}
                    />
                    <i className="fa-solid fa-upload" />
                  </span>
                </div>
              </div>
            </div>
          </div>
 */}


          {/* <form style={{marginBlock:"50px"}}>
            <div className="row">
              <div className="col-sm-6">
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    اسم الشركة <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text" readOnly
                    defaultValue="MassDev's Technologies"
                    value={companyData?.employerName}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="input-block mb-3">
                  <label className="col-form-label">رقم ملف الاستقطاع الضريبي</label>
                  <input
                    className="form-control "
                    defaultValue="000000000"
                    type="text" readOnly
                    value={companyData?.taxFileNumber}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 col-md-6 col-lg-4">
                <div className="input-block mb-3">
                  <label className="col-form-label">العنوان</label>
                  <input
                    className="form-control "
                    defaultValue="CANADA"
                    type="text" readOnly
                    value={`${companyData?.street} ${companyData?.buildingNumber}`}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-3">
                <div className="input-block mb-3">
                  <label className="col-form-label">الدولة</label>
                  <input
                    className="form-control"
                    defaultValue="CANADA"
                    type="text" readOnly
                    value={companyData?.businessAddressCity}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-4">
                <div className="input-block mb-3">
                  <label className="col-form-label">المدينة</label>
                  <input
                    className="form-control"
                    defaultValue="JLM"
                    type="text" readOnly
                    value={companyData?.city}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-3">
                <div className="input-block mb-3">
                  <label className="col-form-label">State/Province</label>
                  <input
                    className="form-control"
                    defaultValue="JLM"
                    type="text" readOnly
                  />
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-4">
                <div className="input-block mb-3">
                  <label className="col-form-label">الرمز البريدي</label>
                  <input
                    className="form-control"
                    defaultValue={91403}
                    type="text" readOnly
                    value={companyData?.postalCode}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="input-block mb-3">
                  <label className="col-form-label">Email</label>
                  <input
                    className="form-control"
                    defaultValue="support@massarwe.com"
                    type="email"
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="input-block mb-3">
                  <label className="col-form-label">Phone Number</label>
                  <input
                    className="form-control"
                    defaultValue="02.623.1666"
                    type="text" readOnly
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="input-block mb-3">
                  <label className="col-form-label">رقم الهاتف</label>
                  <input
                    className="form-control"
                    defaultValue="054.543.1666"
                    type="text" readOnly
                    value={companyData?.phone}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="input-block mb-3">
                  <label className="col-form-label">Fax</label>
                  <input
                    className="form-control"
                    defaultValue="02.6250648"
                    type="text" readOnly
                    value={companyData?.fax}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="input-block mb-3">
                  <label className="col-form-label">Website Url</label>
                  <input
                    className="form-control"
                    defaultValue="https://massbill.com/"
                    type="text" readOnly
                  />
                </div>
              </div>
            </div>
            <div className="submit-section">
              <button className="btn btn-primary submit-btn">
                تحميل ملف جديد
              </button>
            </div>
          </form> */}

          <form >
            <div className="row">
              {
                companyData.map((item, index) => (
                  <div className="col-sm-6" key={index}>
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        {item.ar}<span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text" readOnly
                        defaultValue="MassDev's Technologies"
                        value={item.value}
                      />
                    </div>
                  </div>
                ))
              }

            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default Record10;
