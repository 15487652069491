import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Applogo } from "../../../Routes/ImagePath";
import { useSelector } from "react-redux";
import {
  formatNumbers,
  formatPhone,
  getDate,
} from "../../../utils/openformat/numbers";
import { useTranslation } from "react-i18next";

const AhidInvoiceView = () => {
  const { t } = useTranslation();
  const document = useSelector((state) => state.ahid).currentDocument;
  const company = useSelector((state) => state.ahid).iniFile?.headerList;
  const type =
    document?.header?.documentType === 320 ||
    document?.header?.documentType === 400
      ? 2
      : 1;

  console.log(document);
  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid print-no-margins">
          {/* Page Header */}
          <div className="page-header hide-on-print ">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Invoice</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin-dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">عرض المستندات</li>
                </ul>
              </div>
              <div className="col-auto float-end ms-auto">
                <div className="btn-group btn-group-sm">
                  <button className="btn btn-white">CSV</button>
                  {/* <button className="btn btn-white">PDF</button> */}
                  <button className="btn btn-white">
                    <i
                      className="fa-solid fa-print fa-lg"
                      onClick={() => window.print()}
                    />{" "}
                    Print / PDF
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row " id="print-absolute">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-6 m-b-20">
                      <img src={Applogo} className="inv-logo" alt="Logo" />
                      <ul className="list-unstyled">
                        <li>{company?.businessName}</li>
                        <li>
                          {`${company?.businessAddressStreet} ${company?.businessAddressHouseNumber}`}
                          ,
                        </li>
                        <li>{`${company?.businessAddressCity}, ${company?.businessAddressPostalCode}`}</li>
                        <li>{company?.Identification}</li>
                      </ul>
                    </div>
                    <div className="col-sm-6 m-b-20">
                      <div className="invoice-details">
                        <h3 className="text-uppercase">
                          #{document?.header?.documentNumber}
                        </h3>
                        <ul className="list-unstyled">
                          <li>
                            <span>{t("copy")}</span>
                          </li>
                          <li>
                            {t("date")}:{" "}
                            <span>
                              {getDate(document?.header?.documentDate)}
                            </span>
                          </li>
                          <li>
                            {t("dueDate")}:{" "}
                            <span>{getDate(document?.header?.valueDate)}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 col-lg-7 col-xl-8 m-b-20">
                      {/* <h5>Invoice to:</h5> */}
                      <ul className="list-unstyled">
                        <li>
                          <h5>
                            <strong>{document?.header?.custVendName}</strong>
                          </h5>
                        </li>
                        {/* <li>
                          <span>Global Technologies</span>
                        </li> */}
                        <li>{document?.header?.custVendStreet}</li>
                        <li>{`${document?.header?.custVendCity} ${document?.header?.custVendPostalCode}`}</li>
                        <li>{document?.header?.custVendCountry}</li>
                        <li style={{ direction: "ltr" }}>
                          {formatPhone(document?.header?.custVendPhone)}
                        </li>
                        {/* <li>
                          <Link to="#">barrycuda@example.com</Link>
                        </li> */}
                      </ul>
                    </div>
                    <div className="col-sm-6 col-lg-5 col-xl-4 m-b-20">
                      <span className="text-muted">{t("paymentDetails")}:</span>
                      <ul className="list-unstyled invoice-payment-details">
                        <li>
                          <h5>
                            {t("amountToPay")}:{" "}
                            <span className="text-end">
                              {`${formatNumbers(
                                document?.header?.totalWithVat
                              )} ₪`}
                            </span>
                          </h5>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="table-responsive">
                    {type === 1 ? (
                      <>
                        <table className="table table-striped table-hover">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>{t("itemNumber")}</th>
                              <th className="d-none d-sm-table-cell">
                                {t("description")}
                              </th>
                              <th>{t("unitPrice")}</th>
                              <th>{t("quantity")}</th>
                              <th className="text-end">{t("amount")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {document?.details?.map((item) => (
                              <tr>
                                <td>{item.documentRow}</td>
                                <td>{item.internalSku}</td>
                                <td className="d-none d-sm-table-cell">
                                  {item.description}
                                </td>
                                <td>{formatNumbers(item.priceForUnit)}</td>
                                <td>{item.quantity}</td>
                                <td className="text-end">{`${formatNumbers(
                                  item.rowTotal
                                )} ₪`}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <div>
                          <div className="row invoice-payment">
                            <div className="col-sm-7"></div>
                            <div className="col-sm-5">
                              <div className="m-b-20">
                                <div className="table-responsive no-border">
                                  <table className="table mb-0">
                                    <tbody>
                                      <tr>
                                        <th>{t("total")}:</th>
                                        <td className="text-end">{`${formatNumbers(
                                          document?.header?.totalBeforeDiscount
                                        )} ₪`}</td>
                                      </tr>
                                      <tr>
                                        <th>{t("discount")}:</th>
                                        <td className="text-end">{`${formatNumbers(
                                          document?.header?.documentDiscount
                                        )} ₪`}</td>
                                      </tr>
                                      <tr>
                                        <th>{t("netTotal")}:</th>
                                        <td className="text-end">{`${formatNumbers(
                                          document?.header
                                            ?.totalAfterDiscountNoVat
                                        )} ₪`}</td>
                                      </tr>
                                      <tr>
                                        <th>
                                          {t("vat")}:{" "}
                                          <span className="text-regular">
                                            (17%)
                                          </span>
                                        </th>
                                        <td className="text-end">{`${formatNumbers(
                                          document?.header?.vatAmount
                                        )} ₪`}</td>
                                      </tr>
                                      <tr>
                                        <th>{t("toPay")}:</th>
                                        <td className="text-end text-primary">
                                          <h5>{`${formatNumbers(
                                            document?.header?.totalWithVat
                                          )} ₪`}</h5>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="invoice-info">
                            <h5>{t("otherInfo")}</h5>
                            <p className="text-muted">{t("documentNote")}</p>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <table className="table table-striped table-hover">
                          <thead>
                            <tr>
                              {/* <th>#</th> */}
                              <th>{t("paymentMethod")}</th>
                              <th className="d-none d-sm-table-cell">
                                {t("bank")}
                              </th>
                              <th>{t("branch")}</th>
                              <th>{t("dueDate")}</th>
                              <th>{t("checkNumber")}</th>
                              <th className="text-end">{t("amount")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {document?.details?.map((item) => (
                              <tr>
                                {/* <td>{item.documentRow}</td> */}
                                <td>{item.paymentMethod}</td>
                                <td>{item.bankCode}</td>
                                <td className="d-none d-sm-table-cell">
                                  {item.branchId}
                                </td>
                                <td>{getDate(item.paymentDueDate)}</td>
                                <td>{item.chequeNumber}</td>
                                <td className="text-end">{`${formatNumbers(
                                  item.rowTotal
                                )} ₪`}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <div>
                          <div className="row invoice-payment">
                            <div className="col-sm-7"></div>
                            <div className="col-sm-5">
                              <div className="m-b-20">
                                <div className="table-responsive no-border">
                                  <table className="table mb-0">
                                    <tbody>
                                      <tr>
                                        <th>{t("total")}:</th>
                                        <td className="text-end">{`${formatNumbers(
                                          document?.header?.totalWithVat
                                        )} ₪`}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="invoice-info">
                            <h5>{t("otherInfo")}</h5>
                            <p className="text-muted">{t("documentNote")}</p>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AhidInvoiceView;
