export const arrayToObject = (arr, keyProperty) => {
  if (!arr?.length) return {};
  const created = {};
  arr.forEach((item) => {
    created[item[keyProperty]] = item;
  });
  return created;
};

export const sumArrayByProperty = (arr, property) => {
  let total = 0;
  arr.forEach((item) => (total += item[property]*1));
  return total;
};

export const sumArrayByPropertyValue = (arr, property,value) => {
  let total = 0;
  arr.forEach((item) => {
    if (item[property] == value) total += item[property]
  });
  return total;
};
