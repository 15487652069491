import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { formatNumbers } from '../../../../utils/openformat/numbers'

const useMetrics = () => {
    const {t}=useTranslation()
    const data = useSelector((state) => state.balance).data6111.fullBalanceObj
    
    const list=[
        {
            type:"Current Ratio",
            title:t("Current Ratio"),
            description:t("CurrentRatioD"),
            function:`${t("Current Ratio")} = ${t("CurrentAssets")} ÷ ${t("CurrentLiabilities")}`,
            data:'',
            result:`${formatNumbers(data["7000"]/data["9000"])} `
        },
        {
            type:"Quick Ratio",
            title:t("Quick Ratio"),
            description:t("QuickRatioD"),
            function:`${t("Quick Ratio")} = (${t("CurrentAssets")} - ${t("Inventories")} - ${t("Prepaid Expenses")})÷ ${t("CurrentLiabilities")}`, 
            data:'',
            result:`${formatNumbers((data["7000"] || 0 - data["7800"] || 0 - data["7824"] || 0 )/data["9000"])}`
        },
        {
            type:"Working Capital",
            title:t("Working Capital"),
            description:t("WorkingCapitalD"),
            function:`${t("Working Capital")} = ${t("CurrentAssets")} - ${t("CurrentLiabilities")}`,
            data:'',
            result:formatNumbers(data["7000"] - data["9000"])
        },
        {
            type:"Debt/Equity",
            title:t("Debt/Equity"),
            description:t("DebtEquityD"),
            function:`${t("Debt/Equity")} = ${t("Total Liabilities")} ÷ ${t('Shareholders')}`,
            data:'',
            result:`${formatNumbers((data["9999"] - data['9900'] || 0)/data['9900'] || 1)} `
        },
    ]

    const [display,setDisplay]=useState(list)

    

   

const filterDisplay=(type)=>{
    if(!type)setDisplay(list)
        else setDisplay(list.filter(item=>item.type==type))
}

  return {
    filterDisplay,
    display,list
  }
}

export default useMetrics
