import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { useSelector } from "react-redux";
import { iniReader, bkmvReader } from "../../../utils/openformat/readFile";
import { Link } from "react-router-dom";
import Select from "react-select";
import useToasts from "../Accounting/components/Toasts";
const OpenForamt = () => {
  const { toastrSuccess, toastrError } = useToasts();
  const ahid = useSelector((state) => state.ahid);
  const [iniFile, setIniFile] = useState(null);
  const [bkmvFile, setBkmvFile] = useState(null);
  const [codingType, setCodingType] = useState("");

  const handleIniFileUpload = () => {
    const reader = new FileReader();
    reader.readAsText(iniFile, codingType);
    reader.onload = () => {
      iniReader(reader.result);
    };
    reader.onerror = () => {
      console.log("Error");
    };
  };

  console.log("Company Name: ", ahid.iniFile?.headerList?.businessName);
  const handleBKMVFileUpload = () => {
    const reader = new FileReader();
    reader.readAsText(bkmvFile, codingType);
    reader.onload = () => {
      bkmvReader(reader.result);
    };

    reader.onerror = () => {
      console.log("Error");
    };
  };

  const options = [
    {
      value: "ISO-8859-8",
      label: "Windows ISO-8859-8",
    },
    {
      // value: "CP862",
      value: "CP-862",
      label: "Dos CP-862",
    },
  ];

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };

  const hahdleSelectOption = (item) => {
    setCodingType(item.value);
  };

  const handleUploadFiles = () => {
    if (!iniFile || !bkmvFile || !codingType) {
      // alert("الرجاء اختيار نوع التكويد واختيار الملفات");
      toastrError("الرجاء اختيار نوع التكويد واختيار الملفات");
      return;
    } else if (iniFile.name.toLowerCase() !== "ini.txt") {
      // alert("تأكد من تحميل ملف INI.txt في المكان المناسب");
      toastrError("تأكد من تحميل ملف INI.txt في المكان المناسب");
      return;
    } else if (bkmvFile.name.toLowerCase() !== "bkmvdata.txt") {
      // alert("تأكد من تحميل ملف BKMVDATA.txt في المكان المناسب");
      toastrError("تأكد من تحميل ملف BKMVDATA.txt في المكان المناسب");
      return;
    }

    handleIniFileUpload();
    handleBKMVFileUpload();
    toastrSuccess("تم استيراد البيانات بنجاح!");
  };

  console.log(codingType);
  return (
    <>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs
            maintitle="Open Format"
            title="Dashboard"
            subtitle="Open Format"
          />
          {/* /Page Header */}
          {/* Content Starts */}
          <div className="file-cont-wrap">
            <div className="file-cont-inner">
              <div className="file-cont-header">
                <span>نوع التكويد</span>
                <Select
                  placeholder="اختر نوع التكويد"
                  onChange={hahdleSelectOption}
                  options={options}
                  styles={customStyles}
                />
                <button
                  className="btn btn-primary "
                  onClick={handleUploadFiles}>
                  رفع الملفات
                </button>
              </div>
            </div>
          </div>
          <div className="file-cont-wrap">
            <div className="file-cont-inner">
              <div className="file-cont-header">
                <div className="file-options">
                  <Link
                    to="#"
                    id="file_sidebar_toggle"
                    className="file-sidebar-toggle">
                    <i className="fa-solid fa-bars" />
                  </Link>
                </div>
                <span>تحميل ملف INI</span>
                <div className="file-options">
                  <span className="btn-file">
                    <input
                      type="file"
                      className="upload"
                      onChange={(e) => setIniFile(e.target.files[0])}
                    />
                    <i className="fa-solid fa-upload" />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="file-cont-wrap">
            <div className="file-cont-inner">
              <div className="file-cont-header">
                <div className="file-options">
                  <Link
                    to="#"
                    id="file_sidebar_toggle"
                    className="file-sidebar-toggle">
                    <i className="fa-solid fa-bars" />
                  </Link>
                </div>
                <span>تحميل ملف BKMVDATA</span>
                <div className="file-options">
                  <span className="btn-file">
                    <input
                      type="file"
                      className="upload"
                      onChange={(e) => setBkmvFile(e.target.files[0])}
                    />
                    <i className="fa-solid fa-upload" />
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="row">
            {ahid.iniFile?.recordsList &&
              Object.keys(ahid.iniFile?.recordsList).map((key) => (
                <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3" key={key}>
                  <div className="card dash-widget">
                    <div className="card-body">
                      <span className={`dash-widget-icon fa fa-usd`} />
                      <div className="dash-widget-info">
                        <h3>{key}</h3>
                        <span>{ahid.iniFile?.recordsList[key]}</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div> */}
          <form>
            <div className="row">
              <div className="col-sm-6">
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    اسم الشركة <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    defaultValue="MassDev's Technologies"
                    value={ahid.iniFile?.headerList?.businessName}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="input-block mb-3">
                  <label className="col-form-label">المشتغل المرخص</label>
                  <input
                    className="form-control "
                    defaultValue="000000000"
                    type="text"
                    value={ahid.iniFile?.headerList?.Identification}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="input-block mb-3">
                  <label className="col-form-label">العنوان</label>
                  <input
                    className="form-control "
                    defaultValue="CANADA"
                    type="text"
                    value={`${ahid.iniFile?.headerList?.businessAddressStreet} ${ahid.iniFile?.headerList?.businessAddressHouseNumber}`}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-3">
                <div className="input-block mb-3">
                  <label className="col-form-label">الدولة</label>
                  <input
                    className="form-control"
                    defaultValue="CANADA"
                    type="text"
                    value={ahid.iniFile?.headerList?.businessAddressCity}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-3">
                <div className="input-block mb-3">
                  <label className="col-form-label">المدينة</label>
                  <input
                    className="form-control"
                    defaultValue="JLM"
                    type="text"
                    value={ahid.iniFile?.headerList?.businessAddressCity}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-3">
                <div className="input-block mb-3">
                  <label className="col-form-label">State/Province</label>
                  <input
                    className="form-control"
                    defaultValue="JLM"
                    type="text"
                  />
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-3">
                <div className="input-block mb-3">
                  <label className="col-form-label">الرمز البريدي</label>
                  <input
                    className="form-control"
                    defaultValue={91403}
                    type="text"
                    value={ahid.iniFile?.headerList?.businessAddressPostalCode}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="input-block mb-3">
                  <label className="col-form-label">Email</label>
                  <input
                    className="form-control"
                    defaultValue="support@massarwe.com"
                    type="email"
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="input-block mb-3">
                  <label className="col-form-label">Phone Number</label>
                  <input
                    className="form-control"
                    defaultValue="02.623.1666"
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="input-block mb-3">
                  <label className="col-form-label">Mobile Number</label>
                  <input
                    className="form-control"
                    defaultValue="054.543.1666"
                    type="text"
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="input-block mb-3">
                  <label className="col-form-label">Fax</label>
                  <input
                    className="form-control"
                    defaultValue="02.6250648"
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="input-block mb-3">
                  <label className="col-form-label">Website Url</label>
                  <input
                    className="form-control"
                    defaultValue="https://massbill.com/"
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div className="submit-section">
              <button className="btn btn-primary submit-btn">
                تحميل ملف جديد
              </button>
            </div>
          </form>
          {/* /Content End */}
        </div>
        {/* /Page Content */}
      </div>
    </>
  );
};
export default OpenForamt;
