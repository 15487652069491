
import React, { Component } from 'react';
import Holidays from './holidays';
import CutOffs from './cutOffs';
import Attendance from './attendance';
// import { CalculatorRtX } from './Calculator-return-tax';
// import {} from "../../../Entryfile/imagepath"
import { Applogo } from '../../../../../../Routes/ImagePath';
// from "../../Routes/ImagePath";

class T106 extends Component {
  render() {
    return (
      <div className="page-wrapper">
       
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">قسيمة راتب</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item"><a href="/">الرئيسية </a></li>
                </ul>
              </div>
              <div className="col-auto float-right ml-auto">
                <div className="btn-group btn-group-sm">
                  <button className="btn btn-white">CSV</button>
                  <button className="btn btn-white">PDF</button>
                  <button className="btn btn-white"><i className="fa fa-print fa-lg" /> Print</button>
                </div>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="payslip-custom-title"><span>قسيمة راتب لشهر 4-2022</span></h4>
                  <div className="row">

                  </div>
                  <div className="row">
                  <div className="col-sm-6 m-b-20 ">
                      {/*  div-border */}
                      <div className="invoice-custom-details">
                        <br></br>

                     
                        <ul className="list-unstyled">
                          <li><h3 className="text-uppercase">محمد عبدالله</h3></li>
                          <li>رقم الموظف : <span> 12</span></li>
                          <li>رقم القسم : <span> 50</span></li>
                          <li><span> محاسب</span></li>
                          <li >رقم الهوية : 999 999 9980</li>
                          {/* <li>Joining Date: 1 Jan 2013</li> */}
                        </ul>


                      </div>

                    </div>

                    <div className="col-sm-6 m-b-20 text-left">
                      <img src={Applogo} className="inv-custom-logo" alt="" />
                      <ul className="list-unstyled mb-0 ">
                        <table className='lst'>
                          <tr>
                            <td className='logo-title'>FinTrack</td>

                          </tr>
                          <tr>
                            <td className='lst'>القدس</td>

                          </tr>
                          <tr>
                            <td className='lst'>القلعة 3  </td>

                          </tr>
                       
                          <tr>
                            <td>ملف اقتطاعات : <span> 945111222</span>  </td>

                          </tr>
                          <tr>
                            <td>رقم الشركة : <span> 51555555</span>  </td>

                          </tr>
                        </table>

                      </ul>
                      
                    </div>
                 
                    <div className="col-sm-6 m-b-20">
                      <h4 className="text-uppercase"> تاريخ الالتحاق  : <span> 01-11-2021 </span></h4>
                    </div>
                    <div className="col-sm-6 m-b-20"></div>
                    <div className="col-sm-6 m-b-20 inv-custom-div">
                    <h4 className="text-uppercase"> الأقدمية :  </h4>
                     
                      <table className="table table-borderless t-inv mb-5">
                        <thead>
                          <tr>
                            <th>يوم</th>
                            <th>شهر</th>
                            <th>سنة</th>
                          </tr>
                          <tr>
                            <th>0</th>
                            <th>6</th>
                            <th>0</th>
                          </tr>
                        </thead>
                      </table>
                    </div>
                    <div className="col-sm-6 m-b-20 inv-custom-div">
                    </div>
                    {/* <div className="col-sm-6 m-b-20 invoice-details-custom">
                     <div className="det ">
                       <h4 className="text-uppercase "> رقم الموظف   : <span> 12 </span></h4>                    
                     </div>                    
                   </div>
                   <div className="col-sm-6 m-b-20 invoice-details-custom">
                     <div className="det ">
                       <h4 className="text-uppercase "> رقم القسم  : <span> 120 </span></h4>                    
                     </div>                    
                   </div> */}

                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div>
                        <h4 className="mb-3"><strong>علاوات</strong></h4>
                        <table className="table  t-green">
                          <tbody>
                            <tr className='tr-green'>
                              <td><strong>راتب أساسي</strong> <span className="float-left">6500  <span> &#8362; </span> </span></td>
                            </tr>
                            <tr className='tr-green'>
                              <td><strong>سفريات</strong> <span className="float-left">55  <span> &#8362; </span> </span></td>
                            </tr>
                            <tr className='tr-green'>
                              <td><strong> ساعات اضافية </strong> <span className="float-left">55  <span> &#8362; </span> </span></td>
                            </tr>
                            <tr className='tr-green'>
                              <td><strong> أخرى</strong> <span className="float-left">55  <span> &#8362; </span> </span></td>
                            </tr>
                            <tr className='tr-green' >
                              <td><strong>أخرى</strong> <span className="float-left"><strong>55  <span> &#8362; </span> </strong></span></td>
                            </tr>
                            <tr>
                              <td><strong>مجموع العلاوات </strong> <span className="float-left"><strong>59698  <span> &#8362; </span> </strong></span></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div>
                        <h4 className="mb-3"><strong>اقتطاعات</strong></h4>
                        <table className="table t-red">
                          <tbody>
                            <tr className='tr-red'>
                              <td className='pt-3'><strong>ضريبة دخل </strong> <span className="float-left">0  <span> &#8362; </span> </span></td>
                            </tr>
                            <tr className='tr-red'>
                              <td className='pt-3'><strong>تأمين وطني </strong> <span className="float-left">0  <span> &#8362; </span> </span></td>
                            </tr>
                            <tr className='tr-red'>
                              <td className='pt-3'><strong>تأمين صحي</strong> <span className="float-left">0  <span> &#8362; </span> </span></td>
                            </tr>
                            <tr className='tr-red'>
                              <td className='pt-3 pb-3'><strong>صندوق ادخار</strong> <span className="float-left">300  <span> &#8362; </span> </span>
                               
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <strong>مجموع الاقتطاعات</strong> <span className="float-left"><strong>59698  <span> &#8362; </span> </strong></span>
                               
                              </td>

                            </tr>

                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/* <div className="col-sm-6">
                  </div> */}

                    <div className="col-sm-6">
                      <p><strong className=' salary-privacy'>الراتب الصافي :  <span>  59698 &#8362; </span> </strong> </p>
                    </div>
                    <div className="col-sm-6">
                      <div>
                        <p><strong className=' salary-privacy'>الوضع العائلي  :    <span> متزوج </span> </strong> </p>


                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div>
                        <table className="table table-bordered">
                          <tbody>
                            <tr>
                              <td className='pt-3'><p><strong className='privacy'> نقاط التزكية  </strong> <span className="float-left privacy"><strong>2.25   </strong></span></p></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div>
                        <table className="table table-bordered">
                          <tbody>
                            <tr>
                              <td className='pt-3'><p><strong className='privacy'> قيمة نقطة التزكية   </strong> <span className="float-left privacy"><strong>  501   </strong></span></p></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className='row mt-3'>
                    <br></br>
                    <div className="col-md-6">
                      <h4 className="mb-4"><br></br><strong>ادارة الاجازات </strong></h4>
                      <Holidays />
                    </div>
                    <div className="col-md-6">
                      <h4 className="mb-4"><br></br><strong>معطيات متراكمة  </strong></h4>
                      <CutOffs />
                    </div>
                  </div>
                  <div className='row mb-3'>
                    <h4 className="pb-4"><br></br><strong>أشهر العمل   </strong></h4>
                    <Attendance />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </div>
    );
  }
}
export default T106;
