import structures from '../../utils/s126/structure126.json'
import store from "../../store";
import { upload126 } from '../../salary126';

export const file126Retreive = (text) => {
    const data = text.split("\r\n");

    const created = {};
    for (let key in structures) {
        let object = structures[key];


        let list = data.filter((item) => item.substring(11, 13) == key.substring(4, 6));
        console.log(key)
        created[key] = {
            name: key,
            data: retreiveData(list, object),
        };
    }

    store.dispatch(upload126(created))
}

const retreiveData = (arr, structure) => {
    if (!structure) return [];
    const list = [];
    arr.forEach((line) => {
        const records = [];
        structure.forEach((item) => {
            const val =
                item.type == "נומרי"
                    ? line.substring(item.from - 1, item.to).trim() * 1
                    : getDecimals(
                        line.substring(item.from - 1, item.to).trim(),
                        item.tech
                    );


            records.push({
                ar: item.ar,
                en: item.en,
                he: item.he,
                value: item.isText ? reverseHebrewText(val) : val,
                field: item.fieldNumber,
                key:item.key
            });
        });
        list.push(records);
    });

    return list;
};


const getDecimals = (text, type) => {
    let sign = text.substring(0, 1);
    if (sign != "-" && sign != "+") return text;

    let decimalChars = type.split("v")[1].length;
    let numberChars = type.split("(")[1].split(")")[0] * 1;

    let decimal = text.substring(text.length - decimalChars, text.length) * 1;

    let number = text.substring(1, numberChars + 1) * 1;

    let result = number + "." + decimal;
    result = parseFloat(result);
    if (sign == "-") result=result * -1;
    return result;
};

function reverseHebrewText(text) {
    return text.split('').reverse().join('');
    // return text
}