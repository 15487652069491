import alertify from "alertifyjs";

const useToasts = () => {
  const toastrSuccess = (text) => {
    alertify.set("notifier", "position", "top-center");
    alertify.success(text);
  };

  const toastrInfo = (text) => {
    alertify.set("notifier", "position", "top-right");
    alertify.message(text);
  };

  const toastrError = (text) => {
    alertify.set("notifier", "position", "top-right");
    alertify.error(text);
  };

  return {
    toastrSuccess,
    toastrInfo,
    toastrError,
  };
};

export default useToasts;
