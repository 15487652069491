import React, { useEffect, useReducer, useState } from "react";
import "../../../assets/css/print.css";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { useTranslation } from "react-i18next";
import { quickCheckReducer } from "../../../utils/reducers";
import { useSelector } from "react-redux";
import Select from "react-select";
import useToasts from "./components/Toasts";
import { formatNumbers } from "../../../utils/openformat/numbers";
import { checkAllowedCharactersForNumberInput } from "../../../utils/constants";

const FastAutdit = () => {
  const { t } = useTranslation();
  const [accounts, setAccounts] = useState([]);
  const ahid = useSelector((state) => state.ahid).accounts;
  const { toastrSuccess, toastrError } = useToasts();
  //   console.log(ahid);

  const [state, dispatch] = useReducer(quickCheckReducer, {
    tax: 0,
    salaryTax: 0,
    salarySavings: 0,
    bank: 0,
    cash: 0,
    stock: 0,
    share: 0,
    taxLedger: 0,
    salaryTaxLedger: 0,
    salarySavingsLedger: 0,
    bankLedger: 0,
    cashLedger: 0,
    stockLedger: 0,
    shareLedger: 0,
  });

  useEffect(() => {
    acountsCreator();
    const local = window.localStorage.getItem("quickReview");
    dispatch({ type: "fill", payload: JSON.parse(local) });
  }, []);

  const acountsCreator = () => {
    const arr = [];
    for (let key in ahid) {
      const item = ahid[key];
      arr.push({
        value: item.accountId,
        label: `${item.accountId} - ${item.accountName}`,
        total: item.starterAmount + item.debit - item.credit,
      });
    }

    setAccounts(arr);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    window.localStorage.setItem("quickReview", JSON.stringify(state));
    toastrSuccess("تم الحفظ بنجاح!");
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      flex: 4,
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };

  const handleDispatchNumbers = (type, text, target) => {
    if (checkAllowedCharactersForNumberInput(text)) {
      // debugger;
      let num = "";
      if (text.includes("-")) {
        console.log(text.indexOf("-"));
        if (text.indexOf("-") == text.length - 1)
          num = text.substring(0, text.length - 1) * -1;
        else if (text.indexOf("-") == 0)
          num = text.substring(1, text.length) * -1;
        else {
          toastrError("بلا تخوت");
          num = 0;
          target.focus();
        }
      } else {
        num = text * 1;
      }
      dispatch({ type, payload: num });
      console.log(num);
    } else {
      toastrError("أرقام فقط");
    }
  };

  const handleDipatchInputs = (type, text) => {
    if (checkAllowedCharactersForNumberInput(text)) {
      dispatch({ type, payload: text });
    } else {
      toastrError("أرقام فقط");
    }
  };

  const handleSelectAccount = (type, item) => {
    dispatch({ type, payload: item.total });
  };

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        {/* Page Header */}
        <div className="hide-on-print">
          <Breadcrumbs
            maintitle={t("fastAudit")}
            title="Dashboard"
            subtitle={t("fastAudit")}
          />
        </div>
        {/* /Page Header */}
        <div className="row" id="print-landscape">
          <div className="col-md-6">
            <div className="card">
              <div className="card-header">
                {/* <h4 className="card-title mb-0"></h4> */}
                <h4 className="card-title mb-0">
                  إدخال معطيات طرف ثالث للتدقيق
                </h4>
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      الدخل المصرح للضرائب
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={state.tax}
                      onChange={(e) =>
                        handleDipatchInputs("tax", e.target.value)
                      }
                      onBlur={(e) =>
                        handleDispatchNumbers("tax", e.target.value, e.target)
                      }
                    />
                  </div>
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      رواتب مصرحة للضرائب
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={state.salaryTax}
                      onChange={(e) =>
                        handleDipatchInputs("salaryTax", e.target.value)
                      }
                      onBlur={(e) =>
                        handleDispatchNumbers(
                          "salaryTax",
                          e.target.value,
                          e.target
                        )
                      }
                    />
                  </div>
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      مصاريف صناديق الادخار
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={state.salarySavings}
                      onChange={(e) =>
                        handleDipatchInputs("salarySavings", e.target.value)
                      }
                      onBlur={(e) =>
                        handleDispatchNumbers(
                          "salarySavings",
                          e.target.value,
                          e.target
                        )
                      }
                    />
                  </div>
                  <div className="input-block mb-3">
                    <label className="col-form-label">رصيد البنك</label>
                    <input
                      type="text"
                      className="form-control"
                      value={state.bank}
                      onChange={(e) =>
                        handleDipatchInputs("bank", e.target.value)
                      }
                      onBlur={(e) =>
                        handleDispatchNumbers("bank", e.target.value, e.target)
                      }
                    />
                  </div>
                  <div className="input-block mb-3">
                    <label className="col-form-label">رصيد الصندوق</label>
                    <input
                      type="text"
                      className="form-control"
                      value={state.cash}
                      onChange={(e) =>
                        handleDipatchInputs("cash", e.target.value)
                      }
                      onBlur={(e) =>
                        handleDispatchNumbers("cash", e.target.value, e.target)
                      }
                    />
                  </div>
                  <div className="input-block mb-3">
                    <label className="col-form-label">رصيد الجرد</label>
                    <input
                      type="text"
                      className="form-control"
                      value={state.stock}
                      onChange={(e) =>
                        handleDipatchInputs("stock", e.target.value)
                      }
                      onBlur={(e) =>
                        handleDispatchNumbers("stock", e.target.value, e.target)
                      }
                    />
                  </div>
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      رصيد رأس المال في مراقب الشركات
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={state.share}
                      onChange={(e) =>
                        handleDipatchInputs("share", e.target.value)
                      }
                      onBlur={(e) =>
                        handleDispatchNumbers("share", e.target.value, e.target)
                      }
                    />
                  </div>

                  <div className="text-end hide-on-print">
                    <button type="submit" className="btn btn-primary ">
                      حفظ
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title mb-0">أرصدة الدفاتر</h4>
              </div>

              <div className="card-body">
                <form action="#">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">رصيد الدخل</label>
                        <Select
                          options={accounts}
                          placeholder="Select"
                          styles={customStyles}
                          onChange={(item) => {
                            handleSelectAccount("taxLedger", item);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">الملبغ</label>
                        <h4>
                          <span>{formatNumbers(state.taxLedger)}</span>{" "}
                          {Math.round(state.tax) ==
                          Math.round(state.taxLedger) ? (
                            <i
                              className="fa fa-check"
                              data-bs-toggle="tooltip"
                              title="fa fa-check"
                            />
                          ) : (
                            <i
                              className="fa fa-times"
                              data-bs-toggle="tooltip"
                              title="fa fa-times"
                            />
                          )}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">رصيد الرواتب</label>
                        <Select
                          options={accounts}
                          placeholder="Select"
                          styles={customStyles}
                          onChange={(item) => {
                            handleSelectAccount("salaryTaxLedger", item);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">الملبغ</label>
                        <h4>
                          <span>{formatNumbers(state.salaryTaxLedger)}</span>{" "}
                          {Math.round(state.salaryTax) ==
                          Math.round(state.salaryTaxLedger) ? (
                            <i
                              className="fa fa-check"
                              data-bs-toggle="tooltip"
                              title="fa fa-check"
                            />
                          ) : (
                            <i
                              className="fa fa-times"
                              data-bs-toggle="tooltip"
                              title="fa fa-times"
                            />
                          )}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">
                          مصاريف صناديق الادخار
                        </label>
                        <Select
                          options={accounts}
                          placeholder="Select"
                          styles={customStyles}
                          onChange={(item) => {
                            handleSelectAccount("salarySavingsLedger", item);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">الملبغ</label>
                        <h4>
                          <span>
                            {formatNumbers(state.salarySavingsLedger)}
                          </span>{" "}
                          {Math.round(state.salarySavings) ==
                          Math.round(state.salarySavingsLedger) ? (
                            <i
                              className="fa fa-check"
                              data-bs-toggle="tooltip"
                              title="fa fa-check"
                            />
                          ) : (
                            <i
                              className="fa fa-times"
                              data-bs-toggle="tooltip"
                              title="fa fa-times"
                            />
                          )}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">رصيد البنك</label>
                        <Select
                          options={accounts}
                          placeholder="Select"
                          styles={customStyles}
                          onChange={(item) => {
                            handleSelectAccount("bankLedger", item);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">الملبغ</label>
                        <h4>
                          <span>{formatNumbers(state.bankLedger)}</span>{" "}
                          {Math.round(state.bank) ==
                          Math.round(state.bankLedger) ? (
                            <i
                              className="fa fa-check"
                              data-bs-toggle="tooltip"
                              title="fa fa-check"
                            />
                          ) : (
                            <i
                              className="fa fa-times"
                              data-bs-toggle="tooltip"
                              title="fa fa-times"
                            />
                          )}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">رصيد الصندوق</label>
                        <Select
                          options={accounts}
                          placeholder="Select"
                          styles={customStyles}
                          onChange={(item) => {
                            handleSelectAccount("cashLedger", item);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">الملبغ</label>
                        <h4>
                          <span>{formatNumbers(state.cashLedger)}</span>{" "}
                          {Math.round(state.cash) ==
                          Math.round(state.cashLedger) ? (
                            <i
                              className="fa fa-check"
                              data-bs-toggle="tooltip"
                              title="fa fa-check"
                            />
                          ) : (
                            <i
                              className="fa fa-times"
                              data-bs-toggle="tooltip"
                              title="fa fa-times"
                            />
                          )}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">رصيد الجرد</label>
                        <Select
                          options={accounts}
                          placeholder="Select"
                          styles={customStyles}
                          onChange={(item) => {
                            handleSelectAccount("stockLedger", item);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">الملبغ</label>
                        <h4>
                          <span>{formatNumbers(state.stockLedger)}</span>{" "}
                          {Math.round(state.stock) ==
                          Math.round(state.stockLedger) ? (
                            <i
                              className="fa fa-check"
                              data-bs-toggle="tooltip"
                              title="fa fa-check"
                            />
                          ) : (
                            <i
                              className="fa fa-times"
                              data-bs-toggle="tooltip"
                              title="fa fa-times"
                            />
                          )}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">رصيد رأس المال</label>
                        <Select
                          options={accounts}
                          placeholder="Select"
                          styles={customStyles}
                          onChange={(item) => {
                            handleSelectAccount("shareLedger", item);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">الملبغ</label>
                        <h4>
                          <span>{formatNumbers(state.shareLedger)}</span>{" "}
                          {Math.round(state.share) ==
                          Math.round(state.shareLedger) ? (
                            <i
                              className="fa fa-check"
                              data-bs-toggle="tooltip"
                              title="fa fa-check"
                            />
                          ) : (
                            <i
                              className="fa fa-times"
                              data-bs-toggle="tooltip"
                              title="fa fa-times"
                            />
                          )}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="text-end hide-on-print">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={() => window.print()}>
                      طباعة
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FastAutdit;
