import React from "react";
import Breadcrumbs from "../../../../../components/Breadcrumbs";
import { useSelector } from "react-redux";

import useToasts from "../../../Accounting/components/Toasts";
import { arrayToObject126 } from "../../../../../utils/s126/arrays126";

const Record40 = () => {
  const { toastrSuccess, toastrError } = useToasts();
  const salary = useSelector((state) => state.salary);
  const companyData = salary.type40[0]

  // console.log("copmanyData type40",arrayToObject126(companyData))
  return (
    <>
      <div className="page-wrapper">

        <div className="content container-fluid">

          <Breadcrumbs
            maintitle="40 "
            title="سجلات"
            subtitle="40"
          />

          <form >
            <div className="row">
              {
                companyData.map((item, index) => (
                  <div className="col-sm-6" key={index}>
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        {item.ar}<span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text" readOnly
                        defaultValue="MassDev's Technologies"
                        value={item.value}
                      />
                    </div>
                  </div>
                ))
              }

            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default Record40;
