import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import { useSelector } from "react-redux";
import { iniReader, bkmvReader } from "../../../../utils/openformat/readFile";
import { Link } from "react-router-dom";
import Select from "react-select";
import useToasts from "../../Accounting/components/Toasts";
import { file126Retreive } from "../../../../utils/s126/read126File";
import iconv from 'iconv-lite';
import { arrayToObject126 } from "../../../../utils/s126/arrays126";
import { Table } from "antd";

const Record10Dev = () => {
  const { toastrSuccess, toastrError } = useToasts();
  const salary = useSelector((state) => state.salary);
  const companyData = salary.type10
const columns = [
  
    {
      title: "سطر",
      dataIndex: "",
      render: (text,record,index) => <p className="text-info">{index}</p>,
    },
    {
      title: "الاسم",
      dataIndex: "ar",
    },
    {
      title: "المفتاح",
      dataIndex: "key",
      sorter: (a, b) => a.department.length - b.department.length,
    },
    {
      title: "القيمة",
      dataIndex: "value",
      sorter: (a, b) => a.designation.length - b.designation.length,
    },
    
  ];
  return (
    <>
      <div className="page-wrapper">

        <div className="content container-fluid">

          <Breadcrumbs
            maintitle="سجل 10 "
            title="سجلات"
            subtitle="companyData.key"
          />

          <Table
            className="table-striped"
            style={{ overflowX: "auto" }}
            columns={columns}
            dataSource={companyData}
            rowKey={(record) => record.id}
            pagination={false}
          />
        </div>
      </div>
    </>
  );
};
export default Record10Dev;
