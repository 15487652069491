import React, { useEffect, useState } from "react";
import { Table } from "antd";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import TicketModelPopup from "../../../components/modelpopup/TicketModelPopup";
import Breadcrumbs from "../../../components/Breadcrumbs";
import TicketFilter from "../../../components/TicketFilter";
import { useSelector } from "react-redux";
import {
  formatNumbers,
  sumArrayProperties,
} from "../../../utils/openformat/numbers";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
// import AccountingFilter from "./components/AccountingFilter";
import accountingStructures from "../../../utils/openformat/accounting_structures.json";
import OpenFormatHeader from "../OpenForamt/OpenFormatHeader";
const AccountingIndex = () => {
  const { t } = useTranslation();
  const [totals, setTotals] = useState([]);
  const [data, setData] = useState([]);
  const ahid = useSelector((state) => state.ahid);

  const [categories, setCategories] = useState([]);
  //   const data = useSelector((state) => state.ahid).bkmvFile.Accounts.data;
  //   console.log(data);

  useEffect(() => {
    totalToUI();
    handleRecreateAccountingStructure();
  }, []);

  const handleRecreateAccountingStructure = () => {
    const arr = [];
    for (let key in accountingStructures.rivhit_classifications) {
      const item = accountingStructures.rivhit_classifications[key];
      arr.push({
        value: item.key,
        label: `${item.key} - ${item.ar}`,
      });
    }

    setCategories(arr);
  };

  const totalToUI = () => {
    let obj = accountingStructures.classifications || [];
    const list = [];
    for (let key in obj) {
      list.push(obj[key]);
    }
    setData(list);
  };

  const columns = [
    {
      title: "الكود",
      dataIndex: "key",

      sorter: (a, b) => a.key - b.key,
    },

    {
      title: "الاسم",
      dataIndex: "ar",
      sorter: (a, b) => a.ar < b.ar,
    },
    {
      title: "إنجليزي",
      dataIndex: "en",

      sorter: (a, b) => a.en < b.en,
    },
    {
      title: "عبري",
      dataIndex: "he",

      sorter: (a, b) => a.he < b.he,
    },
  ];

  return (
    <>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs
            maintitle={t("accountingIndex")}
            title={t("dashboard")}
            subtitle={t("accountingIndex")}
            // modal="#add_ticket"
            // name="Add Ticket"
          />
          <OpenFormatHeader />
          <div className="row">
            <div className="col-md-12">
              <div className="card-group m-b-30">
                {totals?.map((total, index) => (
                  <div className="card" key={index}>
                    <div className="card-body">
                      <div className="d-flex justify-content-between mb-3">
                        <div>
                          <span className="d-block">{total.title}</span>
                        </div>
                        <div>
                          <span
                            className={
                              total.percentage.includes("-")
                                ? "text-danger"
                                : "text-success"
                            }>
                            {total.percentage}
                          </span>
                        </div>
                      </div>
                      <h3 className="mb-3">{total.value}</h3>
                      <div className="progress mb-2" style={{ height: "5px" }}>
                        <div
                          className="progress-bar bg-primary"
                          role="progressbar"
                          style={{ width: "70%" }}
                          aria-valuenow={40}
                          aria-valuemin={0}
                          aria-valuemax={100}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* <AccountingFilter
            categoryList={categories}
            setCurrentCategory={handleFilterByCategory}
          /> */}

          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <Table
                  className="table-striped"
                  rowKey={(record) => record.id}
                  style={{ overflowX: "auto" }}
                  columns={columns}
                  dataSource={data?.length > 0 ? data : []}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <TicketModelPopup />
      <DeleteModal Name="Delete Ticket" />
    </>
  );
};

export default AccountingIndex;
