import { useState } from "react";
import { collection, doc, setDoc, getDoc } from "firebase/firestore";
import {
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  updateProfile,
  updatePhoneNumber,
  sendPasswordResetEmail,
} from "firebase/auth";

import { useDispatch } from "react-redux";
import { login, logout } from "../user";

import { auth, db } from "../utils/Firebase";

const useAuthentication = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(true);

  const getCurrentUser = () => {
    onAuthStateChanged(auth, async (user) => {
      const token = await user?.getIdTokenResult();
      if (user) {
        const userdata = {
          email: user.email,
          displayName: user.displayName,
          uid: user.uid,
          admin: token.claims.admin,
          isVerified: user.emailVerified,
        };
        console.log(user);
        await getUserProfile(userdata);
        return true;
      } else {
        return false;
      }
    });
  };

  const getUserProfile = async (userdata) => {
    const docRef = doc(db, "users", userdata.uid);
    try {
      const user = await getDoc(docRef);
      const data = user.data();
      console.log(data);
      if (data) {
        dispatch(login({ ...data, ...userdata }));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const signin = async (email, password) => {
    setLoading(true);
    try {
      const userCredentials = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );

      if (userCredentials) {
        getCurrentUser();
      }
      setLoading(false);
      return "";
    } catch (err) {
      setStatus(false);
      setLoading(false);
      return err.message;
      //   dispatch(
      //     show({
      //       title: "فشل تسجيل الدخول",
      //       message: "تأكد من صحة البريد الإلكتروني وكلمة المرور",
      //       class: "primary",
      //     })
      //   );
    }
  };

  const signup = async (email, password, displayName, phoneNumber) => {
    let msg = "";
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      if (userCredential) {
        const user = userCredential.user;
        await updateProfile(auth.currentUser, {
          displayName,
        });
        // await updatePhoneNumber(auth.currentUser, {
        //   phoneNumber: `+972${phoneNumber}`,
        // });
        await sendEmailVerification(user);
        const colRef = collection(db, "users");
        const result = await setDoc(doc(colRef, user.uid), {
          email,
          phone: phoneNumber,
        });
        msg = "إفحص البريد الإلكتروني من أجل تفعيل الحساب.";
      }
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      msg = " تأكد من صحة البريد الإلكتروني وكلمة المرور.";
    }

    // createUserWithEmailAndPassword(auth, email, password)
    //   .then(async (userCredential) => {
    //     // Signed up
    //     const user = userCredential.user;
    //     await sendEmailVerification(user);
    //     msg = "إفحص البريد الإلكتروني من أجل تفعيل الحساب.";
    //     // ...
    //   })
    //   .catch((error) => {
    //     const errorCode = error.code;
    //     const errorMessage = error.message;
    //     msg = errorMessage;
    //     // ..
    //   });

    console.log(msg);
    return msg;
  };

  const signout = async () => {
    await signOut(auth);
    dispatch(logout());

    // signOut(auth)
    //   .then(() => {
    //     dispatch(logout());
    //   })
    //   .catch((error) => {
    //     alert("error");
    //     console.log("logout error".toUpperCase(), error);
    //   });
  };

  const resetPassword = async (email) => {
    let msg = "";
    try {
      await sendPasswordResetEmail(auth, email);
      msg = "إفحص بريدك الوارد من أجل إعادة تعيين كلمة المرور";
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      msg = error.message;
      // msg = " تأكد من صحة البريد الإلكتروني وكلمة المرور.";
    }

    return msg;
  };

  return { getCurrentUser, signin, signout, signup, resetPassword };
};

export default useAuthentication;
